export const SuccessIcon = ({ width = 20, height = 20 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.256 2.69258C11.6802 1.99045 9.91967 1.81651 8.23695 2.1967C6.55423 2.57689 5.03948 3.49084 3.91862 4.80224C2.79777 6.11364 2.13085 7.75223 2.01734 9.47363C1.90383 11.195 2.34981 12.907 3.28877 14.3542C4.22772 15.8015 5.60935 16.9064 7.22758 17.5042C8.84581 18.1021 10.6139 18.1608 12.2683 17.6717C13.9226 17.1826 15.3745 16.1718 16.4075 14.79C17.4404 13.4083 17.999 11.7297 18 10.0046V9.17715C18 8.62486 18.4477 8.17715 19 8.17715C19.5523 8.17715 20 8.62486 20 9.17715V10.0051C19.9988 12.1616 19.3005 14.2604 18.0093 15.9875C16.7182 17.7147 14.9033 18.9782 12.8354 19.5896C10.7674 20.201 8.55726 20.1276 6.53447 19.3803C4.51168 18.633 2.78465 17.2518 1.61096 15.4428C0.437266 13.6338 -0.120209 11.4938 0.0216768 9.34204C0.163562 7.19029 0.997207 5.14205 2.39828 3.5028C3.79935 1.86354 5.69278 0.721106 7.79619 0.24587C9.89959 -0.229366 12.1003 -0.0119392 14.07 0.865724C14.5745 1.0905 14.8012 1.68168 14.5764 2.18615C14.3517 2.69062 13.7605 2.91736 13.256 2.69258Z"
      fill="#236F38"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.7068 2.09601C20.0975 2.48634 20.0978 3.1195 19.7075 3.51022L10.7075 12.5192C10.52 12.7069 10.2656 12.8124 10.0003 12.8125C9.73499 12.8125 9.48054 12.7072 9.29294 12.5196L6.59294 9.81957C6.20242 9.42905 6.20242 8.79588 6.59294 8.40536C6.98347 8.01483 7.61663 8.01483 8.00716 8.40536L9.99969 10.3979L18.2926 2.09671C18.6829 1.70599 19.3161 1.70568 19.7068 2.09601Z"
      fill="#236F38"
    />
  </svg>
);
