import styled, { css } from 'styled-components';
import Btn from '../../../../../components/Btn';
import { LAYOUT_TYPE } from '../../../../../gql/page/types';
import { colors } from '../../../../../utils/colors';

const BG_PADDING_MAP = {
  [LAYOUT_TYPE.ONE_COL]: '117px 40px 108px',
  [LAYOUT_TYPE.TWO_COL]: '68px 32px',
  [LAYOUT_TYPE.THREE_COL]: '68px 24px',
  [LAYOUT_TYPE.FOUR_COL]: '110px 20px 100px',
  [LAYOUT_TYPE.FIVE_COL]: '119px 16px 100px',
  [LAYOUT_TYPE.SIX_COL]: '119px 16px 100px',
  [LAYOUT_TYPE.SEVEN_COL]: '119px 16px 100px',
  [LAYOUT_TYPE.EIGHT_COL]: '119px 16px 100px',
  [LAYOUT_TYPE.NINE_COL]: '119px 16px 100px',
  [LAYOUT_TYPE.TEN_COL]: '119px 16px 100px',
};

export const AddWidgetContainer = styled.div<{
  col?: boolean;
  type: LAYOUT_TYPE;
  highlight?: boolean;
  isDraggingFrom?: boolean;
}>`
  background: ${({ highlight }) =>
    highlight ? colors.primary5 : 'rgba(233, 235, 242, 0.3)'};
  border-radius: 8px;
  padding: ${({ type }) => BG_PADDING_MAP[type]};
  position: relative;
  display: flex;
  flex-direction: ${({ col }) => (col ? 'column' : 'row')};
  justify-content: space-evenly;
  align-items: center;

  &:hover > .delete-col-btn {
    opacity: 1;
  }

  ${({ isDraggingFrom }) =>
    isDraggingFrom
      ? css`
          & * {
            visibility: hidden;
          }
        `
      : ''}
`;

const CARD_PADDING_MAP = {
  [LAYOUT_TYPE.ONE_COL]: '45px 20px 30px',
  [LAYOUT_TYPE.TWO_COL]: '35px 15px 23px',
  [LAYOUT_TYPE.THREE_COL]: '35px 15px 23px',
  [LAYOUT_TYPE.FOUR_COL]: '25px 10px 16px',
  [LAYOUT_TYPE.FIVE_COL]: '25px 10px 16px',
  [LAYOUT_TYPE.SIX_COL]: '25px 10px 16px',
  [LAYOUT_TYPE.SEVEN_COL]: '25px 10px 16px',
  [LAYOUT_TYPE.EIGHT_COL]: '25px 10px 16px',
  [LAYOUT_TYPE.NINE_COL]: '25px 10px 16px',
  [LAYOUT_TYPE.TEN_COL]: '25px 10px 16px',
};

const CARD_WIDTH_MAP = {
  [LAYOUT_TYPE.ONE_COL]: 280,
  [LAYOUT_TYPE.TWO_COL]: 217,
  [LAYOUT_TYPE.THREE_COL]: 217,
  [LAYOUT_TYPE.FOUR_COL]: 157,
  [LAYOUT_TYPE.FIVE_COL]: 157,
  [LAYOUT_TYPE.SIX_COL]: 157,
  [LAYOUT_TYPE.SEVEN_COL]: 157,
  [LAYOUT_TYPE.EIGHT_COL]: 157,
  [LAYOUT_TYPE.NINE_COL]: 157,
  [LAYOUT_TYPE.TEN_COL]: 157,
};

const CARD_HEIGHT_MAP = {
  [LAYOUT_TYPE.ONE_COL]: 177,
  [LAYOUT_TYPE.TWO_COL]: 137,
  [LAYOUT_TYPE.THREE_COL]: 137,
  [LAYOUT_TYPE.FOUR_COL]: 100,
  [LAYOUT_TYPE.FIVE_COL]: 100,
  [LAYOUT_TYPE.SIX_COL]: 100,
  [LAYOUT_TYPE.SEVEN_COL]: 100,
  [LAYOUT_TYPE.EIGHT_COL]: 100,
  [LAYOUT_TYPE.NINE_COL]: 100,
  [LAYOUT_TYPE.TEN_COL]: 100,
};

const CARD_SVG_HEIGHT_MAP = {
  [LAYOUT_TYPE.ONE_COL]: 67,
  [LAYOUT_TYPE.TWO_COL]: 52,
  [LAYOUT_TYPE.THREE_COL]: 52,
  [LAYOUT_TYPE.FOUR_COL]: 38,
  [LAYOUT_TYPE.FIVE_COL]: 38,
  [LAYOUT_TYPE.SIX_COL]: 38,
  [LAYOUT_TYPE.SEVEN_COL]: 38,
  [LAYOUT_TYPE.EIGHT_COL]: 38,
  [LAYOUT_TYPE.NINE_COL]: 38,
  [LAYOUT_TYPE.TEN_COL]: 38,
};

const CARD_SVG_WIDTH_MAP = {
  [LAYOUT_TYPE.ONE_COL]: 78,
  [LAYOUT_TYPE.TWO_COL]: 60,
  [LAYOUT_TYPE.THREE_COL]: 60,
  [LAYOUT_TYPE.FOUR_COL]: 44,
  [LAYOUT_TYPE.FIVE_COL]: 44,
  [LAYOUT_TYPE.SIX_COL]: 44,
  [LAYOUT_TYPE.SEVEN_COL]: 44,
  [LAYOUT_TYPE.EIGHT_COL]: 44,
  [LAYOUT_TYPE.NINE_COL]: 44,
  [LAYOUT_TYPE.TEN_COL]: 44,
};

export const AddWidgetCard = styled.div<{
  col?: boolean;
  type: LAYOUT_TYPE;
  widthPart: number;
}>`
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0 1.33083px 5.32331px rgba(0, 0, 0, 0.1),
    0 0 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: ${() => CARD_PADDING_MAP[LAYOUT_TYPE.ONE_COL]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.1s ease-in background-color;
  width: ${() => CARD_WIDTH_MAP[LAYOUT_TYPE.ONE_COL]}px;
  min-height: ${() => CARD_HEIGHT_MAP[LAYOUT_TYPE.ONE_COL]}px;

  transform: scale(
    ${({ widthPart }) => {
      const scale = widthPart + 0.3;

      return scale > 1 ? 1 : scale;
    }}
  );

  &:hover {
    background: ${colors.primary5};
  }

  & > svg {
    margin-bottom: 16px;
    width: ${() => CARD_SVG_WIDTH_MAP[LAYOUT_TYPE.ONE_COL]}px;
    height: ${() => CARD_SVG_HEIGHT_MAP[LAYOUT_TYPE.ONE_COL]}px;
  }

  &:not(:last-child) {
    ${({ col }) =>
      css`
        ${col ? 'margin-bottom: 30px' : 'margin-right: 20px'}
      `}
  }
`;

export const DeleteCol = styled(Btn)`
  opacity: 0;
  position: absolute;
  top: 13px;
  right: 13px;
  background-color: transparent !important;
`;
