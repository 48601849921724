import React, { FC, ReactNode, useState } from 'react';
import { SideViewWrapper } from './styles';

type Props = {
  isClosed?: boolean;
  disabled?: boolean;
  children: ReactNode;
};

const TABS_DEFAULT_WIDTH = 400;
const TABS_DEFAULT_MAX_WIDTH = '98%';

const SideView: FC<Props> = ({ children, isClosed, disabled }) => {
  const [width, setWidth] = useState<number>(TABS_DEFAULT_WIDTH);

  return (
    <SideViewWrapper
      disabled={disabled}
      isClosed={isClosed}
      size={{ width: disabled ? '100%' : width, height: '100%' }}
      onResizeStop={(_, __, ___, d) => {
        setWidth((prevWidth) => prevWidth + d.width);
      }}
      maxWidth={disabled ? undefined : TABS_DEFAULT_MAX_WIDTH}
      enable={{
        top: false,
        right: !disabled && !isClosed,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      boundsByDirection
    >
      {children}
    </SideViewWrapper>
  );
};

export default SideView;
