import styled from 'styled-components';
import { colors } from '../../../utils/colors';

export const QueryTableWrapper = styled.div`
  padding: 20px 25px;
  border: 1px solid ${colors.monochromatic5};
  border-radius: 8px;
  margin-top: 12px;
  height: 100%;
  margin-bottom: 20px;
  overflow-y: auto;
`;

export const ResizeHandle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  height: 12px;

  & > div:not(:last-child) {
    margin-bottom: 3px;
  }
`;

export const ResizeHandleLine = styled.div`
  border-top: 1px solid ${colors.monochromatic5};
  height: 0;
  width: 22px;
`;

export const SqlEditorWrapper = styled.div`
  flex: 1;
  height: 100%;
  min-height: 0;
  padding: 14px 8px 14px 0;
  border-radius: 8px;
  border: 1px solid ${colors.monochromatic4};
  margin-bottom: 6px;
`;

export const QueryMainView = styled.div`
  flex: 1;
  width: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;
