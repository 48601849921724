import React, { FC } from 'react';
import { useField } from 'formik';
import { SelectLegacy } from '../Select';

export type Option = {
  value: string | number;
  title: string;
};

type Props = {
  label?: string;
  name: string;
  required?: boolean;
  options: Option[];
  additionalOnChange?: (value: string | number) => void;
  disabled?: boolean;
  placeholder?: string;
  usePortal?: boolean;
};

const FormSelect: FC<Props> = ({
  label,
  name,
  additionalOnChange,
  options,
  placeholder,
  ...props
}) => {
  const [field, meta, utils] = useField(name);

  const isError = !!(meta.touched && meta.error);

  return (
    <SelectLegacy
      label={label}
      error={isError}
      assistiveText={isError ? meta.error : ''}
      options={options}
      selected={
        options.find(({ value }) => field.value === value) ||
        (placeholder ? { value: '', title: placeholder } : options[0])
      }
      onSelect={(selected: Option) => {
        utils.setValue(selected.value);
        additionalOnChange?.(selected.value);
      }}
      {...props}
    />
  );
};

export default FormSelect;
