import styled from 'styled-components';
import { Resizable } from 're-resizable';
import { colors } from '../../utils/colors';

export const SideViewWrapper = styled(Resizable)<{
  isClosed?: boolean;
  disabled?: boolean;
}>`
  padding: 16px;
  display: ${({ isClosed }) => (isClosed ? 'none' : 'flex')};
  flex-direction: column;
  border-right: ${({ disabled }) =>
    disabled ? 'none' : `3px solid ${colors.monochromatic6}`};

  gap: 20px;

  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
`;
