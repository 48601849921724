import React, { FC, Fragment, useMemo, useRef, useState } from 'react';
import { ResizeSensor2 } from '@blueprintjs/popover2';
import { LegendTable, PieWidgetWrapper, PieWrapper } from './styles';
import { useHoversFromData } from '../../utils/hovers';
import { Pie } from '@nivo/pie';
import {
  PieChartConfig,
  WidgetCommonProps,
} from '../../../../gql/widget/types';
import { useFilters } from '../../../../utils/filters';
import { getFormattedValue } from '../../../../utils/format';
import { WIDGET_BREAKPOINTS } from '../../../../utils/common';
import SimpleFilter from '../../../SimpleFilter';
import {  getColorsFromPalette } from '../../../../utils/colors';
import CrossOverContent from '../../components/CrossOverContent';
import GraphStand from '../../../GraphStand';
import {
  ErrorComponents,
  WidgetError,
} from '../../components/WidgetErrorComponents';
import { getIsDA } from '../../../../gql/user/local';
import { useTranslation } from 'react-i18next';

type Props = { data: PieChartConfig } & WidgetCommonProps;

const PieChart: FC<Props> = ({
  data,
  afterTitle,
  filtersVal,
  onFilterChange,
  editProps,
  handleDelete,
  handleReload,
  isEditing,
}) => {
  const [donutSize, setDonutSize] = useState(0);
  const filters = useFilters(data.widgetFilters);
  const sliderContainerRef = useRef<HTMLDivElement | null>(null);
  const hovers = useHoversFromData(data as any);
  const { t } = useTranslation();
  const header = data.title;
  const stand = data.stand;

  const entries = useMemo(() => {
    const pieData = data.data?.[0];

    if (!pieData) {
      return [];
    }

    const { values, labels, type } = pieData;

    return values.map((val, ind) => {
      const label = labels[ind];
      const formattedVal = getFormattedValue(val, type);

      return {
        id: ind,
        value: +val,
        title: label,
        tableInfo: formattedVal,
        hoverEntries: hovers.map(({ title, info }) => ({
          title,
          info: info[ind],
        })),
      };
    });
  }, [data.data, hovers]);

  const isSm = donutSize * 2 <= WIDGET_BREAKPOINTS.SM;
  const isDA = getIsDA();

  const getAlertCase = () => {
    if (editProps?.id && isDA) {
      return ErrorComponents.ALERT_WITH_EDIT;
    }
    if ((!isDA && isEditing) || (isDA && !editProps?.id)) {
      return ErrorComponents.ALERT_WITHOUT_EDIT;
    }
    return ErrorComponents.ALERT_WITH_RELOAD;
  };
  return (
    <ResizeSensor2
      onResize={(entries) => {
        const size = entries[0].contentRect.width / 2;

        setDonutSize(size > 300 ? 300 : size);
      }}
    >
      <div className="widget">
        <SimpleFilter
          header={header}
          filters={filters}
          onFilterSelect={onFilterChange}
          info={data.info}
          className="u-margin-bottom-40"
          value={filtersVal}
          afterTitle={afterTitle}
          sliderContainerRef={sliderContainerRef}
        />
        <PieWidgetWrapper legendPlacement={data.legendPlacement} isSm={isSm}>
          {!entries.length ? (
            <WidgetError
              testId={'incomplete-data'}
              isDA={isDA}
              errorTitle={isDA ? t('error.widget.filter.title') : ''}
              alertCase={getAlertCase()}
              handleReload={handleReload ? handleReload : () => {}}
              handleDelete={handleDelete ? handleDelete : () => {}}
              editProps={
                editProps?.id ? editProps : { id: '', isPreview: false }
              }
            />
          ) : (
            <Fragment>
              <LegendTable
                className="table"
                legendPlacement={data.legendPlacement}
                isSm={isSm}
              >
                {entries.map((e, i) => (
                  <React.Fragment key={`${i}-${e.title}`}>
                    <div className="u-align-center-y u-justify-space-between u-width-100 u-padding-top-10 u-padding-bottom-10">
                      <div className="u-align-center-y">
                        <div
                          className="circle16"
                          style={{ backgroundColor: getColorsFromPalette()[i] }}
                        />
                        <div className="u-margin-left-10 font-14">
                          {e.title}
                        </div>
                      </div>
                      <div className="font-14">{e.tableInfo}</div>
                    </div>
                    {i < entries.length - 1 && (
                      <div className="p-divider__default" />
                    )}
                  </React.Fragment>
                ))}
              </LegendTable>
              <PieWrapper>
                <Pie
                  width={donutSize}
                  height={donutSize}
                  enableArcLabels={false}
                  enableArcLinkLabels={false}
                  colors={getColorsFromPalette()}
                  data={entries}
                  innerRadius={0.833}
                  padAngle={2.4}
                  animate={false}
                  tooltip={({
                    datum: {
                      data,
                      arc: { startAngle, endAngle },
                    },
                  }) => {
                    const inRightHalf = (startAngle + endAngle) / 2 < Math.PI;

                    return (
                      <div
                        className="rv-hint"
                        style={{
                          ...(inRightHalf && {
                            transform: 'translateX(-100%)',
                          }),
                        }}
                      >
                        <div className="colorMonochromatic0 font-14">
                          {data.title}
                        </div>
                        {data.hoverEntries.map((h) => (
                          <CrossOverContent
                            key={`${h.info}-${h.title}`}
                            value={h.info}
                            label={h.title}
                          />
                        ))}
                      </div>
                    );
                  }}
                />
              </PieWrapper>
            </Fragment>
          )}
        </PieWidgetWrapper>
        <div ref={sliderContainerRef} />
        <div className="d-flex u-justify-flex-end">
          <GraphStand stand={stand} />
        </div>
      </div>
    </ResizeSensor2>
  );
};

export default PieChart;
