import { createContext } from 'react';

type ModalContextType = {
  isModalOpen(key?: string): boolean;
  openModal(key?: string): void;
  closeModal(key?: string): void;
};

const ModalContext = createContext<ModalContextType | null>(null);

export default ModalContext;
