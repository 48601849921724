import React, { FC, memo, useState } from 'react';
import { VERSION_TYPE } from '../../gql/dataSource/types';
import { useHistory, useParams } from 'react-router-dom';
import {
  Actions,
  ItemWrapper,
  VersionIcon,
  VersionItem,
  VersionMenu,
} from './styles';
import Btn from '../Btn';
import DeleteVersionModal, { DeletingVersionData } from '../DeleteVersionModal';
import { getBaseRoute } from '../../app/Layout/Sidebar';
import { Metadata } from '../../gql/common/types';
import { ReactComponent as Compare } from '../../assets/icons/compare.svg';
import { format } from 'date-fns';

const TYPE_LABELS = {
  [VERSION_TYPE.LIVE]: 'Live',
  [VERSION_TYPE.DRAFT]: 'Draft',
  [VERSION_TYPE.OLD]: 'Old',
};

export const formatVersionText = ({
  versionType,
  updatedAt,
  updatorFullName,
}: Metadata) =>
  [
    `${TYPE_LABELS[versionType]}`,
    `(${format(new Date(updatedAt), 'dd.MM.yyyy HH:mm')}`,
    updatorFullName ? ` by ${updatorFullName})` : ')',
  ].join(' ');

type Props = {
  versions?: Metadata[];
  onClick?: (version: Metadata) => void;
  onDelete: (versionId: string) => void;
  onDiff?: (versionId: string) => void;
  diffId?: string | null;
  entityName: string;
  deleteLoading: boolean;
  activeId?: string;
};

const VersionsList: FC<Props> = ({
  versions,
  onClick,
  entityName,
  deleteLoading,
  onDelete,
  onDiff,
  diffId,
  activeId,
}) => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [deletingData, setDeletingData] = useState<DeletingVersionData[]>([]);

  return (
    <>
      <VersionMenu>
        {versions?.map((version) => {
          const { versionId, versionType, updatedAt } = version;

          return (
            <ItemWrapper key={versionId}>
              <VersionItem
                active={versionId === (activeId || id)}
                onClick={() => onClick?.(version)}
                hasBorder
              >
                <VersionIcon versionType={versionType} />
                {formatVersionText(version)}
              </VersionItem>
              <Actions>
                {onDiff && versionId !== id && (
                  <Btn
                    iconBtn
                    minimal
                    icon={<Compare />}
                    intent={diffId === versionId ? 'primary' : 'none'}
                    small
                    onClick={() => {
                      onDiff(versionId);
                    }}
                  />
                )}
                <Btn
                  minimal
                  icon="trash"
                  intent="none"
                  small
                  onClick={() => {
                    setDeletingData([
                      {
                        id: versionId,
                        type: versionType,
                        title: updatedAt,
                      },
                    ]);
                  }}
                />
              </Actions>
            </ItemWrapper>
          );
        })}
      </VersionMenu>
      <DeleteVersionModal
        deletingData={deletingData}
        handleClose={() => setDeletingData([])}
        entityName={entityName}
        loading={deleteLoading}
        isVersionList
        onConfirm={async () => {
          try {
            await onDelete(deletingData[0]?.id as string);

            if ((versions?.length as number) <= 1) {
              history.push(getBaseRoute(history.location.pathname));

              return;
            }

            if (deletingData[0]?.id === id) {
              onClick?.(
                versions?.find(
                  (v) => v.versionId !== deletingData[0]?.id,
                ) as Metadata,
              );
            }
          } catch (e) {
          } finally {
            setDeletingData([]);
          }
        }}
      />
    </>
  );
};

export default memo(VersionsList);
