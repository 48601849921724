import { useMutation, useQuery } from '@apollo/client';
import { FC, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { EDIT_PATH } from '../../constants/entities';
import { VERSION_TYPE } from '../../gql/dataSource/types';
import {
  ADD_TO_LIBRARY_VERSION,
  AddToLibraryVersionData,
  AddToLibraryVersionVars,
  CREATE_LIBRARY,
  CreateLibraryData,
  CreateLibraryVars,
} from '../../gql/library/mutations';
import {
  SEARCH_LIBRARIES_WITH_ELEMENTS,
  SearchLibrariesData,
  SearchLibrariesVars,
} from '../../gql/library/queries';
import { groupObjectsByProperty } from '../../utils/generics';
import { FolderTree } from '../FolderTree/FolderTree/FolderTree';
import { formatVersionText } from '../VersionsList';
import { AddToSubModalProps } from './types';

export const AddToLibraryModalContent: FC<AddToSubModalProps> = ({
  search,
  element,
  onAdded,
}) => {
  const history = useHistory();

  const { data: liveLibrariesData } = useQuery<
    SearchLibrariesData,
    SearchLibrariesVars
  >(SEARCH_LIBRARIES_WITH_ELEMENTS, {
    variables: {
      searchInput: {
        text: search,
        versionType: VERSION_TYPE.LIVE,
      },
    },
    fetchPolicy: 'network-only',
  });

  const { data: draftLibrariesData } = useQuery<
    SearchLibrariesData,
    SearchLibrariesVars
  >(SEARCH_LIBRARIES_WITH_ELEMENTS, {
    variables: {
      searchInput: {
        text: search,
        versionType: VERSION_TYPE.DRAFT,
      },
    },
    fetchPolicy: 'network-only',
  });

  const [addToLibrary] = useMutation<
    AddToLibraryVersionData,
    AddToLibraryVersionVars
  >(ADD_TO_LIBRARY_VERSION);

  const [create] = useMutation<CreateLibraryData, CreateLibraryVars>(
    CREATE_LIBRARY,
  );

  const addElementToLibraryVersion = useCallback(
    async (versionId: string) => {
      const { data: updatedLibrary } = await addToLibrary({
        variables: {
          AddToLibraryVersionInput: {
            versionId,
            elementId: element.id,
            elementType: element.type,
          },
        },
      });

      if (!updatedLibrary) {
        return;
      }

      onAdded(
        updatedLibrary.addToLibraryVersion,
        updatedLibrary.addToLibraryVersion.versionId !== versionId,
      );
    },
    [addToLibrary, element.id, element.type, onAdded],
  );

  const createLibraryAndAddElement = useCallback(async () => {
    const { data: newLibrary } = await create({
      variables: {
        CreateLibraryInput: {
          libraryConfig: {
            name: '',
            title: 'New library',
            queries: [],
            widgets: [],
          },
        },
      },
    });

    if (!newLibrary) {
      return;
    }

    await addElementToLibraryVersion(newLibrary.createLibrary.versionId);

    history.push(
      `/libraries/${EDIT_PATH}/${newLibrary.createLibrary.versionId}`,
    );
  }, [addElementToLibraryVersion, create, history]);

  const listMemo = useMemo(() => {
    if (
      !liveLibrariesData?.searchLibraries ||
      !draftLibrariesData?.searchLibraries
    ) {
      return null;
    }

    const allVersions = [
      ...(liveLibrariesData?.searchLibraries ?? []),
      ...(draftLibrariesData?.searchLibraries ?? []),
    ];

    const versionsGroupedByEntityId = groupObjectsByProperty(allVersions, 'id');

    const newLibraryButton = (
      <FolderTree
        key={'NEW'}
        isCaretLeft
        showCheckbox={false}
        onNameClick={() => createLibraryAndAddElement()}
        data={{ name: '+ Create new Library' }}
      />
    );

    const libraryList = Object.values(versionsGroupedByEntityId).map(
      (library) => {
        const [latestVersion] = library;
        const versionsTree = library.map((version) => {
          const isDisabled =
            version.libraryConfig.widgets.includes(element.id) ||
            version.libraryConfig.queries.includes(element.id);

          return {
            name: formatVersionText(version),
            versionId: version.versionId,
            disabled: isDisabled,
            hasTooltip: isDisabled,
            tooltipNote: `The library already contains this ${element.type}`,
            versionTypeIcon: version.versionType,
          };
        });

        return (
          <FolderTree
            key={latestVersion.id}
            isCaretLeft
            showCheckbox={false}
            onNameClick={({ nodeData }) =>
              addElementToLibraryVersion(nodeData.versionId)
            }
            data={{
              name: latestVersion.libraryConfig.name,
              children: versionsTree,
              openOnNameClick: true,
            }}
          />
        );
      },
    );

    return [newLibraryButton, ...libraryList];
  }, [
    liveLibrariesData?.searchLibraries,
    draftLibrariesData?.searchLibraries,
    createLibraryAndAddElement,
    element.id,
    element.type,
    addElementToLibraryVersion,
  ]);

  return <div>{listMemo || null}</div>;
};
