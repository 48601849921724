import { Classes } from '@blueprintjs/core';
import { FC } from 'react';
import { ClearIcon, Search, SearchWrapper } from './styles';
import { useTranslation } from 'react-i18next';

type Props = {
  onChange?: (val: string) => void;
  onClear?: () => void;
  value?: string;
};

const SearchInputLegacy: FC<Props> = ({
  onChange = () => false,
  onClear,
  value,
}) => {
  const { t } = useTranslation();

  return (
    <SearchWrapper className={`${Classes.INPUT_GROUP}`}>
      <Search
        className="search-input"
        placeholder={t('common.search')!}
        dir="auto"
        value={value}
        onChange={(e: any) => onChange(e.target.value)}
      />
      <ClearIcon icon="cross" onClick={() => (onClear || onChange)('')} />
    </SearchWrapper>
  );
};

export default SearchInputLegacy;
