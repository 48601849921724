import { useMutation, useQuery } from '@apollo/client';
import cn from 'classnames';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SolutionsRoute } from '../../../../../../app/Routes';
import { AppToaster } from '../../../../../../app/Toaster';
import Btn from '../../../../../../components/Btn';
import {
  InlineAlert,
  InlineAlertSize,
  InlineAlertType,
} from '../../../../../../components/InlineAlert';
import { Field } from '../../../../../../components/PivotChartPanel';
import { SpinnerWithCheckMark } from '../../../../../../components/SpinnerWithCheckMark';
import { WIDGET_COMPONENTS_MAP } from '../../../../../../components/Widget';
import SearchInputLegacy from '../../../../../../components/inputs/SearchInputLegacy';
import { EDIT_PATH } from '../../../../../../constants/entities';
import {
  GET_HIERARCHY_STRUCTURES,
  GetHierarchyStructuresData,
  GetHierarchyStructuresVars,
} from '../../../../../../gql/hierarchy/queries';
import { StructureTree } from '../../../../../../gql/hierarchy/types';
import {
  UPDATE_ACCESS,
  UpdateAccessData,
  UpdateAccessVars,
} from '../../../../../../gql/permission/mutations';
import { PERMISSION_ENTITY_TYPE } from '../../../../../../gql/permission/types';
import {
  BUILD_STRUCTURE_BY_VERSION_ID,
  BuildStructureByVersionIdData,
  BuildStructureByVersionIdVars,
} from '../../../../../../gql/structure/queries';
import { AccessUserInherit, CustomerRole } from '../../../../../../gql/types';
import { useUserQuery } from '../../../../../../gql/user/hooks';
import {
  getIsGroupEditor,
  getLocalUserData,
} from '../../../../../../gql/user/local';
import {
  GET_USER_GROUPS,
  GetUserGroupsData,
} from '../../../../../../gql/user/queries';
import {
  PUBLISH_WIDGET_VERSION,
  PublishWidgetVersionVars,
} from '../../../../../../gql/widget/mutations';
import {
  BUILD_WIDGET_PREVIEW,
  BuildWidgetPreviewData,
  BuildWidgetPreviewVars,
} from '../../../../../../gql/widget/queries';
import {
  COLUMN_TYPE,
  CONFIG_ELEMENT_VALUES_TYPE,
  DOMAIN_TYPE,
  InsightRawTableData,
  WIDGET_TYPE,
} from '../../../../../../gql/widget/types';
import { useSearchEntityUtils } from '../../../../../../utils/hooks/entity';
import {
  ENTITY_SORT_ORDER,
  PIVOT_FUNCTION,
} from '../../../../../../utils/pivot';
import Hierarchy from '../../../../../Hierarchy';
import { useAddWidgetIdToPage, useEditingContext } from '../../../editing';
import { CreateWidgetConfig } from './QueryPreview';
import WidgetSettings from './WidgetSettings';
import {
  CREATE_WIDGET_FIELD_MAP,
  useCreateWidgetMutation,
  useUpdateAndPublishWidgetVersionMutation,
} from './utils';

type Props = {
  colInd: number;
  isHierarchyShown?: boolean;
  isViewOnly?: boolean;
  rawTable?: InsightRawTableData;
  rowInd: number;
  skipPermission?: boolean;
  versionId?: string;
  structureId?: string;
  widgetConfig: CreateWidgetConfig;
  queryFields?: Field[];
  handleConfirm: () => void;
  onChange?: () => void;
  onClose: () => void;
  refetchWidget?: (newType: WIDGET_TYPE) => void;
  setDialogActions?: Dispatch<SetStateAction<JSX.Element | null>>;
  setWidgetConfig: Dispatch<SetStateAction<CreateWidgetConfig>>;
  setCreatedWidget?: Dispatch<SetStateAction<null>>;
};

const CLAMP_THRESHOLD = 1000000;

const LIMIT_STEP = 50;
const OFFSET = 50;

const WidgetEditor = ({
  colInd,
  isHierarchyShown,
  isViewOnly = false,
  rawTable,
  rowInd,
  skipPermission = false,
  versionId,
  structureId,
  widgetConfig,
  queryFields,
  handleConfirm,
  onChange,
  onClose,
  refetchWidget,
  setDialogActions,
  setWidgetConfig,
  setCreatedWidget,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [localWidget, setLocalWidget] = useState<any>(null);
  const [structureTrees, setStructureTrees] = useState<StructureTree[]>([]);
  const [rows, setRows] = useState(100);
  const [isSearching, setIsSearching] = useState(false);
  const [offset, setOffset] = useState(0);
  const { searchInput, search, onSearch } = useSearchEntityUtils();

  const { data: currentUserGroupsData } = useUserQuery<GetUserGroupsData, null>(
    GET_USER_GROUPS,
  );

  const isTable = widgetConfig.type === WIDGET_TYPE.TABLE;

  const WidgetComponent = WIDGET_COMPONENTS_MAP[widgetConfig.type];

  const widgetData: any = useMemo(() => {
    if (!widgetConfig?.data.length) {
      return;
    }

    let disabledIndexes = widgetConfig.data
      .map(({ disabled }, index) => (disabled ? index : null))
      .filter((index): index is number => typeof index === 'number');

    const commonConfig = {
      title: widgetConfig.title,
    };

    if (widgetConfig.type === WIDGET_TYPE.TABLE) {
      return {
        ...commonConfig,
        header: widgetConfig.data.filter(({ disabled }) => !disabled),
        data:
          rawTable?.content.map((row) =>
            row
              .map((val, ind) => ({
                type:
                  rawTable?.header[ind].valuesType ||
                  CONFIG_ELEMENT_VALUES_TYPE.string,
                value: val ?? '-',
              }))
              .filter((_, ind) => !disabledIndexes.includes(ind)),
          ) || [],
      };
    } else {
      const domainInd = widgetConfig.data.findIndex(
        ({ isSelectedDimension }) => isSelectedDimension,
      );

      const domain = widgetConfig.data[domainInd];

      const content = rawTable?.content || [];

      const dataSets = widgetConfig.data
        .map((d, ind) => {
          if (
            disabledIndexes.includes(ind) ||
            d.colType === COLUMN_TYPE.DIMENSION
          ) {
            return null;
          }

          return {
            ...d,
            type: d.valuesType,
            values: content.map((row) => row[ind]),
          };
        })
        .filter(Boolean);

      const isAllPositive = dataSets.every((ds) =>
        ds?.values.every((val) => val === null || +val >= 0),
      );

      const isClamp = dataSets.every((ds) =>
        ds?.values.every((val) => val === null || +val >= CLAMP_THRESHOLD),
      );

      return {
        ...commonConfig,
        zeroBased: isAllPositive || isClamp,
        domains: [
          {
            ...domain,
            type: DOMAIN_TYPE.X_AXIS,
            tiltLabels: true,
            values: content.map((row) => row[domainInd]),
          },
        ],
        data: dataSets,
      };
    }
  }, [rawTable, widgetConfig.data, widgetConfig.title, widgetConfig.type]);

  const { data: previewData, loading: isLoadingPreviewData } = useQuery<
    BuildWidgetPreviewData,
    BuildWidgetPreviewVars
  >(BUILD_WIDGET_PREVIEW, {
    variables: {
      structureId: structureId ?? '',
      source: {
        type: widgetConfig.type,
        name: widgetConfig.name,
        title: widgetConfig.title,
        queryId: widgetConfig.queryId,
        widgetFilters: [],
        pageFilters: [],
        domains:
          widgetConfig?.pivot?.rowField &&
          widgetConfig.type !== WIDGET_TYPE.TABLE
            ? ([
                {
                  type: DOMAIN_TYPE.X_AXIS,
                  column: widgetConfig.pivot.rowField.id,
                  title: widgetConfig.pivot.rowField.name,
                },
              ] as any)
            : [],
        pivot: {
          pivotColumn: widgetConfig?.pivot?.columnField?.id,
          dataColumn: widgetConfig?.pivot?.valueField?.id,
          type: widgetConfig?.pivot?.valueField?.type,
          pivotFunction:
            widgetConfig?.pivot?.pivotFunction ??
            (widgetConfig?.pivot?.valueField
              ? widgetConfig.pivot.valueField.type === 'number'
                ? PIVOT_FUNCTION.SUM
                : PIVOT_FUNCTION.COUNT
              : undefined),
          groupBy:
            widgetConfig.type === WIDGET_TYPE.TABLE
              ? widgetConfig?.pivot?.rowField?.id
              : undefined,
          orderBy: widgetConfig?.pivot?.rowField?.id,
          orderDirection: widgetConfig?.pivot?.rowField
            ? ENTITY_SORT_ORDER.ASC
            : undefined,
        },
      },
    },
    skip:
      !structureId ||
      !widgetConfig.pivot ||
      !widgetConfig.pivot.rowField ||
      !widgetConfig.pivot.valueField,
  });

  const [createWidget, { loading: createWidgetLoading }] =
    useCreateWidgetMutation(widgetConfig.type);

  const [publishVersion, { loading: publishVersionLoading }] = useMutation<
    any,
    PublishWidgetVersionVars
  >(PUBLISH_WIDGET_VERSION);

  const [updateAccess, { loading: updateAccessLoading }] = useMutation<
    UpdateAccessData<AccessUserInherit>,
    UpdateAccessVars<AccessUserInherit>
  >(UPDATE_ACCESS);

  const [
    updateAndPublishWidgetVersion,
    { loading: updateAndPublishWidgetVersionLoading },
  ] = useUpdateAndPublishWidgetVersionMutation(widgetConfig.type);

  const confirmLoading =
    createWidgetLoading ||
    publishVersionLoading ||
    updateAccessLoading ||
    updateAndPublishWidgetVersionLoading;

  const addWidgetIdToPage = useAddWidgetIdToPage();

  const confirm = useCallback(async () => {
    if (!currentUserGroupsData) {
      return;
    }

    const { data, ...commonConfig } = widgetConfig;

    const domain = data.find(({ isSelectedDimension }) => isSelectedDimension)!;

    const newWidgetConfig = commonConfig.pivot
      ? {
          ...commonConfig,
          domains:
            widgetConfig?.pivot?.rowField && !isTable
              ? ([
                  {
                    type: DOMAIN_TYPE.X_AXIS,
                    column: widgetConfig.pivot.rowField.id,
                    title: widgetConfig.pivot.rowField.name,
                  },
                ] as any)
              : undefined,
          pivot: {
            pivotColumn: widgetConfig?.pivot?.columnField?.id,
            dataColumn: widgetConfig?.pivot?.valueField?.id,
            type: widgetConfig?.pivot?.valueField?.type,
            pivotFunction:
              widgetConfig?.pivot?.pivotFunction ??
              (widgetConfig?.pivot?.valueField
                ? widgetConfig.pivot.valueField.type === 'number'
                  ? PIVOT_FUNCTION.SUM
                  : PIVOT_FUNCTION.COUNT
                : undefined),
            groupBy: isTable ? widgetConfig?.pivot?.rowField?.id : undefined,
            orderBy: widgetConfig?.pivot?.rowField?.id,
            orderDirection: widgetConfig?.pivot?.rowField
              ? ENTITY_SORT_ORDER.ASC
              : undefined,
          },
        }
      : isTable
      ? {
          ...commonConfig,
          data: data
            .filter(({ disabled }) => !disabled)
            .map(({ id, column, weight, borderRight, title, valuesType }) => ({
              id,
              type: valuesType,
              column,
              weight,
              borderRight,
              header: title,
              sortable: false,
            })),
        }
      : {
          ...commonConfig,
          zeroBased: widgetData?.zeroBased,
          domains: [
            {
              type: DOMAIN_TYPE.X_AXIS,
              title: domain.title,
              tiltLabels: true,
              column: domain.column,
              valuesType: domain.valuesType,
            },
          ],
          data: data
            .filter(
              ({ disabled, colType }) =>
                !disabled && colType === COLUMN_TYPE.MEASURE,
            )
            .map(({ id, column, title, valuesType }) => ({
              id,
              title,
              type: valuesType,
              column,
            })),
        };

    try {
      if (versionId) {
        await updateAndPublishWidgetVersion({
          variables: {
            UpdateAndPublishWidgetVersion: {
              versionId,
              widgetConfig: newWidgetConfig,
            },
          },
        });

        refetchWidget?.(newWidgetConfig.type);
      } else {
        const { data: newWidgetData } = await createWidget({
          variables: {
            CreateWidgetInput: {
              widgetConfig: newWidgetConfig,
            },
          },
          ...(skipPermission && {
            context: {
              headers: {
                'x-leto-csv-skip-permission': 'true',
              },
            },
          }),
        });

        const newWidget =
          newWidgetData?.[CREATE_WIDGET_FIELD_MAP[widgetConfig.type]];

        setCreatedWidget?.(newWidget);
        const currentUser = getLocalUserData()!;

        const editorGroups = currentUserGroupsData.getUserGroups
          .filter(({ members }) => {
            return members.some(({ group_role, _user }) => {
              return (
                _user?._id === currentUser.sub &&
                group_role.includes(CustomerRole.editor)
              );
            });
          })
          .map(({ _id }) => _id);

        const accessUsers: AccessUserInherit[] = [
          {
            userId: currentUser.sub,
            inherit: false,
          },
          ...editorGroups.map((id) => ({
            groupId: id,
            inherit: false,
          })),
        ];

        if (getIsGroupEditor()) {
          await updateAccess({
            variables: {
              UpdateAccessInput: {
                entityId: newWidget.id,
                entityType: PERMISSION_ENTITY_TYPE.WIDGET,
                access: {
                  public: false,
                  included: true,
                  users: accessUsers,
                },
              },
            },
          });
        } else {
          await publishVersion({
            variables: {
              entityVersionInput: {
                versionId: newWidget.versionId,
              },
            },
          });
        }

        if (isHierarchyShown) {
          setLocalWidget(newWidget);

          return;
        }

        addWidgetIdToPage(newWidget.id, rowInd, colInd);
      }

      AppToaster.show({
        message: versionId
          ? 'Änderungen wurden gespeichert'
          : 'Neues Diagramm wurde erfolgreich zur Seite hinzugefügt',
        intent: 'success',
      });

      handleConfirm();
    } catch {}
  }, [
    currentUserGroupsData,
    widgetConfig,
    isTable,
    widgetData?.zeroBased,
    versionId,
    handleConfirm,
    updateAndPublishWidgetVersion,
    refetchWidget,
    createWidget,
    skipPermission,
    setCreatedWidget,
    isHierarchyShown,
    addWidgetIdToPage,
    rowInd,
    colInd,
    updateAccess,
    publishVersion,
  ]);

  const isTitleEmpty = useMemo(
    () => !widgetConfig.title || widgetConfig.data.some(({ title }) => !title),
    [widgetConfig.data, widgetConfig.title],
  );

  const {
    loading: hierarchyStructuresLoading,
    previousData: prevStructureTrees,
  } = useQuery<GetHierarchyStructuresData, GetHierarchyStructuresVars>(
    GET_HIERARCHY_STRUCTURES,
    {
      variables: {
        searchInput: { text: search, first: LIMIT_STEP, offset },
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const length = data.getHierarchyStructures.length;

        setRows(length);
        setStructureTrees((prev) => [...prev, ...data.getHierarchyStructures]);

        setIsSearching(false);
      },
    },
  );

  const scrollNext = useCallback(() => setOffset((prev) => prev + OFFSET), []);

  const { setCurrentStructure } = useEditingContext();

  const { refetch: refetchStructure } = useQuery<
    BuildStructureByVersionIdData,
    BuildStructureByVersionIdVars
  >(BUILD_STRUCTURE_BY_VERSION_ID, {
    skip: true,
  });

  const goToVersion = useCallback(
    async (_?: string, pathname?: string, hierarchyPath?: string) => {
      if (pathname === 'solutions') {
        return;
      }

      const structureVId = hierarchyPath?.split('/')[0];

      const { data: structureData } = await refetchStructure({
        structureVersionId: structureVId,
      });

      const structure = structureData?.buildStructureByVersionId;

      setCurrentStructure(structure);

      history.push(`${SolutionsRoute.path}/${EDIT_PATH}/${hierarchyPath}`, {
        widgetId: localWidget?.id,
      });
    },
    [history, localWidget?.id, refetchStructure, setCurrentStructure],
  );

  useEffect(() => {
    if (localWidget && setDialogActions) {
      setDialogActions(
        <div className="u-display-space-between align-items-center">
          <span className="title-text font-26 font-w-400">
            Wählen Sie den Bericht, dem Sie das Widget hinzufügen möchten
          </span>
          <SearchInputLegacy
            onChange={(value) => {
              onSearch(value);
              setStructureTrees([]);
              setOffset(0);
              setIsSearching(true);
              setRows(100);
            }}
            value={searchInput}
          />
        </div>,
      );
    }
  }, [
    localWidget,
    onSearch,
    searchInput,
    setDialogActions,
    widgetConfig?.title,
  ]);

  const missingPivotFieldDescription = useMemo(() => {
    const missingPivotFields = isTable
      ? [
          ...(!widgetConfig.pivot?.rowField ? [t('pivot.rows-inline')] : []),
          ...(!widgetConfig.pivot?.valueField
            ? [t('pivot.values-inline')]
            : []),
        ]
      : [
          ...(!widgetConfig.pivot?.rowField ? [t('pivot.x-axis-inline')] : []),
          ...(!widgetConfig.pivot?.valueField
            ? [t('pivot.y-axis-inline')]
            : []),
        ];

    switch (missingPivotFields.length) {
      case 1:
        return t('pivot.missing-required-field-description', {
          field: missingPivotFields[0],
        });

      case 2:
        return t('pivot.missing-2-required-fields-description', {
          field1: missingPivotFields[0],
          field2: missingPivotFields[1],
        });
    }
    return undefined;
  }, [
    isTable,
    widgetConfig.pivot?.rowField,
    widgetConfig.pivot?.valueField,
    t,
  ]);

  if (localWidget) {
    return (
      <Hierarchy
        isAddingFromInsights
        showNested
        hierarchiesData={structureTrees}
        rows={rows}
        setStructureTrees={setStructureTrees}
        scrollNext={scrollNext}
        type="widgets"
        isLoading={
          (!prevStructureTrees && hierarchyStructuresLoading) || isSearching
        }
        goToVersion={goToVersion}
      />
    );
  }

  const widgetPreviewComponent = (
    <div
      className={cn(
        'grow overflow-y-auto px-3 py-2.5',
        isTable ? 'overflow-x-auto' : 'overflow-x-hidden',
      )}
    >
      {widgetConfig.pivot ? (
        missingPivotFieldDescription ? (
          <InlineAlert
            title={t('pivot.missing-required-fields-title')}
            description={missingPivotFieldDescription}
            size={InlineAlertSize.LARGE}
            type={InlineAlertType.INFO}
            hasGrayBg
          />
        ) : isLoadingPreviewData ? (
          <div className={'absolute left-1/2'}>
            <SpinnerWithCheckMark state="PENDING" size={400} />
          </div>
        ) : previewData?.buildWidgetPreview ? (
          <WidgetComponent data={previewData.buildWidgetPreview as any} />
        ) : (
          <InlineAlert
            title={t('pivot.missing-required-fields-title')}
            size={InlineAlertSize.LARGE}
            type={InlineAlertType.INFO}
            hasGrayBg
          />
        )
      ) : (
        rawTable && widgetData && <WidgetComponent data={widgetData} />
      )}
    </div>
  );

  if (isViewOnly) {
    return widgetPreviewComponent;
  }

  return (
    <div className="flex h-full flex-col">
      <div className="flex grow flex-row overflow-scroll">
        <div className="flex-none overflow-y-auto">
          <WidgetSettings
            widgetConfig={widgetConfig}
            setWidgetConfig={setWidgetConfig}
            queryFields={queryFields}
            onChange={onChange}
          />
        </div>
        {widgetPreviewComponent}
      </div>
      <div className="mt-5 flex justify-end space-x-5">
        {!versionId && (
          <Btn
            text={t('common.cancel')}
            lg
            outlined
            onClick={onClose}
            intent="none"
          />
        )}
        <Btn
          id="widget-editor-confirm-btn"
          text={versionId ? 'Speichern' : 'Zur Seite hinzufügen'}
          lg
          onClick={confirm}
          disabled={
            isTitleEmpty ||
            !currentUserGroupsData ||
            (widgetConfig.pivot &&
              (!widgetConfig.pivot.rowField || !widgetConfig.pivot.valueField))
          }
          loading={confirmLoading}
        />
      </div>
    </div>
  );
};

export default WidgetEditor;
