import React, { FC, ReactNode, useContext, useState } from 'react';
import { useMountEffect } from '../../utils/common';

declare global {
  interface Window {
    onUsersnapCXLoad: (api: UsersnapApi) => void;
  }
}

export const UsersnapContext = React.createContext<UsersnapApi | null>(null);

type InitParams = Record<any, any>;

type Props = {
  apiKey: string;
  initParams?: InitParams;
  children: ReactNode;
};

export type UsersnapApi = {
  init: (initParams?: InitParams) => void;
  destroy: () => void;
};

const DISABLE_USERSNAP = 'DISABLE_USERSNAP';

const UsersnapProvider: FC<Props> = ({ apiKey, initParams = {}, children }) => {
  const [usersnapApi, setUsersnapApi] = useState<UsersnapApi | null>(null);
  const disabled = localStorage.getItem(DISABLE_USERSNAP) === 'true';

  useMountEffect(() => {
    if (disabled) {
      return;
    }

    let usersnapApi: UsersnapApi | null = null;

    window.onUsersnapCXLoad = function (api) {
      api.init(initParams);
      usersnapApi = api;
      setUsersnapApi(api);
    };

    const script = document.createElement('script');
    script.defer = true;
    script.src = `https://widget.usersnap.com/global/load/${apiKey}?onload=onUsersnapCXLoad`;
    document.head.appendChild(script);

    return () => {
      usersnapApi?.destroy();
      script.remove();
    };
  });

  return (
    <UsersnapContext.Provider value={usersnapApi}>
      {children}
    </UsersnapContext.Provider>
  );
};

export const useUsersnapApi = () => {
  return useContext(UsersnapContext);
};

export default UsersnapProvider;
