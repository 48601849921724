import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { EditorMode } from '../../../../../gql/common/types';
import { LAYOUT_TYPE_VALS } from '../../../../../gql/page/types';
import { getIsAnalyticsEngineer, getIsDA } from '../../../../../gql/user/local';
import { useCreateWidgetMutation } from '../../../../../gql/widget/hooks';
import { WIDGET_TYPE } from '../../../../../gql/widget/types';
import useOpen from '../../../../../utils/hooks/useOpen';
import { useCurrentLocalPage, useEditingContext } from '../../editing';
import TextWidgetForm from '../RowSeparator/TextWidgetForm';
import InsightsLibrary from './InsightsLibrary';
import { ReactComponent as TextWidget } from './add-text-widget.svg';
import { ReactComponent as CreateWidget } from './create-widget.svg';
import { ReactComponent as Library } from './library.svg';
import { AddWidgetCard, AddWidgetContainer, DeleteCol } from './styles';
import { useTranslation } from 'react-i18next';

type Props = {
  rowInd: number;
  colInd: number;
  highlight?: boolean;
  isDraggingFrom?: boolean;
};

const AddWidget: FC<Props> = ({
  rowInd,
  colInd,
  highlight,
  isDraggingFrom,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const localPage = useCurrentLocalPage();
  const { updatePageConfig, localStructure } = useEditingContext();

  const {
    open: insightOpen,
    handleOpen: insightHandleOpen,
    handleClose: insightHandleClose,
  } = useOpen();

  const {
    open: textOpen,
    handleOpen: textHandleOpen,
    handleClose: textHandleClose,
  } = useOpen();

  const [createWidget] = useCreateWidgetMutation(WIDGET_TYPE.LINE_CHART);

  const layoutCopy = [...(localPage?.page?.pageConfig?.layout || [])];

  const currentRow = layoutCopy[rowInd];
  const currentCol = currentRow.columns[colInd];
  const layoutType = currentRow.type;
  const layoutTypeInd = LAYOUT_TYPE_VALS.indexOf(layoutType);
  const currentColspan = currentCol.colspan ?? 1;

  const isSingleCol = layoutTypeInd + 1 === currentColspan;

  return (
    <>
      {textOpen ? (
        <TextWidgetForm
          rowIndex={rowInd}
          colInd={colInd}
          handleClose={textHandleClose}
        />
      ) : (
        <AddWidgetContainer
          col={!isSingleCol}
          type={layoutType}
          highlight={highlight}
          isDraggingFrom={isDraggingFrom}
        >
          <AddWidgetCard
            onClick={textHandleOpen}
            widthPart={currentColspan / (layoutTypeInd + 1)}
            col={!isSingleCol}
            type={layoutType}
            className="add-text-widget-btn"
          >
            <TextWidget />
            <div className="colorPrimary-0 u-text-align-center u-hyphens-none">
              Text hinzufügen
            </div>
          </AddWidgetCard>
          <AddWidgetCard
            onClick={insightHandleOpen}
            widthPart={currentColspan / (layoutTypeInd + 1)}
            col={!isSingleCol}
            type={layoutType}
            className="add-library-widget-btn"
          >
            <Library />
            <div className="colorPrimary-0 u-text-align-center u-hyphens-none">
              {t('entity.insights-libraries')}
            </div>
          </AddWidgetCard>
          {(getIsDA() || getIsAnalyticsEngineer()) && (
            <AddWidgetCard
              onClick={async () => {
                const {
                  data: { createLineChartWidget: newWidget },
                } = await createWidget({
                  variables: {
                    CreateWidgetInput: {
                      widgetConfig: {
                        name: '',
                        title: 'New widget',
                        type: 'line_chart',
                      },
                      editorMode: EditorMode.VISUAL,
                      structureId: localStructure?.id,
                    },
                  },
                });

                history.push(`/widgets/${newWidget.versionId}`, {
                  backURL: history.location.pathname,
                  addTo: {
                    structureVersionId: localStructure?.versionId,
                    pageVersionId: localPage?.page?.pageConfig?.versionId,
                    path: history.location.pathname,
                    row: rowInd,
                    col: colInd,
                  },
                });
              }}
              widthPart={currentColspan / (layoutTypeInd + 1)}
              col={!isSingleCol}
              type={layoutType}
              className="create-widget-btn"
            >
              <CreateWidget />
              <div className="colorPrimary-0 u-text-align-center u-hyphens-none">
                Create new widget
              </div>
            </AddWidgetCard>
          )}
          {!isSingleCol && (
            <DeleteCol
              icon="trash"
              iconBtn
              intent="none"
              className="delete-col-btn"
              onClick={() => {
                const newType =
                  LAYOUT_TYPE_VALS[layoutTypeInd - currentColspan];

                updatePageConfig(
                  {
                    layout: [
                      ...layoutCopy.slice(0, rowInd),
                      ...(isSingleCol
                        ? []
                        : [
                            {
                              ...currentRow,
                              type: newType,
                              columns: currentRow?.columns.filter(
                                (_, ind) => ind !== colInd,
                              ),
                            },
                          ]),
                      ...layoutCopy.slice(rowInd + 1),
                    ],
                  },
                  localPage?.page?.id,
                );
              }}
            />
          )}
        </AddWidgetContainer>
      )}
      <InsightsLibrary
        open={insightOpen}
        handleClose={insightHandleClose}
        rowInd={rowInd}
        colInd={colInd}
      />
    </>
  );
};

export default AddWidget;
