import React, { FC, useEffect, useMemo, useState } from 'react';
import { Tooltip2 } from '@blueprintjs/popover2';
import { StyledModalLegacy, StyledEditorSwitch } from './styles';
import { EditorMode } from '../../gql/common/types';

type Props = {
  checked: boolean;
  disabled: boolean;
  requireConfirmation: boolean;
  onChange(isChecked: boolean): void;
};

export const VisualEditorSwitch: FC<Props> = ({
  checked,
  disabled,
  requireConfirmation,
  onChange,
}) => {
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

  return (
    <>
      <Tooltip2
        content={<span>Please fix editor errors to continue</span>}
        placement="top"
        disabled={!disabled}
      >
        <StyledEditorSwitch
          id="navbar-visual-editor-switch"
          checked={checked}
          disabled={disabled}
          onChange={() => {
            const willEnableVisualEditor = !checked;

            if (willEnableVisualEditor && requireConfirmation) {
              setConfirmationModalOpen(true);
            } else {
              onChange(willEnableVisualEditor);
            }
          }}
        />
      </Tooltip2>

      <StyledModalLegacy
        isOpen={isConfirmationModalOpen}
        title="Warning"
        content="Parts of the configuration may be lost if you switch to the visual mode. Are you sure that you want to continue?"
        confirmButtonText="Continue to visual editor"
        cancelButtonText="Stay in expert mode"
        intent="danger"
        onCancel={() => {
          setConfirmationModalOpen(false);
        }}
        onConfirm={() => {
          onChange(!checked);
          setConfirmationModalOpen(false);
        }}
      />
    </>
  );
};

type VisualEditorState = {
  isVisualEditorEnabled: boolean;
  editorMode: EditorMode;
  switchElement: React.ReactElement;
};

export function useVisualEditor(
  initialEnabled: boolean,
  isDisabled: boolean,
  showAlertOnSwitch: boolean,
  deps: any[],
  onSwitch?: (isVisualEditorEnabled: boolean) => void,
): VisualEditorState {
  const [isVisualEditorEnabled, setEnabled] = useState(initialEnabled);

  useEffect(() => {
    setEnabled(initialEnabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const switchElement = useMemo(
    () => (
      <VisualEditorSwitch
        key="editor-switch"
        checked={isVisualEditorEnabled}
        disabled={isDisabled}
        requireConfirmation={showAlertOnSwitch}
        onChange={(state) => {
          setEnabled(state);
          onSwitch?.(state);
        }}
      />
    ),
    [isVisualEditorEnabled, isDisabled, showAlertOnSwitch, onSwitch],
  );

  const editorMode = isVisualEditorEnabled
    ? EditorMode.VISUAL
    : EditorMode.EXPERT;

  return { isVisualEditorEnabled, switchElement, editorMode };
}
