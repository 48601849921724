import React from 'react';
import { ItemRenderer } from '@blueprintjs/select/lib/esm/common/itemRenderer';
import { Icon, MenuItem } from '@blueprintjs/core';
import { ItemPredicate } from '@blueprintjs/select';

const escapeRegExpChars = (text: string) => {
  return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1');
};

export const highlightText = (text: string, query: string) => {
  let lastIndex = 0;
  const words = query
    .split(/\s+/)
    .filter((word) => word.length > 0)
    .map(escapeRegExpChars);
  if (words.length === 0) {
    return [text];
  }
  const regexp = new RegExp(words.join('|'), 'gi');
  const tokens: React.ReactNode[] = [];
  while (true) {
    const match = regexp.exec(text);
    if (!match) {
      break;
    }
    const length = match[0].length;
    const before = text.slice(lastIndex, regexp.lastIndex - length);
    if (before.length > 0) {
      tokens.push(before);
    }
    lastIndex = regexp.lastIndex;
    tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
  }
  const rest = text.slice(lastIndex);
  if (rest.length > 0) {
    tokens.push(rest);
  }
  return tokens;
};

export type SearchableOption = { title: string; value: string };

export const renderSearchableItem =
  (selectedValue?: string): ItemRenderer<SearchableOption> =>
  // eslint-disable-next-line react/display-name
  (item, { handleClick, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const text = item.title;

    const highlightedText = highlightText(text, query);

    const { disabled } = modifiers;
    let active = item.value === selectedValue;

    return (
      <MenuItem
        active={active}
        disabled={disabled}
        key={item.value}
        onClick={handleClick}
        text={
          active ? (
            <>
              <Icon icon="small-tick" /> {highlightedText}
            </>
          ) : (
            highlightedText
          )
        }
      />
    );
  };

export const searchablePredicate: ItemPredicate<SearchableOption> = (
  query,
  item,
  _index,
  exactMatch,
) => {
  const normalizedName = item.title.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedName === normalizedQuery;
  } else {
    return normalizedName.indexOf(normalizedQuery) >= 0;
  }
};
