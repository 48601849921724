import React, { useEffect, useRef } from 'react';
import ReactModal, { Styles } from 'react-modal';
import {
  ActionButton,
  CancelButton,
  ModalActionsWrapper,
  ModalBtnSeparator,
  ModalContent,
  ModalHeader,
  ModalHeaderGroup,
  ModalSeparator,
  ModalWrapper,
} from './styles';
import { BtnProps } from '../Btn';

function merge<A extends Record<string, any>, B extends Record<string, any>>(
  objA: A,
  objB: B,
): A & B {
  const result = {
    ...objA,
    ...objB,
  };

  Object.keys(objA).forEach((key) => {
    if (typeof result[key] === 'object') {
      // @ts-ignore
      result[key] = merge(objA[key], objB[key]);
    }
  });

  return result;
}

const customStyles: Styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '331px',
    maxWidth: '50%',
    width: 'min-content',
    padding: '19px 15px',

    overflow: 'auto',
    borderRadius: '8px',
    position: 'relative',
    backgroundColor: '#FFFFFF',
    border: 'none',
    willChange: 'scroll-position',
  },
  overlay: {
    height: '100vh',
    overflowY: 'hidden',
    backgroundColor: 'rgba(133, 133, 133, 0.4)',
    outline: 'none',
    zIndex: 100,
  },
};

ReactModal.setAppElement('#root');

const disableBodyScroll = () => {
  document.body.style.overflow = 'hidden';
};

const enableBodyScroll = () => {
  document.body.style.overflow = '';
};

type Props = {
  cancelButtonProps?: Partial<BtnProps>;
  cancelButtonText?: string | React.ReactNode;
  confirmButtonProps?: Partial<BtnProps>;
  confirmButtonText?: string | React.ReactNode;
  content: React.JSX.Element | string | null;
  headerExtras?: React.JSX.Element;
  intent?: 'danger' | 'action';
  isOpen: boolean;
  style?: Styles;
  title: React.JSX.Element | React.ReactNode | string;

  onCancel: () => void;
  onConfirm?: () => void;
  onDismiss?: () => void;

  shouldCloseOnEsc?: boolean;
  shouldCloseOnOverlayClick?: boolean;
};

const ModalBody: React.FC<Props> = (props) => {
  return (
    <ModalWrapper>
      <ModalHeaderGroup hasExtra={!!props.headerExtras}>
        <ModalHeader>{props.title}</ModalHeader>
        {props.headerExtras ? <div>{props.headerExtras}</div> : null}
      </ModalHeaderGroup>
      <ModalSeparator />
      <ModalContent>{props.content}</ModalContent>
      {props.intent ? (
        <ModalActionsWrapper intent={props.intent}>
          <ActionButton
            intent={
              props.intent === 'danger'
                ? 'danger'
                : props.intent === 'action'
                ? 'primary'
                : 'none'
            }
            text={props.confirmButtonText}
            onClick={props.onConfirm}
            {...props.confirmButtonProps}
          />
          <ModalBtnSeparator />
          <CancelButton
            intent="none"
            text={props.cancelButtonText}
            onClick={props.onCancel}
            {...props.cancelButtonProps}
          />
        </ModalActionsWrapper>
      ) : null}
    </ModalWrapper>
  );
};

const ModalLegacy: React.FC<Props> = (props) => {
  const overlayRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (props.isOpen) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }

    return () => {
      enableBodyScroll();
    };
  }, [props.isOpen]);

  const onCancel = (event?: React.MouseEvent | React.KeyboardEvent) => {
    const isOverlayClick =
      (event as React.MouseEvent)?.target === overlayRef.current;
    const isEscButton = (event as React.KeyboardEvent)?.key === 'Escape';

    if ((isOverlayClick || isEscButton) && props.onDismiss) {
      props.onDismiss();
    } else if (props.onCancel) {
      props.onCancel();
    }

    enableBodyScroll();
  };

  return (
    <ReactModal
      overlayRef={(ref) => {
        overlayRef.current = ref;
      }}
      shouldCloseOnEsc={props.shouldCloseOnEsc}
      shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
      isOpen={props.isOpen}
      onRequestClose={(event) => onCancel(event)}
      style={merge(customStyles, props.style ?? {})}
    >
      <ModalBody {...props} onCancel={onCancel} />
    </ReactModal>
  );
};

export default ModalLegacy;
