import React, { FC } from 'react';
import { ActionsWrapper } from './styles';
import { useApolloClient } from '@apollo/client';
import { useHelpers } from '@remirror/react';
import { WIDGET_TYPE } from '../../../../../../gql/widget/types';
import { Tooltip2 } from '@blueprintjs/popover2';
import { BUILD_TEXT_WIDGET } from '../../../../../../gql/widget/queries';
import { useAddWidgetIdToPage, useEditingContext } from '../../../editing';
import { getLocalUserData } from '../../../../../../gql/user/local';
import {
  WIDGET_CREATE_PREFIX,
  WIDGET_UPDATE_PREFIX,
} from '../../../../../../components/Widget';
import Btn from '../../../../../../components/Btn';

type Props = {
  handleClose: () => void;
  title: string;
  rowIndex: number;
  colInd: number;
  widgetInd?: number;
  versionId?: string;
  isEmpty?: boolean;
  maxValid?: boolean;
  id?: string;
};

const Actions: FC<Props> = ({
  handleClose,
  isEmpty,
  title,
  rowIndex,
  colInd,
  widgetInd,
  versionId,
  maxValid,
  id,
}) => {
  const { cache } = useApolloClient();

  const { getMarkdown, getCountMaximum } = useHelpers();
  const addWidget = useAddWidgetIdToPage();
  const { savedStructure } = useEditingContext();

  return (
    <ActionsWrapper>
      <Btn lgSlim intent="none" outlined onClick={handleClose}>
        Abbrechen
      </Btn>
      <Tooltip2
        content={
          !maxValid
            ? `Ihre Eigabe ist auf ${getCountMaximum()} Zeichen begrenzt.`
            : 'Bitte einen Titel hinzufügen.'
        }
        placement="top"
        disabled={!!title && maxValid}
      >
        <Btn
          lgSlim
          onClick={() => {
            const markdown = getMarkdown();

            const user = getLocalUserData();

            const newId = `${
              versionId ? WIDGET_UPDATE_PREFIX : WIDGET_CREATE_PREFIX
            }-${id || new Date().getTime().toString(16)}`;

            const widgetConfig = {
              title,
              name: title,
              type: WIDGET_TYPE.TEXT,
              text: markdown,
              queryId: null,
              isCreatedByCustomer: true,
              __typename: 'BuiltTextWidgetConfig',
            };

            cache.writeQuery({
              query: BUILD_TEXT_WIDGET,
              variables: {
                source: {
                  appliedFilters: [],
                  appliedPageFilters: [],
                  structureId: savedStructure?.versionId,
                  widgetId: newId,
                },
              },
              data: {
                buildTextWidget: {
                  versionId: versionId || '',
                  createdBy: user?.sub,
                  createdAt: new Date(),
                  creatorFullName: `${user?.firstName} ${user?.lastName}`,
                  isCreatedByCustomer: true,
                  widgetConfig,
                  __typename: 'BuiltTextWidget',
                },
              },
            });

            addWidget(newId, rowIndex, colInd, false, widgetInd);
          }}
          disabled={!title || isEmpty || !maxValid}
        >
          Speichern
        </Btn>
      </Tooltip2>
    </ActionsWrapper>
  );
};

export default Actions;
