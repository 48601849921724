import React from 'react';
import { BarCustomLayer } from '@nivo/bar';
import { BuiltWidgetData } from '../../../../../gql/widget/types';
import { colors } from '../../../../../utils/colors';
import { UNSELECTED_COLOR } from '../../usePalette';
import { getFormattedValue } from '../../../../../utils/format';

const LABEL_PADDING = 5;

const BarValueLabels =
  (
    dataSeries: BuiltWidgetData[],
    selectedData: number | null,
    enableLabels: boolean,
    isColumn?: boolean,
    stacked?: boolean,
  ): BarCustomLayer<any> =>
  // eslint-disable-next-line react/display-name
  ({ bars }) => {
    if (!enableLabels) {
      return null;
    }

    const textColor = stacked ? colors.white : colors.monochromatic0;

    return (
      <g>
        {bars.map(({ width, height, x, y, data: { id, value }, key }) => {
          if (value === null) {
            return null;
          }

          const series = dataSeries[id as number];

          const isPositive = value >= 0;

          const BarTranslateX = isPositive
            ? x + width + LABEL_PADDING
            : x - LABEL_PADDING;
          const ColumnTranslateX = x + width / 2;

          const BarTranslateY = y + height / 2;
          const ColumnTranslateY =
            y + (stacked ? height / 2 : isPositive ? -10 : height + 10);

          const BarAnchor = isPositive ? 'start' : 'end';

          return (
            <text
              key={`${key}-value-label`}
              transform={`translate(${
                isColumn ? ColumnTranslateX : BarTranslateX
              }, ${isColumn ? ColumnTranslateY : BarTranslateY})`}
              textAnchor={isColumn ? 'middle' : BarAnchor}
              dominantBaseline="central"
              fontSize={11}
              fill={
                selectedData === null || selectedData === +id
                  ? textColor
                  : UNSELECTED_COLOR
              }
              pointerEvents="none"
            >
              {getFormattedValue(String(value), series?.type)}
            </text>
          );
        })}
      </g>
    );
  };

export default BarValueLabels;
