import React from 'react';
import { BarCustomLayer } from '@nivo/bar';
import { colors } from '../../../../../utils/colors';
import { getFormattedValue } from '../../../../../utils/format';
import { CONFIG_ELEMENT_VALUES_TYPE } from "../../../../../gql/widget/types";

const BarTotals =
  (
    enableLabels: boolean,
    valuesType?: CONFIG_ELEMENT_VALUES_TYPE,
  ): BarCustomLayer<any> =>
  // eslint-disable-next-line react/display-name
  ({ bars, xScale, yScale }) => {
    if (!enableLabels) {
      return null;
    }

    const totals: Record<string, number> = {};
    const width = bars[0].width;

    bars.forEach(({ data: { indexValue, value } }) => {
      if (value === null) {
        return;
      }

      if (!totals[indexValue]) {
        totals[indexValue] = 0;
      }

      totals[indexValue] += value;
    });

    return (
      <g>
        {Object.entries(totals).map(([indexValue, total]) => {
          const x = xScale(indexValue as any) + width / 2;
          const y = yScale(total) - 10;

          return (
            <text
              key={`${indexValue}-total-label`}
              x={x}
              y={y}
              textAnchor="middle"
              dominantBaseline="ideographic"
              fontSize={11}
              fill={colors.monochromatic1}
              pointerEvents="none"
            >
              {getFormattedValue(String(total), valuesType)}
            </text>
          );
        })}
      </g>
    );
  };

export default BarTotals;
