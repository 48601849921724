import React, {
  cloneElement,
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import {
  BlockquoteExtension,
  BoldExtension,
  BulletListExtension,
  HardBreakExtension,
  HeadingExtension,
  ItalicExtension,
  LinkExtension,
  ListItemExtension,
  MarkdownExtension,
  OrderedListExtension,
  PlaceholderExtension,
  StrikeExtension,
  TrailingNodeExtension,
} from 'remirror/extensions';
import { CountExtension } from '@remirror/extension-count';
import { ExtensionPriority } from 'remirror';
import {
  useRemirror,
  ThemeProvider,
  Remirror,
  EditorComponent,
  useHelpers,
  useEvent,
} from '@remirror/react';
import { sanitize } from 'dompurify';
import { EditorWrapper } from './styles';
import Toolbar from './Toolbar';
import { LAYOUT_TYPE } from "../../gql/page/types";

// const getLocalPersistenceHook = (storageKey: string) => () => {
//   const { getMarkdown } = useHelpers();
//
//   const saveLocally = useDebouncedCallback(() => {
//     const local = JSON.parse(localStorage.getItem(storageKey) || '{}');
//
//     local.content = getMarkdown();
//
//     localStorage.setItem(storageKey, JSON.stringify(local));
//   }, 300);
//
//   useEvent('keydown', saveLocally);
// };

const MAX_LENGTH = 5000;

const getMaxLengthHook =
  (setMaxValid: Dispatch<SetStateAction<boolean>>) => () => {
    const { isCountValid, getCharacterCount } = useHelpers();

    useEvent('keyup', () => {
      setMaxValid(isCountValid());
    });

    useEvent('paste', (e: ClipboardEvent) => {
      const paste = e.clipboardData?.getData('text');

      setMaxValid(getCharacterCount() + (paste?.length || 0) < MAX_LENGTH);
    });
  };

const getIsEmptyHook =
  (setIsEmpty: Dispatch<SetStateAction<boolean>>) => () => {
    const { getCharacterCount } = useHelpers();

    useEvent('keyup', () => {
      setIsEmpty(!getCharacterCount());
    });

    useEvent('paste', (e: ClipboardEvent) => {
      const paste = e.clipboardData?.getData('text');

      setIsEmpty(!paste?.length);
    });
  };

type Props = {
  actions?: ReactElement;
  initialContent?: string;
  layoutType: LAYOUT_TYPE;
};

const MDEditor: FC<Props> = ({ actions, initialContent, layoutType }) => {
  const [isEmpty, setIsEmpty] = useState(!initialContent);

  const [maxValid, setMaxValid] = useState(
    isEmpty || (initialContent?.length || 0) <= MAX_LENGTH
  );

  const extensions = useCallback(
    () => [
      new PlaceholderExtension({ placeholder: 'Beispieltext...' }),
      new LinkExtension({ autoLink: true }),
      new BoldExtension(),
      new StrikeExtension(),
      new ItalicExtension(),
      new HeadingExtension(),
      new LinkExtension(),
      new BlockquoteExtension(),
      new BulletListExtension({ enableSpine: true }),
      new OrderedListExtension(),
      new ListItemExtension({
        priority: ExtensionPriority.High,
        enableCollapsible: true,
      }),
      new TrailingNodeExtension(),
      new MarkdownExtension({ copyAsMarkdown: false, htmlSanitizer: sanitize }),
      new HardBreakExtension(),
      new CountExtension({ maximum: MAX_LENGTH }),
    ],
    []
  );

  const { manager, state } = useRemirror({
    extensions,
    stringHandler: 'markdown',
    content: initialContent || '',
  });

  return (
    <EditorWrapper isEmpty={isEmpty}>
      <ThemeProvider
        theme={{
          fontFamily: {
            default: "'GT-Eesti-Pro-Display', sans-serif",
            heading: "'GT-Eesti-Pro-Display', sans-serif",
          },
          radius: {
            border: '8px',
          },
        }}
        className="raq"
      >
        <Remirror
          manager={manager}
          hooks={[getMaxLengthHook(setMaxValid), getIsEmptyHook(setIsEmpty)]}
          initialContent={state}
        >
          <EditorComponent />
          <Toolbar layoutType={layoutType} />
          {actions && cloneElement(actions, { isEmpty, maxValid })}
        </Remirror>
      </ThemeProvider>
    </EditorWrapper>
  );
};

export default MDEditor;
