import { ApolloClient, Observable, from, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { CHRONOS_MICROSERVICE } from '../gql/chronos/hooks';
import { ATHENA_MICROSERVICE } from '../gql/dataSource/hooks';
import { COLLECT_MICROSERVICE } from '../gql/form/hooks';
import { USER_MANAGEMENT_MICROSERVICE } from '../gql/user/hooks';
import {
  getIsDA,
  getRefreshToken,
  getToken,
  removeRefreshToken,
  removeToken,
  setRefreshToken,
  setToken,
} from '../gql/user/local';
import { REFRESH_TOKEN } from '../gql/user/mutations';
import { ENV_VAR, getEnvVar } from '../utils/common';
import history from '../utils/history';
import { addUsetiful } from '../utils/usetiful';
import { AUTH_ROUTES } from './Routes';
import { AppToaster } from './Toaster';
import { cache } from './cache';

declare global {
  interface Window {
    _env_?: {
      REACT_APP_GRAPHQL_URL?: string;
      REACT_APP_GRAPHQL_USER_MANAGEMENT_URL?: string;
      REACT_APP_AXIOS_URL?: string;
    };
  }
}

const mainLink = createUploadLink({
  uri: getEnvVar(ENV_VAR.GRAPHQL_URL),
  fetchOptions: {
    keepalive: true,
  },
});

const userManagementLink = createUploadLink({
  uri: getEnvVar(ENV_VAR.GRAPHQL_USER_MANAGEMENT_URL),
});

const collectLink = createUploadLink({
  uri: getEnvVar(ENV_VAR.GRAPHQL_COLLECT_URL),
});

const chronosLink = createUploadLink({
  uri: getEnvVar(ENV_VAR.GRAPHQL_CHRONOS_URL),
});

const athenaLink = createUploadLink({
  uri: getEnvVar(ENV_VAR.GRAPHQL_ATHENA_URL),
});

export const ERROR_CODES = {
  INVALID_JWT: 'INVALID_JWT',
  ACCESS_DENIED: 'ACCESS_DENIED',
  INVALID_ID: 'INVALID_ID',
};

export const AUTH_ERROR_CODES = [401, 403];

const NO_ERROR_NOTIFICATION_OPERATIONS = ['Auth'];

const authAPIClient = new ApolloClient({
  link: setContext((_, { headers }) => {
    const refreshToken = getRefreshToken();

    return {
      headers: {
        ...headers,
        authorization: refreshToken ? `Bearer ${refreshToken}` : '',
      },
    };
  }).concat(userManagementLink as any),
  cache,
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    const isDA = getIsDA();

    if (graphQLErrors) {
      for (let { message, extensions } of graphQLErrors) {
        if (isDA) {
          if (
            extensions?.response?.statusCode === 409 &&
            NO_ERROR_NOTIFICATION_OPERATIONS.includes(operation.operationName)
          ) {
            AppToaster.show({ intent: 'danger', message, timeout: 0 });
          }

          if (
            !NO_ERROR_NOTIFICATION_OPERATIONS.includes(
              operation.operationName,
            ) &&
            !AUTH_ERROR_CODES.includes(extensions?.response?.statusCode) &&
            extensions?.code !== ERROR_CODES.INVALID_JWT
          ) {
            AppToaster.show({ intent: 'danger', message });
          }
        }

        if (
          extensions?.code === ERROR_CODES.INVALID_JWT ||
          (AUTH_ERROR_CODES.includes(extensions?.response?.statusCode) &&
            operation.operationName !== 'Auth')
        ) {
          return new Observable((observer) => {
            authAPIClient
              .mutate({
                mutation: REFRESH_TOKEN,
              })
              .then(
                ({
                  data: {
                    refreshToken: { accessToken, refreshToken },
                  },
                }) => {
                  setToken(accessToken);
                  setRefreshToken(refreshToken);
                  operation.setContext(({ headers = {} }) => ({
                    headers: {
                      ...headers,
                      authorization: `Bearer ${accessToken}`,
                    },
                  }));
                },
              )
              .then(() => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };
                // Retry last failed request
                forward(operation).subscribe(subscriber);
              })
              .catch((error) => {
                observer.error(error);
                logout();
              });
          });
        }
      }
    }

    if (isDA && networkError && networkError.name !== 'AbortError') {
      AppToaster.show({ intent: 'danger', message: networkError.message });
    }
  },
);

const authLink = setContext((req, { headers }) => {
  const accessToken = getToken();

  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
      clientURL: window.location.href,
      solution: 'Leto Admin',
    },
  };
});

export const client = new ApolloClient({
  connectToDevTools: process.env.NODE_ENV === 'development',
  link: from([errorLink, authLink]).split(
    (operation) =>
      operation.getContext().microservice === USER_MANAGEMENT_MICROSERVICE,
    userManagementLink as any,
    split(
      (operation) =>
        operation.getContext().microservice === COLLECT_MICROSERVICE,
      collectLink as any,
      split(
        (operation) =>
          operation.getContext().microservice === CHRONOS_MICROSERVICE,
        chronosLink as any,
        split(
          (operation) =>
            operation.getContext().microservice === ATHENA_MICROSERVICE,
          athenaLink as any,
          mainLink as any,
        ),
      ),
    ),
  ),
  cache,
});

export const JWT_ERROR_QUERY_PARAM = 'jwtError';

const logout = async () => {
  removeToken();
  removeRefreshToken();
  sessionStorage.clear();

  try {
    await client.clearStore();
  } catch (e) {}

  addUsetiful();

  history.push(`${AUTH_ROUTES[0].path}?${JWT_ERROR_QUERY_PARAM}`);
};
