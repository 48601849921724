import { Access, AccessUserInherit } from '../types';
import { IFile, Metadata } from '../common/types';

export enum STRUCTURE_ELEMENT_TYPE {
  PAGE = 'page',
  LABEL = 'label',
  GROUP = 'group',
  LINK = 'link',
  SECTION = 'section',
  // FE only
  GROUP_END = 'GROUP_END',
}

export type StructureConfigElement = {
  type: STRUCTURE_ELEMENT_TYPE;
  pageId?: string;
  text?: string;
  title?: string;
  groupElements?: StructureConfigElement[];
  url?: string;
  // FE only
  id: string;
  _groupElements?: StructureConfigElement[];
};

export type Variable = {
  name: string;
  value: string;
};

export type StructureConfig = {
  title: string;
  name: string;
  evaluatedName?: string;
  datasourceId?: string;
  url: string;
  info: string;
  allowExtendedLibrary: boolean;
  elements: StructureConfigElement[];
  icon?: IFile;
  variables: Variable[];
};

export type StructureAccess = Access & { users: AccessUserInherit[] };

export type Structure = Metadata & {
  icon?: IFile;
  structureConfig: StructureConfig;
  access: StructureAccess;
};

export type ITag = {
  entityIds: string[];
  id: string;
  title: string;
};
