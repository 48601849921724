import React, { FC } from 'react';
import { Icon } from '@blueprintjs/core';
import styled from 'styled-components';
import { BACKGROUND_COLOR_TAG, CARET } from './@constants';

const IndicatorIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  & > svg {
    display: inline;
  }
`;

interface Props {
  value: string | number;
  tag?: typeof BACKGROUND_COLOR_TAG[keyof typeof BACKGROUND_COLOR_TAG];
  icon?: typeof CARET[keyof typeof CARET];
  customIcon?: string;
  customClassName?: string;
}

const BackgroundColorTag: FC<Props> = ({
  icon,
  value,
  tag = 'grey',
  customClassName = '',
}) => {
  const tagType = BACKGROUND_COLOR_TAG[tag] || BACKGROUND_COLOR_TAG.grey;

  return (
    <div
      className={`
      bg-tag
      u-align-center-y
      u-justify-center
      ${icon && 'bg-tag__padding__icon'}
      ${tagType}
      ${customClassName}
      `}
    >
      {icon && (
        <IndicatorIcon
          icon={icon as any}
          iconSize={20}
          className="u-margin-right-5"
        />
      )}
      {value}
    </div>
  );
};

export default BackgroundColorTag;
