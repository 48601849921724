import React, { FC } from 'react';
import { ReactComponent as Page } from '../../../assets/icons/page.svg';
import { ReactComponent as Widget } from '../../../assets/icons/widget.svg';
import { ReactComponent as Structure } from '../../../assets/icons/structure.svg';
import { ReactComponent as Site } from '../../../assets/icons/structure.svg';
import { ReactComponent as Form } from '../../../assets/icons/form.svg';
import { ENTITY_TYPES } from '../../../gql/types';
import { VERSION_TYPE } from '../../../gql/dataSource/types';
import {
  CrossIcon,
  DotIcon,
  EntityIconWrapper,
  ImageIcon,
  IndicatorIconPlace,
  VersionTypeIcon,
} from './styles';

type Props = {
  type: ENTITY_TYPES;
  color?: string;
  versionTypeIcon?: VERSION_TYPE;
  icon?: FC | { file: string };
  // Icon size in px
  iconSize?: number;
  // Whether to show the version icon based on 'treeNodeColorMap'
  hideVersionIcon?: boolean;
};

const ICONS_MAP = {
  [ENTITY_TYPES.PAGE]: <Page />,
  [ENTITY_TYPES.STRUCTURE]: <Structure />,
  [ENTITY_TYPES.WIDGET]: <Widget />,
  [ENTITY_TYPES.QUERY]: <Widget />,
  Site: <Site />,
  Form: <Form />,
};

const EntityIcon: FC<Props> = ({
  type,
  color,
  versionTypeIcon,
  icon,
  iconSize = 24,
  hideVersionIcon = false,
}) => {
  const Icon = icon ? (
    'file' in icon ? (
      <ImageIcon src={icon.file} />
    ) : (
      React.createElement(icon)
    )
  ) : (
    ICONS_MAP[type]
  );

  return (
    <EntityIconWrapper iconSize={iconSize}>
      {Icon}
      {!hideVersionIcon &&
        (color === 'orange' ? (
          <>
            <IndicatorIconPlace />
            <CrossIcon />
          </>
        ) : (
          color === 'blue' && (
            <>
              <IndicatorIconPlace />
              <DotIcon />
            </>
          )
        ))}
      {versionTypeIcon && <VersionTypeIcon versionType={versionTypeIcon} />}
    </EntityIconWrapper>
  );
};

export default EntityIcon;
