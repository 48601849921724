import { gql } from '@apollo/client';
import { fileFragment, metadata } from '../common/fragments';

export const baseStructureFragment = gql`
  fragment BaseStructure on BaseGroupElement {
    type
    pageId
    title
    text
    url
  }
`;

export const variableFragment = gql`
  fragment Variable on Variable {
    name
    value
  }
`;

export const structureConfigElementFragment = gql`
  fragment StructureConfigElement on BaseStructureElement {
    type
    pageId
    text
    title
    groupElements {
      ...BaseStructure
    }
    url
  }
  ${baseStructureFragment}
`;

export const structureConfigFragment = gql`
  fragment StructureConfig on BaseStructureConfig {
    title
    name
    datasourceId
    url
    info
    evaluatedName
    allowExtendedLibrary
    elements {
      ...StructureConfigElement
    }
    variables {
      ...Variable
    }
  }
  ${structureConfigElementFragment}
  ${variableFragment}
`;

export const structureUserFragment = gql`
  fragment StructureUser on StructureUser {
    groupId
    userId
    inherit
  }
`;

export const structureFragment = gql`
  fragment Structure on Structure {
    ${metadata}
    icon {
        ...File
    }
    structureConfig {
      ...StructureConfig
    }
    access {
      public
      users {
        ...StructureUser
      }
    }
  }
  ${structureConfigFragment}
  ${structureUserFragment}
  ${fileFragment}
`;

export const builtStructureFragment = gql`
  fragment Structure on BuiltStructure {
    ${metadata}
    icon {
        ...File
    }
    structureConfig {
      ...StructureConfig
    }
    access {
      public
      users {
        ...StructureUser
      }
    }
  }
  ${structureConfigFragment}
  ${structureUserFragment}
  ${fileFragment}
`;

export const tagFragment = gql`
  fragment Tag on Tag {
    entityIds
    id
    title
  }
`;
