import * as yup from 'yup';
import { QUERY_OPERATION } from '../../../../gql/query/types';
import { CONFIG_ELEMENT_VALUES_TYPE } from '../../../../gql/widget/types';

export enum COLUMN_TYPE {
  DIMENSION = 'DIMENSION',
  MEASURE = 'MEASURE',
}

export type Column = {
  columnType?: COLUMN_TYPE;
  type: CONFIG_ELEMENT_VALUES_TYPE | string;
  allowedOperations?: QUERY_OPERATION[];
  name: string;
  column: string;
  description?: string;
  hidden: boolean;
};

export type QueryProperties = {
  name: string;
  title: string;
  columns: Column[];
  isHidden: boolean;
};

export const validationSchema = yup.object().shape({
  columns: yup.array().of(
    yup.object().shape({
      columnType: yup
        .string()
        .oneOf(Object.values(COLUMN_TYPE))
        .required('Required'),
      name: yup.string().required('Required'),
      description: yup.string().notRequired(),
      type: yup
        .string()
        .oneOf(Object.values(CONFIG_ELEMENT_VALUES_TYPE))
        .notRequired(),
      allowedOperations: yup
        .array()
        .of(yup.string().oneOf(Object.values(QUERY_OPERATION)))
        .notRequired()
        .nullable(),
    }),
  ),
});
