import React, {
  FC,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  AddColumn,
  ColResizeHandle,
  Column as StyledColumn,
  DeleteRowBtn,
  Grid,
  RowDragHandle,
} from './styles';
import {
  AppliedWidgetFilter,
  FILTER_TYPES,
} from '../../../../gql/widget/types';
import {
  BuiltPageConfig,
  Column,
  HORIZONTAL_ALIGN,
  LAYOUT_TYPE,
  LAYOUT_TYPE_VALS,
  LayoutElement,
} from '../../../../gql/page/types';
import { useInView } from 'react-intersection-observer';
import RowSeparator, { MAX_CREATED_ROWS } from './RowSeparator';
import {
  DND_TYPE,
  getIsEditorPage,
  useCurrentLocalPage,
  useEditingContext,
} from '../editing';
import AddWidget from './AddWidget';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { ReactComponent as Add } from './RowSeparator/add.svg';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { ReactComponent as DragHandleIcon } from '../../../../assets/icons/drag-handle.svg';
import { Menu, MenuItem, mergeRefs } from '@blueprintjs/core';
import { v4 } from 'uuid';
import Widget from '../../../../components/Widget';
import { getIsDA } from '../../../../gql/user/local';
import { PreviewProperties } from '../../../../components/EditorPanels/Preview';

export const isFreetextFilterType = (type?: FILTER_TYPES) =>
  type && [FILTER_TYPES.FREETEXT, FILTER_TYPES.FREETEXT_VECTOR].includes(type);

const LAYOUT_TYPE_MAX_WIDGET = {
  [LAYOUT_TYPE.ONE_COL]: 1,
  [LAYOUT_TYPE.TWO_COL]: 2,
  [LAYOUT_TYPE.THREE_COL]: 3,
  [LAYOUT_TYPE.FOUR_COL]: 4,
  [LAYOUT_TYPE.FIVE_COL]: 5,
  [LAYOUT_TYPE.SIX_COL]: 6,
  [LAYOUT_TYPE.SEVEN_COL]: 7,
  [LAYOUT_TYPE.EIGHT_COL]: 8,
  [LAYOUT_TYPE.NINE_COL]: 9,
  [LAYOUT_TYPE.TEN_COL]: 10,
};

type ColumnListProps = Omit<
  PageRowProps,
  'id' | 'columns' | 'setAllWidgetsLoaded'
> & {
  elements: LayoutElement[];
  colInd: number;
  setLoadedWidget: (id: string) => void;
  colspan: number;
  horizontalAlign?: HORIZONTAL_ALIGN;
  previewProperties?: PreviewProperties;
};

const WidgetsColumn: FC<ColumnListProps> = ({
  structureId,
  elements,
  pageUrl,
  ind,
  colInd,
  isStructurePreview,
  inView,
  setLoadedWidget,
  pageScenarioIds,
  defaultPageFilters,
  pageFilters,
  page,
  type,
  colspan,
  horizontalAlign,
  previewProperties,
}) => {
  return (
    <>
      {elements
        .filter((el) => el.type === 'widget')
        .map(({ widgetId }, widgetInd) => {
          const key = `${pageUrl}-${ind}-${colInd}-${widgetInd}-${widgetId}`;

          return (
            <div className="widget-pdf" key={key}>
              <Widget
                id={widgetId as string}
                structureId={structureId}
                isStructurePreview={isStructurePreview}
                inView={inView}
                setLoadedWidget={setLoadedWidget}
                identifier={key}
                pageScenarioIds={pageScenarioIds}
                defaultPageFilters={defaultPageFilters}
                pageFilters={pageFilters.map(({ title, ...rest }) => rest)}
                pageFilterSections={page?.filterSections}
                defaultFilters={
                  page?.defaultFilters?.find(
                    (filter) => filter.widgetId === widgetId,
                  )?.filters
                }
                filtersForPrint={
                  page.pageFilters
                    ?.map(
                      ({
                        title,
                        defaultSelectedFilter,
                        id,
                        showTotal,
                        labels,
                        values,
                      }) => {
                        const selectedFilter = pageFilters.find(
                          ({ filterId }) => filterId === id,
                        );

                        const defaultFilter =
                          !showTotal && defaultSelectedFilter;

                        let selectedLabel: any = isFreetextFilterType(
                          selectedFilter?.type,
                        )
                          ? selectedFilter?.value
                          : selectedFilter?.title;

                        if (
                          selectedFilter &&
                          Array.isArray(selectedFilter.value)
                        ) {
                          const multiLabels = selectedFilter.value.map(
                            (val) => {
                              const valInd = values.findIndex((v) => v === val);

                              return labels[valInd];
                            },
                          );

                          selectedLabel = multiLabels.length && multiLabels;
                        }

                        return {
                          title,
                          value: selectedLabel || defaultFilter,
                        };
                      },
                    )
                    .filter(({ value }) => Boolean(value)) || []
                }
                colNum={LAYOUT_TYPE_MAX_WIDGET[type] / colspan}
                horizontalAlign={horizontalAlign}
                rowInd={ind}
                colInd={colInd}
                widgetInd={widgetInd}
                previewProperties={previewProperties}
              />
            </div>
          );
        })}
    </>
  );
};

const ColumnMemo = memo(WidgetsColumn);

const COL_MAX_ELEMENTS = 5;
const MAX_COLS_PER_ROW = 5;

export const INITIAL_COLSPAN = 2;

const TOTAL_COL_SPANS = LAYOUT_TYPE_VALS.length;

const getNewCol = (colspan?: number) => ({
  elements: [],
  colspan: colspan || INITIAL_COLSPAN,
});

export type PageRowProps = {
  ind: number;
  id: string;
  columns: Column[];
  structureId: string;
  isStructurePreview: boolean;
  separator?: boolean;
  pageFilters: Array<AppliedWidgetFilter & { title?: string }>;
  page: BuiltPageConfig;
  type: LAYOUT_TYPE;
  inView: boolean;
  setAllWidgetsLoaded: React.Dispatch<SetStateAction<boolean[]>>;
  pageUrl?: string;
  pageScenarioIds?: string[];
  defaultPageFilters: AppliedWidgetFilter[];
  previewProperties?: PreviewProperties;
};

const PageRow: FC<PageRowProps> = ({
  page,
  id,
  pageFilters,
  isStructurePreview,
  structureId,
  ind,
  columns,
  type,
  inView: manualInView,
  setAllWidgetsLoaded,
  pageUrl,
  pageScenarioIds,
  defaultPageFilters,
  previewProperties,
}) => {
  const [resizeData, setResizeData] = useState({
    initialX: 0,
    isDragging: false,
    colInd: -1,
    left: false,
  });

  const [loadedWidgets, setLoadedWidgets] = useState<{
    [key: string]: boolean;
  }>({ id: false });

  const [highlight, setHighlight] = useState(false);

  const setLoadedWidget = useCallback(
    (id: string) => {
      if (loadedWidgets[id]) {
        return;
      }

      setLoadedWidgets((prev) => ({
        ...prev,
        [id]: true,
      }));
    },
    [loadedWidgets],
  );

  const maxCols = LAYOUT_TYPE_MAX_WIDGET[type];
  let colspanCount = 0;

  const removeInd = columns.findIndex(({ colspan = 1 }) => {
    colspanCount += colspan;

    return colspanCount > maxCols;
  });

  useEffect(() => {
    setLoadedWidgets(
      columns
        .filter((_, colInd) => !(removeInd !== -1 && colInd >= removeInd))
        .flatMap(({ elements }) => elements)
        .filter((el) => el.type === 'widget')
        .reduce(
          (acc, { widgetId }) => ({
            ...acc,
            [widgetId as string]: false,
          }),
          {},
        ),
    );
  }, [columns, removeInd]);

  const { ref, inView: autoInView } = useInView({
    triggerOnce: true,
    fallbackInView: true,
    initialInView: ind <= 5,
  });

  useEffect(() => {
    if (Object.values(loadedWidgets).every(Boolean)) {
      setAllWidgetsLoaded((prev) => {
        const newState = [...prev];
        newState[ind] = true;

        return newState;
      });
    }
  }, [ind, loadedWidgets, setAllWidgetsLoaded]);

  const inView = manualInView || autoInView;

  const { isCustomerEditorModeEnabled, updatePageConfig } = useEditingContext();

  const localPage = useCurrentLocalPage();

  const isEditing =
    isCustomerEditorModeEnabled &&
    (page.allowCustomerEdits || getIsDA() || getIsEditorPage(pageUrl));

  const layoutCopy = useMemo(
    () => [...(localPage?.page?.pageConfig?.layout || [])],
    [localPage?.page?.pageConfig?.layout],
  );

  const layoutTypeInd = LAYOUT_TYPE_VALS.indexOf(type);

  const isNewColDisabled = columns.length >= MAX_COLS_PER_ROW;

  const isAutoNewColDisabled = isNewColDisabled || type === LAYOUT_TYPE.TEN_COL;

  const isEmptyRow = useMemo(
    () => columns.every((c) => !c?.elements?.length),
    [columns],
  );

  useEffect(() => {
    const onUp = () => {
      setResizeData((prev) =>
        prev.isDragging
          ? {
              initialX: 0,
              isDragging: false,
              colInd: -1,
              left: false,
            }
          : prev,
      );
    };

    const onDrag = (e: MouseEvent) => {
      const { initialX, isDragging, colInd, left } = resizeData;

      if (!isDragging) {
        return;
      }

      const diff = (e.clientX - initialX) * (left ? -1 : 1);
      const currentTypeInd = LAYOUT_TYPE_VALS.indexOf(layoutCopy[ind].type);

      const stepSize = 110 - 10 * (currentTypeInd + 1);

      if (Math.abs(diff) < stepSize) {
        return;
      }

      const isIncrase = diff > 0;

      const isFirstLeft = colInd === 0 && left;
      const isLastRight = colInd === columns.length - 1 && !left;

      if ((isFirstLeft || isLastRight) && isIncrase) {
        return;
      }

      const isMaxLimit = currentTypeInd >= LAYOUT_TYPE_VALS.length - 1;
      const isMinLimit = !currentTypeInd;

      let newTypeInd;

      if (isIncrase && isMaxLimit) {
        newTypeInd = LAYOUT_TYPE_VALS.length - 1;
      } else if (isIncrase && !isMaxLimit) {
        newTypeInd = currentTypeInd + 1;
      } else if (!isIncrase && isMinLimit) {
        newTypeInd = 0;
      } else {
        newTypeInd = currentTypeInd - 1;
      }

      const currentColspan = columns[colInd].colspan ?? 1;

      let newColspan;

      const isCreatingNewCol = (isFirstLeft || isLastRight) && !isIncrase;

      if (isIncrase && isMaxLimit) {
        newColspan = currentColspan;
      } else if (isIncrase && !isMaxLimit) {
        newColspan = currentColspan + 1;
      } else if (currentColspan === 1 && !isCreatingNewCol) {
        return;
      } else {
        newColspan = currentColspan - 1;
      }

      let isNewFirst = false;
      let isNewLast = false;
      let increment;

      if (isCreatingNewCol) {
        if (isAutoNewColDisabled) {
          return;
        }

        increment =
          currentTypeInd + INITIAL_COLSPAN >= TOTAL_COL_SPANS
            ? INITIAL_COLSPAN - 1
            : INITIAL_COLSPAN;

        newTypeInd = currentTypeInd + increment;
        newColspan = currentColspan;

        isNewFirst = isFirstLeft;
        isNewLast = isLastRight;
      }

      updatePageConfig(
        {
          layout: [
            ...layoutCopy.slice(0, ind),
            {
              ...layoutCopy[ind],
              type: LAYOUT_TYPE_VALS[newTypeInd],
              columns: [
                ...(isNewFirst ? [getNewCol(increment)] : []),
                ...columns.slice(0, colInd),
                {
                  ...columns[colInd],
                  colspan: newColspan,
                },
                ...columns.slice(colInd + 1),
                ...(isNewLast ? [getNewCol(increment)] : []),
              ],
            },
            ...layoutCopy.slice(ind + 1),
          ],
        },
        localPage?.page?.id,
      );

      setResizeData({
        initialX: e.clientX,
        isDragging: !isCreatingNewCol,
        colInd,
        left,
      });
    };

    document.addEventListener('mouseup', onUp);
    document.addEventListener('mousemove', onDrag);

    return () => {
      document.removeEventListener('mouseup', onUp);
      document.removeEventListener('mousemove', onDrag);
    };
  }, [
    columns,
    ind,
    isAutoNewColDisabled,
    layoutCopy,
    localPage?.page?.id,
    resizeData,
    updatePageConfig,
  ]);

  if (isEmptyRow && !isEditing) {
    return null;
  }

  return (
    <Draggable
      draggableId={`${DND_TYPE.ROW}-${id}`}
      index={ind}
      isDragDisabled={!isEditing}
    >
      {(provided, snapshot) => {
        const isHighlight = highlight || snapshot.isDragging;

        return (
          <div ref={provided.innerRef} {...provided.draggableProps}>
            <Grid
              colNum={maxCols}
              highlight={isHighlight}
              className="page-row"
              ref={ref}
            >
              {columns.map(
                (
                  { elements, verticalAlign, horizontalAlign, colspan = 1 },
                  colInd,
                ) => {
                  if (removeInd !== -1 && colInd >= removeInd) {
                    return null;
                  }

                  const isEmptyCol = isEditing && !elements?.length;

                  return (
                    <Droppable
                      key={colInd}
                      droppableId={`${ind}-${colInd}-${id}`}
                      type={DND_TYPE.WIDGET}
                      isDropDisabled={elements.length >= COL_MAX_ELEMENTS}
                    >
                      {(provided, snapshot) => {
                        const isLastDragged =
                          snapshot.draggingFromThisWith &&
                          elements.length === 1;

                        const resizeHandleCommonProps = {
                          isResizing: resizeData.isDragging,
                          isResizingThis:
                            resizeData.isDragging &&
                            resizeData.colInd === colInd,
                          className: 'resize-handle',
                        };

                        return (
                          <StyledColumn
                            verticalAlign={verticalAlign}
                            colspan={colspan}
                            className="page-column"
                            highlight={snapshot.isDraggingOver && !isEmptyCol}
                            isEditing={isEditing}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {isEditing && (
                              <ColResizeHandle
                                {...resizeHandleCommonProps}
                                onMouseDown={(e) => {
                                  setResizeData({
                                    initialX: e.clientX,
                                    isDragging: true,
                                    colInd,
                                    left: false,
                                  });
                                }}
                              />
                            )}
                            {isEditing && (
                              <ColResizeHandle
                                {...resizeHandleCommonProps}
                                left
                                onMouseDown={(e) => {
                                  setResizeData({
                                    initialX: e.clientX,
                                    isDragging: true,
                                    colInd,
                                    left: true,
                                  });
                                }}
                              />
                            )}
                            {snapshot?.draggingFromThisWith &&
                              elements.length === 1 && (
                                <AddWidget
                                  rowInd={ind}
                                  colInd={colInd}
                                  isDraggingFrom={
                                    !!snapshot.draggingFromThisWith
                                  }
                                />
                              )}
                            {isEmptyCol ? (
                              <AddWidget
                                rowInd={ind}
                                colInd={colInd}
                                highlight={snapshot.isDraggingOver}
                              />
                            ) : (
                              <>
                                <ColumnMemo
                                  elements={elements}
                                  structureId={structureId}
                                  pageUrl={pageUrl}
                                  ind={ind}
                                  colInd={colInd}
                                  isStructurePreview={isStructurePreview}
                                  inView={inView}
                                  setLoadedWidget={setLoadedWidget}
                                  pageScenarioIds={pageScenarioIds}
                                  defaultPageFilters={defaultPageFilters}
                                  pageFilters={pageFilters}
                                  page={page}
                                  type={type}
                                  colspan={colspan}
                                  horizontalAlign={horizontalAlign}
                                  previewProperties={previewProperties}
                                />
                                {!isLastDragged && provided.placeholder}
                              </>
                            )}
                          </StyledColumn>
                        );
                      }}
                    </Droppable>
                  );
                },
              )}
              {isEditing && (
                <AddColumn disabled={isNewColDisabled}>
                  <Tooltip2
                    content={
                      <span className="font-12">
                        {isNewColDisabled
                          ? 'Maximale Anzahl von Spalten ist erreicht.'
                          : 'Neue Spalte hinzufügen.'}
                      </span>
                    }
                    placement="left"
                    popoverClassName="u-margin-bottom-50"
                  >
                    <Add
                      className="add-icon"
                      onClick={() => {
                        if (isNewColDisabled) {
                          return;
                        }

                        const increment =
                          layoutTypeInd + INITIAL_COLSPAN >= TOTAL_COL_SPANS
                            ? INITIAL_COLSPAN - 1
                            : INITIAL_COLSPAN;

                        const newTypeInd = layoutTypeInd + increment;

                        const isExceedingMax = newTypeInd >= TOTAL_COL_SPANS;

                        const newType =
                          LAYOUT_TYPE_VALS[
                            isExceedingMax ? TOTAL_COL_SPANS - 1 : newTypeInd
                          ];

                        let newCols = [...columns, getNewCol(increment)];

                        if (isExceedingMax) {
                          let maxColspan = 0;
                          let maxColspanInd = 0;

                          columns.forEach((col, ind) => {
                            const colspan = col.colspan ?? 1;

                            if (colspan > maxColspan) {
                              maxColspanInd = ind;
                              maxColspan = colspan;
                            }
                          });

                          newCols = [
                            ...columns.slice(0, maxColspanInd),
                            {
                              ...columns[maxColspanInd],
                              colspan: maxColspan - 1,
                            },
                            ...columns.slice(maxColspanInd + 1),
                            getNewCol(1),
                          ];
                        }

                        updatePageConfig(
                          {
                            layout: [
                              ...layoutCopy.slice(0, ind),
                              {
                                ...layoutCopy[ind],
                                type: newType,
                                columns: newCols,
                              },
                              ...layoutCopy.slice(ind + 1),
                            ],
                          },
                          localPage?.page?.id,
                        );
                      }}
                    />
                  </Tooltip2>
                </AddColumn>
              )}
              {isEditing && (
                <RowDragHandle
                  className="delete-row-btn"
                  highlight={isHighlight}
                  onMouseEnter={() => setHighlight(true)}
                  onMouseLeave={() => setHighlight(false)}
                  {...provided.dragHandleProps}
                >
                  <Popover2
                    placement="left-start"
                    popoverClassName="row-drag-handle"
                    minimal
                    content={
                      <Menu>
                        <MenuItem
                          text="Löschen"
                          onClick={() => {
                            updatePageConfig(
                              {
                                layout: [
                                  ...layoutCopy.slice(0, ind),
                                  ...layoutCopy.slice(ind + 1),
                                ],
                              },
                              localPage?.page?.id,
                            );
                          }}
                        />
                        <MenuItem
                          text="Duplizieren"
                          disabled={layoutCopy.length >= MAX_CREATED_ROWS}
                          onClick={() => {
                            updatePageConfig(
                              {
                                layout: [
                                  ...layoutCopy.slice(0, ind + 1),
                                  { ...layoutCopy[ind], id: v4() },
                                  ...layoutCopy.slice(ind + 1),
                                ],
                              },
                              localPage?.page?.id,
                            );
                          }}
                        />
                      </Menu>
                    }
                    renderTarget={({
                      isOpen: isPopoverOpen,
                      ref: ref1,
                      ...popoverProps
                    }) => (
                      <Tooltip2
                        content={
                          <div className="font-12">
                            <div>Ziehen zum Verschieben.</div>
                            <div>Zum Menü öffnen anklicken.</div>
                          </div>
                        }
                        disabled={isPopoverOpen}
                        placement="top"
                        openOnTargetFocus={false}
                        renderTarget={({
                          isOpen: isTooltipOpen,
                          ref: ref2,
                          ...tooltipProps
                        }) => (
                          <DeleteRowBtn
                            {...popoverProps}
                            {...tooltipProps}
                            ref={mergeRefs(ref1, ref2)}
                            highlight={isHighlight || isPopoverOpen}
                          >
                            <DragHandleIcon />
                          </DeleteRowBtn>
                        )}
                      />
                    )}
                  />
                </RowDragHandle>
              )}
            </Grid>
            {isEditing && <div className="u-margin-bottom-60" />}
            <RowSeparator
              rowIndex={ind + 1}
              structureId={structureId}
              disabled={!isEditing}
            />
          </div>
        );
      }}
    </Draggable>
  );
};

export default PageRow;
