import { Form, Formik } from 'formik';
import styled from 'styled-components';
import { colors } from '../../../utils/colors';
import { FormGroup } from '@blueprintjs/core';

export const Wrapper = styled.div``;

export const DetailsFormik = styled(Formik)``;

export const DetailsForm = styled(Form)`
  &&& input {
    background: ${colors.white};
  }
  &&& .highlight {
    display: none;
  }
`;

export const DetailsFormGroup = styled(FormGroup)`
  margin: 10px 0 0 15px;
  font-size: 10px;
  color: ${colors.monochromatic2};

  &&& label span {
    color: ${colors.secondary0};
    margin-left: -4px;
  }

  & .searchable-outer-target {
    justify-content: flex-start;
  }
`;

export const FormFieldActions = styled.div`
  display: none;
  align-items: center;
  gap: 12px;

  position: absolute;
  right: 4%;
  top: 50%;

  transform: translateY(-50%);

  border: 1px solid ${colors.monochromatic5};
  border-radius: 4px;

  padding: 3px 5px;
`;

export const FormFieldWrapper = styled.div`
  position: relative;

  &&& input {
    cursor: pointer;

    display: flex;
    align-items: flex-start;
  }

  &:hover ${FormFieldActions} {
    display: flex;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  padding: 2px;
  width: 26px;
  height: 26px;

  &:hover {
    background: ${colors.primary5};
    border-radius: 5px;
    box-shadow: 0px 2px 8px rgba(41, 38, 38, 0.23);
  }

  &:first-child:hover svg {
    path {
      fill: ${colors.primary2};
    }
  }

  &:last-child:hover svg {
    path {
      stroke: ${colors.primary2};
    }
  }
`;
