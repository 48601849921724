import { InMemoryCache } from '@apollo/client';

export const cache = new InMemoryCache({
  typePolicies: {
    DataSource: {
      keyFields: ['versionId'],
    },
    Widget: {
      keyFields: (_, { fragmentMap }: any) => {
        return [fragmentMap?.WidgetTree ? 'id' : 'versionId'];
      },
    },
    Page: {
      keyFields: (_, { fragmentMap }: any) => {
        return [fragmentMap?.PageTree ? 'id' : 'versionId'];
      },
    },
    Structure: {
      keyFields: (_, { fragmentMap }: any) => {
        return [fragmentMap?.StructureTree ? 'id' : 'versionId'];
      },
    },
    Site: {
      keyFields: ['versionId'],
    },
    /* Hierarchy: {
        keyFields: false,
      }, */
    PageTree: {
      keyFields: false,
    },
    StructureTree: {
      keyFields: false,
    },
    MapChartWidget: {
      keyFields: ['versionId'],
    },
    LineChartWidget: {
      keyFields: ['versionId'],
    },
    ColumnChartWidget: {
      keyFields: ['versionId'],
    },
    BarChartWidget: {
      keyFields: ['versionId'],
    },
    SimpleStatWidget: {
      keyFields: ['versionId'],
    },
    TableWidgetType: {
      keyFields: ['versionId'],
    },
    PieChartWidget: {
      keyFields: ['versionId'],
    },
    Form: {
      keyFields: ['versionId'],
    },
    // Widget
    WidgetAccess: {
      keyFields: false,
    },
    WidgetUser: {
      keyFields: false,
    },
    BaseWidgetConfig: {
      keyFields: false,
    },
    BaseWidgetFilter: {
      keyFields: false,
    },
    BaseWidgetPageFilter: {
      keyFields: false,
    },
    BaseData: {
      keyFields: false,
    },
    BaseDomain: {
      keyFields: false,
    },
    ChartHover: {
      keyFields: false,
    },
    ChartDataConfig: {
      keyFields: false,
    },
    BuiltWidgetFiltersConfig: {
      keyFields: false,
    },
    BaseSeparator: {
      keyFields: false,
    },
    BaseHighlight: {
      keyFields: false,
    },
    FilterSection: {
      keyFields: false,
    },
    FilterScenario: {
      keyFields: false,
    },
    BuiltFilterSectionConfig: {
      keyFields: false,
    },
    BuiltFilterScenarioConfig: {
      keyFields: false,
    },
    //  Page
    PageAccess: {
      keyFields: false,
    },
    PageUser: {
      keyFields: false,
    },
    BasePageConfig: {
      keyFields: false,
    },
    BasePageFilter: {
      keyFields: false,
    },
    PageFilterDependency: {
      keyFields: false,
    },
    BaseLayout: {
      keyFields: false,
    },
    BaseColumn: {
      keyFields: false,
    },
    BaseLayoutElement: {
      keyFields: false,
    },
    BuiltPageFiltersConfig: {
      keyFields: false,
    },
    BuiltPageConfig: {
      keyFields: ['versionId'],
    },
    //  Structure
    StructureAccess: {
      keyFields: false,
    },
    StructureUser: {
      keyFields: false,
    },
    BaseStructureConfig: {
      keyFields: false,
    },
    BaseStructureElement: {
      keyFields: false,
    },
    BaseGroupElement: {
      keyFields: false,
    },
    File: {
      keyFields: false,
    },
    Variable: {
      keyFields: false,
    },
    //   Site
    BaseSiteConfig: {
      keyFields: false,
    },
    //  Map Chart
    MapChartWidgetConfig: {
      keyFields: false,
    },
    MapChartWidgetRegion: {
      keyFields: false,
    },
    Coordinates: {
      keyFields: false,
    },
    MapChartWidgetData: {
      keyFields: false,
    },
    //  Line Chart
    LineChartWidgetConfig: {
      keyFields: false,
    },
    //  Column Chart
    ColumnChartWidgetConfig: {
      keyFields: false,
    },
    //  Bar Chart
    BarChartWidgetConfig: {
      keyFields: false,
    },
    // Simple Stat
    SimpleStatWidgetConfig: {
      keyFields: false,
    },
    SimpleStatData: {
      keyFields: false,
    },
    BuiltSimpleStatData: {
      keyFields: false,
    },
    // Table
    TableWidgetConfig: {
      keyFields: false,
    },
    TableWidgetData: {
      keyFields: false,
    },
    TableSpanningHeader: {
      keyFields: false,
    },
    TableHeader: {
      keyFields: false,
    },
    BuiltTableData: {
      keyFields: false,
    },
    // Pie Chart
    PieChartWidgetConfig: {
      keyFields: false,
    },
    // Form
    FormAccess: {
      keyFields: false,
    },
    FormUser: {
      keyFields: false,
    },
    FormConfig: {
      keyFields: false,
    },
    //  Query
    QueryType: {
      keyFields: ['versionId'],
    },
    BaseQueryConfig: {
      keyFields: false,
    },
    QueryMeasure: {
      keyFields: false,
    },
    QueryDimension: {
      keyFields: false,
    },
    //  Library
    Library: {
      keyFields: ['versionId'],
    },
    LibraryConfig: {
      keyFields: false,
    },
    InsightEntity: {
      keyFields: ['versionId'],
    },
    InsightEntityConfig: {
      keyFields: false,
    },
  },
});
