import { editor } from 'monaco-editor';
import React, { useEffect, useMemo } from 'react';
import { useNavContext } from '../../app/Layout/NavBar';
import { PageConfig } from '../../gql/page/types';
import { WidgetConfig, WidgetFilter } from '../../gql/widget/types';

export const useEditorValue = (editorValue: string) =>
  useMemo(() => {
    try {
      return JSON.parse(editorValue);
    } catch (e) {
      return '';
    }
  }, [editorValue]);

export const useQueryPageFiltersFields = (editorValue: string) =>
  useMemo(() => {
    try {
      const config = JSON.parse(editorValue) as PageConfig;

      return config.pageFilters.map(({ query }) => query);
    } catch (e) {
      return [];
    }
  }, [editorValue]);

export const useQueryWidgetFiltersFields = (
  editorValue: string,
  isScenarios?: boolean,
) =>
  useMemo(() => {
    try {
      const config = JSON.parse(editorValue) as WidgetConfig;

      const scenarios = config?.filterSections?.[0]?.filters;

      const filters = isScenarios
        ? (scenarios as WidgetFilter[])
        : config.widgetFilters;

      return filters.map(({ query }) => query);
    } catch (e) {
      return [];
    }
  }, [editorValue, isScenarios]);

export const useStandaloneEditor = (
  editor: editor.IStandaloneDiffEditor | editor.IStandaloneCodeEditor | null,
) =>
  useMemo(
    (): editor.IStandaloneCodeEditor =>
      (editor as editor.IStandaloneDiffEditor)?.getModifiedEditor?.() || editor,
    [editor],
  );

export function usePageActions(
  newPageActions: (React.JSX.Element | null)[] | null,
  deps?: any[],
) {
  const { setPageActions } = useNavContext();

  useEffect(() => {
    setPageActions(newPageActions?.filter(Boolean) as React.JSX.Element[]);

    return () => setPageActions(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
