import React, { FC } from 'react';
import { Metadata } from '../../../gql/common/types';
import { MetaItem, MetaMenu } from './styles';
import { format } from 'date-fns';

export const META_LABEL: Record<string, string> = {
  id: 'Entity ID',
  versionId: 'Version ID',
  versionNumber: 'Version number',
  createdAt: 'Created at',
  creatorFullName: 'Created by',
  updatedAt: 'Updated at',
  updatorFullName: 'Updated by',
  versionType: 'Version type',
};

type Props = { item?: Metadata & Record<any, any> };

const MetaInfo: FC<Props> = ({ item }) => {
  if (!item) {
    return null;
  }

  const meta: Omit<Metadata, 'createdBy' | 'updatedBy' | 'editorMode'> = {
    id: item.id,
    versionId: item.versionId,
    createdAt: format(new Date(item.createdAt), 'dd.MM.yyyy HH:mm'),
    creatorFullName: item.creatorFullName,
    updatedAt: format(new Date(item.updatedAt), 'dd.MM.yyyy HH:mm'),
    updatorFullName: item.updatorFullName,
    versionType: item.versionType,
  };

  return (
    <MetaMenu>
      {Object.entries(meta).map(
        ([key, val]) =>
          val && (
            <MetaItem key={key}>
              <span>{META_LABEL[key]}: </span>
              <span id={`meta-${key}`}>{val}</span>
            </MetaItem>
          ),
      )}
    </MetaMenu>
  );
};

export default MetaInfo;
