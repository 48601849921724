import {
  CONFIG_ELEMENT_VALUES_TYPE,
  DATA_HIGHLIGHT_SOURCE,
  FILTER_TYPES,
  MAP_BG_TYPE,
  SCALE_LOGIC,
  WIDGET_TYPE,
} from '../../../../gql/widget/types';
import { colors } from '../../../../utils/colors';
import {
  PIVOT_WIDGET_TYPES,
  TYPES_WITHOUT_HOVERS,
  TYPES_WITHOUT_WIDGET_FILTERS,
} from '../WidgetSchema';

export const getCommon = (
  type: WIDGET_TYPE | '',
  additionalToLevelFields?: Record<string, string>,
) => ({
  title: '',
  name: '',
  type: type,
  queryId: '',
  info: '',
  ...(additionalToLevelFields || {}),
  pageFilters: [
    {
      id: '',
      column: '',
      type: FILTER_TYPES.DROPDOWN,
    },
  ],
  ...(!TYPES_WITHOUT_WIDGET_FILTERS.includes(type as WIDGET_TYPE)
    ? {
        widgetFilters: [
          {
            id: '',
            title: '',
            type: '',
            info: '',
            valuesType: 'string',
            column: '',
            labels: '',
            totalAlgo: 'label',
            totalLabel: '',
            showTotal: false,
            injectionColumn: '',
            queryId: '',
            defaultSelectedFilter: '',
            defaultValueQueryId: '',
            defaultValueColumn: '',
            tiltLabels: false,
            dependencies: {
              widget: [
                {
                  id: '',
                  column: '',
                },
              ],
              page: [
                {
                  id: '',
                  column: '',
                },
              ],
            },
          },
        ],
        stand: {
          queryId: '',
          dateColumn: '',
          source: '',
        },
      }
    : {}),
  ...(!TYPES_WITHOUT_HOVERS.includes(type as WIDGET_TYPE)
    ? {
        hovers: [
          {
            type: 'string',
            title: '',
            column: '',
            labels: '',
            refersTo: '',
          },
        ],
      }
    : {}),
});

const getDefaultConfig = (type: WIDGET_TYPE | '') => ({
  ...getCommon(type),
  zeroBased: false,
  showDots: 'auto',
  data: [
    {
      id: '',
      title: '',
      type: 'string',
      column: '',
      labels: '',
      info: '',
      showInHover: true,
      color: '',
      ...(type === WIDGET_TYPE.LINE_CHART
        ? {
            highlights: [
              {
                start: '',
                end: '',
                type: 'dashed',
                legend: '',
                source: DATA_HIGHLIGHT_SOURCE.FIXED,
                column: '',
              },
            ],
          }
        : {}),
    },
  ],
  domains: [
    {
      type: 'xAxis',
      title: '',
      column: '',
      showValue: false,
      showAllLabels: false,
      tiltLabels: false,
      valuesType: CONFIG_ELEMENT_VALUES_TYPE.string,
      highlights: [
        {
          type: '',
          color: '',
          start: '',
          end: '',
          queryId: '',
          source: '',
        },
      ],
      separators: [
        {
          type: '',
          text: '',
          position: '',
          queryId: '',
        },
      ],
    },
  ],
  ...(PIVOT_WIDGET_TYPES.includes(type as WIDGET_TYPE)
    ? {
        pivot: {
          pivotColumn: '',
          dataColumn: '',
          type: '',
          pivotFunction: '',
          info: '',
          aggregationFunction: '',
          aggregationLabel: '',
          colors: [],
          ...(type === WIDGET_TYPE.TABLE
            ? {
                total: 'none',
                totalLabel: '',
              }
            : {}),
        },
      }
    : {}),
});

export const LINE_CONFIG = getDefaultConfig(WIDGET_TYPE.LINE_CHART);
export const GROUPED_COLUMN_CONFIG = getDefaultConfig(
  WIDGET_TYPE.GROUPED_COLUMN_CHART,
);
export const STACKED_COLUMN_CONFIG = getDefaultConfig(
  WIDGET_TYPE.STACKED_COLUMN_CHART,
);
export const BAR_CONFIG = getDefaultConfig(WIDGET_TYPE.BAR_CHART);
export const OTHER_CONFIG = getDefaultConfig('');

export const MAP_CONFIG = {
  ...getCommon(WIDGET_TYPE.CHOROPLETH_MAP),
  negativeColor: colors.secondExtended7,
  neutralColor: colors.primary0,
  positiveColor: colors.white,
  scaleLogic: SCALE_LOGIC.FROM_ZERO,
  data: [
    {
      title: '',
      type: 'string',
      showInHover: true,
      column: '',
      labels: '',
      shapes: '',
    },
  ],
  zoom: '90000',
  center: {
    x: '13.4',
    y: '52.5',
  },
  background: {
    type: MAP_BG_TYPE.OSM,
  },
};

export const TABLE_CONFIG = {
  ...getCommon(WIDGET_TYPE.TABLE),
  data: [
    {
      id: '',
      type: 'string',
      borderRight: true,
      colorColumn: '',
      column: '',
      weight: 1,
      align: 'right',
      header: '',
      info: '',
      infoColumn: '',
      additionalTextColumn: '',
      sortable: false,
      total: 'none',
      totalLabel: '',
    },
  ],
};

export const SIMPLE_STAT_CONFIG = {
  ...getCommon(WIDGET_TYPE.SIMPLE_STAT),
  data: {
    id: '',
    title: '',
    numberColumn: '',
    numberType: CONFIG_ELEMENT_VALUES_TYPE.string,
    diffColumn: '',
    diffColorColumn: '',
    diffIndicatorColumn: '',
    info: '',
  },
};

export const TEXT_CONFIG = {
  type: WIDGET_TYPE.TEXT,
  name: '',
  title: '',
  text: '',
  variables: [
    {
      name: '',
      type: 'text',
      queryId: '',
      column: '',
    },
  ],
};

export const PIE_CONFIG = {
  ...getCommon(WIDGET_TYPE.PIE_CHART, { legendPlacement: 'left' }),
  data: [
    {
      title: '',
      type: 'string',
      column: '',
      labels: '',
      showInHover: true,
    },
  ],
};
