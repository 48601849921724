import { gql } from '@apollo/client';
import {
  insightEntityFragment,
  insightQueryFragment,
  insightWidgetFragment,
  libraryFragment,
} from './fragments';
import { InsightEntity, InsightQuery, InsightWidget, Library } from './types';
import { VERSION_TYPE } from '../dataSource/types';
import { metadata } from '../common/fragments';
import { LIBRARY_ENTITY } from '../../containers/Libraries/LibraryEdit/EntityTypeTabs';
import { InviteMultipleUsersInput } from '../user/types';

export type GetLibraryVersionData = { libraryVersion: Library };

export type GetLibraryVersionVars = {
  versionId: string;
};

export const GET_LIBRARY_VERSION = gql`
  query GetLibraryVersion($versionId: String!) {
    libraryVersion(versionId: $versionId) {
      ...Library
    }
  }
  ${libraryFragment}
`;

export type GetAllLibraryVersionsData = {
  allLibraryVersions: Array<Omit<Library, 'libraryConfig'>>;
};

export type GetAllLibraryVersionsVars = {
  id: string;
};

export const GET_ALL_LIBRARY_VERSIONS = gql`
  query GetAllLibraryVersions($id: String!) {
    allLibraryVersions(id: $id) {
      ${metadata}
    }
  }
`;

export type SearchLibrariesData = { searchLibraries: Library[] };

export type SearchLibrariesVars = {
  searchInput: {
    text: string;
    versionType: VERSION_TYPE;
    sortField?: string;
    sortOrder?: string;
    first?: number;
  };
};

export const SEARCH_LIBRARIES = gql`
  query SearchLibraries(
    $searchInput: SearchEntitiesInput!
  ) {
    searchLibraries(
      searchInput: $searchInput
    ) {
        ${metadata}
        libraryConfig {
            name
        }
    }
  }
`;

export const SEARCH_LIBRARIES_WITH_ELEMENTS = gql`
  query SearchLibraries(
    $searchInput: SearchEntitiesInput!
  ) {
    searchLibraries(
      searchInput: $searchInput
    ) {
        ${metadata}
        libraryConfig {
            name
            widgets
            queries
        }
    }
  }
`;

export type SearchInsightEntitiesData = {
  searchInsightEntitiesByType: InsightEntity[];
};

export type SearchInsightEntitiesVars = {
  text: string;
  insightEntityType?: LIBRARY_ENTITY;
  libraryVersionId?: string;
  first?: number;
  offset?: number;
};

export const SEARCH_INSIGHT_ENTITIES = gql`
  query SearchInsightEntitiesByType(
    $text: String!
    $insightEntityType: String
    $libraryVersionId: String
    $first: Float
    $offset: Float
  ) {
    searchInsightEntitiesByType(
      text: $text
      insightEntityType: $insightEntityType
      libraryVersionId: $libraryVersionId
      first: $first
      offset: $offset
    ) {
      ...InsightEntity
    }
  }
  ${insightEntityFragment}
`;

export type GetLiveLibrariesByUserData = {
  getLiveLibrariesByUser: Library[];
};

export type GetLiveLibrariesByUserVars = {
  user?: InviteMultipleUsersInput;
};

export const GET_LIVE_LIBRARIES_BY_USER = gql`
  query GetLiveLibrariesByUser($user: UserInput!) {
    getLiveLibrariesByUser(user: $user) {
      ...Library
    }
  }
  ${libraryFragment}
`;

export type GetLiveLibrariesByGroupData = {
  getLiveLibrariesByGroup: Library[];
};

export type GetLiveLibrariesByGroupVars = {
  groupId?: string;
};

export const GET_LIVE_LIBRARIES_BY_GROUP = gql`
  query GetLiveLibrariesByGroup($groupId: String!) {
    getLiveLibrariesByGroup(groupId: $groupId) {
      ...Library
    }
  }
  ${libraryFragment}
`;

export type GetInsightWidgetsByStructureData = {
  getInsightLiveWidgetsByStructure: InsightWidget[];
};

export type GetInsightWidgetsByStructureVars = {
  searchInput: {
    text: string;
    first: number;
    sortField?: string;
    sortOrder?: 'ASC' | 'DESC';
  };
  structureId: string;
};

export const GET_INSIGHT_WIDGETS_BY_STRUCTURE = gql`
  query GetInsightLiveWidgetsByStructure(
    $searchInput: SearchEntitiesInput!
    $structureId: String!
  ) {
    getInsightLiveWidgetsByStructure(
      searchInput: $searchInput
      structureId: $structureId
    ) {
      ...InsightWidget
    }
  }
  ${insightWidgetFragment}
`;

export type GetAllInsightWidgetsData = {
  getAllInsightLiveWidgets: InsightWidget[];
};

export type GetAllInsightWidgetsVars = {
  searchInput: {
    text: string;
    first: number;
    sortField?: string;
    sortOrder?: 'ASC' | 'DESC';
  };
};

export const GET_ALL_INSIGHT_WIDGETS = gql`
  query GetAllInsightLiveWidgets($searchInput: SearchEntitiesInput!) {
    getAllInsightLiveWidgets(searchInput: $searchInput) {
      ...InsightWidget
    }
  }
  ${insightWidgetFragment}
`;

export type GetInsightQueriesByStructureData = {
  getInsightLiveQueriesByStructure: InsightQuery[];
};

export type GetInsightQueriesByStructureVars = {
  searchInput: {
    text: string;
    first: number;
    sortField?: string;
    sortOrder?: 'ASC' | 'DESC';
  };
  structureId?: string;
};

export const GET_INSIGHT_QUERIES_BY_STRUCTURE = gql`
  query GetInsightLiveQueriesByStructure(
    $searchInput: SearchEntitiesInput!
    $structureId: String
  ) {
    getInsightLiveQueriesByStructure(
      searchInput: $searchInput
      structureId: $structureId
    ) {
      ...InsightQuery
    }
  }
  ${insightQueryFragment}
`;

export type GetAllInsightQueriesData = {
  getAllInsightLiveQueries: InsightQuery[];
};

export type GetAllInsightQueriesVars = {
  searchInput: {
    text: string;
    first: number;
    sortField?: string;
    sortOrder?: 'ASC' | 'DESC';
  };
};

export const GET_ALL_INSIGHT_QUERIES = gql`
  query GetAllInsightLiveQueries($searchInput: SearchEntitiesInput!) {
    getAllInsightLiveQueries(searchInput: $searchInput) {
      ...InsightQuery
    }
  }
  ${insightQueryFragment}
`;

export type LibraryLiveVersionData = {
  libraryLiveVersion: Library;
};

export type LibraryLiveVersionVars = {
  id: string;
};

export const LIBRARY_LIVE_VERSION = gql`
  query GetLibraryLiveVersion($id: String!) {
    libraryLiveVersion(id: $id) {
      ...Library
    }
  }
  ${libraryFragment}
`;
