import { gql } from '@apollo/client';

export const annotationUserFragment = gql`
  fragment AnnotationUser on AnnotationUser {
    id
    name
  }
`;

export const emojiAnnotationFragment = gql`
  fragment EmojiAnnotation on EmojiAnnotation {
    code
    name
    users {
      ...AnnotationUser
    }
  }
  ${annotationUserFragment}
`;

export const annotationFragment = gql`
  fragment Annotation on Annotation {
    entityVersionId
    emojiAnnotations {
      ...EmojiAnnotation
    }
  }
  ${emojiAnnotationFragment}
`;
