import cn from 'classnames';
import { FieldProps, getIn } from 'formik';
import { useId } from 'react';
import { BaseLabelProps, InputLabel } from '../InputLabel';

export type Props = FieldProps & {
  id?: string;
  placeholder: string;
  label?: string;
  type?: string;
  min?: number;
  max?: number;
  step?: number;
  hideErrors?: boolean;
  isLabelDisabled?: boolean;
} & BaseLabelProps;

export default function TextInput(props: Props) {
  const {
    id,
    field,
    form,
    placeholder,
    type = 'text',
    min,
    max,
    step,
    disabled = false,
    hideErrors = false,
    isLabelDisabled = props.disabled,
  } = props;
  const generatedId = useId();
  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  return (
    <InputLabel
      {...props}
      disabled={isLabelDisabled}
      htmlFor={id || generatedId}
      error={!hideErrors && touched && error}
    >
      <input
        id={id || generatedId}
        data-testid="text-input"
        className={cn(
          [
            'h-11',
            'w-full',
            'rounded-lg',
            'border',
            'border-concrete-jungle-5',
            'py-2.5',
            'px-3',
            'text-sm',
            'text-blueberry-1',
            'active:text-primary',
            'disabled:cursor-not-allowed',
            'disabled:text-disabled',
            'disabled:border-concrete-jungle-5',
            'disabled:bg-concrete-jungle-8',
            'focus:focused-input',
            'hover:border-concrete-jungle-3',
            'placeholder:text-concrete-jungle-3',
            'placeholder:italic',
          ],
          {
            '!border-raspberry-3': touched && error,
            'text-center': type === 'number',
          },
        )}
        {...field}
        placeholder={placeholder}
        disabled={disabled}
        type={type || 'text'}
        min={min}
        max={max}
        step={step}
      />
    </InputLabel>
  );
}
