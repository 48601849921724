import { useEffect } from 'react';

import { getLocalUserData } from '../gql/user/local';
import { ENV_VAR, getEnvVar } from './common';
import { USER_ROLE_TEXT } from './usetiful';

export const useGainsight = () => {
  useEffect(() => {
    const apiKey = getEnvVar(ENV_VAR.GAINSIGHT_API_KEY);

    if (!apiKey) {
      return;
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';

    const code = `(function(n,t,a,e,co){var i="aptrinsic";n[i]=n[i]||function(){
      (n[i].q=n[i].q||[]).push(arguments)},n[i].p=e;n[i].c=co;
    var r=t.createElement("script");r.async=!0,r.src=a+"?a="+e;
    var c=t.getElementsByTagName("script")[0];c.parentNode.insertBefore(r,c)
  })(window,document,"https://web-sdk-eu.aptrinsic.com/api/aptrinsic.js","${apiKey}");`;

    try {
      script.appendChild(document.createTextNode(code));
    } catch {
      script.text = code;
    }

    document.body.appendChild(script);

    return script.remove;
  }, []);
};

type OrganizationFields = {
  id?: string;
  name?: string;
  Program?: string;
};

export const initGainsight = (organizationFields?: OrganizationFields) => {
  const apiKey = getEnvVar(ENV_VAR.GAINSIGHT_API_KEY);
  const user = getLocalUserData();

  if (!user || !apiKey) {
    return;
  }

  const roles = Array.from(
    new Set(user.groups.flatMap(({ roles }) => roles)),
  ).join(',');

  const groupNames = user.groups.map(({ name }) => name).join(',');

  (window as any).aptrinsic(
    'identify',
    {
      id: user.sub,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      role: USER_ROLE_TEXT[user.role],
      CustomerUserRole: roles,
      UserGroup: groupNames,
    },
    {
      id: organizationFields?.id,
      name: organizationFields?.name,
    },
  );
};
