import React, { FC, ReactNode } from 'react';

interface Props {
  circleStyles?: string | { [key: string]: number | string };
  label: ReactNode;
  value: ReactNode;
}

const CrossOverContent: FC<Props> = ({ label, value, circleStyles }) => {
  let labelCircleStyles = {};

  if (typeof circleStyles === 'object') {
    labelCircleStyles = {
      style: {
        minWidth: 10,
        minHeight: 10,
        marginRight: 5,
        marginTop: -1,
        ...circleStyles,
      },
      className: 'width-10 height-10 border-radius-50',
    };
  } else if (typeof circleStyles === 'string') {
    labelCircleStyles = {
      style: { minWidth: 10, minHeight: 10, marginTop: -1 },
      className: `${circleStyles} width-10 height-10 border-radius-50 u-margin-right-5`,
    };
  }

  return (
    <div className="cross-over-content">
      <div className="u-margin-top-10 u-align-center-y u-justify-space-between">
        <div className="u-align-center-y">
          <div {...labelCircleStyles} />
          <div className="label font-12 colorMonochromatic2">{label}</div>
        </div>
        <div className="u-margin-left-5 font-14 colorMonochromatic0">
          {value}
        </div>
      </div>
    </div>
  );
};

export default CrossOverContent;
