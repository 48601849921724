import styled from 'styled-components';

export const EditWrapper = styled.div`
  height: 100%;
  display: flex;
  width: 100%;
`;

export const MainView = styled.div`
  flex: 1;
  width: 100%;
  min-width: 0;
  padding: 16px;
`;

export const EditorWrapper = styled.div`
  height: calc(100% - 80px);
`;

export const InfoBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow-x: hidden;
  margin-right: 20px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > * {
    margin-left: 10px;
  }
`;

export const BackToPage = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  > svg {
    transform: rotateZ(90deg);
    margin-right: 6px;
  }
`;
