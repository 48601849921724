import React, { FC } from 'react';
import { CheckboxProps } from '@blueprintjs/core/lib/esm/components/forms/controls';
import { StyledCheckbox } from './styles';

type Props = CheckboxProps;

const Checkbox: FC<Props> = (props) => {
  return <StyledCheckbox {...props} />;
};

export default Checkbox;
