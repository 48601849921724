import React, { FC, useMemo } from 'react';
import { SidebarCollapsible } from './SidebarCollapsible';
import {
  DeleteBtn,
  ElementInput,
  ElementWrapper,
  StyledPageLink,
} from './styles';
import {
  STRUCTURE_ELEMENT_TYPE,
  StructureConfigElement,
} from '../../../../../gql/structure/types';
import { getValidLengthTitle, useEditingContext } from '../../editing';
import { DraggableProvided } from 'react-beautiful-dnd';
import useOpen from '../../../../../utils/hooks/useOpen';
import { colors } from '../../../../../utils/colors';
import { useTranslation } from 'react-i18next';
import { getIsDA } from '../../../../../gql/user/local';

const getClickableLink = (link: string | undefined) => {
  if (!link) {
    return;
  }

  return link.startsWith('http://') || link.startsWith('https://')
    ? link
    : `http://${link}`;
};

export const DeleteIcon: FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <span title={t('action.remove')!} className="delete-icon">
      <DeleteBtn
        viewBox="0 0 24 24"
        className="u-has-transition"
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
      />
    </span>
  );
};

type Props = {
  element: StructureConfigElement;
  onPageClick: (pageId: string) => void;
  isEditModeEnabled?: boolean;
  editablePagesIds?: string[];
  selectedPage: string;
  editable?: boolean;
  ind: number;
  groupInd?: number;
  provided?: DraggableProvided;
  isSubEl?: boolean;
};

const SidebarElement: FC<Props> = ({
  element,
  onPageClick,
  isEditModeEnabled,
  editablePagesIds,
  selectedPage,
  editable,
  ind,
  groupInd,
  provided,
  isSubEl,
}) => {
  const { t } = useTranslation();
  const { open, handleOpen, handleClose } = useOpen();

  const {
    setDeletingStructureEl,
    updateStructureElement,
    updatePageConfig,
    isFullEditable,
    localStructure,
  } = useEditingContext();

  const isLastPage = useMemo(
    () =>
      localStructure?.structureConfig.elements
        .flatMap((el) => el.groupElements || el)
        .filter(({ pageId }) => pageId).length === 1,
    [localStructure?.structureConfig.elements],
  );

  const isActivePage = selectedPage === element.pageId;

  const isDA = getIsDA();

  const isEditableLabel =
    element.type === STRUCTURE_ELEMENT_TYPE.LABEL && (isDA || isFullEditable);

  const isEdit = isEditModeEnabled && (editable || isEditableLabel);
  const nonInputEdit = isEdit && !open;

  const commonInputProps = useMemo(
    () => ({
      autoFocus: true,
      onBlur: handleClose,
      onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          e.currentTarget.blur();
        }
      },
    }),
    [handleClose],
  );

  return (
    <ElementWrapper
      onDoubleClick={() => {
        if (isEdit) {
          handleOpen();
        }
      }}
      isInGroup={isEdit && isSubEl}
      disableDeleteHover={element.type === STRUCTURE_ELEMENT_TYPE.GROUP}
      className="sidebar__links__container"
      ref={provided?.innerRef}
      {...provided?.draggableProps}
      {...provided?.dragHandleProps}
    >
      {element.type === STRUCTURE_ELEMENT_TYPE.LABEL && (
        <div className="u-align-center-y font-16 colorPrimary-0 u-margin-top-10 sidebar-label">
          {open ? (
            <ElementInput
              {...commonInputProps}
              value={element.text}
              onChange={(e) => {
                const title = getValidLengthTitle(e.target.value);

                if (title === null) {
                  return;
                }

                updateStructureElement({ text: title }, ind, groupInd);
              }}
            />
          ) : (
            element.text
          )}
          {nonInputEdit && (
            <DeleteIcon onClick={() => setDeletingStructureEl(element)} />
          )}
        </div>
      )}
      {element.type === STRUCTURE_ELEMENT_TYPE.SECTION && (
        <div className="font-12 colorSecondary-0 u-margin-bottom-10 u-margin-top-20 sidebar-section">
          {open ? (
            <ElementInput
              {...commonInputProps}
              value={element.text}
              onChange={(e) => {
                const title = getValidLengthTitle(e.target.value);

                if (title === null) {
                  return;
                }

                updateStructureElement({ text: title }, ind, groupInd);
              }}
            />
          ) : (
            element.text
          )}
          {nonInputEdit && (
            <DeleteIcon onClick={() => setDeletingStructureEl(element)} />
          )}
        </div>
      )}
      {element.type === STRUCTURE_ELEMENT_TYPE.PAGE && (
        <StyledPageLink
          className={`${
            isSubEl ? 'sidebar-subpage' : 'sidebar-page'
          } u-align-center-y link__mono3 u-margin-top-10 ${
            isActivePage ? 'isRouteActive' : ''
          }`}
          onClick={() => onPageClick(element.pageId as string)}
        >
          {open ? (
            <ElementInput
              {...commonInputProps}
              value={element.title}
              onChange={(e) => {
                const title = getValidLengthTitle(e.target.value);

                if (title === null) {
                  return;
                }

                updateStructureElement({ title }, ind, groupInd);
                updatePageConfig({ title, name: title }, element.pageId);
              }}
            />
          ) : (
            element.title || t('structure.new-page')
          )}
          {isEditModeEnabled &&
            (editablePagesIds?.includes(element.pageId!) || isDA) &&
            !open &&
            !isLastPage && (
              <DeleteIcon onClick={() => setDeletingStructureEl(element)} />
            )}
        </StyledPageLink>
      )}
      {element.type === STRUCTURE_ELEMENT_TYPE.GROUP && (
        <SidebarCollapsible
          element={element}
          onPageClick={onPageClick}
          selectedPage={selectedPage}
          editable={editable}
          groupInd={ind}
          isLastPage={isLastPage}
        />
      )}
      {element.type === STRUCTURE_ELEMENT_TYPE.GROUP_END && (
        <div
          style={{ height: 20 }}
          className="d-flex align-items-center sidebar-group-end"
        >
          <div
            style={{
              borderTop: `1px solid ${colors.primary3}`,
              minHeight: 1,
              width: 60,
            }}
          />
        </div>
      )}
      {element.type === STRUCTURE_ELEMENT_TYPE.LINK &&
        (element.text || element.url) && (
          <a
            style={{
              pointerEvents: isActivePage ? 'none' : 'unset',
            }}
            className={`u-align-center-y link__alt u-cursor-pointer u-margin-top-20 sidebar-link`}
            href={getClickableLink(element.url)}
            target="_blank"
            rel="noreferrer"
          >
            <span className="u-margin-right-5">
              {element.text || element.url}
            </span>{' '}
            <span>➝</span>
            {nonInputEdit && (
              <DeleteIcon onClick={() => setDeletingStructureEl(element)} />
            )}
          </a>
        )}
    </ElementWrapper>
  );
};

export default SidebarElement;
