import { gql } from '@apollo/client';

export const baseMetadata = `
    id
    versionId
    versionNumber
    createdBy
    createdAt
    updatedBy
    updatedAt
    versionType
    creatorFullName
    updatorFullName
`;

export const metadata = `
    ${baseMetadata}
    datasourceId
    editorMode
`;

export const fileFragment = gql`
  fragment File on File {
    file
    filename
    mimetype
    encoding
  }
`;

export const buildWidgetMeta = `
    versionId
    createdBy
    createdAt
    creatorFullName
    isCreatedByCustomer
`;
