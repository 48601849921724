import { gql } from '@apollo/client';
import { metadata } from '../common/fragments';
import { widgetConfigFragment } from '../widget/fragments';

export const libraryConfigFragment = gql`
  fragment LibraryConfig on LibraryConfig {
    title
    name
    queries
    widgets
  }
`;

export const libraryFragment = gql`
  fragment Library on Library {
    ${metadata}
      libraryConfig {
      ...LibraryConfig
    }
  }
  ${libraryConfigFragment}
`;

export const insightEntityFragment = gql`
  fragment InsightEntity on InsightEntity {
    createdAt
    creatorFullName
    entityType
    id
    updatedAt
    versionId
    widgetType
    entityConfig {
      name
      title
    }
  }
`;

export const insightWidgetFragment = gql`
  fragment InsightWidget on InsightWidget {
    id
    widgetConfig {
      ...WidgetConfig
    }
  }
  ${widgetConfigFragment}
`;

export const insightQueryFragment = gql`
  fragment InsightQuery on InsightQuery {
    id
    versionId
    versionType
    creatorFullName
    updatedAt
    queryConfig {
      name
      title
    }
  }
`;
