import { gql } from '@apollo/client';
import { permissionFragment } from './fragments';
import { Permission } from './types';

export type GetPermissionByEntityIdData<T> = {
  getPermissionByEntityId: Permission<T>;
};

export type GetPermissionByEntityIdVars = {
  entityId: string;
};

export const GET_PERMISSION_BY_ENTITY_ID = gql`
  query GetPermissionByEntityId($entityId: String!) {
    getPermissionByEntityId(entityId: $entityId) {
      ...Permission
    }
  }
  ${permissionFragment}
`;

export type CanCreateSolutionData = {
  canCreateSolution: boolean;
};

export const CAN_CREATE_SOLUTION = gql`
  query CanCreateSolution {
    canCreateSolution
  }
`;
