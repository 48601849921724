import styled from 'styled-components';
import { colors } from '../../../utils/colors';

export const MetaMenu = styled.div``;

export const MetaItem = styled.div`
  display: flex;
  gap: 6px;

  border-bottom: 1px solid ${colors.monochromatic5};

  color: ${colors.monochromatic2};

  span:last-child {
    color: ${colors.primary0};
  }

  &&& {
    padding: 10px 5px;
  }
`;
