import React, { FC } from 'react';
import { MenuItem } from '@blueprintjs/core';
import { Suggest2, Suggest2Props } from '@blueprintjs/select';

type Props = Partial<Suggest2Props<any>>;

const SearchableInput: FC<Props> = ({
  inputValueRenderer,
  inputProps,
  popoverProps,
  ...props
}) => {
  return (
    <Suggest2
      closeOnSelect
      inputProps={{
        ...inputProps,
        className: `${inputProps?.className || ''} searchable-input`,
        placeholder: inputProps?.placeholder || 'Search',
      }}
      popoverProps={{
        minimal: true,
        ...popoverProps,
        className: `${popoverProps?.className || ''}`,
        popoverClassName: `searchable-popover searchable-input-popover ${
          popoverProps?.popoverClassName || ''
        }`,
      }}
      {...props}
      inputValueRenderer={inputValueRenderer || (() => '')}
      // @ts-ignore
      itemsEqual="value"
      noResults={<MenuItem disabled text={'No results'} />}
    />
  );
};

export default SearchableInput;
