import { gql } from '@apollo/client';
import {
  builtStructureFragment,
  structureFragment,
  tagFragment,
} from './fragments';
import { ITag, Structure } from './types';
import { Page } from '../page/types';
import { VERSION_TYPE } from '../dataSource/types';
import { metadata } from '../common/fragments';

export type GetAllStructuresAllVersionTypesData = {
  live: Structure[];
  draft: Structure[];
  old: Structure[];
};

export type GetAllStructuresAllVersionTypesVars = null;

export const GET_ALL_STRUCTURES_ALL_VERSION_TYPES = gql`
  query GetAllStructuresAllVersionTypes {
    live: allStructuresByType(versionType: "${VERSION_TYPE.LIVE}") {
      ...GetAllStructuresAllVersionTypesFragment
    }
    draft: allStructuresByType(versionType: "${VERSION_TYPE.DRAFT}") {
      ...GetAllStructuresAllVersionTypesFragment
    }
    old: allStructuresByType(versionType: "${VERSION_TYPE.OLD}") {
      ...GetAllStructuresAllVersionTypesFragment
    }
  }
  fragment GetAllStructuresAllVersionTypesFragment on Structure {
    structureConfig {
      name
    }
    ${metadata}
  }
`;

export type GetStructureVersionData = { structureVersion: Structure };

export type GetStructureVersionVars = {
  versionId: string;
};

export const GET_STRUCTURE_VERSION = gql`
  query GetStructureVersion($versionId: String!) {
    structureVersion(versionId: $versionId) {
      ...Structure
    }
  }
  ${structureFragment}
`;

export type GetAllStructureVersionsData = {
  allStructureVersions: Array<Omit<Structure, 'structureConfig' | 'access'>>;
};

export type GetAllStructureVersionsVars = {
  id: string;
};

export const GET_ALL_STRUCTURE_VERSIONS = gql`
  query GetAllStructureVersions($id: String!) {
    allStructureVersions(id: $id) {
      ${metadata}
    }
  }
`;

export type BuildStructureByVersionIdData = {
  buildStructureByVersionId: Structure;
};

export type BuildStructureByVersionIdVars = {
  structureVersionId: string;
};

export const BUILD_STRUCTURE_BY_VERSION_ID = gql`
  query BuildStructureByVersionId($structureVersionId: String!) {
    buildStructureByVersionId(structureVersionId: $structureVersionId) {
      ...Structure
    }
  }
  ${builtStructureFragment}
`;

export type BuildLiveStructureData = {
  buildLiveStructure: Structure;
};

export type BuildLiveStructureVars = {
  structureId: string;
};

export const BUILD_LIVE_STRUCTURE = gql`
  query BuildLiveStructure($structureId: String!) {
    buildLiveStructure(structureId: $structureId) {
      ...Structure
    }
  }
  ${structureFragment}
`;

export type GetAllPageUsagesData = { allPageUsagesByStructures: Structure[] };

export type GetAllPageUsagesVars = {
  entityId: {
    id: string;
  };
};

export const GET_ALL_PAGE_USAGES = gql`
  query allPageUsagesByStructures($entityId: EntityIdInput!) {
    allPageUsagesByStructures(pageId: $entityId) {
      ...Structure
    }
  }
  ${structureFragment}
`;

export type SearchStructuresData = { searchStructures: Structure[] };

export type SearchStructuresVars = {
  searchInput: {
    text: string;
    versionType: VERSION_TYPE;
    first?: number;
  };
};

export const SEARCH_STRUCTURES = gql`
  query SearchStructures($searchInput: SearchEntitiesInput!) {
    searchStructures(searchInput: $searchInput) {
      ...Structure
      structureConfig {
        evaluatedName
      }
    }
  }
  ${structureFragment}
`;

export type GetTagsData = { getTags: ITag[] };

export const GET_TAGS = gql`
  query GetTags {
    getTags {
      ...Tag
    }
  }
  ${tagFragment}
`;

export type GetTagsByEntityIdData = { getTagsByEntityId: ITag[] };

export type GetTagsByEntityIdVars = {
  entityId: string;
};

export const GET_TAGS_BY_ENTITY_ID = gql`
  query GetTagsByEntityId($entityId: String!) {
    getTagsByEntityId(entityId: $entityId) {
      ...Tag
    }
  }
  ${tagFragment}
`;

export type ExportLiveStructureData = {
  fileName: string;
  file: Record<string, any>;
};

export type ExportLiveStructureVars = {
  structureId: string;
};

export const EXPORT_LIVE_STRUCTURE = gql`
  query ExportLiveStructure($structureId: String!) {
    exportLiveStructure(structureId: $structureId)
  }
`;

export type PermittedStructuresData = {
  allPermittedLiveStructures: Structure[];
};

export const PERMITTED_STRUCTURES = gql`
  query PermittedStructures {
    allPermittedLiveStructures {
      id
      versionId
      structureConfig {
        title
        datasourceId
      }
    }
  }
`;

export type StructuresByUrlData = {
  liveStructureIdsByUrl: string[];
};

export type StructuresByUrlVars = {
  url: string;
};

export const STRUCTURES_BY_URL = gql`
  query StructuresByUrl($url: String!) {
    liveStructureIdsByUrl(url: $url)
  }
`;

export type GetStructureByAccessData = {
  getFirstStructureByAccess: Structure;
};

export const GET_STRUCTURE_BY_ACCESS = gql`
  query GetStructureByAccess {
    getFirstStructureByAccess {
      id
      versionId
    }
  }
`;

export type GetPublishableEntitiesVars = {
  getPublishableEntitiesInput: {
    id: string;
  };
};

export type GetPublishableEntitiesData = {
  getPublishableEntities: {
    structure?: Omit<Structure, 'access' | 'icon'>;
    pages: Omit<Page, 'access' | 'icon'>[];
  };
};

export const GET_PUBLISHABLE_ENTITIES = gql`
  query GetPublishableEntities(
    $getPublishableEntitiesInput: GetPublishableEntitiesInput!
  ) {
    getPublishableEntities(
      GetPublishableEntitiesInput: $getPublishableEntitiesInput
    ) {
      structure {
        createdAt
        creatorFullName
        versionId
        updatedAt
        updatorFullName
        versionType
        structureConfig {
          name
        }
      }
      pages {
        createdAt
        creatorFullName
        versionId
        updatedAt
        updatorFullName
        versionType
        pageConfig {
          name
        }
      }
    }
  }
`;
