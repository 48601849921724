import React, { FC, useState } from 'react';
import {
  TextFormWrapper,
  TextTitleAsterisk,
  TextTitleInput,
  TextTitlePlaceholder,
  TextTitleWrapper,
} from './styles';
import Actions from './Actions';
import { useCurrentLocalPage } from '../../../editing';
import MDEditor from '../../../../../../components/MDEditor';
import { TextWidgetData } from '../../../../../../gql/widget/types';

type Props = {
  rowIndex: number;
  colInd: number;
  widgetInd?: number;
  handleClose: () => void;
  versionId?: string;
  data?: TextWidgetData;
  id?: string;
};

const TextWidgetForm: FC<Props> = ({
  rowIndex,
  colInd,
  widgetInd,
  handleClose,
  versionId,
  data,
  id,
}) => {
  const [title, setTitle] = useState(data?.name || '');
  const localPage = useCurrentLocalPage();

  const layoutType = localPage?.page?.pageConfig?.layout?.[rowIndex].type;

  return (
    <>
      <TextFormWrapper>
        <TextTitleWrapper>
          <TextTitleInput
            className="text-widget-title-input"
            value={title}
            onChange={(e) => {
              const newTitle = e.target.value;

              setTitle(newTitle);
            }}
          />
          {!title && (
            <TextTitlePlaceholder>
              Titel hinzufügen...
              <TextTitleAsterisk>*</TextTitleAsterisk>
            </TextTitlePlaceholder>
          )}
        </TextTitleWrapper>
        <div className="p-divider__default u-margin-bottom-20" />
        <div>
          <MDEditor
            initialContent={data?.text}
            layoutType={layoutType!}
            actions={
              <Actions
                handleClose={handleClose}
                title={title}
                rowIndex={rowIndex}
                colInd={colInd}
                widgetInd={widgetInd}
                versionId={versionId}
                id={id}
              />
            }
          />
        </div>
        <div className="u-margin-bottom-50" />
      </TextFormWrapper>
    </>
  );
};

export default TextWidgetForm;
