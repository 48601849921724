import { FC, useEffect } from 'react';
import L from 'leaflet';
import { useMap } from 'react-leaflet';
import 'leaflet-tilelayer-colorizr';

type Pixel = { r: number; g: number; b: number; a: number };

type Props = {};

const GrayscaleTileLayer: FC<Props> = () => {
  const map = useMap();

  useEffect(() => {
    const layer = (L.tileLayer as any)
      .colorizr('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        colorize: ({ r, g, b }: Pixel) => {
          const luma = r * 0.2126 + g * 0.7152 + b * 0.0722;

          return { r: luma, g: luma, b: luma };
        },
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      })
      .addTo(map);

    return () => {
      map.removeLayer(layer);
    };
  }, [map]);

  return null;
};

export default GrayscaleTileLayer;
