import cn from 'classnames';
import {
  ButtonHTMLAttributes,
  FC,
  MouseEvent,
  ReactNode,
  SVGProps,
} from 'react';

type ButtonAttributes = ButtonHTMLAttributes<HTMLButtonElement>;

export type Props = {
  children?: ReactNode;
  variant?: 'primary' | 'ghost' | 'cancel';
  size?: 'large' | 'medium' | 'small' | 'small2';
  disabled?: boolean;
  leadingIcon?: FC<SVGProps<SVGSVGElement>>;
  trailingIcon?: FC<SVGProps<SVGSVGElement>>;
  onClick?: (event: MouseEvent) => void;
  type?: ButtonAttributes['type'];
  isFullWidth?: boolean;
} & Pick<ButtonAttributes, 'dataId'>;

const Button = ({
  children,
  dataId,
  variant = 'primary',
  size = 'medium',
  disabled,
  leadingIcon: LeadingIcon,
  trailingIcon: TrailingIcon,
  onClick,
  type = 'button',
  isFullWidth = false,
}: Props) => {
  return (
    <button
      className={cn(
        'btn flex cursor-pointer items-center gap-1.5 rounded-lg border-2 border-solid font-sans text-sm disabled:cursor-not-allowed',
        {
          'text-concrete-jungle-8':
            variant === 'primary' && (!LeadingIcon || !TrailingIcon),
          'text-white': variant === 'primary' && (LeadingIcon || TrailingIcon),
        },
        {
          'border-primary bg-primary hover:border-blueberry-3 hover:bg-blueberry-3 disabled:border-disabled disabled:bg-disabled':
            variant === 'primary',
          'border-primary bg-inherit text-primary hover:bg-blueberry-6 disabled:border-disabled disabled:bg-inherit disabled:text-disabled':
            variant === 'ghost',
          'border-none bg-raspberry-2 text-white hover:bg-raspberry-3 disabled:border-disabled disabled:bg-inherit disabled:text-disabled':
            variant === 'cancel',
        },
        {
          'px-5 py-3.5': size === 'large',
          'px-5 py-2.5': size === 'medium',
          'px-3.5 py-2.5': size === 'small',
          'px-4 py-1.5': size === 'small2',
          'w-full justify-center py-2.5': isFullWidth,
        },
      )}
      type={type}
      data-id={dataId}
      disabled={disabled}
      onClick={onClick}
    >
      {LeadingIcon && (
        <LeadingIcon
          className={cn({
            'text-white': variant === 'primary' || variant === 'cancel',
            'text-primary': variant === 'ghost' && !disabled,
            'text-disabled': variant === 'ghost' && disabled,
          })}
        />
      )}
      {children}
      {TrailingIcon && (
        <TrailingIcon
          className={cn({
            'text-white': variant === 'primary' || variant === 'cancel',
            'text-primary': variant === 'ghost' && !disabled,
            'text-disabled': variant === 'ghost' && disabled,
          })}
        />
      )}
    </button>
  );
};

export default Button;
