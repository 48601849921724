import styled from 'styled-components';

export const SkeletonLoaderContainer = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding-top: 15px;

  & > div {
    width: ${({ width }) => width};
    margin-bottom: 20px;
    height: 20px;
  }
`;
