export default function InfoPoint({ color = '#282d61' }) {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.8125 0.6875C2.60257 0.6875 0 3.29101 0 6.5C0 9.71087 2.60257 12.3125 5.8125 12.3125C9.02243 12.3125 11.625 9.71087 11.625 6.5C11.625 3.29101 9.02243 0.6875 5.8125 0.6875ZM5.8125 3.26562C6.35616 3.26562 6.79688 3.70634 6.79688 4.25C6.79688 4.79366 6.35616 5.23438 5.8125 5.23438C5.26884 5.23438 4.82812 4.79366 4.82812 4.25C4.82812 3.70634 5.26884 3.26562 5.8125 3.26562ZM7.125 9.21875C7.125 9.37407 6.99907 9.5 6.84375 9.5H4.78125C4.62593 9.5 4.5 9.37407 4.5 9.21875V8.65625C4.5 8.50093 4.62593 8.375 4.78125 8.375H5.0625V6.875H4.78125C4.62593 6.875 4.5 6.74907 4.5 6.59375V6.03125C4.5 5.87593 4.62593 5.75 4.78125 5.75H6.28125C6.43657 5.75 6.5625 5.87593 6.5625 6.03125V8.375H6.84375C6.99907 8.375 7.125 8.50093 7.125 8.65625V9.21875Z"
        fill={color}
      />
    </svg>
  );
}
