import React, { FC, ReactNode } from 'react';
import { CloseIcon, TagWrap } from './styles';
import { ReactComponent as Close } from '../../assets/icons/close.svg';

type Props = {
  color?: string;
  lg?: boolean;
  children: ReactNode;
  onDelete?: () => void;
};

const Tag: FC<Props> = ({ color, lg, onDelete, children }) => {
  return (
    <TagWrap color={color} lg={lg}>
      {children}
      {onDelete && (
        <CloseIcon onClick={onDelete}>
          <Close />
        </CloseIcon>
      )}
    </TagWrap>
  );
};

export default Tag;
