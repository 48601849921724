import React, { FC } from 'react';
import { useActive, useChainedCommands, useCommands } from '@remirror/react';
import { ToolbarItem, ToolbarWrapper } from './styles';
import { ReactComponent as Bold } from './assets/bold.svg';
import { ReactComponent as Italic } from './assets/italic.svg';
import { ReactComponent as Strike } from './assets/strike.svg';
import { ReactComponent as H1 } from './assets/h1.svg';
import { ReactComponent as H2 } from './assets/h2.svg';
import { ReactComponent as List } from './assets/list.svg';
import { ReactComponent as OrderedList } from './assets/ordered-list.svg';
import { LAYOUT_TYPE } from "../../gql/page/types";

type Props = { layoutType: LAYOUT_TYPE };

const ToolbarItems = [
  {
    name: 'bold',
    command: 'toggleBold',
    icon: <Bold />,
  },
  {
    name: 'italic',
    command: 'toggleItalic',
    icon: <Italic />,
  },
  {
    name: 'strike',
    command: 'toggleStrike',
    icon: <Strike />,
  },
  {
    name: 'heading',
    key: 'h1',
    command: 'toggleHeading',
    icon: <H1 />,
    args: { level: 1 },
  },
  {
    name: 'heading',
    key: 'h2',
    command: 'toggleHeading',
    icon: <H2 />,
    args: { level: 2 },
  },
  {
    name: 'bulletList',
    command: 'toggleBulletList',
    icon: <List />,
  },
  {
    name: 'orderedList',
    command: 'toggleOrderedList',
    icon: <OrderedList />,
  },
];

const Toolbar: FC<Props> = ({ layoutType }) => {
  // @ts-ignore
  const chain = useChainedCommands();
  const commands = useCommands();
  const active = useActive();

  return (
    <ToolbarWrapper layoutType={layoutType}>
      {ToolbarItems.map(({ name, command, icon, args, key }) => (
        <ToolbarItem
          layoutType={layoutType}
          key={key || name}
          onClick={() => {
            chain[command](args).focus().run();
          }}
          active={(active as any)[name](args)}
          disabled={!commands[command].enabled(args)}
        >
          {icon}
        </ToolbarItem>
      ))}
    </ToolbarWrapper>
  );
};

export default Toolbar;
