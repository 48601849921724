import { gql } from '@apollo/client';
import { PermissionAccess } from '../types';
import { permissionFragment } from './fragments';
import { Permission, PERMISSION_ENTITY_TYPE } from './types';

// AccessUser | AccessUserInherit
export type UpdateAccessData<T> = { updateAccess: Permission<T> };

export type UpdateAccessVars<T> = {
  UpdateAccessInput: {
    entityType: PERMISSION_ENTITY_TYPE;
    entityId: string;
    access: PermissionAccess<T>;
  };
};

export const UPDATE_ACCESS = gql`
  mutation UpdateAccess($UpdateAccessInput: UpdateAccessInput!) {
    updateAccess(UpdateAccessInput: $UpdateAccessInput) {
      ...Permission
    }
  }
  ${permissionFragment}
`;
