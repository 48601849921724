import { gql } from '@apollo/client';
import { buildWidgetMeta, metadata } from '../common/fragments';
import { VERSION_TYPE } from '../dataSource/types';
import { ENTITY_SEARCH_TYPES } from '../types';
import {
  barWidgetFragment,
  buildBarConfigFragment,
  buildColumnConfigFragment,
  buildLineConfigFragment,
  buildMapConfigFragment,
  buildPieConfigFragment,
  buildSimpleStatConfigFragment,
  buildTableConfigFragment,
  buildTextFragment,
  columnWidgetFragment,
  lineWidgetFragment,
  mapWidgetFragment,
  pieWidgetFragment,
  simpleStatWidgetFragment,
  tableWidgetFragment,
  textWidgetFragment,
  widgetFragment,
} from './fragments';
import {
  BuildWidgetInput,
  BuiltWidgetConfig,
  LineChartConfig,
  WIDGET_TYPE,
  Widget,
  WidgetPreviewConfig,
} from './types';

export type GetAllWidgetsData = { allWidgetsByType: Widget[] };

export type GetAllWidgetsVars = {
  versionType: VERSION_TYPE;
};

export const GET_ALL_WIDGETS = gql`
  query GetAllWidgets($versionType: String!) {
    allWidgetsByType(versionType: $versionType) {
      ...Widget
    }
  }
  ${widgetFragment}
`;

export type GetAllWidgetsAllVersionTypesData = {
  live: Widget[];
  draft: Widget[];
  old: Widget[];
};

export type GetAllWidgetsAllVersionTypesVars = null;

export const GET_ALL_WIDGETS_ALL_VERSION_TYPES = gql`
  query GetAllWidgetsAllVersionTypes {
    live: allWidgetsByType(versionType: "${VERSION_TYPE.LIVE}") {
     ...GetAllWidgetsAllVersionTypesFragment
    }
    draft: allWidgetsByType(versionType: "${VERSION_TYPE.DRAFT}") {
      ...GetAllWidgetsAllVersionTypesFragment
    }
    old: allWidgetsByType(versionType: "${VERSION_TYPE.OLD}") {
      ...GetAllWidgetsAllVersionTypesFragment
    }
  }
  fragment GetAllWidgetsAllVersionTypesFragment on Widget {
    widgetConfig {
      name
    }
    ${metadata}
  }
`;

export type GetWidgetVersionData = { widgetVersion: Widget };

export type GetWidgetVersionVars = {
  versionId: string;
};

export const GET_WIDGET_VERSION = gql`
  query GetWidgetVersion($versionId: String!) {
    widgetVersion(versionId: $versionId) {
      ...Widget
    }
  }
  ${widgetFragment}
`;

export const GET_LINE_WIDGET_VERSION = gql`
  query GetLineWidgetVersion($versionId: String!) {
    lineChartWidgetVersion(versionId: $versionId) {
      ...LineWidget
    }
  }
  ${lineWidgetFragment}
`;

export const GET_PIE_WIDGET_VERSION = gql`
  query GetPieWidgetVersion($versionId: String!) {
    pieChartWidgetVersion(versionId: $versionId) {
      ...PieChartWidget
    }
  }
  ${pieWidgetFragment}
`;

export const GET_TABLE_WIDGET_VERSION = gql`
  query GetTableWidgetVersion($versionId: String!) {
    tableWidgetVersion(versionId: $versionId) {
      ...TableWidgetType
    }
  }
  ${tableWidgetFragment}
`;

export const GET_TEXT_WIDGET_VERSION = gql`
  query GetTextWidgetVersion($versionId: String!) {
    textWidgetVersion(versionId: $versionId) {
      ...TextWidget
    }
  }
  ${textWidgetFragment}
`;

export const GET_SIMPLE_STAT_WIDGET_VERSION = gql`
  query GetSimpleStatWidgetVersion($versionId: String!) {
    simpleStatWidgetVersion(versionId: $versionId) {
      ...SimpleStatWidget
    }
  }
  ${simpleStatWidgetFragment}
`;

export const GET_MAP_WIDGET_VERSION = gql`
  query GetMapWidgetVersion($versionId: String!) {
    mapChartWidgetVersion(versionId: $versionId) {
      ...MapWidget
    }
  }
  ${mapWidgetFragment}
`;

export const GET_BAR_WIDGET_VERSION = gql`
  query GetBarWidgetVersion($versionId: String!) {
    barChartWidgetVersion(versionId: $versionId) {
      ...BarWidget
    }
  }
  ${barWidgetFragment}
`;

export const GET_COLUMN_WIDGET_VERSION = gql`
  query GetColumnWidgetVersion($versionId: String!) {
    columnChartWidgetVersion(versionId: $versionId) {
      ...ColumnWidget
    }
  }
  ${columnWidgetFragment}
`;

export type GetAllWidgetVersionsData = {
  allWidgetVersions: Array<Omit<Widget, 'widgetConfig' | 'access'>>;
};

export type GetAllWidgetVersionsVars = {
  id: string;
};

export const GET_ALL_WIDGET_VERSIONS = gql`
  query GetAllWidgetVersions($id: String!) {
    allWidgetVersions(id: $id) {
      ${metadata}
    }
  }
`;

export type GetAllWidgetNonLiveUniqueIdsData = {
  allWidgetNonLiveUniqueIDs: string[];
};

export type GetAllWidgetNonLiveUniqueIdsVars = null;

export const GET_ALL_WIDGET_NON_LIVE_UNIQUE_IDS = gql`
  query GetAllWidgetNonLiveUniqueIds {
    allWidgetNonLiveUniqueIDs
  }
`;

export type BuildLineChartWidgetByVersionIdData = {
  buildLineChartWidgetByVersionId: LineChartConfig;
};

export type BuildLineChartWidgetByVersionIdVars = {
  source: BuildWidgetInput;
};

export const BUILD_LINE_CHART_WIDGET_BY_VERSION_ID = gql`
  query BuildLineChartWidget(
    $source: BuildLineChartWidgetByVersionIdInput!
      $structureSearchType: String! = "${ENTITY_SEARCH_TYPES.ID}"
      $widgetSearchType: String! = "${ENTITY_SEARCH_TYPES.VERSION_ID}"
  ) {
    buildLineChartWidgetByVersionId(
      source: $source
      structureSearchType: $structureSearchType
      widgetSearchType: $widgetSearchType
    ) {
      ...BuiltLineChartWidgetConfig
    }
  }
  ${buildLineConfigFragment}
`;

export const BUILD_PIE_CHART_WIDGET_BY_VERSION_ID = gql`
  query BuildPieChartWidget(
    $source: BuildPieChartWidgetByVersionIdInput!
      $structureSearchType: String! = "${ENTITY_SEARCH_TYPES.ID}"
      $widgetSearchType: String! = "${ENTITY_SEARCH_TYPES.VERSION_ID}"
  ) {
    buildPieChartWidgetByVersionId(
      source: $source
      structureSearchType: $structureSearchType
      widgetSearchType: $widgetSearchType
    ) {
      ...BuiltPieChartWidgetConfig
    }
  }
  ${buildPieConfigFragment}
`;

export const BUILD_TABLE_WIDGET_BY_VERSION_ID = gql`
  query BuildTableChartWidget(
      $source: BuildTableWidgetByVersionIdInput!
      $structureSearchType: String! = "${ENTITY_SEARCH_TYPES.ID}"
      $widgetSearchType: String! = "${ENTITY_SEARCH_TYPES.VERSION_ID}"
  ) {
    buildTableWidgetByVersionId(source: $source,
      structureSearchType: $structureSearchType,
      widgetSearchType: $widgetSearchType) {
      ...BuiltTableWidgetConfig
    }
  }
  ${buildTableConfigFragment}
`;

export const BUILD_TEXT_WIDGET_BY_VERSION_ID = gql`
  query BuildTextWidget($source: BuildTextWidgetByVersionIdInput!
      $structureSearchType: String! = "${ENTITY_SEARCH_TYPES.ID}"
      $widgetSearchType: String! = "${ENTITY_SEARCH_TYPES.VERSION_ID}"
  ) {
    buildTextWidgetByVersionId(source: $source,
      structureSearchType: $structureSearchType,
      widgetSearchType: $widgetSearchType) {
      ...BuiltTextWidgetConfig
    }
  }
  ${buildTextFragment}
`;

export const BUILD_MAP_CHART_WIDGET_BY_VERSION_ID = gql`
  query BuildMapChartWidget($source: BuildMapChartWidgetByVersionIdInput!
      $structureSearchType: String! = "${ENTITY_SEARCH_TYPES.ID}"
      $widgetSearchType: String! = "${ENTITY_SEARCH_TYPES.VERSION_ID}"
  ) {
    buildMapChartWidgetByVersionId(source: $source,
      structureSearchType: $structureSearchType,
      widgetSearchType: $widgetSearchType) {
      ...BuiltMapChartWidgetConfig
    }
  }
  ${buildMapConfigFragment}
`;

export const BUILD_BAR_CHART_WIDGET_BY_VERSION_ID = gql`
  query BuildBarChartWidget($source: BuildBarChartWidgetByVersionIdInput!
      $structureSearchType: String! = "${ENTITY_SEARCH_TYPES.ID}"
      $widgetSearchType: String! = "${ENTITY_SEARCH_TYPES.VERSION_ID}"
  ) {
    buildBarChartWidgetByVersionId(source: $source,
      structureSearchType: $structureSearchType,
      widgetSearchType: $widgetSearchType) {
      ...BuiltBarChartWidgetConfig
    }
  }
  ${buildBarConfigFragment}
`;

export const BUILD_STACKED_COLUMN_CHART_WIDGET_BY_VERSION_ID = gql`
  query BuildStackedColumnChartWidget($source: BuildStackedColumnChartWidgetByVersionIdInput!
      $structureSearchType: String! = "${ENTITY_SEARCH_TYPES.ID}"
      $widgetSearchType: String! = "${ENTITY_SEARCH_TYPES.VERSION_ID}"
  ) {
    buildStackedColumnChartWidgetByVersionId(source: $source,
      structureSearchType: $structureSearchType,
      widgetSearchType: $widgetSearchType) {
      ...BuiltColumnChartWidgetConfig
    }
  }
  ${buildColumnConfigFragment}
`;

export const BUILD_GROUPED_COLUMN_CHART_WIDGET_BY_VERSION_ID = gql`
  query BuildGroupedColumnChartWidget($source: BuildGroupedColumnChartWidgetByVersionIdInput!
      $structureSearchType: String! = "${ENTITY_SEARCH_TYPES.ID}"
      $widgetSearchType: String! = "${ENTITY_SEARCH_TYPES.VERSION_ID}"
  ) {
    buildGroupedColumnChartWidgetByVersionId(source: $source,
      structureSearchType: $structureSearchType,
      widgetSearchType: $widgetSearchType) {
      ...BuiltColumnChartWidgetConfig
    }
  }
  ${buildColumnConfigFragment}
`;

export const BUILD_SIMPLE_STAT_WIDGET_BY_VERSION_ID = gql`
  query BuildSimpleStatWidget(
    $source: BuildSimpleStatWidgetByVersionIdInput!
    $structureSearchType: String! = "${ENTITY_SEARCH_TYPES.ID}"
    $widgetSearchType: String! = "${ENTITY_SEARCH_TYPES.VERSION_ID}"
  ) {
    buildSimpleStatWidgetByVersionId(
      source: $source
      structureSearchType: $structureSearchType
      widgetSearchType: $widgetSearchType
    ) {
      ...BuiltSimpleStatWidgetConfig
    }
  }
  ${buildSimpleStatConfigFragment}
`;

export type SearchWidgetsData = { searchWidgets: Widget[] };

export type SearchWidgetsVars = {
  searchInput: {
    text: string;
    versionType: VERSION_TYPE;
    first?: number;
  };
};

export const SEARCH_WIDGETS = gql`
  query SearchWidgets($searchInput: SearchEntitiesInput!) {
    searchWidgets(searchInput: $searchInput) {
      ...Widget
    }
  }
  ${widgetFragment}
`;

export type LiveWidgetVersionData = {
  widgetLiveVersion: Widget;
};

export type LiveWidgetVersionVars = {
  id: string;
};

export const LIVE_WIDGET_VERSION = gql`
  query LiveWidgetVersion($id: String!) {
    widgetLiveVersion(id: $id) {
      ...Widget
    }
  }
  ${widgetFragment}
`;

export type BuildLineChartWidgetData = {
  buildLineChartWidget: LineChartConfig;
};

export type BuildWidgetVars = {
  source: BuildWidgetInput;
};

export const BUILD_LINE_CHART_WIDGET = gql`
    query BuildLineChartWidget($source: BuildLineChartWidgetInput!) {
        buildLineChartWidget(source: $source) {
            ${buildWidgetMeta}
            widgetConfig {
                ...BuiltLineChartWidgetConfig
            }
        }
    }
    ${buildLineConfigFragment}
`;

export const BUILD_BAR_CHART_WIDGET = gql`
    query BuildBarChartWidget($source: BuildBarChartWidgetInput!) {
        buildBarChartWidget(source: $source) {
            ${buildWidgetMeta}
            widgetConfig {
                ...BuiltBarChartWidgetConfig
            }
        }
    }
    ${buildBarConfigFragment}
`;

export const BUILD_GROUPED_COLUMN_CHART_WIDGET = gql`
    query BuildGroupedColumnChartWidget($source: BuildGroupedColumnChartWidgetInput!) {
        buildGroupedColumnChartWidget(source: $source) {
            ${buildWidgetMeta}
            widgetConfig {
                ...BuiltColumnChartWidgetConfig
            }
        }
    }
    ${buildColumnConfigFragment}
`;

export const BUILD_MAP_CHART_WIDGET = gql`
    query BuildMapChartWidget($source: BuildMapChartWidgetInput!) {
        buildMapChartWidget(source: $source) {
            ${buildWidgetMeta}
            widgetConfig {
                ...BuiltMapChartWidgetConfig
            }
        }
    }
    ${buildMapConfigFragment}
`;

export const BUILD_PIE_CHART_WIDGET = gql`
    query BuildPieChartWidget($source: BuildPieChartWidgetInput!) {
        buildPieChartWidget(source: $source) {
            ${buildWidgetMeta}
            widgetConfig {
                ...BuiltPieChartWidgetConfig
            }
        }
    }
    ${buildPieConfigFragment}
`;

export const BUILD_SIMPLE_STAT_WIDGET = gql`
    query BuildSimpleStatWidget($source: BuildSimpleStatWidgetInput!) {
        buildSimpleStatWidget(source: $source) {
            ${buildWidgetMeta}
            widgetConfig {
                ...BuiltSimpleStatWidgetConfig
            }
        }
    }
    ${buildSimpleStatConfigFragment}
`;

export const BUILD_STACKED_COLUMN_CHART_WIDGET = gql`
    query BuildStackedColumnChartWidget($source: BuildStackedColumnChartWidgetInput!) {
        buildStackedColumnChartWidget(source: $source) {
            ${buildWidgetMeta}
            widgetConfig {
                ...BuiltColumnChartWidgetConfig
            }
        }
    }
    ${buildColumnConfigFragment}
`;

export const BUILD_TABLE_WIDGET = gql`
    query BuildTableChartWidget($source: BuildTableWidgetInput!) {
        buildTableWidget(source: $source) {
            ${buildWidgetMeta}
            widgetConfig {
                ...BuiltTableWidgetConfig
            }
        }
    }
    ${buildTableConfigFragment}
`;

export const BUILD_TEXT_WIDGET = gql`
    query BuildTextWidget($source: BuildTextWidgetInput!) {
        buildTextWidget(source: $source) {
            ${buildWidgetMeta}
            widgetConfig {
                ...BuiltTextWidgetConfig
            }
        }
    }
    ${buildTextFragment}
`;

export type BuildWidgetPreviewData = { buildWidgetPreview: BuiltWidgetConfig };

export type BuildWidgetPreviewVars = {
  structureId?: string;
  source: WidgetPreviewConfig;
};

export const BUILD_WIDGET_PREVIEW = gql`
  query BuildWidgetPreview(
    $structureId: String
    $source: WidgetPreviewConfigInput!
  ) {
    buildWidgetPreview(structureId: $structureId, source: $source)
  }
`;

export type WidgetTypeData = { widgetLiveTypeById: WIDGET_TYPE };

export type WidgetTypeVars = {
  id: string;
};

export const GET_WIDGET_TYPE = gql`
  query WidgetType($id: String!) {
    widgetLiveTypeById(id: $id)
  }
`;
