import { useQuery } from '@apollo/client/react/hooks/useQuery';
import { useMutation } from '@apollo/client';

export const ATHENA_MICROSERVICE = 'ATHENA_MICROSERVICE';

export const useAthenaQuery: typeof useQuery = (query, options) =>
  useQuery(query, {
    ...options,
    context: { microservice: ATHENA_MICROSERVICE },
  });

export const useAthenaMutation: typeof useMutation = (mutation, options) =>
  useMutation(mutation, {
    ...options,
    context: { microservice: ATHENA_MICROSERVICE },
  });
