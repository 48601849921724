import { useMutation, useQuery } from '@apollo/client';
import { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Hierarchy, { CREATE_PAGE_ID } from '../../containers/Hierarchy';
import {
  GET_HIERARCHY_STRUCTURES,
  GetHierarchyStructuresData,
  GetHierarchyStructuresVars,
} from '../../gql/hierarchy/queries';
import { StructureTree } from '../../gql/hierarchy/types';
import {
  ADD_TO_PAGE_VERSION,
  AddToPageVersionData,
  AddToPageVersionVars,
  CREATE_PAGE,
  CreatePageData,
  CreatePageVars,
} from '../../gql/page/mutations';

import { useTranslation } from 'react-i18next';
import { SolutionsRoute } from '../../app/Routes';
import { EDIT_PATH } from '../../constants/entities';
import { INITIAL_COLSPAN } from '../../containers/Solutions/Structure/Page/PageRow';
import { EditorMode } from '../../gql/common/types';
import { LAYOUT_TYPE_VALS, Page } from '../../gql/page/types';
import {
  CREATE_STRUCTURE_VERSION,
  CreateStructureVersionData,
  CreateStructureVersionVars,
} from '../../gql/structure/mutations';
import {
  BUILD_LIVE_STRUCTURE,
  BuildLiveStructureData,
  BuildLiveStructureVars,
} from '../../gql/structure/queries';
import {
  STRUCTURE_ELEMENT_TYPE,
  Structure,
  StructureConfigElement,
} from '../../gql/structure/types';
import {
  getIsAnalyticsEngineer,
  getIsCustomer,
  getIsDA,
} from '../../gql/user/local';
import { cleanTypeName } from '../../utils/editor';
import { useSearchEntityUtils } from '../../utils/hooks/entity';
import { AddToSubModalProps } from './types';
import { VERSION_TYPE } from '../../gql/dataSource/types';
import { ReactComponent as Plus } from '../../assets/icons/plus.svg';

const LIMIT_STEP = 50;
const OFFSET = 50;

export const AddToPageModalContent: FC<AddToSubModalProps> = ({
  search,
  element,
}) => {
  const { t } = useTranslation();
  const [structureTrees, setStructureTrees] = useState<StructureTree[]>([]);
  const [rows, setRows] = useState(100);
  const [offset, setOffset] = useState(0);

  const scrollNext = useCallback(() => setOffset((prev) => prev + OFFSET), []);

  const history = useHistory();

  const { goToVersion } = useSearchEntityUtils();

  useEffect(() => {
    setStructureTrees([]);
  }, [search]);

  const [addToPage] = useMutation<AddToPageVersionData, AddToPageVersionVars>(
    ADD_TO_PAGE_VERSION,
  );

  const [createPage] = useMutation<CreatePageData, CreatePageVars>(CREATE_PAGE);

  const [createStructureVersion] = useMutation<
    CreateStructureVersionData,
    CreateStructureVersionVars
  >(CREATE_STRUCTURE_VERSION);

  const { refetch: refetchLiveStructure } = useQuery<
    BuildLiveStructureData,
    BuildLiveStructureVars
  >(BUILD_LIVE_STRUCTURE, {
    skip: true,
  });

  const addElementToPageVersion = useCallback(
    async (structure: Structure, page: Page) => {
      const isCreatePage = page.versionId === CREATE_PAGE_ID;

      let structureVersionId = structure.versionId;

      let finalPageVersionId;

      if (isCreatePage) {
        const { data: newPageData } = await createPage({
          variables: {
            CreatePageInput: {
              pageConfig: {
                defaultFilters: [],
                filterSections: [],
                pageFilters: [],
                layout: [
                  {
                    type: LAYOUT_TYPE_VALS[INITIAL_COLSPAN - 1],
                    columns: [
                      {
                        elements: [{ type: 'widget', widgetId: element.id }],
                        colspan: INITIAL_COLSPAN,
                      },
                    ],
                  },
                ],
                info: '',
                name: '',
                title: t('structure.new-page'),
                allowCustomerEdits: !getIsDA(),
                subTitle: '',
              },
              editorMode: EditorMode.VISUAL,
            },
            editorStructureId: structure.id,
          },
        });

        const newPage = newPageData?.createPage;

        finalPageVersionId = newPage?.versionId;

        const { data: liveStructureData } = await refetchLiveStructure({
          structureId: structure.id,
        });

        const liveStructure = cleanTypeName(
          liveStructureData?.buildLiveStructure,
        );

        const { data: newStructureData } = await createStructureVersion({
          variables: {
            id: structure.id,
            CreateStructureInput: {
              structureConfig: {
                ...liveStructure.structureConfig,
                elements: [
                  ...liveStructure.structureConfig.elements,
                  {
                    type: STRUCTURE_ELEMENT_TYPE.PAGE,
                    pageId: newPage?.id,
                    title: newPage?.pageConfig.title,
                  } as StructureConfigElement,
                ],
              },
              icon: liveStructure.icon || null,
              editorMode: EditorMode.VISUAL,
            },
          },
        });

        structureVersionId =
          newStructureData?.createStructureVersion.versionId!;
      } else {
        const { data: updatedPage } = await addToPage({
          variables: {
            AddToPageVersionInput: {
              versionId: page.versionId,
              elementId: element.id,
              elementType: element.type,
            },
          },
        });

        finalPageVersionId = updatedPage?.addToPageVersion.versionId;
      }

      history.push(
        `${SolutionsRoute.path}/${EDIT_PATH}/${structureVersionId}/${finalPageVersionId}`,
      );
    },
    [
      addToPage,
      createPage,
      createStructureVersion,
      element.id,
      element.type,
      history,
      refetchLiveStructure,
      t,
    ],
  );

  const { loading: hierarchyStructuresLoading } = useQuery<
    GetHierarchyStructuresData,
    GetHierarchyStructuresVars
  >(GET_HIERARCHY_STRUCTURES, {
    variables: { searchInput: { text: search, first: LIMIT_STEP, offset } },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const length = data.getHierarchyStructures.length;

      setRows(length);
      setStructureTrees((prev) => [...prev, ...data.getHierarchyStructures]);
    },
  });

  return (
    <Hierarchy
      showNested={getIsAnalyticsEngineer() || getIsCustomer()}
      hierarchiesData={structureTrees}
      rows={rows}
      setStructureTrees={setStructureTrees}
      scrollNext={scrollNext}
      isLoading={hierarchyStructuresLoading}
      type="page_versions"
      openOnNameClick
      showHierarchyControls={false}
      onNodeClick={(event, structure) => {
        addElementToPageVersion(structure.structure, event.nodeData);
      }}
      goToVersion={goToVersion}
      isVersions={getIsDA()}
      noOldVersions
      isAddingFromInsights={!getIsDA()}
      additionalItems={[
        {
          page: {
            id: CREATE_PAGE_ID,
            versionId: CREATE_PAGE_ID,
            versionType: VERSION_TYPE.LIVE,
            pageConfig: {
              name: '',
              title: t('action.add-new-page'),
            },
            isAdditional: true,
            icon: Plus,
          } as unknown as Page,
          widgets: [],
        },
      ]}
      showVersionAsText={true}
    />
  );
};
