import styled from 'styled-components';
import { VERTICAL_ALIGN } from '../../../../gql/page/types';
import { colors } from "../../../../utils/colors";

const V_ALIGNS = {
  [VERTICAL_ALIGN.TOP]: 'flex-start',
  [VERTICAL_ALIGN.MIDDLE]: 'center',
  [VERTICAL_ALIGN.BOTTOM]: 'flex-end',
};

export const Grid = styled.div<{ colNum: number; highlight?: boolean }>`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(${({ colNum }) => colNum}, 1fr);
  position: relative;

  padding: 8px;
  border-radius: 8px;
  background-color: ${({ highlight }) =>
    highlight ? 'rgba(233, 235, 242, 0.4)' : 'transparent'};
  margin-bottom: 10px;

  transition: background-color 0.2s ease-in-out;

  &:hover > .delete-row-btn {
    opacity: 1;
  }
`;

export const Column = styled.div<{
  verticalAlign?: VERTICAL_ALIGN;
  colspan?: number;
  highlight?: boolean;
  isEditing?: boolean;
}>`
  position: relative;
  height: ${({ isEditing }) => (isEditing ? '100%' : 'auto')};
  min-width: 0;
  grid-column-end: span ${({ colspan = 1 }) => colspan};
  align-self: ${({ verticalAlign = VERTICAL_ALIGN.TOP }) =>
    V_ALIGNS[verticalAlign]};

  display: flex;
  flex-direction: column;

  background-color: ${({ highlight }) =>
    highlight ? 'rgba(233, 235, 242, 0.4)' : 'transparent'};

  &:hover > .resize-handle {
    opacity: 1;
  }
`;

export const ColResizeHandle = styled.div<{
  left?: boolean;
  isResizing?: boolean;
  isResizingThis?: boolean;
}>`
  position: absolute;
  ${({ left }) => (left ? 'left: -2px' : 'right: -2px')};
  top: 50%;
  transform: translateY(-75%);
  width: 4px;
  height: 38px;
  background: ${colors.monochromatic4};
  border-radius: 4px;
  cursor: col-resize;
  z-index: 1;
  user-select: none;
  opacity: ${({ isResizingThis, isResizing }) => {
    if (isResizingThis) {
      return 1;
    }

    return isResizing ? '0 !important' : 0;
  }};
  transition: opacity 0.2s ease-in-out;
`;

export const AddColumn = styled.div<{ disabled?: boolean }>`
  position: absolute;
  right: -25px;
  top: 50%;

  & * {
    outline: none !important;
  }

  & .add-icon {
    transition: all 0.2s ease-in-out;
    width: 24px;
    height: 24px;
    color: ${colors.monochromatic4};

    transform: translateY(-100%);

    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

    &:hover {
      color: ${({ disabled }) =>
        disabled ? colors.monochromatic4 : colors.primary2};
    }
  }
`;

export const RowDragHandle = styled.div<{ highlight: boolean }>`
  opacity: ${({ highlight }) => (highlight ? 1 : 0)};
  position: absolute;
  top: 10px;
  left: -18px;
  transition: opacity 0.2s ease-in-out;
`;

export const DeleteRowBtn = styled.div<{ highlight: boolean }>`
  background-color: ${({ highlight }) =>
    highlight ? colors.primary5 : 'transparent'};
  color: ${({ highlight }) => (highlight ? '#4A599A' : colors.monochromatic4)};
  cursor: grab;
  padding: 2px;
  width: 16px;
  height: 24px;
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
`;

export const PageCreator = styled.div`
  text-align: right;
  margin-top: 50px;
  color: #cacaca;
  transform: translateY(-50px);
`;
