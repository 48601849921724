import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Collapse } from '@blueprintjs/core';
import {
  STRUCTURE_ELEMENT_TYPE,
  StructureConfigElement,
} from '../../../../../gql/structure/types';
import { getValidLengthTitle, useEditingContext } from '../../editing';
import { DeleteIcon } from './SidebarElement';
import { ElementInput, GroupWrapper, SubElementWrapper } from './styles';
import { useTranslation } from 'react-i18next';

type SubProps = {
  groupElements: StructureConfigElement[];
  openInd: number | null;
  isEdit?: boolean;
  setOpenInd: (ind: number) => void;
  commonInputProps: Record<any, any>;
  showDelete?: boolean;
};

const SubElements: FC<Props & SubProps> = ({
  openInd,
  groupElements,
  selectedPage,
  isEdit,
  setOpenInd,
  onPageClick,
  commonInputProps,
  groupInd,
  showDelete,
  isLastPage,
}) => {
  const { t } = useTranslation();
  const { updateStructureElement, updatePageConfig, setDeletingStructureEl } =
    useEditingContext();

  return (
    <>
      {groupElements.map((el, ind) => {
        const isActivePage = selectedPage === el.pageId;

        const isInput = openInd === ind + 1;

        return (
          <SubElementWrapper
            key={ind}
            onDoubleClick={() => {
              if (isEdit) {
                setOpenInd(ind + 1);
              }
            }}
            className={
              el.type === STRUCTURE_ELEMENT_TYPE.SECTION
                ? 'u-padding-top-20 u-padding-bottom-10'
                : 'u-padding-top-10'
            }
          >
            {el.type === STRUCTURE_ELEMENT_TYPE.PAGE && (
              <div
                className={`sidebar-subpage sidebar__link link__mono3 u-cursor-pointer ${
                  isActivePage ? 'isRouteActive' : ''
                }`}
                onClick={() => onPageClick(el.pageId!)}
              >
                {isInput ? (
                  <ElementInput
                    {...commonInputProps}
                    value={el.title}
                    onChange={(e) => {
                      const title = getValidLengthTitle(e.target.value);

                      if (title === null) {
                        return;
                      }

                      updateStructureElement({ title }, ind, groupInd);
                      updatePageConfig({ title, name: title }, el.pageId);
                    }}
                  />
                ) : (
                  el.title || t('structure.new-page')
                )}
                {showDelete && !isLastPage && (
                  <DeleteIcon onClick={() => setDeletingStructureEl(el)} />
                )}
              </div>
            )}
            {el.type === STRUCTURE_ELEMENT_TYPE.LABEL && (
              <div className="u-margin-left-10 u-align-center-y font-16 colorPrimary-0 sidebar-label sidebar-sublabel">
                {isInput ? (
                  <ElementInput
                    {...commonInputProps}
                    value={el.text}
                    onChange={(e) => {
                      const text = getValidLengthTitle(e.target.value);

                      if (text === null) {
                        return;
                      }

                      updateStructureElement({ text }, ind, groupInd);
                    }}
                  />
                ) : (
                  el.text
                )}
                {showDelete && (
                  <DeleteIcon onClick={() => setDeletingStructureEl(el)} />
                )}
              </div>
            )}
            {el.type === STRUCTURE_ELEMENT_TYPE.SECTION && (
              <div className="u-margin-left-10 font-12 colorSecondary-0 sidebar-section sidebar-subsection">
                {isInput ? (
                  <ElementInput
                    {...commonInputProps}
                    value={el.text}
                    onChange={(e) => {
                      const text = getValidLengthTitle(e.target.value);

                      if (text === null) {
                        return;
                      }

                      updateStructureElement({ text }, ind, groupInd);
                    }}
                  />
                ) : (
                  el.text
                )}
                {showDelete && (
                  <DeleteIcon onClick={() => setDeletingStructureEl(el)} />
                )}
              </div>
            )}
          </SubElementWrapper>
        );
      })}
    </>
  );
};

const SubElementsMemo = memo(SubElements);

interface Props {
  element: StructureConfigElement;
  initialState?: boolean;
  onPageClick: (pageId: string) => void;
  selectedPage: string;
  editable?: boolean;
  groupInd: number;
  isLastPage: boolean;
}

export const SidebarCollapsible: FC<Props> = (props) => {
  const {
    element,
    onPageClick,
    selectedPage,
    initialState = false,
    editable,
    groupInd,
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(initialState);
  const [openInd, setOpenInd] = useState<number | null>(null);

  const groupElements = element?.groupElements;

  const {
    setDeletingStructureEl,
    isCustomerEditorModeEnabled,
    updateStructureElement,
  } = useEditingContext();

  const isEdit = isCustomerEditorModeEnabled && editable;
  const showDelete = isEdit && openInd === null;

  useEffect(() => {
    const shouldOpen = groupElements?.some(
      ({ pageId }) => pageId === selectedPage,
    );

    setIsOpen(!!shouldOpen);
  }, [groupElements, selectedPage]);

  const toggleCollapse = useCallback(() => {
    if (isCustomerEditorModeEnabled) {
      return;
    }

    const firstPageUrl = groupElements?.find((el) => el?.pageId)?.pageId;

    if (selectedPage !== firstPageUrl) {
      onPageClick(firstPageUrl as string);
    }
  }, [groupElements, isCustomerEditorModeEnabled, onPageClick, selectedPage]);

  const commonInputProps = useMemo(
    () => ({
      autoFocus: true,
      onBlur: () => setOpenInd(null),
      onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          e.currentTarget.blur();
        }
      },
    }),
    [],
  );

  return (
    <>
      <GroupWrapper
        onClick={toggleCollapse}
        onDoubleClick={() => {
          if (isEdit) {
            setOpenInd(0);
          }
        }}
        className={`
          sidebar-group
          link__mono3 
          u-margin-top-10 
          ${isOpen ? 'u-cursor-default' : 'u-cursor-pointer'} 
          ${isOpen ? 'isRouteActive' : ''}
        `}
      >
        {openInd === 0 ? (
          <ElementInput
            {...commonInputProps}
            value={element.title}
            onChange={(e) => {
              const title = getValidLengthTitle(e.target.value);

              if (title === null) {
                return;
              }

              updateStructureElement({ title }, groupInd);
            }}
          />
        ) : (
          element.title
        )}
        {showDelete && (
          <DeleteIcon onClick={() => setDeletingStructureEl(element)} />
        )}
      </GroupWrapper>
      {!!groupElements?.length && (
        <Collapse isOpen={isOpen}>
          <SubElementsMemo
            {...props}
            groupElements={groupElements}
            openInd={openInd}
            isEdit={isEdit}
            setOpenInd={setOpenInd}
            commonInputProps={commonInputProps}
            showDelete={showDelete}
          />
        </Collapse>
      )}
    </>
  );
};
