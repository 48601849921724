import React, { FC } from 'react';
import { TypeOption, TypeSelector } from './styles';
import { ReactComponent as BarChart } from '../../../assets/icons/widgets/bar-chart.svg';
import { ReactComponent as ColumnChartGrouped } from '../../../assets/icons/widgets/column-chart-grouped.svg';
import { ReactComponent as ColumnChartStacked } from '../../../assets/icons/widgets/column-chart-stacked.svg';
import { ReactComponent as LineChart } from '../../../assets/icons/widgets/line-chart.svg';
import { SETTINGS } from './index';
import { Tooltip2 } from '@blueprintjs/popover2';
import { WIDGET_TYPE } from '../../../gql/widget/types';

const WIDGET_TYPE_OPTIONS = [
  {
    type: WIDGET_TYPE.GROUPED_COLUMN_CHART,
    icon: <ColumnChartGrouped />,
    maxDomain: 1000,
  },
  {
    type: WIDGET_TYPE.STACKED_COLUMN_CHART,
    icon: <ColumnChartStacked />,
    maxDomain: 1000,
  },
  { type: WIDGET_TYPE.LINE_CHART, icon: <LineChart />, maxDomain: 9999 },
  { type: WIDGET_TYPE.BAR_CHART, icon: <BarChart />, maxDomain: 200 },
];

type Props = {
  val?: WIDGET_TYPE;
  initialValue?: WIDGET_TYPE;
  setSettingsVal?: React.Dispatch<React.SetStateAction<Record<SETTINGS, any>>>;
  onChange?: (newVal: any) => void;
  domainLength: number;
};

const WidgetType: FC<Props> = ({
  setSettingsVal,
  initialValue,
  val,
  onChange,
  domainLength,
}) => {
  return (
    <div>
      <div>Art der Darstellung</div>
      <TypeSelector>
        {WIDGET_TYPE_OPTIONS.map(({ type, icon, maxDomain }) => {
          const disabled = domainLength > maxDomain;

          return (
            <Tooltip2
              key={type}
              content={<span>Nicht anwendbar für dieses Diagramm.</span>}
              placement="top"
              disabled={!disabled}
            >
              <TypeOption
                disabled={disabled}
                onClick={() => {
                  if (disabled) {
                    return;
                  }

                  setSettingsVal?.((prevState) => ({
                    ...prevState,
                    [SETTINGS.WIDGET_TYPE]: type,
                  }));
                  onChange?.(type);
                }}
                isSelected={(val || initialValue) === type}
              >
                {icon}
              </TypeOption>
            </Tooltip2>
          );
        })}
      </TypeSelector>
    </div>
  );
};

export default WidgetType;
