import { FilterForPrint } from '../Widget';
import { IColumn, IContent, IJsonSheet, ISettings } from './JSONasXLSX/types';
import { Model } from 'survey-core';
import {
  BuiltWidgetConfig,
  PieChartConfig,
  TableConfig,
  WIDGET_TYPE,
} from '../../gql/widget/types';
import {
  FORM_ELEMENT_TYPE,
  SurveyElement,
} from '../../containers/Forms/Form/FormSummary';
import { TRANSFORM_UNITS, getFormattedValue } from '../../utils/format';

export type XLSXType = {
  data: IJsonSheet[];
  settings: ISettings;
};

export default class XLSXExport {
  private data: unknown;
  private filters?: FilterForPrint[];
  private formConfig?: any;

  constructor(
    widgetData: unknown,
    filters?: FilterForPrint[],
    formConfig?: any,
  ) {
    this.data = widgetData;
    this.filters = filters;
    this.formConfig = formConfig;
  }

  public getWidget(chart: WIDGET_TYPE | 'form' | undefined, survey?: Model) {
    if (chart === WIDGET_TYPE.TABLE) {
      return this._resolveTableWidget();
    } else if (chart === WIDGET_TYPE.PIE_CHART) {
      return this._resolvePieWidget();
    } else if (chart === WIDGET_TYPE.CHOROPLETH_MAP) {
      return this._resolveMapWidget();
    } else if (chart === 'form') {
      return this._resolveForm(survey as Model);
    } else {
      return this._resolveOtherWidget();
    }
  }

  private _printPageFilters(
    content: IContent[],
    columns: IColumn[],
  ): {
    content: IContent[];
    columns: IColumn[];
  } {
    if (!this.filters?.length) {
      return {
        content,
        columns,
      };
    }

    columns[columns.length] = {
      label: 'Der eingesetzte Seitenfilter gibt die folgende Ansicht wieder',
      value: (row) => row[columns.length],
    };

    this.filters?.forEach((filter, idx) => {
      const filterValue = filter?.value;
      if (Array.isArray(filterValue)) {
        content[idx][columns.length] = `${filter.title}: ${filterValue.join(
          ', ',
        )}`;
      } else {
        content[idx][columns.length] = `${filter.title}: ${filterValue}`;
      }
    });

    return {
      columns,
      content,
    };
  }

  private _XLSXData(columns: IColumn[], content: IContent[]): XLSXType {
    return {
      data: [{ ...this._printPageFilters(content, columns) }],
      settings: {
        fileName:
          (this.data as BuiltWidgetConfig).title ||
          Object.keys(this.data as Record<any, any>)[0],
      },
    };
  }

  private _resolveTableWidget(): XLSXType | undefined {
    try {
      const widget = this.data as TableConfig;
      const columns = widget.header.map((el, idx) => {
        return {
          label: el.title,
          value: (row: IContent) => row[idx],
        };
      });

      const isNumber = (num: string | number) =>
        !isNaN(parseFloat(num as string)) && !isNaN((num as number) - 0);

      const content = widget.data.reduce((acc, el) => {
        const temp: IContent = {};

        el.forEach((_el, idx) => {
          const value = _el?.value;
          const type = _el?.type;
          const formattedValue = getFormattedValue(value, type);

          if (isNumber(value)) {
            temp[idx] = parseFloat(value);
          } else {
            temp[idx] = formattedValue || '-';
          }
        });

        acc.push(temp);

        return acc;
      }, [] as IContent[]);

      return this._XLSXData(columns, content);
    } catch {}
  }

  private _resolveOtherWidget(): XLSXType | undefined {
    try {
      const widget = this.data as BuiltWidgetConfig;
      const columns = widget.data.reduce(
        (acc, el, idx) => {
          acc.push({
            label: el.title,
            value: (row: IContent) => row[idx + 1],
          });

          return acc;
        },
        [
          {
            label: widget.domains?.[0]?.title ?? '',
            value: (row: IContent) => row[0],
          },
        ],
      );

      const content = widget.domains[0].values.reduce((acc, el, idx) => {
        const temp: IContent = {};

        columns.forEach((_, _idx) => {
          if (_idx === 0) {
            temp[_idx] = el;
          } else {
            temp[_idx] = +widget.data[_idx - 1].values[idx] ?? '';
          }
        });

        acc.push(temp);

        return acc;
      }, [] as IContent[]);

      return this._XLSXData(columns, content);
    } catch {}
  }

  private _resolveMapWidget(): XLSXType | undefined {
    try {
      let widgetHovers = (this.data as BuiltWidgetConfig).hovers;

      const widgetData = (this.data as BuiltWidgetConfig).data[0];

      const widgetConfig = [...(widgetHovers || []), widgetData];

      const columns = widgetConfig.map((widget, idx) => ({
        label: widget.title,
        value: (row: IContent) => row[idx],
      }));

      if (widgetData) {
        columns.push({
          label: 'Region',
          value: (row: IContent) => row[columns.length - 1],
        });
      }

      if (!widgetHovers?.length) {
        const widgetValues = widgetData.values.length
          ? widgetData.values
          : widgetData.labels;

        const content = widgetValues.length
          ? widgetValues.reduce((acc: IContent[], _: any, idx: number) => {
              const temp: IContent = {};

              const value = widgetData.values?.[idx];
              const label = widgetData.labels?.[idx] || '';
              temp[0] = value
                ? `${value} ${widgetData.type === 'percent' ? '%' : ''}`
                : '';
              temp[1] = label;

              acc.push(temp);

              return acc;
            }, [])
          : [];

        return this._XLSXData(columns, content);
      }

      const content = widgetHovers?.[0].values.reduce(
        (acc: IContent[], _, idx) => {
          const temp: IContent = {};

          widgetHovers?.[0].values.forEach((__, _idx) => {
            const hover = widgetHovers?.[_idx];
            const value = hover?.values[idx];

            temp[_idx] = value
              ? `${value} ${hover?.type === 'percent' ? '%' : ''}`
              : '';
          });

          acc.push(temp);

          return acc;
        },
        [],
      );

      content.forEach((contentItem, idx) => {
        widgetData.values.forEach((_, _idx) => {
          const value = widgetData.values?.[idx];
          const label = widgetData.labels?.[idx] || '';
          contentItem[widgetHovers?.length || 0] = value
            ? `${value} ${widgetData.type === 'percent' ? '%' : ''}`
            : '';
          contentItem[widgetHovers?.length! + 1 || 1] = label;
        });
      });

      return this._XLSXData(columns, content);
    } catch {}
  }

  private _resolvePieWidget(): XLSXType | undefined {
    try {
      const widget = this.data as PieChartConfig;
      const hasLabels = widget.data?.[0].labels?.length;

      const columns = widget.data.map((el, idx) => {
        return {
          label: el.title,
          value: (row: IContent) => row[hasLabels ? idx + 1 : idx],
        };
      });

      if (hasLabels) {
        columns.unshift({
          label: widget.hovers?.[0]?.title || '',
          value: (row: IContent) => row[0],
        });
      }

      const content = widget.data[0].values.reduce((acc, el, idx) => {
        const temp: IContent = {};

        if (hasLabels) {
          temp[0] = widget.data[0].labels[idx];
        }

        columns.forEach((_, _idx) => {
          temp[hasLabels ? _idx + 1 : _idx] =
            +widget.data[_idx]?.values?.[idx] || '';
        });

        acc.push(temp);

        return acc;
      }, [] as IContent[]);

      return this._XLSXData(columns, content);
    } catch {}
  }

  private _resolveForm(survey: Model): XLSXType | undefined {
    const getVisibleQuestions = (formData: any, survey: Model) => {
      const visibleData: Record<any, any> = {};

      Object.keys(formData).forEach((questionName) => {
        if (survey.getQuestionByName(questionName)?.isVisible ?? true) {
          visibleData[questionName] = formData[questionName];
        }
      });

      return visibleData;
    };

    const formData = getVisibleQuestions(this.data, survey);
    const formConfig = this.formConfig;
    const elements = formConfig?.pages.map((page: any) => page.elements).flat();

    const columns: IColumn[] = [
      { label: Object.keys(formData)[0], value: (row: IContent) => row[0] },
      { label: '', value: (row) => row[1] },
    ];

    const getSurveyText = (title: any) => {
      const text = title?.de || title?.default || title;

      return text?.trim();
    };

    const getTableValue = (formKey: any, el?: SurveyElement): string => {
      let value = '';

      const getFormValue = (formValue: any, _idx = 0): string => {
        return Object.entries(formValue).reduce((acc, formEntry, idx) => {
          const [, value] = formEntry;

          const title =
            el?.rows?.[idx]?.text?.de ||
            el?.rows?.[idx]?.text ||
            el?.rows?.[idx] ||
            el?.columns?.[idx]?.title?.de ||
            el?.columns?.[idx]?.title ||
            el?.columns?.[idx]?.name;

          const choice = el?.columns?.[idx]?.choices?.find(
            (choice: any) => String(choice) === String(value),
          );

          const translatedChoice =
            choice?.text || choice?.text?.de || choice || value;

          if (title) {
            acc += `${title?.trim()?.replace(/:/, '')}: ${
              typeof value === 'object'
                ? Object.values(value!)[0]
                : translatedChoice
            }, `;
          }

          return acc;
        }, '');
      };

      if (el && el?.type === FORM_ELEMENT_TYPE.BOOL) {
        if (el.labelTrue && el.labelFalse) {
          return getSurveyText(formKey ? el.labelTrue : el.labelFalse);
        }

        return formKey ? 'Ja' : 'Nein';
      }

      if (Array.isArray(formKey)) {
        if (typeof formKey[0] === 'object') {
          value = formKey.map((_formKey) => getFormValue(_formKey)).join('');
        } else {
          const formKeys = formKey;

          value =
            el?.choices
              ?.filter((choice) => formKeys?.includes?.(choice?.value))
              ?.map((choice) => choice?.text)
              .join(', ') || '';
        }
      } else if (typeof formKey === 'object' && !Array.isArray(formKey)) {
        value = getFormValue(formKey);
      } else {
        value =
          el?.choices?.find((choice: any) => choice?.value === String(formKey))
            ?.text || String(formKey);
      }

      return value;
    };

    const getTitle = (key: string) => {
      const element = elements.find((element: any) => element?.name === key);
      if (element?.hasOwnProperty('title')) {
        return (
          element?.title?.de ||
          element?.title?.title?.de ||
          element?.title ||
          ' '
        );
      }

      return key;
    };
    const content = Object.keys(formData)
      .map((key) => ({
        0: getTitle(key),
        1: getTableValue(
          formData[key],
          elements.find((element: any) => element?.name === key),
        ),
      }))
      // Filter excel rows that have no value in the second column
      .filter((tableRow) => Boolean(tableRow[1]));

    return this._XLSXData(columns, content);
  }
}
