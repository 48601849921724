import { gql } from '@apollo/client';
import { metadata } from '../common/fragments';
import { dataSourceFragment } from './fragments';
import { DataSource, VERSION_TYPE } from './types';

export type GetAllDataSourcesData = { allDataSourcesByType: DataSource[] };

export type GetAllDataSourcesVars = {
  versionType: VERSION_TYPE;
};

export const GET_ALL_DATA_SOURCES = gql`
  query GetAllDataSources($versionType: String!) {
    allDataSourcesByType(versionType: $versionType) {
      ...DataSource
    }
  }
  ${dataSourceFragment}
`;

export type GetAllDataSourcesAllVersionTypesData = {
  live: DataSource[];
  draft: DataSource[];
  old: DataSource[];
};

export type GetAllDataSourcesAllVersionTypesVars = null;

export const GET_ALL_DATA_SOURCES_ALL_VERSION_TYPES = gql`
  query GetAllDataSourcesAllVersionTypes {
    live: allDataSourcesByType(versionType: "${VERSION_TYPE.LIVE}") {
      ...GetAllDataSourcesAllVersionTypesFragment
    }
    draft: allDataSourcesByType(versionType: "${VERSION_TYPE.DRAFT}") {
      ...GetAllDataSourcesAllVersionTypesFragment
    }
    old: allDataSourcesByType(versionType: "${VERSION_TYPE.OLD}") {
      ...GetAllDataSourcesAllVersionTypesFragment
    }
  }
  fragment GetAllDataSourcesAllVersionTypesFragment on DataSource {
    dataSourceConfig {
      name
    }
    ${metadata}
  }
`;

export type GetDataSourceVersionData = { dataSourceVersion: DataSource };

export type GetDataSourceVersionVars = {
  versionId: string;
};

export const GET_DATA_SOURCE_VERSION = gql`
  query GetDataSourceVersion($versionId: String!) {
    dataSourceVersion(versionId: $versionId) {
      ...DataSource
    }
  }
  ${dataSourceFragment}
`;

export type GetAllDataSourceVersionsData = {
  allDataSourceVersions: Array<Omit<DataSource, 'dataSourceConfig'>>;
};

export type GetAllDataSourceVersionsVars = {
  id: string;
};

export const GET_ALL_DATA_SOURCE_VERSIONS = gql`
  query GetAllDataSourceVersions($id: String!) {
    allDataSourceVersions(id: $id) {
      ${metadata}
    }
  }
`;

export type TestDataSourceConnectionData = { testConnection: string };

export type TestDataSourceConnectionVars = {
  dataSourceVersionId: string;
};

export const TEST_DATA_SOURCE_CONNECTION = gql`
  query TestDataSourceConnection($dataSourceVersionId: String!) {
    testConnection(dataSourceVersionId: $dataSourceVersionId)
  }
`;

export type GetAllDataSourceUniqueIdsData = {
  allDataSourceUniqueIDs: string[];
};

export type GetAllDataSourceUniqueIdsVars = null;

export const GET_ALL_DATA_SOURCE_UNIQUE_IDS = gql`
  query GetAllDataSourceUniqueIds {
    allDataSourceUniqueIDs
  }
`;

export type GetLiveDataSourceInfoData = {
  getLiveDataSourceInfo: { id?: string; name?: string };
};

export type GetLiveDataSourceInfoVars = {
  id: string;
};

export const GET_LIVE_DATA_SOURCE_INFO = gql`
  query GetLiveDataSourceInfo($id: String!) {
    getLiveDataSourceInfo(id: $id) {
      id
      name
    }
  }
`;
