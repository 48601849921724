import React, { useEffect, useState } from 'react';
import '../assets/styles/App.scss';
import './index.css';
import './index.scss';
import '../assets/styles/shared-styles/index.scss';
import '../main.scss';
import { Router } from 'react-router-dom';
import Layout from './Layout';
import Routes from './Routes';
import { ApolloProvider } from '@apollo/client';
import { client } from './apollo';
import history from '../utils/history';
import { ENV_VAR, getEnvVar } from '../utils/common';
import { getLocalUserData } from '../gql/user/local';
import { addUsetiful } from '../utils/usetiful';
import './i18n';
import {
  buildEditingContextReducer,
  DefaultEditingContextValue,
  EditingContext,
  EditingContextType,
} from '../containers/Solutions/Structure/editing';
import UsersnapProvider from '../components/UsersnapProvider';
import { useGainsight } from '../utils/gainsight';
import ModalProvider from '../providers/modal';

function App() {
  const user = getLocalUserData();

  const [editingContextValue, setEditingContextValue] =
    useState<EditingContextType>(DefaultEditingContextValue);

  useEffect(() => {
    buildEditingContextReducer(setEditingContextValue);
  }, []);

  useEffect(() => {
    addUsetiful();
  }, []);

  useGainsight();

  return (
    <ApolloProvider client={client}>
      <ModalProvider>
        <Router history={history}>
          <UsersnapProvider
            apiKey={getEnvVar(ENV_VAR.USERSNAP_GLOBAL_API_KEY) as string}
            initParams={
              user
                ? {
                    user: {
                      userId: user.sub,
                      email: user.email,
                    },
                  }
                : {}
            }
          >
            <EditingContext.Provider value={editingContextValue}>
              <Layout>
                <Routes />
              </Layout>
            </EditingContext.Provider>
          </UsersnapProvider>
        </Router>
      </ModalProvider>
    </ApolloProvider>
  );
}

export default App;
