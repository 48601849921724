import React from 'react';
import { PointSymbolProps } from '@nivo/line';
import { TRANSLUCENT_OPACITY } from '../../utils/dataHighlights';

const PointSymbol: (props: Readonly<PointSymbolProps>) => React.ReactNode = ({
  size,
  color,
  borderWidth,
  borderColor,
  datum,
}) => {
  return (
    <g>
      <circle
        fill={color}
        r={size / 2}
        strokeWidth={borderWidth}
        stroke={borderColor}
        fillOpacity={datum.translucent ? TRANSLUCENT_OPACITY : 1}
      />
    </g>
  );
};

export default PointSymbol;
