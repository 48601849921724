import styled from 'styled-components';
import { colors } from '../../../utils/colors';
import { VersionIcon } from '../../VersionsList/styles';

export const EntityIconWrapper = styled.div<{ iconSize: number }>`
  ${({ iconSize }) => `height: ${iconSize}px;`}
  ${({ iconSize }) => `width: ${iconSize}px;`}
  position: relative;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

export const IndicatorIconPlace = styled.div`
  background: white;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const CrossIcon = styled.div`
  &:after {
    position: absolute;
    right: 1px;
    bottom: -3.5px;
    content: '\\00d7';
    font-weight: bold;
    color: ${colors.secondary0};
  }
`;

export const DotIcon = styled.div`
  &:after {
    position: absolute;
    right: 1px;
    bottom: -3.5px;
    content: '●';
    font-size: 8px;
    font-weight: bold;
    color: #f7b500;
  }
`;

export const VersionTypeIcon = styled(VersionIcon)`
  margin-left: -16px;
  position: relative;
  top: 6px;
`;

export const ImageIcon = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 8px;
  margin: 2px;
`;
