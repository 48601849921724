import { useQuery } from '@apollo/client/react/hooks/useQuery';
import { Menu, MenuItem, mergeRefs } from '@blueprintjs/core';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { ReactComponent as MoreIcon } from '../../../assets/icons/nav-icons/more.svg';
import { getStructureSubdomain } from '../../../containers/Solutions/Structure/Sidebar/Sidebar';
import { useCollectQuery } from '../../../gql/form/hooks';
import { PERMITTED_FORMS, PermittedFormsData } from '../../../gql/form/queries';
import {
  PERMITTED_STRUCTURES,
  PermittedStructuresData,
} from '../../../gql/structure/queries';
import { useCurrentUserQuery } from '../../../gql/user/hooks';
import {
  getIsAnalyticsEngineer,
  getIsCustomer,
  getIsDA,
  getIsGroupAdmin,
  getIsGroupEditor,
} from '../../../gql/user/local';
import { Feature } from '../../../gql/user/types';
import {
  AdminRoute,
  ArchiveRoute,
  DatasetRoute,
  DatasourcesRoute,
  ExplorerRoute,
  FormsRoute,
  IRoute,
  LibrariesRoute,
  PagesRoute,
  QueriesRoute,
  SchedulersRoute,
  SolutionPreviewsRoute,
  SolutionsRoute,
  WidgetsRoute,
} from '../../Routes';
import { SidebarItem, SidebarTooltip, Wrapper } from './styles';

export const getBaseRoute = (pathname: string): string =>
  `/${pathname.split('/')[1]}`;

const isRouteActive = (route: IRoute, pathname: string) => {
  switch (route.label) {
    case QueriesRoute.label:
      return (
        pathname.startsWith(QueriesRoute.path) &&
        pathname.length <= QueriesRoute.path.length + 1
      );

    case ExplorerRoute.label:
      return (
        pathname.startsWith(`${QueriesRoute.path}/`) &&
        pathname.length > QueriesRoute.path.length + 1
      );

    default:
      return pathname.startsWith(route.path);
  }
};

const Sidebar = () => {
  const ADDITIONAL_ITEMS = [
    DatasourcesRoute,
    SolutionPreviewsRoute,
    PagesRoute,
  ];

  const history = useHistory();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const baseRoute = getBaseRoute(pathname);
  const isDA = getIsDA();
  const isAE = getIsAnalyticsEngineer();

  const { data: structuresData } = useQuery<PermittedStructuresData>(
    PERMITTED_STRUCTURES,
    {
      skip: isDA,
    },
  );

  const { data: formsData } = useCollectQuery<PermittedFormsData>(
    PERMITTED_FORMS,
    {
      skip: isDA,
    },
  );

  const { features } = useCurrentUserQuery();

  const [mainItems, bottomItems] = useMemo(() => {
    const isEditor = getIsGroupEditor();
    const isAdmin = getIsGroupAdmin();
    const isCustomer = getIsCustomer();
    const isCSVFeatureEnabled = features?.includes(Feature.csvUpload);

    if (isDA) {
      return [
        [
          SolutionsRoute,
          WidgetsRoute,
          ExplorerRoute,
          QueriesRoute,
          LibrariesRoute,
          FormsRoute,
        ],
        [AdminRoute, SchedulersRoute],
      ];
    }

    if (isAE) {
      return [
        [
          SolutionsRoute,
          WidgetsRoute,
          ExplorerRoute,
          QueriesRoute,
          DatasetRoute,
        ],
        [...(isAdmin ? [AdminRoute] : [])],
      ];
    }

    return [
      [
        ...(structuresData?.allPermittedLiveStructures.length
          ? [SolutionsRoute]
          : []),
        ...(formsData?.allPermittedLiveForms.length
          ? [FormsRoute, ArchiveRoute]
          : []),
        ...(isCustomer
          ? isEditor && isCSVFeatureEnabled
            ? [DatasetRoute, LibrariesRoute]
            : [LibrariesRoute]
          : []),
      ],
      [...(isAdmin ? [AdminRoute] : [])],
    ];
  }, [
    isDA,
    isAE,
    features,
    formsData?.allPermittedLiveForms.length,
    structuresData?.allPermittedLiveStructures.length,
  ]);

  const renderSidebarItems = useCallback(
    (items: IRoute[]) =>
      items.map((route) => {
        const { label, path, icon } = route;
        const isActive = isRouteActive(route, pathname);
        const Icon = icon!;

        return (
          <Tooltip2
            key={path}
            content={
              // t('navigation.solutions')
              // t('navigation.widgets')
              // t('navigation.queries')
              // t('navigation.dataset')
              // t('navigation.libraries')
              // t('navigation.forms')
              // t('navigation.archive')
              // t('navigation.admin-console')
              // t('navigation.schedulers')
              <SidebarTooltip>{t(`navigation.${label}`)}</SidebarTooltip>
            }
            placement="right-end"
            popoverClassName="custom-popover-content"
            minimal
          >
            <Link
              to={path.includes(':owner') ? path.replace(':owner', 'my') : path}
              id={`sidebar-item-${label}`}
            >
              <SidebarItem isActive={isActive}>
                <Icon />
              </SidebarItem>
            </Link>
          </Tooltip2>
        );
      }),
    [pathname, t],
  );

  const subdomain = getStructureSubdomain();

  if (subdomain) {
    return null;
  }

  return (
    <Wrapper id="main-sidebar">
      {renderSidebarItems(mainItems)}
      {isDA && (
        <Popover2
          content={
            <Menu>
              {ADDITIONAL_ITEMS.map(({ label, path }) => (
                <MenuItem
                  id={`sidebar-item-more-${label}`}
                  key={path}
                  // t('navigation.datasources')
                  // t('navigation.solution-previews')
                  // t('navigation.pages')
                  text={t(`navigation.${label}`)}
                  onClick={() => history.push(path)}
                />
              ))}
            </Menu>
          }
          minimal
          placement="right-start"
          renderTarget={({
            isOpen: isPopoverOpen,
            ref: ref1,
            ...popoverProps
          }) => (
            <Tooltip2
              placement="right-end"
              popoverClassName="custom-popover-content"
              minimal
              content={<SidebarTooltip>{t('navigation.more')}</SidebarTooltip>}
              disabled={isPopoverOpen}
              openOnTargetFocus={false}
              renderTarget={({
                isOpen: isTooltipOpen,
                ref: ref2,
                ...tooltipProps
              }) => (
                <SidebarItem
                  {...popoverProps}
                  {...tooltipProps}
                  ref={mergeRefs(ref1, ref2)}
                  id="sidebar-item-more"
                  isActive={
                    isPopoverOpen ||
                    ADDITIONAL_ITEMS.map((item) => item.path).includes(
                      baseRoute,
                    )
                  }
                >
                  <MoreIcon />
                </SidebarItem>
              )}
            />
          )}
        />
      )}
      <div style={{ flex: 1 }} />
      {renderSidebarItems(bottomItems)}
    </Wrapper>
  );
};

export default Sidebar;
