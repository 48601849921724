import { useQuery } from '@apollo/client/react/hooks/useQuery';
import { useMutation } from '@apollo/client';
import {
  GET_USER,
  GET_USER_FEATURES,
  GetUserData,
  GetUserFeaturesData,
  GetUserVars,
} from './queries';
import { getIsGroupAdmin, getLocalUserData, getToken, isAuth } from './local';
import jwt_decode from 'jwt-decode';
import { USER_ROLES } from './types';
import { CustomerRole } from '../types';
import { useLogout } from '../../utils/hooks/useLogout';
import {
  SEARCH_ORGANIZATIONS,
  SearchOrganizationsData,
  SearchOrganizationsVars,
} from '../organization/queries';

export const USER_MANAGEMENT_MICROSERVICE = 'USER_MANAGEMENT_MICROSERVICE';

export const useUserQuery: typeof useQuery = (query, options) =>
  useQuery(query, {
    ...options,
    context: { microservice: USER_MANAGEMENT_MICROSERVICE },
  });

export const useUserMutation: typeof useMutation = (mutation, options) =>
  useMutation(mutation, {
    ...options,
    context: { microservice: USER_MANAGEMENT_MICROSERVICE },
  });

export type UserGroup = {
  _id: string;
  name: string;
  roles: CustomerRole[];
};

export interface JWT {
  firstName: string;
  lastName: string;
  email: string;
  role: USER_ROLES;
  groups: UserGroup[];
  sub: string;
  iat: string;
  exp: string;
  ext: string;
}

export const useCurrentUserQuery = () => {
  const logout = useLogout();

  const token = getToken();
  const id = token ? jwt_decode<JWT>(token).sub : '';

  const {
    data: userData,
    refetch: refetchCurrentUser,
    loading: loadingUser,
  } = useUserQuery<GetUserData, GetUserVars>(GET_USER, {
    variables: { id },
    skip: !isAuth(),
    onError: logout,
  });

  const { data: featuresData, loading: loadingFeatures } =
    useQuery<GetUserFeaturesData>(GET_USER_FEATURES, {
      skip: !isAuth(),
    });

  const { data: organizationsData } = useQuery<
    SearchOrganizationsData,
    SearchOrganizationsVars
  >(SEARCH_ORGANIZATIONS, {
    fetchPolicy: 'network-only',
    variables: {
      searchInput: {
        text: id,
      },
    },
    skip: !isAuth(),
  });

  return {
    user: userData?.findUserById,
    loadingUser: loadingUser && loadingFeatures,
    refetchCurrentUser,
    features: featuresData?.getUserFeatures,
    organization: organizationsData?.searchOrganizations?.[0],
  };
};

export const useHasAdminRights = () => {
  const localUser = getLocalUserData();
  const userRole = localUser?.role;

  return (
    userRole === USER_ROLES.DATA_ANALYST ||
    userRole === USER_ROLES.ANALYTICS_ENGINEER ||
    getIsGroupAdmin()
  );
};
