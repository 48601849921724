import React, { FC, useState } from 'react';
import { Label, SelectAssistiveText } from './styles';
import { Required } from '../TextInput/styles';
import { ReactComponent as InfoPoint } from '../../../assets/icons/info-point.svg';
import PopoverLegacy, { Selected } from '../../Popover/PopoverLegacy';
import InfoTooltip from '../../InfoTooltip';

type Props = {
  label?: string;
  error?: boolean;
  assistiveText?: string;
  onSelect: (selected: Selected) => void;
  options: Selected[];
  selected?: Selected;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  hasInfo?: boolean;
  usePortal?: boolean;
};

const Select: FC<Props> = ({
  label,
  error,
  options,
  assistiveText,
  onSelect,
  selected,
  className,
  required,
  placeholder,
  hasInfo,
  ...props
}) => {
  const [internalSelected, setInternalSelected] = useState(
    placeholder ? { value: '', title: placeholder } : options[0],
  );

  return (
    <div className={className}>
      {label && (
        <Label error={error}>
          {label}
          {required && <Required disabled={props.disabled}>*</Required>}
        </Label>
      )}
      <div style={{ display: 'flex' }}>
        <PopoverLegacy
          multiline
          selections={options}
          selectedContent={selected || internalSelected}
          {...props}
          required={!label && required}
          onClick={({ selected }) => {
            onSelect(selected);
            setInternalSelected(selected);
          }}
        />
        {hasInfo && (selected || internalSelected)?.value && (
          <div style={{ margin: '3px 6px 0' }}>
            <InfoTooltip
              content={(selected || internalSelected)?.tooltipContent as string}
              placement={'right'}
            >
              <InfoPoint color="#4A599A" width={15} />
            </InfoTooltip>
          </div>
        )}
      </div>
      {assistiveText && (
        <SelectAssistiveText>{assistiveText}</SelectAssistiveText>
      )}
    </div>
  );
};

export default Select;
