export enum PIVOT_FUNCTION {
  AVG = 'avg',
  COUNT = 'count',
  FIRST = 'first',
  LAST = 'last',
  MAX = 'max',
  MIN = 'min',
  SUM = 'sum',
  DISTINCT_COUNT = 'distinct_count',
}

export enum ENTITY_SORT_ORDER {
  ASC = 'ASC',
  DESC = 'DESC',
}
