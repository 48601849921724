import { useMemo } from 'react';
import { BuiltWidgetConfig } from '../../../gql/widget/types';

export type DomainSeparator = { label: string; x: number };

export const useSeparators = (data: BuiltWidgetConfig): DomainSeparator[] =>
  useMemo(
    () =>
      data?.domains
        ?.flatMap(
          ({ separators, values }) =>
            separators?.map(({ text, position }) => {
              const x = values.findIndex((val) => val === position);

              return {
                label: text,
                x,
              };
            }) || [],
        )
        .filter(({ x }) => x !== -1) || [],
    [data?.domains],
  );
