import { gql } from '@apollo/client';
import { buildPageConfigFragment, pageFragment } from './fragments';
import { Page, PageAccess, PageConfig } from './types';
import { TextWidgetConfig } from '../widget/types';
import { AddToElement } from '../../components/BtnAddTo/types';
import { EditorMode } from '../common/types';
import { ENTITY_SEARCH_TYPES } from '../types';

export type CreatePageData = { createPage: Page };

export type CreatePageVars = {
  CreatePageInput: {
    pageConfig: PageConfig;
    access?: PageAccess;
    editorMode: EditorMode;
  };
  editorStructureId?: string;
};

export const CREATE_PAGE = gql`
  mutation CreatePage(
    $CreatePageInput: CreatePageInput!
    $editorStructureId: String
  ) {
    createPage(
      CreatePageInput: $CreatePageInput
      editorStructureId: $editorStructureId
    ) {
      ...Page
    }
  }
  ${pageFragment}
`;

export type CreatePageVersionData = {
  createPageVersion: Page;
};

export type CreatePageVersionVars = {
  CreatePageInput: {
    pageConfig: PageConfig;
    access?: PageAccess;
    editorMode: EditorMode;
  };
  id: string;
};

export const CREATE_PAGE_VERSION = gql`
  mutation CreatePageVersion($CreatePageInput: CreatePageInput!, $id: String!) {
    createPageVersion(CreatePageInput: $CreatePageInput, id: $id) {
      ...Page
    }
  }
  ${pageFragment}
`;

export type UpdatePageVersionData = {
  updatePageVersion: Page;
};

export type UpdatePageVersionVars = {
  UpdatePageVersionInput: {
    versionId: string;
    pageConfig: PageConfig;
    editorMode: EditorMode;
  };
};

export const UPDATE_PAGE_VERSION = gql`
  mutation UpdatePageVersion($UpdatePageVersionInput: UpdatePageVersionInput!) {
    updatePageVersion(UpdatePageVersionInput: $UpdatePageVersionInput) {
      ...Page
    }
  }
  ${pageFragment}
`;

export type UpdateAndPublishPageVersionData = {
  updateAndPublishPageVersion: Page;
};

export type UpdateAndPublishPageVersionVars = {
  UpdateAndPublishPageVersion: {
    versionId: string;
    pageConfig: PageConfig;
  };
  editorStructureId?: string;
};

export const UPDATE_AND_PUBLISH_PAGE_VERSION = gql`
  mutation UpdateAndPublishPageVersion(
    $UpdateAndPublishPageVersion: UpdateAndPublishPageVersionInput!
    $editorStructureId: String
  ) {
    updateAndPublishPageVersion(
      UpdateAndPublishPageVersion: $UpdateAndPublishPageVersion
      editorStructureId: $editorStructureId
    ) {
      ...Page
    }
  }
  ${pageFragment}
`;

export type DeletePageVersionData = {
  deletePageVersion: Page[];
};

export type DeletePageVersionVars = {
  DeletePageVersionInput: {
    versionId: string;
  }[];
};

export const DELETE_PAGE_VERSION = gql`
  mutation DeletePageVersion(
    $DeletePageVersionInput: [DeletePageVersionInput!]!
  ) {
    deletePageVersions(DeletePageVersionInput: $DeletePageVersionInput) {
      ...Page
    }
  }
  ${pageFragment}
`;

export type UpdatePageDataSourceData = { updatePageDataSource: Page };

export type UpdatePageDataSourceVars = {
  datasourceId: string;
  versionId: string;
};

export const UPDATE_PAGE_DATASOURCE = gql`
  mutation UpdatePageDataSource($datasourceId: String!, $versionId: String!) {
    updatePageDataSource(datasourceId: $datasourceId, versionId: $versionId) {
      ...Page
    }
  }
  ${pageFragment}
`;

export type AppendWidgetData = { appendWidgetToPage: PageConfig };

export type AppendWidgetVars = {
  appendWidgetToPageInput: {
    pageId: string;
    structureVersionId: string;
    rowIndex: number;
    columnIndex: number;
    elementIndex: number;
    colspan?: number;
    widgetConfig?: TextWidgetConfig;
    pageIdType?: ENTITY_SEARCH_TYPES;
    widgetId?: string;
  };
};

export const APPEND_WIDGET = gql`
  mutation AppendWidget($appendWidgetToPageInput: AppendWidgetToPageInput!) {
    appendWidgetToPage(appendWidgetToPageInput: $appendWidgetToPageInput) {
      ...BuiltPageConfig
    }
  }
  ${buildPageConfigFragment}
`;

export type DetachWidgetData = { detachWidgetFromPage: Page };

export type DetachWidgetVars = {
  detachWidgetFromPageInput: {
    pageId: string;
    widgetId: string;
    structureId: string;
    pageIdType?: ENTITY_SEARCH_TYPES;
  };
};

export const DETACH_WIDGET = gql`
  mutation DetachWidget(
    $detachWidgetFromPageInput: DetachWidgetFromPageInput!
  ) {
    detachWidgetFromPage(
      detachWidgetFromPageInput: $detachWidgetFromPageInput
    ) {
      ...BuiltPageConfig
    }
  }
  ${buildPageConfigFragment}
`;

export type PatchAndPublishPageVersionData = {
  patchAndPublishPageVersion: Page;
};

export type PatchAndPublishPageVersionVars = {
  PatchAndPublishPageVersion: {
    id: string;
    pageConfig: PageConfig;
  };
  editorStructureId: string;
};

export const PATCH_AND_PUBLISH_PAGE_VERSION = gql`
  mutation PatchAndPublishPageVersion(
    $PatchAndPublishPageVersion: PatchAndPublishPageVersionInput!
    $editorStructureId: String!
  ) {
    patchAndPublishPageVersion(
      PatchAndPublishPageVersion: $PatchAndPublishPageVersion
      editorStructureId: $editorStructureId
    ) {
      ...Page
    }
  }
  ${pageFragment}
`;

export type DeletePageData = {
  deletePage: Page[];
};

export type DeletePageVars = {
  DeletePageInput: {
    id: string;
  };
};

export const DELETE_PAGE = gql`
  mutation DeletePage($DeletePageInput: DeletePageInput!) {
    deletePage(DeletePageInput: $DeletePageInput) {
      ...Page
    }
  }
  ${pageFragment}
`;

export type AddToPageVersionData = {
  addToPageVersion: Page;
};

export type AddToPageVersionVars = {
  AddToPageVersionInput: {
    versionId: string;
    elementId: string;
    elementType: AddToElement;
  };
};

export const ADD_TO_PAGE_VERSION = gql`
  mutation AddToPageVersion($AddToPageVersionInput: AddToPageVersionInput!) {
    addToPageVersion(AddToPageVersionInput: $AddToPageVersionInput) {
      ...Page
    }
  }
  ${pageFragment}
`;
