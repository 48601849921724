import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useQuery } from '@apollo/client';
import { ENTITY_TYPES } from '../gql/types';
import { useQueryParam } from './common';
import {
  GET_SITE_VERSION,
  GetSiteVersionData,
  GetSiteVersionVars,
} from '../gql/site/queries';
import {
  GET_STRUCTURE_VERSION,
  GetStructureVersionData,
  GetStructureVersionVars,
} from '../gql/structure/queries';
import { GET_PAGE_VERSION } from '../gql/page/queries';
import {
  GET_WIDGET_VERSION,
  GetWidgetVersionData,
  GetWidgetVersionVars,
} from '../gql/widget/queries';
import { useHistory } from 'react-router-dom';
import { getLocalUserData, isAuth } from '../gql/user/local';
import { USER_ROLES } from '../gql/user/types';

export type IPreviewContext = Record<
  ENTITY_TYPES,
  Record<string, string> | null
>;

export const DefaultPreviewContextValue = {
  [ENTITY_TYPES.STRUCTURE]: null,
  [ENTITY_TYPES.PAGE]: null,
  [ENTITY_TYPES.WIDGET]: null,
  [ENTITY_TYPES.QUERY]: null,
  isCustomerEditorModeEnabled: false,
};

export const PreviewContext = createContext<IPreviewContext>(
  DefaultPreviewContextValue,
);

export const usePreviewEntity = (entityType: ENTITY_TYPES) => {
  return useContext(PreviewContext)[entityType];
};

export const useGetPreviewConfig = (
  setContextValue: Dispatch<SetStateAction<IPreviewContext>>,
) => {
  const [isFinished, setIsFinished] = useState(false);

  const siteVersionId = useQueryParam('siteVersionId') as string;

  const { data: versionData } = useQuery<
    GetSiteVersionData,
    GetSiteVersionVars
  >(GET_SITE_VERSION, {
    variables: { versionId: siteVersionId },
    skip: isFinished || !siteVersionId,
  });

  const versionConfig = versionData?.siteVersion.siteConfig;

  const { refetch: refetchStructure } = useQuery<
    GetStructureVersionData,
    GetStructureVersionVars
  >(GET_STRUCTURE_VERSION, {
    skip: true,
  });

  const { refetch: refetchPage } = useQuery(GET_PAGE_VERSION, {
    skip: true,
  });

  const { refetch: refetchWidget } = useQuery<
    GetWidgetVersionData,
    GetWidgetVersionVars
  >(GET_WIDGET_VERSION, {
    skip: true,
  });

  const getConfig = useCallback(async () => {
    if (!versionConfig || isFinished) {
      return;
    }

    try {
      if (versionConfig.structures) {
        const structuresIdToVersionIdMap: Record<string, string> = {};

        await Promise.all(
          versionConfig.structures.map(async (versionId) => {
            const {
              data: {
                structureVersion: { id },
              },
            } = await refetchStructure({ versionId });

            structuresIdToVersionIdMap[id] = versionId;
          }),
        );

        setContextValue((prev) => ({
          ...prev,
          [ENTITY_TYPES.STRUCTURE]: structuresIdToVersionIdMap,
        }));
      }

      if (versionConfig.pages) {
        const pagesIdToVersionIdMap: Record<string, string> = {};

        await Promise.all(
          versionConfig.pages.map(async (versionId) => {
            const {
              data: {
                pageVersion: { id },
              },
            } = await refetchPage({ versionId });

            pagesIdToVersionIdMap[id] = versionId;
          }),
        );

        setContextValue((prev) => ({
          ...prev,
          [ENTITY_TYPES.PAGE]: pagesIdToVersionIdMap,
        }));
      }

      if (versionConfig.widgets) {
        const widgetsIdToVersionIdMap: Record<string, string> = {};

        await Promise.all(
          versionConfig.widgets.map(async (versionId) => {
            const {
              data: {
                widgetVersion: { id },
              },
            } = await refetchWidget({ versionId });

            widgetsIdToVersionIdMap[id] = versionId;
          }),
        );

        setContextValue((prev) => ({
          ...prev,
          [ENTITY_TYPES.WIDGET]: widgetsIdToVersionIdMap,
        }));
      }

      setIsFinished(true);
    } catch {}
  }, [
    isFinished,
    refetchPage,
    refetchStructure,
    refetchWidget,
    setContextValue,
    versionConfig,
  ]);

  useEffect(() => {
    if (siteVersionId) {
      getConfig();
    }
  }, [getConfig, siteVersionId]);

  return isFinished || !siteVersionId;
};

export const useRemovePreviewParamsEffect = () => {
  const history = useHistory();
  const siteVersionId = useQueryParam('siteVersionId') as string;
  const user = getLocalUserData();

  useEffect(() => {
    if (siteVersionId && (!isAuth() || user?.role === USER_ROLES.CUSTOMER)) {
      history.replace(history.location.pathname);
    }
  }, [history, siteVersionId, user]);
};
