import { gql } from '@apollo/client';
import { userFragment, userGroupFragment } from './fragments';
import {
  InvitedUserInput,
  User,
  UsersGroup,
  HubspotLog,
  Feature,
} from './types';

export type GetUserData = { findUserById: User };

export type GetUserVars = {
  id: string;
};

export const GET_USER = gql`
  query GetUser($id: String!) {
    findUserById(id: $id) {
      ...User
    }
  }
  ${userFragment}
`;

export type GetAllUserGroupsData = { getAllUserGroups: UsersGroup[] };

export type GetAllUserGroupsVars = null;

export const GET_ALL_USER_GROUPS = gql`
  query GetAllUserGroups {
    getAllUserGroups {
      ...UsersGroup
    }
  }
  ${userGroupFragment}
`;

export type SearchUsersData = { searchUsers: User[] };

export type SearchUsersVars = {
  input: string;
  groupId?: string;
  sortField?: string;
  sortOrder?: string;
  organizationId?: string;
};

export const SEARCH_USERS = gql`
  query SearchUsers(
    $input: String!
    $groupId: String
    $sortField: String
    $sortOrder: String
    $organizationId: String
  ) {
    searchUsers(
      input: $input
      groupId: $groupId
      sortField: $sortField
      sortOrder: $sortOrder
      organizationId: $organizationId
    ) {
      ...User
    }
  }
  ${userFragment}
`;

export type SearchUserGroupsData = { searchUserGroups: UsersGroup[] };

export type SearchUserGroupsVars = {
  name: string;
  organizationId?: string;
};

export const SEARCH_USER_GROUPS = gql`
  query SearchUserGroups($name: String!, $organizationId: String) {
    searchUserGroups(name: $name, organizationId: $organizationId) {
      ...UsersGroup
    }
  }
  ${userGroupFragment}
`;

export type GetUserGroupData = { findGroupById: UsersGroup };

export type GetUserGroupVars = {
  id: string;
};

export const GET_USER_GROUP = gql`
  query GetUserGroup($id: String!) {
    findGroupById(id: $id) {
      ...UsersGroup
    }
  }
  ${userGroupFragment}
`;

export type GetGroupsByUserData = { getGroupsByUserId: UsersGroup[] };

export type GetGroupsByUserVars = {
  id: string;
};

export const GET_GROUPS_BY_USER = gql`
  query GetGroupsByUser($id: String!) {
    getGroupsByUserId(id: $id) {
      ...UsersGroup
    }
  }
  ${userGroupFragment}
`;

export type CheckValidResetPasswordData = {
  checkValidReset: boolean;
};

export type CheckValidResetPasswordVars = {
  token: string;
};

export const CHECK_VALID_REST_PASSWORD = gql`
  query CheckValidReset($token: String!) {
    checkValidReset(token: $token)
  }
`;

export type GetInvitedUserData = {
  getInvitedUser: InvitedUserInput;
};

export type GetInvitedUserVars = {
  token: string;
};

export const GET_INVITED_USER = gql`
  query GetInvitedUser($token: String!) {
    getInvitedUser(token: $token) {
      firstName
      lastName
      email
    }
  }
`;

export type HubspotSyncData = { getOngoingHubspotSync: HubspotLog };

export type HubspotSyncVars = {};

export const GET_SYNC_HUBSPOT = gql`
  query GetHubspotSync {
    getOngoingHubspotSync {
      isRunning
      progress
      total
    }
  }
`;

export type GetUserGroupsData = {
  getUserGroups: UsersGroup[];
};

export const GET_USER_GROUPS = gql`
  query GetUserGroups {
    getUserGroups {
      ...UsersGroup
    }
  }
  ${userGroupFragment}
`;

export type GetUserFeaturesData = {
  getUserFeatures: Feature[];
};

export const GET_USER_FEATURES = gql`
  query GetUserFeatures {
    getUserFeatures
  }
`;
