import { HTMLTableProps, Spinner } from '@blueprintjs/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InlineAlert, InlineAlertSize, InlineAlertType } from '../InlineAlert';
import { CenterWrapper, StyledEntityTable } from './styles';

type Props = {
  isEmpty?: boolean;
  loading?: boolean;
  className?: string;
  // TODO: remove from props when usage from forms is removed
  emptyText?: string;
  autoColWidth?: boolean;
  textFirst?: boolean;
} & HTMLTableProps;

const EntityTable: FC<Props> = ({
  isEmpty,
  interactive = true,
  className,
  loading,
  children,
  emptyText = 'Nothing here yet. Be the first to create something!',
  autoColWidth,
  textFirst,
  ...props
}) => {
  const { t } = useTranslation();

  return isEmpty || loading ? (
    <>
      {loading ? (
        <CenterWrapper>
          <Spinner />
        </CenterWrapper>
      ) : (
        <div className="mx-14 mt-7">
          <InlineAlert
            title={t('error.no-datasets.title')}
            type={InlineAlertType.INFO}
            size={InlineAlertSize.LARGE}
            hasGrayBg
          />
        </div>
      )}
    </>
  ) : (
    <StyledEntityTable
      interactive={interactive}
      className={className ?? ''}
      autoColWidth={autoColWidth}
      textFirst={textFirst}
      {...props}
    >
      {children}
    </StyledEntityTable>
  );
};

export default EntityTable;
