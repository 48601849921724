import styled from 'styled-components';
import { AssistiveText } from '../TextInput/styles';
import { colors } from '../../../utils/colors';

export const Label = styled.div<{ error?: boolean }>`
  font-size: 14px;
  color: ${({ error }) => (error ? colors.grapefruit : colors.monochromatic2)};
`;

export const SelectAssistiveText = styled(AssistiveText)`
  padding-left: 0;
  margin-top: 10px;
`;
