import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  ItemPredicate,
  ItemRenderer,
  Suggest2Props,
} from '@blueprintjs/select';
import { Icon, MenuItem } from '@blueprintjs/core';
import { TOTAL_FILTER_VALUE, WidgetFilterEntry } from '../../../utils/filters';
import SearchableDropdown from '../../inputs/SearchableDropdown';
import Btn from '../../Btn';
import OldSearchableMultiInput from '../../inputs/SearchableMultiInput/OldSearchableMultiInput';
import { highlightText } from '../../../utils/select';

type Props = {
  values: WidgetFilterEntry[];
  defaultItem: WidgetFilterEntry;
  onSelect: (selected: WidgetFilterEntry) => void;
  multiple: boolean;
  disableSearch: boolean;
  resetItem: WidgetFilterEntry;
} & Partial<Suggest2Props<any>>;

const SearchableDropdownFilter: FC<Props> = ({
  values,
  onSelect,
  selectedItem,
  defaultItem,
  multiple,
  disableSearch,
  // Might not need
  resetItem,
  ...props
}) => {
  const isDefaultTotal = defaultItem.value === TOTAL_FILTER_VALUE;
  const [isSelectedTotal, setIsSelectedTotal] = useState<boolean>(false);

  const [query, setQuery] = useState('');
  const [multiValues, setMultiValues] = useState<WidgetFilterEntry[]>(
    isDefaultTotal ? [resetItem] : [defaultItem],
  );

  const selectedItemValue = selectedItem?.value ?? [];

  const shouldUpdateFilters =
    !isSelectedTotal &&
    multiple &&
    Boolean(multiValues.length || selectedItemValue.length);

  useEffect(() => {
    if (shouldUpdateFilters) {
      const newFilters: WidgetFilterEntry[] =
        selectedItemValue
          ?.map?.((val: string) => {
            return values.find(({ value }) => value === val);
          })
          .filter(Boolean) || [];

      onSelect({
        title: '',
        value: newFilters.map(({ value }) => value),
      });

      setMultiValues(newFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shouldUpdateFilters,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(selectedItemValue),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(values),
  ]);

  const onMultipleRemove = useCallback(
    (item: WidgetFilterEntry) => {
      setMultiValues((prev) => {
        const newFilters = prev.filter(({ value }) => value !== item.value);

        let newVal = newFilters.map(({ value }) => value);
        const isTotal = resetItem.value === TOTAL_FILTER_VALUE;

        // Apply total when pressing the X near the value
        if (!newFilters.length) {
          if (isTotal) {
            // @ts-ignore
            newVal = TOTAL_FILTER_VALUE;
          } else {
            newVal = [resetItem.value];
          }

          setIsSelectedTotal(isTotal);
        }

        onSelect({
          ...item,
          value: newVal,
        });

        return newFilters;
      });
    },
    [onSelect, resetItem.value],
  );

  const renderItem: ItemRenderer<any> = useCallback(
    (item, { handleClick, modifiers }) => {
      if (!modifiers.matchesPredicate) {
        return null;
      }
      const text = item.title;

      const highlightedText = highlightText(text, query);

      const isTotal = item.value === TOTAL_FILTER_VALUE;

      const { disabled } = modifiers;
      let active = selectedItem ? item.value === selectedItem?.value : isTotal;

      if (multiple) {
        active = multiValues.length
          ? multiValues.some(({ value }) => value === item.value)
          : isTotal;
      }

      const onActiveClick = () => {
        multiple ? onMultipleRemove(item) : onSelect(defaultItem);
      };

      return (
        <MenuItem
          active={active}
          disabled={disabled}
          key={item.value}
          onClick={
            active
              ? onActiveClick
              : (e) => {
                  if (isTotal) {
                    onSelect({
                      ...resetItem,
                      value: TOTAL_FILTER_VALUE,
                    });

                    setMultiValues([]);
                    setQuery('');

                    setIsSelectedTotal(isTotal);

                    return;
                  }

                  handleClick(e);
                }
          }
          text={
            active ? (
              <Fragment>
                <Icon icon="small-tick" /> {highlightedText}
              </Fragment>
            ) : (
              highlightedText
            )
          }
        />
      );
    },
    [
      defaultItem,
      multiValues,
      multiple,
      onMultipleRemove,
      onSelect,
      query,
      resetItem,
      selectedItem,
    ],
  );

  const filter: ItemPredicate<WidgetFilterEntry> = useCallback(
    (_, item, _index, exactMatch) => {
      const normalizedName = item.title.toLowerCase();
      const normalizedQuery = query.toLowerCase();

      if (exactMatch) {
        return normalizedName === normalizedQuery;
      } else {
        return normalizedName.indexOf(normalizedQuery) >= 0;
      }
    },
    [query],
  );

  const commonProps = useMemo(
    () => ({
      itemsEqual: 'value',
      items: values,
      itemRenderer: renderItem,
      itemPredicate: filter,
      fill: true,
      popoverProps: {
        onClosing: () => {
          setQuery('');
        },
      },
      query,
      onQueryChange: (q: string) => {
        setQuery(disableSearch ? '' : q);
      },
    }),
    [disableSearch, filter, query, renderItem, values],
  );

  return multiple ? (
    <OldSearchableMultiInput
      onItemSelect={(selected: WidgetFilterEntry) => {
        setMultiValues((prev) => {
          const newFilters = [...prev, selected];
          setIsSelectedTotal(false);

          onSelect({
            ...selected,
            value: newFilters.map(({ value }) => value),
          });

          return newFilters;
        });
      }}
      onRemove={onMultipleRemove}
      tagRenderer={(item) => {
        if (!item || item.value === TOTAL_FILTER_VALUE) {
          return '';
        }

        return item.title;
      }}
      tagInputProps={{
        className: disableSearch ? 'u-cursor-pointer' : '',
        inputProps: {
          className: disableSearch
            ? 'u-caret-transparent u-cursor-pointer'
            : '',
          value: query,
        },
        rightElement: multiValues.length ? (
          <Btn
            className="freetext-close-btn"
            icon="cross"
            iconBtn
            minimal
            intent="none"
            onClick={() => {
              const isDefaultTotal = resetItem.value === TOTAL_FILTER_VALUE;

              onSelect({
                ...resetItem,
                value: isDefaultTotal ? TOTAL_FILTER_VALUE : [resetItem.value],
              });
              setMultiValues(isDefaultTotal ? [] : [resetItem]);

              setIsSelectedTotal(isDefaultTotal);

              setQuery('');
            }}
          />
        ) : undefined,
      }}
      placeholder={
        values[0].value === TOTAL_FILTER_VALUE ? values[0].title : ''
      }
      selectedItems={multiValues}
      noResults={<MenuItem disabled text="No results." />}
      {...commonProps}
      popoverProps={{
        ...commonProps?.popoverProps,
        minimal: true,
        popoverClassName: 'popover-filter',
      }}
      {...props}
    />
  ) : (
    <SearchableDropdown
      closeOnSelect
      onItemSelect={(selected: WidgetFilterEntry) => {
        onSelect(selected);
      }}
      selectedItem={selectedItem}
      className="widget-filter searchable-filter"
      {...commonProps}
      {...props}
    />
  );
};

export default SearchableDropdownFilter;
