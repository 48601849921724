import React, { FC } from 'react';
import { SkeletonLoaderContainer } from './styles';

type Props = {
  rows?: number;
  width?: string;
};

const SkeletonLoader: FC<Props> = ({ rows = 6, width = '500px' }) => {
  return (
    <SkeletonLoaderContainer width={width}>
      {Array(rows)
        .fill(rows)
        .map((_, ind) => (
          <div key={ind} className="skeleton-loading" />
        ))}
    </SkeletonLoaderContainer>
  );
};

export default SkeletonLoader;
