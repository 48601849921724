import { useQuery } from '@apollo/client/react/hooks/useQuery';
import { useMutation } from '@apollo/client';

export const COLLECT_MICROSERVICE = 'COLLECT_MICROSERVICE';

export const useCollectQuery: typeof useQuery = (query, options) =>
  useQuery(query, {
    ...options,
    context: { microservice: COLLECT_MICROSERVICE },
  });

export const useCollectMutation: typeof useMutation = (mutation, options) =>
  useMutation(mutation, {
    ...options,
    context: { microservice: COLLECT_MICROSERVICE },
  });
