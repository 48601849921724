import React, { FC } from 'react';
import { VERSION_TYPE } from '../../gql/dataSource/types';
import styled from 'styled-components';
import ModalLegacy from '../Modal/ModalLegacy';

const BoldTitle = styled.div`
  display: inline;
  font-family: 'GT-Eesti-Pro-Display-medium', sans-serif !important;
`;

export type DeletingVersionData = {
  id: string;
  title: string;
  type?: VERSION_TYPE;
} | null;

type Props = {
  deletingData: DeletingVersionData[];
  loading: boolean;
  isVersionList?: boolean;
  handleClose: () => void;
  onConfirm: () => Promise<void>;
  entityName: string;
};

const getDeletionWarningText = (
  entityName: string,
  deletingData: DeletingVersionData[],
  isVersionList?: boolean,
) => {
  const title = deletingData?.[0]?.title;
  const isBulkDelete = deletingData?.length > 1;

  entityName = entityName === 'Site' ? 'Solution Preview' : entityName;

  if (isBulkDelete) {
    return (
      <>
        This action will delete all the selected entities and they cannot be
        restored. Are you sure?
      </>
    );
  } else {
    return (
      <div>
        Are you sure you want to delete{' '}
        {isVersionList ? 'version from' : `${entityName}:`}{' '}
        <BoldTitle>{title}</BoldTitle>? Somebody may still need it.
      </div>
    );
  }
};

const DeleteVersionModal: FC<Props> = ({
  deletingData,
  handleClose,
  onConfirm,
  entityName,
  isVersionList,
}) => {
  const isBulkDelete = deletingData?.length > 1;

  return (
    <ModalLegacy
      isOpen={!!deletingData?.length}
      title={
        isBulkDelete
          ? 'Delete'
          : `${isVersionList ? 'Delete version from' : 'Delete'} ${
              deletingData[0]?.title
            }`
      }
      content={
        deletingData &&
        getDeletionWarningText(entityName, deletingData, isVersionList)
      }
      confirmButtonText="Delete"
      cancelButtonText="Cancel"
      intent="danger"
      onCancel={handleClose}
      onConfirm={onConfirm}
    />
  );
};

export default DeleteVersionModal;
