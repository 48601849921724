import { gql } from '@apollo/client';
import { metadata } from '../common/fragments';

export const pageTreeFragment = gql`
  fragment PageTree on PageTree {
    page {
      ${metadata}
      pageConfig {
        name
        title
      }
    }
    widgets {
      ${metadata}
      widgetConfig {
        name
      }
    }
  }
`;

export const pageVersionsFragment = gql`
  fragment StructurePageVersions on StructurePageVersions {
    page {
      ${metadata}
      pageConfig {
        name
        title
      }
    }

    versions {
      ${metadata}
        pageConfig {
            name
            title
        }
    }
  }
`;

export const hierarchyFragment = gql`
  fragment StructureTree on StructureTree {
    structure {
      ${metadata}
      structureConfig  {
        name
        title
      }
    }
    pageTrees {
      ...PageTree
    }
    pageQuantity
  }
  ${pageTreeFragment}
`;
