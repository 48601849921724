import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ButtonProps, Spinner } from '@blueprintjs/core';
import { ResizeSensor2, Tooltip2 } from '@blueprintjs/popover2';
import { Monaco, OnValidate } from '@monaco-editor/react';
import { editor } from 'monaco-editor';
import { Resizable } from 're-resizable';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { AppToaster } from '../../../app/Toaster';
import { ReactComponent as VisualiseButtonIcon } from '../../../assets/icons/button/standard/visualise.svg';
import { ReactComponent as WarnCircle } from '../../../assets/icons/warn-circle.svg';
import AddStructure from '../../../components/AddStructure';
import { useBackButton } from '../../../components/BackBtn';
import Btn from '../../../components/Btn';
import { ButtonAddTo } from '../../../components/BtnAddTo';
import { AddToElement, AddToTarget } from '../../../components/BtnAddTo/types';
import { Button } from '../../../components/Button';
import EditorPanels, {
  Props as EditorPanelsProps,
  TAB_IDS,
} from '../../../components/EditorPanels';
import Details, {
  DetailsFields,
} from '../../../components/EditorPanels/Details';
import { DetailProperties } from '../../../components/EditorPanels/Details/form';
import MetaInfo from '../../../components/EditorPanels/MetaInfo';
import Tags from '../../../components/EditorPanels/Tags';
import WidgetUsages from '../../../components/EditorPanels/WidgetUsages';
import { EmojiPanel } from '../../../components/EmojiPanel';
import JsonEditor from '../../../components/JsonEditor';
import ModalLegacy from '../../../components/Modal/ModalLegacy';
import PageHeader from '../../../components/PageHeader';
import SideView from '../../../components/SideView';
import VersionsList from '../../../components/VersionsList';
import { useVisualEditor } from '../../../components/VisualEditorSwitch';
import { StyledModalLegacy } from '../../../components/VisualEditorSwitch/styles';
import { NEW_ITEM_ID_URL } from '../../../constants/entities';
import {
  ADD_EMOJI_ANNOTATION,
  AddEmojiAnnotationData,
  EmojiAnnotationVars,
  REMOVE_EMOJI_ANNOTATION,
  RemoveEmojiAnnotationData,
} from '../../../gql/annotation/mutations';
import {
  GET_EMOJI_ANNOTATIONS,
  GetEmojiAnnotationsData,
  GetEmojiAnnotationsVars,
} from '../../../gql/annotation/queries';
import { EmojiAnnotation } from '../../../gql/annotation/types';
import { EditorMode } from '../../../gql/common/types';
import {
  useAthenaMutation,
  useAthenaQuery,
} from '../../../gql/dataSource/hooks';
import { VERSION_TYPE } from '../../../gql/dataSource/types';
import {
  CREATE_QUERY,
  CREATE_QUERY_VERSION,
  CreateQueryData,
  CreateQueryVars,
  CreateQueryVersionData,
  CreateQueryVersionVars,
  DELETE_QUERY_VERSION,
  DeleteQueryVersionData,
  DeleteQueryVersionVars,
  UPDATE_AND_PUBLISH_QUERY_VERSION,
  UPDATE_QUERY_DATASOURCE,
  UPDATE_QUERY_VERSION,
  UpdateAndPublishQueryVersionData,
  UpdateAndPublishQueryVersionVars,
  UpdateQueryDataSourceData,
  UpdateQueryDataSourceVars,
  UpdateQueryVersionData,
  UpdateQueryVersionVars,
} from '../../../gql/query/mutations';
import {
  GET_ALL_QUERY_VERSIONS,
  GET_QUERY_VERSION,
  GET_RAW_QUERY_TABLE_BY_STRING,
  GetAllQueryVersionsData,
  GetAllQueryVersionsVars,
  GetQueryVersionData,
  GetQueryVersionVars,
  GetRawQueryTableByStringData,
  GetRawQueryTableByStringVars,
  QUERY_USAGES_BY_PAGES,
  QUERY_USAGES_BY_WIDGETS,
  QueryUsagesByPagesData,
  QueryUsagesByWidgetsData,
  QueryUsagesVars,
  RawTableData,
} from '../../../gql/query/queries';
import { QueryConfig } from '../../../gql/query/types';
import { ENTITY_TYPES } from '../../../gql/types';
import {
  getIsAnalyticsEngineer,
  getIsDA,
  getLocalUserData,
} from '../../../gql/user/local';
import { CONFIG_ELEMENT_VALUES_TYPE } from '../../../gql/widget/types';
import { formatEditor, stringifyForEditor } from '../../../utils/editor';
import { formatSQL } from '../../../utils/format';
import { usePageTitleEffect } from '../../../utils/hooks/document';
import { usePageActions } from '../../../utils/hooks/editor';
import { useShortcuts } from '../../../utils/hooks/entity';
import useOpen from '../../../utils/hooks/useOpen';
import { EditWrapper } from '../../Widgets/Widget/styles';
import PreviewTable from './PreviewTable';
import PropertiesTab from './PropertiesTab';
import { COLUMN_TYPE, Column, QueryProperties } from './PropertiesTab/form';
import { QuerySchema } from './QuerySchema';
import {
  QueryMainView,
  QueryTableWrapper,
  ResizeHandle,
  ResizeHandleLine,
  SqlEditorWrapper,
} from './styles';

export const NEW_QUERY = 'select ';
export const NEW_QUERY_JSON = JSON.stringify(
  {
    query: NEW_QUERY,
    name: '',
    title: '',
  },
  null,
  '\t',
);

function getEditorMode(isVisualEditorEnabled: boolean) {
  return isVisualEditorEnabled ? EditorMode.VISUAL : EditorMode.EXPERT;
}

function getConfigFromActiveEditor(
  editorMode: EditorMode,
  queryEditorValue: string,
  properties?: QueryProperties,
): QueryConfig {
  const queryConfig = JSON.parse(queryEditorValue) as QueryConfig;

  if (editorMode === EditorMode.EXPERT) {
    return queryConfig;
  }

  return getColumnsData(queryConfig, properties!);
}

const getColumnsData = (
  queryConfig: QueryConfig,
  properties: QueryProperties,
): QueryConfig => ({
  ...queryConfig,
  name: properties.name,
  title: properties.title,
  isHidden: properties.isHidden,
  dimensions: properties.columns
    .filter(({ columnType }) => columnType === COLUMN_TYPE.DIMENSION)
    .map((d) => ({
      type: d.type,
      name: d.name,
      column: d.column,
      description: d.description,
      hidden: d.hidden,
    })),
  measures: properties.columns
    .filter(({ columnType }) => columnType === COLUMN_TYPE.MEASURE)
    .map((m) => ({
      type: m.type,
      name: m.name,
      column: m.column,
      description: m.description,
      allowedOperations: m.allowedOperations,
      hidden: m.hidden,
    })),
});

function mapConfigToProperties(queryConfig?: QueryConfig): QueryProperties {
  return {
    name: queryConfig?.name || '',
    title: queryConfig?.title || '',
    isHidden: queryConfig?.isHidden || false,
    columns: [
      ...(queryConfig?.dimensions?.map((d) => ({
        columnType: COLUMN_TYPE.DIMENSION,
        column: d.column,
        description: d.description || '',
        name: d.name || '',
        type: d.type || CONFIG_ELEMENT_VALUES_TYPE.string,
        hidden: d.hidden,
      })) || []),
      ...(queryConfig?.measures?.map((m) => ({
        columnType: COLUMN_TYPE.MEASURE,
        column: m.column,
        description: m.description || '',
        name: m.name || '',
        type: m.type || CONFIG_ELEMENT_VALUES_TYPE.string,
        allowedOperations: m.allowedOperations || [],
        hidden: m.hidden,
      })) || []),
    ],
  };
}

function serializeValueForEditor(
  editorMode: EditorMode,
  queryConfig?: QueryConfig,
) {
  if (editorMode === EditorMode.EXPERT) {
    return stringifyForEditor(queryConfig);
  }

  return queryConfig?.query;
}

const QueryEdit = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
  const monacoRef = useRef<Monaco | null>(null);
  const { open, handleOpen, handleClose } = useOpen();
  const [isEditorValid, setIsValid] = useState(true);
  const [diffId, setDiffId] = useState<string | null>(null);
  const [diff, setDiff] = useState<string | null>(null);
  const [queryEditorValue, setQueryEditorValue] = useState(NEW_QUERY);
  const [jsonEditorValue, setJsonEditorValue] = useState(NEW_QUERY_JSON);
  const [saved, setSaved] = useState(true);
  const [isQueryChanged, setIsQueryChanged] = useState(true);
  const [mainPanelWidth, setMainPanelWidth] = useState(0);
  const [tableHeight, setTableHeight] = useState(300);
  const [liveUpdaterName, setLiveUpdaterName] = useState<string | null>(null);
  const {
    open: addStructureOpen,
    handleOpen: handleAddStructureOpen,
    handleClose: handleAddStructureClose,
  } = useOpen();

  const isNew = id === NEW_ITEM_ID_URL;

  const isDA = getIsDA();

  const [properties, setProperties] = useState<DetailProperties>({
    title: '',
    name: '',
    structureId: '',
  });

  const [columns, setColumns] = useState<Column[]>([]);

  const [isPropertiesValid, setIsPropertiesValid] = useState(true);
  const [tableData, setTableData] = useState<RawTableData | null>(null);
  const [backModalContent, setBackModalContent] = useState('');

  const [isPublishClicked, setIsPublishClicked] = useState<boolean>(false);

  const isValid =
    isEditorValid &&
    isPropertiesValid &&
    Boolean(columns.length) &&
    !!queryEditorValue;

  const { data: versionData, refetch: refetchQueryVersion } = useAthenaQuery<
    GetQueryVersionData,
    GetQueryVersionVars
  >(GET_QUERY_VERSION, {
    variables: { versionId: id },
    skip: isNew,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const queryConfig = data.queryVersion?.queryConfig;

      setQueryEditorValue(queryConfig?.query);
      setJsonEditorValue(stringifyForEditor(queryConfig));

      const mappedQueryConfig = mapConfigToProperties(queryConfig);

      setProperties({
        name: mappedQueryConfig.name,
        title: mappedQueryConfig.title,
        isHidden: mappedQueryConfig.isHidden,
        versionType: versionData?.queryVersion.versionType,
        structureId: data.queryVersion.datasourceId || '',
      });

      setColumns(mappedQueryConfig.columns);
    },
  });

  const [updateDataSource] = useAthenaMutation<
    UpdateQueryDataSourceData,
    UpdateQueryDataSourceVars
  >(UPDATE_QUERY_DATASOURCE);

  usePageTitleEffect(versionData?.queryVersion.queryConfig.name || 'Query');

  const editorMode = versionData?.queryVersion.editorMode ?? EditorMode.VISUAL;
  const usesVisualEditorMode = isNew || editorMode === EditorMode.VISUAL;

  const { isVisualEditorEnabled, switchElement } = useVisualEditor(
    usesVisualEditorMode,
    !isNew && !isValid,
    false,
    [usesVisualEditorMode, versionData?.queryVersion.versionId],
    async (isVisualEditorEnabled) => {
      if (isNew) {
        return;
      }

      const newEditorMode = getEditorMode(isVisualEditorEnabled);

      await saveDraft(undefined, newEditorMode);

      if (diffId) {
        await onDiff(diffId);
      }
    },
  );

  const { refetch: refetchDiff } = useAthenaQuery<
    GetQueryVersionData,
    GetQueryVersionVars
  >(GET_QUERY_VERSION, { skip: !diff });

  const query = versionData?.queryVersion;

  const isDraft = query?.versionType === VERSION_TYPE.DRAFT;

  const { data: queryVersionsData, refetch: refetchVersions } = useAthenaQuery<
    GetAllQueryVersionsData,
    GetAllQueryVersionsVars
  >(GET_ALL_QUERY_VERSIONS, {
    variables: { id: query?.id as string },
    skip: isNew || !query?.id || !isDA,
    fetchPolicy: 'network-only',
  });

  const hasLiveVersion = useMemo(
    () =>
      queryVersionsData?.allQueryVersions.some(
        (query) => query.versionType === VERSION_TYPE.LIVE,
      ),
    [queryVersionsData?.allQueryVersions],
  );

  const { backBtn, backURL, backURLState } = useBackButton(
    {
      label: 'Back to the widget',
    },
    setBackModalContent,
    isValid,
    Boolean(hasLiveVersion),
    query?.id as string,
    ENTITY_TYPES.QUERY,
  );

  const localUser = getLocalUserData();

  const [emojiAnnotations, setEmojiAnnotations] = useState<EmojiAnnotation[]>(
    [],
  );

  useQuery<GetEmojiAnnotationsData, GetEmojiAnnotationsVars>(
    GET_EMOJI_ANNOTATIONS,
    {
      skip: !isDA || !id || id === NEW_ITEM_ID_URL,
      fetchPolicy: 'network-only',
      onCompleted: (data) => setEmojiAnnotations(data.getEmojiAnnotations),
      variables: {
        entityVersionId: id,
      },
    },
  );

  const [addEmojiAnnotation] = useMutation<
    AddEmojiAnnotationData,
    EmojiAnnotationVars
  >(ADD_EMOJI_ANNOTATION, {
    onCompleted: (data) => setEmojiAnnotations(data.addEmojiAnnotation),
  });

  const [removeEmojiAnnotation] = useMutation<
    RemoveEmojiAnnotationData,
    EmojiAnnotationVars
  >(REMOVE_EMOJI_ANNOTATION, {
    onCompleted: (data) => setEmojiAnnotations(data.removeEmojiAnnotation),
  });

  const afterGetTable = useCallback(
    ({ getRawQueryTableByQueryString: data }: GetRawQueryTableByStringData) => {
      if (!data.header.length && isDA) {
        AppToaster.show({
          intent: 'danger',
          message: 'Query returned an empty set.',
        });
      }

      setTableData(data);

      setColumns((prevColumns) => {
        if (!prevColumns) {
          setIsPropertiesValid(false);
        }

        const existingCols = prevColumns?.map((c) => c?.column) || [];

        const newCols = data.header;

        return [
          ...(prevColumns?.filter(({ column }) => newCols.includes(column)) ||
            []),
          ...newCols
            .filter((colName) => !existingCols.includes(colName))
            .map((colName) => ({
              columnType: COLUMN_TYPE.MEASURE,
              type: CONFIG_ELEMENT_VALUES_TYPE.string,
              name: '',
              column: colName,
              description: '',
              allowedOperations: [],
              hidden: false,
            })),
        ];
      });

      setIsQueryChanged(false);
    },
    [isDA],
  );

  const [getTable, { loading: getTableLoading }] = useLazyQuery<
    GetRawQueryTableByStringData,
    GetRawQueryTableByStringVars
  >(GET_RAW_QUERY_TABLE_BY_STRING, {
    fetchPolicy: 'network-only',
    onCompleted: afterGetTable,
    onError: (error) => {
      const errorMsg: string = error?.message ?? `Error`;
      setTableData({ header: [errorMsg], content: [], total: 0 });
    },
  });

  const { refetch: refetchTable, loading: refetchTableLoading } = useQuery<
    GetRawQueryTableByStringData,
    GetRawQueryTableByStringVars
  >(GET_RAW_QUERY_TABLE_BY_STRING, {
    skip: true,
    fetchPolicy: 'network-only',
    onError: () => {
      setTableData(null);
    },
  });

  const syncCols = useCallback(async () => {
    const currentColNames = columns?.map(({ column }) => column) || [];

    const { data } = await refetchTable({
      query: queryEditorValue,
      structureId: properties?.structureId,
    });

    afterGetTable(data);

    const {
      getRawQueryTableByQueryString: { header },
    } = data;

    const isSynced =
      header.every((colName) => currentColNames.includes(colName)) &&
      currentColNames.every((colName) => header.includes(colName));

    if (!isSynced && isDA) {
      AppToaster.show({
        intent: 'danger',
        message:
          'Column definition missing. Please validate column configuration.',
      });
    }

    return isSynced;
  }, [
    afterGetTable,
    columns,
    queryEditorValue,
    properties?.structureId,
    refetchTable,
    isDA,
  ]);

  const { data: pageUsages } = useQuery<
    QueryUsagesByPagesData,
    QueryUsagesVars
  >(QUERY_USAGES_BY_PAGES, {
    variables: { queryId: query?.id! },
    skip: isNew || !query?.id || !isDA,
  });

  const { data: widgetUsages } = useQuery<
    QueryUsagesByWidgetsData,
    QueryUsagesVars
  >(QUERY_USAGES_BY_WIDGETS, {
    variables: { queryId: query?.id! },
    skip: isNew || !query?.id,
  });

  const usages = useMemo(
    () => [
      {
        title: 'Widgets',
        slug: 'widgets',
        entities: widgetUsages?.allQueryUsagesByWidgets?.map((widget) => ({
          ...widget,
          name: widget.widgetConfig.name,
        }))!,
      },
      {
        title: 'Pages',
        slug: 'pages',
        entities: pageUsages?.allQueryUsagesByPages?.map((page) => ({
          ...page,
          name: page.pageConfig.name,
        }))!,
      },
    ],
    [pageUsages?.allQueryUsagesByPages, widgetUsages?.allQueryUsagesByWidgets],
  );

  const [updateVersion, { loading: updateVersionLoading }] = useAthenaMutation<
    UpdateQueryVersionData,
    UpdateQueryVersionVars
  >(UPDATE_QUERY_VERSION);

  const [create, { loading: createLoading }] = useAthenaMutation<
    CreateQueryData,
    CreateQueryVars
  >(CREATE_QUERY);

  const [createVersion, { loading: createVersionLoading }] = useAthenaMutation<
    CreateQueryVersionData,
    CreateQueryVersionVars
  >(CREATE_QUERY_VERSION);

  const [updateAndPublishVersion, { loading: updateAndPublishVersionLoading }] =
    useAthenaMutation<
      UpdateAndPublishQueryVersionData,
      UpdateAndPublishQueryVersionVars
    >(UPDATE_AND_PUBLISH_QUERY_VERSION);

  const [deleteQuery, { loading: deleteLoading }] = useAthenaMutation<
    DeleteQueryVersionData,
    DeleteQueryVersionVars
  >(DELETE_QUERY_VERSION);

  const onValidate: OnValidate = useCallback(
    (markers) => setIsValid(!markers.length),
    [],
  );

  const prepareConfigForSaving = useCallback(() => {
    const { title = '', name = '', isHidden = false } = properties ?? {};
    const queryProperties: QueryProperties = {
      columns,
      title,
      name,
      isHidden,
    };

    return getConfigFromActiveEditor(
      editorMode,
      jsonEditorValue,
      queryProperties,
    );
  }, [editorMode, jsonEditorValue, properties, columns]);

  const saveDraft = useCallback(
    async (allowSave?: boolean, forcedEditorMode?: EditorMode) => {
      const syncColsResult = await syncCols();

      if (!(isValid && syncColsResult)) {
        return;
      }

      const editorMode = getEditorMode(isVisualEditorEnabled);
      try {
        const queryConfig = prepareConfigForSaving();

        if (!isDraft && query) {
          const { data: newDraftData } = await createVersion({
            variables: {
              id: query.id,
              CreateQueryInput: {
                editorMode: forcedEditorMode ?? editorMode,
                queryConfig,
                // FIXME: avoid assigning structureId to datasourceId
                datasourceId: properties?.structureId,
              },
            },
          });

          history.push(
            `/queries/${newDraftData?.createQueryVersion.versionId}`,
          );
        } else {
          await updateVersion({
            variables: {
              UpdateQueryVersionInput: {
                versionId: id,
                queryConfig,
                editorMode: forcedEditorMode ?? editorMode,
              },
            },
          });

          await refetchQueryVersion();
        }

        await refetchVersions();

        if (!allowSave) {
          setSaved(true);
        }
      } catch (e) {}
    },
    [
      prepareConfigForSaving,
      syncCols,
      isValid,
      isVisualEditorEnabled,
      properties,
      isDraft,
      query,
      refetchVersions,
      createVersion,
      history,
      updateVersion,
      id,
      refetchQueryVersion,
    ],
  );

  const updateJsonEditor = useCallback(
    (changes: Partial<QueryConfig>) => {
      let activeConfig;

      try {
        activeConfig = JSON.parse(jsonEditorValue);
      } catch (e) {
        return;
      }

      const updatedConfig = Object.assign(activeConfig, changes);

      setJsonEditorValue(stringifyForEditor(updatedConfig));
    },
    [setJsonEditorValue, jsonEditorValue],
  );

  const createNew = useCallback(async () => {
    if (!isValid || !(await syncCols())) {
      return;
    }

    try {
      const queryConfig = prepareConfigForSaving();

      const { data: newData } = await create({
        variables: {
          CreateQueryInput: {
            queryConfig,
            // FIXME: avoid assigning structureId to datasourceId
            datasourceId: properties?.structureId,
          },
        },
      });

      history.push(`/queries/${newData?.createQuery.versionId}`);
      setSaved(true);
    } catch (e) {}
  }, [prepareConfigForSaving, create, history, isValid, properties, syncCols]);

  const publish = useCallback(async () => {
    if (isPublishClicked) {
      return;
    }

    setIsPublishClicked(true);

    try {
      const isColsSynced = await syncCols();

      if (!isValid || !isColsSynced) {
        return;
      }

      const editorMode = getEditorMode(isVisualEditorEnabled);
      const queryConfig = prepareConfigForSaving();

      const { data: newData } = await updateAndPublishVersion({
        variables: {
          UpdateAndPublishQueryVersion: {
            versionId: id,
            editorMode,
            queryConfig,
          },
        },
      });

      history.push(
        `/queries/${newData?.updateAndPublishQueryVersion.versionId}`,
        history.location.state,
      );

      await refetchVersions();
    } catch {
      // Silent catch
    } finally {
      handleClose();
      setLiveUpdaterName(null);
      setIsPublishClicked(false);
    }
  }, [
    prepareConfigForSaving,
    isPublishClicked,
    syncCols,
    isValid,
    isVisualEditorEnabled,
    updateAndPublishVersion,
    id,
    history,
    refetchVersions,
    handleClose,
  ]);

  useShortcuts(publish, query);

  const onDiff = useCallback(
    async (versionId: string, refresh?: boolean) => {
      if (diffId === versionId && !refresh) {
        setDiffId(null);
        setDiff(null);

        return;
      }

      try {
        const {
          data: {
            queryVersion: { queryConfig },
          },
        } = await refetchDiff({ versionId });

        const newDiff = serializeValueForEditor(
          getEditorMode(isVisualEditorEnabled),
          queryConfig,
        );

        setDiffId(versionId);
        setDiff(newDiff ?? null);
      } catch (e) {}
    },
    [diffId, refetchDiff, isVisualEditorEnabled],
  );

  const createBtnProps = useMemo(
    (): ButtonProps => ({
      onClick: createNew,
      loading: createLoading,
    }),
    [createNew, createLoading],
  );

  const saveBtnProps = useMemo(
    (): ButtonProps => ({
      onClick: () => saveDraft(),
      loading: updateVersionLoading || createVersionLoading,
    }),
    [createVersionLoading, saveDraft, updateVersionLoading],
  );

  const publishBtnProps = useMemo(
    (): ButtonProps => ({
      onClick: async () => {
        if (isDraft) {
          try {
            const draftCreatedAt = query?.createdAt;

            const res = await refetchVersions();

            const live = res?.data?.allQueryVersions?.find(
              ({ versionType }) => versionType === VERSION_TYPE.LIVE,
            );

            if (
              live &&
              draftCreatedAt &&
              new Date(live.updatedAt).getTime() >
                new Date(draftCreatedAt).getTime()
            ) {
              setLiveUpdaterName(live.updatorFullName || '<name>');
            }
          } catch {}
        }

        handleOpen();
      },
    }),
    [handleOpen, isDraft, query?.createdAt, refetchVersions],
  );

  const actions = useMemo(
    () =>
      isNew
        ? [
            <Tooltip2
              key="button-create"
              content={<span>Please fix editor errors to continue</span>}
              placement="top"
              disabled={isValid}
            >
              <Btn
                text="Save as Draft"
                {...createBtnProps}
                disabled={!isValid}
                intent="primary"
                md
              />
            </Tooltip2>,
          ]
        : [
            <Tooltip2
              key="button-publish"
              content={<span>Please fix editor errors to continue</span>}
              placement="top"
              disabled={isValid}
            >
              <Btn text="Publish" disabled={!isValid} {...publishBtnProps} md />
            </Tooltip2>,
            <Tooltip2
              key="button-save"
              content={<span>Please fix editor errors to continue</span>}
              placement="top"
              disabled={isValid}
            >
              <Btn
                text={isDraft ? 'Save' : 'Save as Draft'}
                {...saveBtnProps}
                disabled={!isValid || (isDraft && saved)}
                md
              />
            </Tooltip2>,
          ],
    [
      createBtnProps,
      isDraft,
      isNew,
      isValid,
      publishBtnProps,
      saveBtnProps,
      saved,
    ],
  );

  usePageActions(
    [
      backBtn,
      !getIsAnalyticsEngineer() ? switchElement : <></>,
      <Tooltip2
        key="add-to"
        content="Only live queries can be added to a library. Please publish your query."
        disabled={versionData?.queryVersion.versionType === VERSION_TYPE.LIVE}
      >
        <ButtonAddTo
          label="Add to"
          disabled={versionData?.queryVersion.versionType !== VERSION_TYPE.LIVE}
          targets={[AddToTarget.LIBRARY]}
          element={{
            id: versionData?.queryVersion.id!,
            type: AddToElement.QUERY,
          }}
        />
      </Tooltip2>,
      <Button
        key="visualize"
        leadingIcon={VisualiseButtonIcon}
        onClick={() =>
          history.push(`/widgets/new`, {
            backURLState: {
              id: versionData?.queryVersion.id,
              structureId: properties?.structureId,
              name: `${properties?.name} Widget`,
            },
          })
        }
        size="small2"
        variant="primary"
        disabled={isNew || isDraft}
      >
        Visualise
      </Button>,
      ...actions,
    ],
    [
      isVisualEditorEnabled,
      isValid,
      queryEditorValue,
      versionData?.queryVersion.id,
      versionData?.queryVersion.versionId,
      versionData?.queryVersion.versionType,
      hasLiveVersion,
      actions,
    ],
  );

  const mountFormatting = useCallback(() => {
    monacoRef.current?.languages.registerDocumentFormattingEditProvider('sql', {
      provideDocumentFormattingEdits(model, options) {
        return [
          {
            range: model.getFullModelRange(),
            text: formatSQL(model.getValue()),
          },
        ];
      },
    });
  }, []);

  const jsonEditor = useMemo(
    () => (
      <JsonEditor
        height={330}
        onValidate={onValidate}
        editorRef={editorRef}
        monacoRef={monacoRef}
        original={diff}
        schema={QuerySchema}
        onMount={(_, { editor }) => {
          onValidate(editor.getModelMarkers({}));
        }}
        onSave={() => {
          if (isDraft && saved) {
            return;
          }

          if (isNew) {
            createNew();
          } else {
            formatEditor(editorRef.current);

            saveDraft();
          }
        }}
        onAutoSave={(val) => {
          if (isNew) {
            createNew();
          } else {
            saveDraft(true);
          }
        }}
        autosaveDeps={[id]}
        value={jsonEditorValue}
        onChange={(value, e) => {
          const updatedConfig = getConfigFromActiveEditor(
            EditorMode.EXPERT,
            value as string,
          );

          const mappedQueryConfig = mapConfigToProperties(updatedConfig);

          setProperties((prev) => ({
            ...prev,
            name: mappedQueryConfig.name,
            title: mappedQueryConfig.title,
          }));

          setColumns(mappedQueryConfig.columns);

          setQueryEditorValue(updatedConfig?.query);
          setJsonEditorValue(value as string);

          const change: any = e.changes[0];

          const isInsert = !change.rangeOffset && change.forceMoveMarkers;

          if (!isInsert) {
            setSaved(false);
          }
        }}
      />
    ),
    [
      createNew,
      diff,
      jsonEditorValue,
      id,
      isDraft,
      isNew,
      onValidate,
      saveDraft,
      saved,
    ],
  );

  useEffect(() => {
    if (!properties?.structureId) {
      return;
    }

    isNew ? syncCols() : saveDraft();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties?.structureId]);

  const editorTabs = useMemo(() => {
    const tabs: EditorPanelsProps['tabs'] = [];

    tabs.push({
      id: isVisualEditorEnabled ? TAB_IDS.DETAILS : TAB_IDS.PREVIEW,
      content: (
        <Details
          fields={
            isVisualEditorEnabled
              ? [
                  DetailsFields.TITLE,
                  DetailsFields.NAME,
                  DetailsFields.STRUCTURE,
                  DetailsFields.IS_HIDDEN,
                ]
              : [DetailsFields.STRUCTURE]
          }
          setIsValid={(isValid) => {
            if (isVisualEditorEnabled) {
              setIsValid(isValid);
            }
          }}
          entityType={ENTITY_TYPES.QUERY}
          properties={properties}
          setProperties={(props) => {
            if (props) {
              updateJsonEditor({
                name: props.name,
                title: props.title,
                isHidden: props.isHidden,
              });
            }

            setProperties(props);
            setSaved(false);
          }}
          handleAddStructureOpen={handleAddStructureOpen}
        />
      ),
    });

    if (isVisualEditorEnabled) {
      tabs.push({
        id: TAB_IDS.PROPERTIES,
        isDisplayContents: true,
        content: columns.length ? (
          <PropertiesTab
            columns={columns}
            setIsValid={(isValid) => {
              if (isVisualEditorEnabled) {
                setIsValid(isValid);
              }
            }}
            onChange={() => setSaved(false)}
            setColumns={setColumns}
          />
        ) : null,
      });
    } else {
      tabs.push({
        id: TAB_IDS.JSON_EDITOR,
        content: jsonEditor,
      });
    }

    if (!isNew && isDA) {
      tabs.push(
        {
          id: TAB_IDS.VERSIONS,
          content: (
            <VersionsList
              versions={queryVersionsData?.allQueryVersions}
              onClick={({ versionId }) => {
                setSaved(true);
                history.push(`/queries/${versionId}`);

                if ((diff || diffId) && versionId === diffId) {
                  setDiffId(null);
                  setDiff(null);
                }
              }}
              deleteLoading={deleteLoading}
              entityName="Query"
              onDelete={async (id: string) => {
                try {
                  await deleteQuery({
                    variables: {
                      DeleteQueryVersionInput: [
                        {
                          versionId: id,
                        },
                      ],
                    },
                  });

                  if (id === diffId) {
                    setDiffId(null);
                    setDiff(null);
                  }

                  if (
                    (queryVersionsData?.allQueryVersions.length as number) > 1
                  ) {
                    await refetchVersions();
                  }
                } catch (e) {}
              }}
              diffId={diffId}
              onDiff={onDiff}
            />
          ),
        },
        {
          id: TAB_IDS.META,
          content: <MetaInfo item={query} />,
        },
        {
          id: TAB_IDS.USAGES,
          content: query && <WidgetUsages usages={usages} />,
        },
        {
          id: TAB_IDS.TAGS,
          content: <Tags entityId={query?.id} />,
        },
      );
    }

    return tabs;
  }, [
    isVisualEditorEnabled,
    properties,
    handleAddStructureOpen,
    isNew,
    isDA,
    updateJsonEditor,
    columns,
    jsonEditor,
    queryVersionsData?.allQueryVersions,
    deleteLoading,
    diffId,
    onDiff,
    query,
    usages,
    history,
    diff,
    deleteQuery,
    refetchVersions,
  ]);

  return (
    <>
      <PageHeader
        collapsed
        title={`Datasets - ${
          isNew ? 'New Query' : (query?.queryConfig.name as string) || ''
        }`}
      />
      <EditWrapper>
        <SideView isClosed={editorTabs.length === 0}>
          <EditorPanels tabs={editorTabs} />
        </SideView>
        <ResizeSensor2
          onResize={(entries) =>
            setMainPanelWidth(entries[0].contentRect.width)
          }
        >
          <QueryMainView>
            {isVisualEditorEnabled ? (
              <SqlEditorWrapper>
                <JsonEditor
                  onValidate={onValidate}
                  language="sql"
                  onMount={(editor) => {
                    editor.updateOptions({
                      minimap: { enabled: false },
                    });

                    mountFormatting();
                  }}
                  editorRef={editorRef}
                  monacoRef={monacoRef}
                  original={diff}
                  onSave={() => {
                    if (isDraft && saved) {
                      return;
                    }

                    if (isNew) {
                      createNew();
                    } else {
                      saveDraft();
                    }
                  }}
                  autosaveDeps={[]}
                  value={queryEditorValue}
                  onChange={(query = '', ev) => {
                    setQueryEditorValue(query);
                    updateJsonEditor({ query });

                    const change: any = ev.changes[0];

                    const isInsert =
                      !change.rangeOffset && change.forceMoveMarkers;

                    if (!isInsert) {
                      setSaved(false);
                      setIsQueryChanged(true);
                    }
                  }}
                />
              </SqlEditorWrapper>
            ) : null}
            <div className="flex items-center gap-4">
              {isDA && id !== NEW_ITEM_ID_URL && (
                <div className="grow">
                  <EmojiPanel
                    emojis={emojiAnnotations}
                    userId={localUser?.sub}
                    previewEmoji="1f914"
                    previewCaption={t('query.reaction-preview-caption')}
                    addUser={async (emojiCode, emojiName) =>
                      await addEmojiAnnotation({
                        variables: {
                          input: {
                            entityVersionId: id,
                            code: emojiCode,
                            name: emojiName,
                          },
                        },
                      })
                    }
                    removeUser={async (emojiCode, emojiName) =>
                      await removeEmojiAnnotation({
                        variables: {
                          input: {
                            entityVersionId: id,
                            code: emojiCode,
                            name: emojiName,
                          },
                        },
                      })
                    }
                  />
                </div>
              )}
              <div className="ml-auto flex items-center gap-4">
                <Btn
                  md
                  text="Format"
                  disabled={!queryEditorValue}
                  onClick={() => formatEditor(editorRef.current)}
                />
                <Btn
                  md
                  disabled={
                    !properties?.structureId ||
                    !queryEditorValue ||
                    getTableLoading ||
                    !isQueryChanged
                  }
                  text="Preview"
                  onClick={() =>
                    getTable({
                      variables: {
                        query: queryEditorValue,
                        structureId: properties?.structureId || '',
                      },
                    })
                  }
                />
              </div>
            </div>
            <Resizable
              maxHeight="80%"
              minHeight="15%"
              style={{ marginBottom: 20 }}
              size={{ width: '100%', height: tableHeight }}
              onResizeStop={(e, direction, ref, d) => {
                setTableHeight((prevWidth) => prevWidth + d.height);
              }}
              enable={{
                top: true,
                right: false,
                bottom: false,
                left: false,
                topRight: false,
                bottomRight: false,
                bottomLeft: false,
                topLeft: false,
              }}
              boundsByDirection
              handleComponent={{
                top: (
                  <ResizeHandle>
                    <ResizeHandleLine />
                    <ResizeHandleLine />
                  </ResizeHandle>
                ),
              }}
            >
              <QueryTableWrapper>
                {getTableLoading ? (
                  <div className="d-flex align-items-center justify-content-center u-height-100">
                    <Spinner />
                  </div>
                ) : (
                  tableData && <PreviewTable queryData={tableData} />
                )}
              </QueryTableWrapper>
            </Resizable>
          </QueryMainView>
        </ResizeSensor2>
      </EditWrapper>
      <ModalLegacy
        isOpen={open}
        title={liveUpdaterName ? ((<WarnCircle />) as any) : 'Publish'}
        content={
          liveUpdaterName ? (
            <div style={{ width: 396 }}>
              <p>
                {`The live version from which your draft was created is no longer valid, ${liveUpdaterName} has published a new update.`}
              </p>
              <b>
                {`Please verify the changes before publishing your version to avoid overwriting ${liveUpdaterName}’s work.`}
              </b>
            </div>
          ) : (
            <p>Are you sure you want to publish this version?</p>
          )
        }
        confirmButtonText="Publish"
        cancelButtonText="Cancel"
        intent={liveUpdaterName ? 'danger' : 'action'}
        onCancel={() => {
          handleClose();
          setLiveUpdaterName(null);
        }}
        onConfirm={publish}
        confirmButtonProps={{
          loading: updateAndPublishVersionLoading || refetchTableLoading,
        }}
      />
      <StyledModalLegacy
        isOpen={Boolean(backModalContent)}
        title="Warning"
        content={backModalContent}
        confirmButtonText="Delete and go back"
        cancelButtonText="Cancel"
        intent="danger"
        onCancel={() => {
          setBackModalContent('');
        }}
        onConfirm={async () => {
          setBackModalContent('');
          await deleteQuery({
            variables: {
              DeleteQueryVersionInput: [
                {
                  versionId: id,
                },
              ],
            },
          });

          history.push(backURL!, {
            queryId: id,
            ...backURLState,
          });
        }}
      />
      <AddStructure
        open={addStructureOpen}
        handleClose={handleAddStructureClose}
        onClick={(structure) => {
          setProperties({
            ...properties,
            structureId: structure.id,
          });

          if (!isNew) {
            updateDataSource({
              variables: {
                // FIXME: avoid assigning structureId to datasourceId
                datasourceId: structure.id,
                versionId: id,
              },
            });
          }
        }}
      />
    </>
  );
};

export default QueryEdit;
