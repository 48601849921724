import * as yup from 'yup';
import { VERSION_TYPE } from '../../../gql/dataSource/types';

export type DetailProperties = {
  name?: string;
  title?: string;
  datasourceId?: string;
  queryId?: string;
  structureId?: string;
  isHidden?: boolean;
  versionType?: VERSION_TYPE;
};

export const validationSchema = yup.object().shape({
  name: yup.string(),
  title: yup.string().required('Required'),
});
