import styled from 'styled-components';
import { Menu } from '@blueprintjs/core';
import { colors } from '../../utils/colors';
import { VERSION_TYPE } from '../../gql/dataSource/types';

export const Actions = styled.div`
  display: none;

  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);

  & > * {
    margin-left: 4px;
  }
`;

export const ItemWrapper = styled.div`
  position: relative;

  &:hover ${Actions} {
    display: block;
  }
`;

export const VersionItem = styled.div<{
  active?: boolean;
  hasBorder?: boolean;
}>`
  color: ${({ active }) => (active ? colors.primary0 : colors.monochromatic2)};
  ${({ hasBorder }) =>
    hasBorder &&
    `
    border-bottom: 1px solid ${colors.monochromatic5};
  `}

  display: flex;
  align-items: center;

  gap: 8px;

  &&& {
    padding: 10px 5px;
  }
`;

const VERSION_ICON_COLOR = {
  [VERSION_TYPE.LIVE]: colors.secondExtended1,
  [VERSION_TYPE.DRAFT]: colors.monochromatic4,
  [VERSION_TYPE.OLD]: colors.secondary5,
};

export const VersionIcon = styled.div<{ versionType: VERSION_TYPE }>`
  width: 10px;
  height: 10px;
  background: ${({ versionType }) => VERSION_ICON_COLOR[versionType]};

  border-radius: 50%;

  flex-shrink: 0;
`;

export const VersionMenu = styled(Menu)`
  background: transparent;

  &::-webkit-scrollbar {
    display: none;
  }
`;
