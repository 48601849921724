import React, { FC } from 'react';
import { FilterSelection } from '..';
import { FilterSection } from '../../../gql/widget/types';
import ScenarioSection from './ScenarioSection';

type Props = {
  scenarios?: FilterSection[];
  selectedFilters: FilterSelection[];
  updateSelectedFilters: (newVal: any) => void;
  isSm?: boolean;
  isPage?: boolean;
};

const Scenarios: FC<Props> = ({
  updateSelectedFilters,
  scenarios,
  selectedFilters,
  isSm,
  isPage,
}) => {
  if (!scenarios) {
    return null;
  }

  return (
    <div>
      {scenarios.map((scenario, ind) => (
        <ScenarioSection
          key={ind}
          scenario={scenario}
          updateSelectedFilters={updateSelectedFilters}
          selectedFilters={selectedFilters}
          isSm={isSm}
          isPage={isPage}
        />
      ))}
    </div>
  );
};

export default Scenarios;
