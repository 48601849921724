import { gql } from '@apollo/client';
import { emojiAnnotationFragment } from './fragments';
import { EmojiAnnotation } from './types';

export type EmojiAnnotationVars = {
  input: {
    entityVersionId: string;
    code: string;
    name: string;
  };
};

export type AddEmojiAnnotationData = {
  addEmojiAnnotation: EmojiAnnotation[];
};

export const ADD_EMOJI_ANNOTATION = gql`
  mutation AddEmojiAnnotation($input: EmojiAnnotationInput!) {
    addEmojiAnnotation(input: $input) {
      ...EmojiAnnotation
    }
  }
  ${emojiAnnotationFragment}
`;

export type RemoveEmojiAnnotationData = {
  removeEmojiAnnotation: EmojiAnnotation[];
};

export const REMOVE_EMOJI_ANNOTATION = gql`
  mutation RemoveEmojiAnnotation($input: EmojiAnnotationInput!) {
    removeEmojiAnnotation(input: $input) {
      ...EmojiAnnotation
    }
  }
  ${emojiAnnotationFragment}
`;
