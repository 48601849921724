import React, { FC } from 'react';
import { SidebarLoaderContainer } from './styles';

type Props = {};

const SidebarLoader: FC<Props> = () => {
  return (
    <SidebarLoaderContainer>
      {Array(6)
        .fill(null)
        .map((_, ind) => (
          <div key={ind} className="widget-loading" />
        ))}
    </SidebarLoaderContainer>
  );
};

export default SidebarLoader;
