import { gql } from '@apollo/client';
import { metadata } from '../common/fragments';
import { EditorMode } from '../common/types';
import {
  barWidgetFragment,
  columnWidgetFragment,
  lineWidgetFragment,
  mapWidgetFragment,
  pieWidgetFragment,
  simpleStatFragment,
  simpleStatWidgetFragment,
  tableWidgetFragment,
  textWidgetFragment,
  widgetFragment,
} from './fragments';
import {
  TextWidgetConfig,
  WIDGET_TYPE,
  Widget,
  WidgetAccess,
  WidgetConfig,
} from './types';

export type CreateLineWidgetData = { createLineChartWidget: Widget };

export type CreateLineWidgetVars = {
  CreateWidgetInput: {
    widgetConfig: WidgetConfig;
    editorMode?: EditorMode;
    access?: WidgetAccess;
  };
};

export const CREATE_LINE_WIDGET = gql`
  mutation CreateLineWidget($CreateWidgetInput: CreateLineChartWidgetInput!) {
    createLineChartWidget(CreateWidgetInput: $CreateWidgetInput) {
      ...LineWidget
    }
  }
  ${lineWidgetFragment}
`;

export const CREATE_PIE_WIDGET = gql`
  mutation CreatePieWidget($CreateWidgetInput: CreatePieChartWidgetInput!) {
    createPieChartWidget(CreateWidgetInput: $CreateWidgetInput) {
      ...PieChartWidget
    }
  }
  ${pieWidgetFragment}
`;

export const CREATE_TABLE_WIDGET = gql`
  mutation CreateTableWidget($CreateWidgetInput: CreateTableWidgetInput!) {
    createTableWidget(CreateWidgetInput: $CreateWidgetInput) {
      ...TableWidgetType
    }
  }
  ${tableWidgetFragment}
`;

export const CREATE_TEXT_WIDGET = gql`
  mutation CreateTextWidget($CreateWidgetInput: CreateTextWidgetInput!) {
    createTextWidget(CreateWidgetInput: $CreateWidgetInput) {
      ...TextWidget
    }
  }
  ${textWidgetFragment}
`;

export const CREATE_SIMPLE_STAT_WIDGET = gql`
  mutation CreateSimpleStat($CreateWidgetInput: CreateSimpleStatWidgetInput!) {
    createSimpleStatWidget(CreateWidgetInput: $CreateWidgetInput) {
      ...SimpleStatWidget
    }
  }
  ${simpleStatFragment}
`;

export const CREATE_MAP_WIDGET = gql`
  mutation CreateMapWidget($CreateWidgetInput: CreateMapChartWidgetInput!) {
    createMapChartWidget(CreateWidgetInput: $CreateWidgetInput) {
      ...MapWidget
    }
  }
  ${mapWidgetFragment}
`;

export const CREATE_BAR_WIDGET = gql`
  mutation CreateBarWidget($CreateWidgetInput: CreateBarChartWidgetInput!) {
    createBarChartWidget(CreateWidgetInput: $CreateWidgetInput) {
      ...BarWidget
    }
  }
  ${barWidgetFragment}
`;

export const CREATE_COLUMN_WIDGET = gql`
  mutation CreateColumnWidget(
    $CreateWidgetInput: CreateColumnChartWidgetInput!
  ) {
    createColumnChartWidget(CreateWidgetInput: $CreateWidgetInput) {
      ...ColumnWidget
    }
  }
  ${columnWidgetFragment}
`;

export const CREATE_WIDGET_FIELD_MAP: Record<any, any> = {
  [WIDGET_TYPE.LINE_CHART]: 'createLineChartWidget',
  [WIDGET_TYPE.PIE_CHART]: 'createPieChartWidget',
  [WIDGET_TYPE.TABLE]: 'createTableWidget',
  [WIDGET_TYPE.TEXT]: 'createTextWidget',
  [WIDGET_TYPE.CHOROPLETH_MAP]: 'createMapChartWidget',
  [WIDGET_TYPE.BAR_CHART]: 'createBarChartWidget',
  [WIDGET_TYPE.STACKED_COLUMN_CHART]: 'createColumnChartWidget',
  [WIDGET_TYPE.GROUPED_COLUMN_CHART]: 'createColumnChartWidget',
  [WIDGET_TYPE.SIMPLE_STAT]: 'createSimpleStatWidget',
};

export const CREATE_LINE_WIDGET_VERSION = gql`
  mutation CreateLineWidgetVersion(
    $CreateWidgetInput: CreateLineChartWidgetInput!
    $id: String!
  ) {
    createLineChartWidgetVersion(
      CreateWidgetInput: $CreateWidgetInput
      id: $id
    ) {
      ...LineWidget
    }
  }
  ${lineWidgetFragment}
`;

export const CREATE_PIE_WIDGET_VERSION = gql`
  mutation CreatePieWidgetVersion(
    $CreateWidgetInput: CreatePieChartWidgetInput!
    $id: String!
  ) {
    createPieChartWidgetVersion(
      CreateWidgetInput: $CreateWidgetInput
      id: $id
    ) {
      ...PieChartWidget
    }
  }
  ${pieWidgetFragment}
`;

export const CREATE_TABLE_WIDGET_VERSION = gql`
  mutation CreateTableWidgetVersion(
    $CreateWidgetInput: CreateTableWidgetInput!
    $id: String!
  ) {
    createTableWidgetVersion(CreateWidgetInput: $CreateWidgetInput, id: $id) {
      ...TableWidgetType
    }
  }
  ${tableWidgetFragment}
`;

export const CREATE_TEXT_WIDGET_VERSION = gql`
  mutation CreateTextWidgetVersion(
    $CreateWidgetInput: CreateTextWidgetInput!
    $id: String!
  ) {
    createTextWidgetVersion(CreateWidgetInput: $CreateWidgetInput, id: $id) {
      ...TextWidget
    }
  }
  ${textWidgetFragment}
`;

export const CREATE_SIMPLE_STAT_WIDGET_VERSION = gql`
  mutation CreateSimpleStatWidgetVersion(
    $CreateWidgetInput: CreateSimpleStatWidgetInput!
    $id: String!
  ) {
    createSimpleStatWidgetVersion(
      CreateWidgetInput: $CreateWidgetInput
      id: $id
    ) {
      ...SimpleStatWidget
    }
  }
  ${simpleStatFragment}
`;

export const CREATE_MAP_WIDGET_VERSION = gql`
  mutation CreateMapWidgetVersion(
    $CreateWidgetInput: CreateMapChartWidgetInput!
    $id: String!
  ) {
    createMapChartWidgetVersion(
      CreateWidgetInput: $CreateWidgetInput
      id: $id
    ) {
      ...MapWidget
    }
  }
  ${mapWidgetFragment}
`;

export const CREATE_BAR_WIDGET_VERSION = gql`
  mutation CreateBarWidgetVersion(
    $CreateWidgetInput: CreateBarChartWidgetInput!
    $id: String!
  ) {
    createBarChartWidgetVersion(
      CreateWidgetInput: $CreateWidgetInput
      id: $id
    ) {
      ...BarWidget
    }
  }
  ${barWidgetFragment}
`;

export const CREATE_COLUMN_WIDGET_VERSION = gql`
  mutation CreateColumnWidgetVersion(
    $CreateWidgetInput: CreateColumnChartWidgetInput!
    $id: String!
  ) {
    createColumnChartWidgetVersion(
      CreateWidgetInput: $CreateWidgetInput
      id: $id
    ) {
      ...ColumnWidget
    }
  }
  ${columnWidgetFragment}
`;

export const CREATE_WIDGET_VERSION_FIELD_MAP: Record<any, any> = {
  [WIDGET_TYPE.LINE_CHART]: 'createLineChartWidgetVersion',
  [WIDGET_TYPE.PIE_CHART]: 'createPieChartWidgetVersion',
  [WIDGET_TYPE.TABLE]: 'createTableWidgetVersion',
  [WIDGET_TYPE.TEXT]: 'createTextWidgetVersion',
  [WIDGET_TYPE.CHOROPLETH_MAP]: 'createMapChartWidgetVersion',
  [WIDGET_TYPE.BAR_CHART]: 'createBarChartWidgetVersion',
  [WIDGET_TYPE.STACKED_COLUMN_CHART]: 'createColumnChartWidgetVersion',
  [WIDGET_TYPE.GROUPED_COLUMN_CHART]: 'createColumnChartWidgetVersion',
  [WIDGET_TYPE.SIMPLE_STAT]: 'createSimpleStatWidgetVersion',
};

export const UPDATE_LINE_WIDGET_VERSION = gql`
  mutation UpdateLineWidgetVersion(
    $UpdateWidgetVersionInput: UpdateLineChartWidgetVersionInput!
  ) {
    updateLineChartWidgetVersion(
      UpdateWidgetVersionInput: $UpdateWidgetVersionInput
    ) {
      ...LineWidget
    }
  }
  ${lineWidgetFragment}
`;

export const UPDATE_PIE_WIDGET_VERSION = gql`
  mutation UpdatePieWidgetVersion(
    $UpdateWidgetVersionInput: UpdatePieChartWidgetVersionInput!
  ) {
    updatePieChartWidgetVersion(
      UpdateWidgetVersionInput: $UpdateWidgetVersionInput
    ) {
      ...PieChartWidget
    }
  }
  ${pieWidgetFragment}
`;

export const UPDATE_TABLE_WIDGET_VERSION = gql`
  mutation UpdateTableWidgetVersion(
    $UpdateWidgetVersionInput: UpdateTableWidgetVersionInput!
  ) {
    updateTableWidgetVersion(
      UpdateWidgetVersionInput: $UpdateWidgetVersionInput
    ) {
      ...TableWidgetType
    }
  }
  ${tableWidgetFragment}
`;

export const UPDATE_TEXT_WIDGET_VERSION = gql`
  mutation UpdateTextWidgetVersion(
    $UpdateWidgetVersionInput: UpdateTextWidgetVersionInput!
  ) {
    updateTextWidgetVersion(
      UpdateWidgetVersionInput: $UpdateWidgetVersionInput
    ) {
      ...TextWidget
    }
  }
  ${textWidgetFragment}
`;

export const UPDATE_SIMPLE_STAT_WIDGET_VERSION = gql`
  mutation UpdateSimpleStatWidgetVersion(
    $UpdateWidgetVersionInput: UpdateSimpleStatWidgetVersionInput!
  ) {
    updateSimpleStatWidgetVersion(
      UpdateWidgetVersionInput: $UpdateWidgetVersionInput
    ) {
      ...SimpleStatWidget
    }
  }
  ${simpleStatWidgetFragment}
`;

export const UPDATE_MAP_WIDGET_VERSION = gql`
  mutation UpdateMapWidgetVersion(
    $UpdateWidgetVersionInput: UpdateMapChartWidgetVersionInput!
  ) {
    updateMapChartWidgetVersion(
      UpdateWidgetVersionInput: $UpdateWidgetVersionInput
    ) {
      ...MapWidget
    }
  }
  ${mapWidgetFragment}
`;

export const UPDATE_BAR_WIDGET_VERSION = gql`
  mutation UpdateBarWidgetVersion(
    $UpdateWidgetVersionInput: UpdateBarChartWidgetVersionInput!
  ) {
    updateBarChartWidgetVersion(
      UpdateWidgetVersionInput: $UpdateWidgetVersionInput
    ) {
      ...BarWidget
    }
  }
  ${barWidgetFragment}
`;

export const UPDATE_COLUMN_WIDGET_VERSION = gql`
  mutation UpdateColumnWidgetVersion(
    $UpdateWidgetVersionInput: UpdateColumnChartWidgetVersionInput!
  ) {
    updateColumnChartWidgetVersion(
      UpdateWidgetVersionInput: $UpdateWidgetVersionInput
    ) {
      ...ColumnWidget
    }
  }
  ${columnWidgetFragment}
`;

export const UPDATE_AND_PUBLISH_LINE_WIDGET_VERSION = gql`
  mutation UpdateAndPublishLineWidgetVersion(
    $UpdateAndPublishWidgetVersion: UpdateAndPublishLineChartWidgetVersionInput!
  ) {
    updateAndPublishLineChartWidgetVersion(
      UpdateAndPublishWidgetVersion: $UpdateAndPublishWidgetVersion
    ) {
      ...LineWidget
    }
  }
  ${lineWidgetFragment}
`;

export const UPDATE_AND_PUBLISH_PIE_WIDGET_VERSION = gql`
  mutation UpdateAndPublishPieWidgetVersion(
    $UpdateAndPublishWidgetVersion: UpdateAndPublishPieChartWidgetVersionInput!
  ) {
    updateAndPublishPieChartWidgetVersion(
      UpdateAndPublishWidgetVersion: $UpdateAndPublishWidgetVersion
    ) {
      ...PieChartWidget
    }
  }
  ${pieWidgetFragment}
`;

export const UPDATE_AND_PUBLISH_TABLE_WIDGET_VERSION = gql`
  mutation UpdateAndPublishTableWidgetVersion(
    $UpdateAndPublishWidgetVersion: UpdateAndPublishTableWidgetVersionInput!
  ) {
    updateAndPublishTableWidgetVersion(
      UpdateAndPublishWidgetVersion: $UpdateAndPublishWidgetVersion
    ) {
      ...TableWidgetType
    }
  }
  ${tableWidgetFragment}
`;

export type UpdateAndPublishTextWidgetVersionVars = {
  UpdateAndPublishWidgetVersion: {
    versionId: string;
    widgetConfig: TextWidgetConfig;
    editorMode?: EditorMode;
  };
};

export const UPDATE_AND_PUBLISH_TEXT_WIDGET_VERSION = gql`
  mutation UpdateAndPublishTextWidgetVersion(
    $UpdateAndPublishWidgetVersion: UpdateAndPublishTextWidgetVersionInput!
  ) {
    updateAndPublishTextWidgetVersion(
      UpdateAndPublishWidgetVersion: $UpdateAndPublishWidgetVersion
    ) {
      ...TextWidget
    }
  }
  ${textWidgetFragment}
`;

export const UPDATE_AND_PUBLISH_MAP_WIDGET_VERSION = gql`
  mutation UpdateAndPublishMapWidgetVersion(
    $UpdateAndPublishWidgetVersion: UpdateAndPublishMapChartWidgetVersionInput!
  ) {
    updateAndPublishMapChartWidgetVersion(
      UpdateAndPublishWidgetVersion: $UpdateAndPublishWidgetVersion
    ) {
      ...MapWidget
    }
  }
  ${mapWidgetFragment}
`;

export const UPDATE_AND_PUBLISH_BAR_WIDGET_VERSION = gql`
  mutation UpdateAndPublishBarWidgetVersion(
    $UpdateAndPublishWidgetVersion: UpdateAndPublishBarChartWidgetVersionInput!
  ) {
    updateAndPublishBarChartWidgetVersion(
      UpdateAndPublishWidgetVersion: $UpdateAndPublishWidgetVersion
    ) {
      ...BarWidget
    }
  }
  ${barWidgetFragment}
`;

export const UPDATE_AND_PUBLISH_COLUMN_WIDGET_VERSION = gql`
  mutation UpdateAndPublishColumnWidgetVersion(
    $UpdateAndPublishWidgetVersion: UpdateAndPublishColumnChartWidgetVersionInput!
  ) {
    updateAndPublishColumnChartWidgetVersion(
      UpdateAndPublishWidgetVersion: $UpdateAndPublishWidgetVersion
    ) {
      ...ColumnWidget
    }
  }
  ${columnWidgetFragment}
`;

export const UPDATE_AND_PUBLISH_SIMPLE_STAT_WIDGET_VERSION = gql`
  mutation UpdateAndPublishSimpleStatWidgetVersion(
    $UpdateAndPublishWidgetVersion: UpdateAndPublishSimpleStatWidgetVersionInput!
  ) {
    updateAndPublishSimpleStatWidgetVersion(
      UpdateAndPublishWidgetVersion: $UpdateAndPublishWidgetVersion
    ) {
      ...SimpleStatWidget
    }
  }
  ${simpleStatWidgetFragment}
`;

export const UPDATE_AND_PUBLISH_WIDGET_FIELD_MAP: Record<any, any> = {
  [WIDGET_TYPE.LINE_CHART]: 'updateAndPublishLineChartWidgetVersion',
  [WIDGET_TYPE.PIE_CHART]: 'updateAndPublishPieChartWidgetVersion',
  [WIDGET_TYPE.TABLE]: 'updateAndPublishTableWidgetVersion',
  [WIDGET_TYPE.TEXT]: 'updateAndPublishTextWidgetVersion',
  [WIDGET_TYPE.CHOROPLETH_MAP]: 'updateAndPublishMapChartWidgetVersion',
  [WIDGET_TYPE.BAR_CHART]: 'updateAndPublishBarChartWidgetVersion',
  [WIDGET_TYPE.STACKED_COLUMN_CHART]:
    'updateAndPublishColumnChartWidgetVersion',
  [WIDGET_TYPE.GROUPED_COLUMN_CHART]:
    'updateAndPublishColumnChartWidgetVersion',
  [WIDGET_TYPE.SIMPLE_STAT]: 'updateAndPublishSimpleStatWidgetVersion',
};

export type DeleteWidgetVersionData = {
  deleteWidgetVersions: Widget[];
};

export type DeleteWidgetVersionVars = {
  DeleteWidgetVersionInput: {
    versionId: string;
  }[];
};

export const DELETE_WIDGET_VERSION = gql`
  mutation DeleteWidgetVersion(
    $DeleteWidgetVersionInput: [EntityVersionInput!]!
  ) {
    deleteWidgetVersions(DeleteWidgetVersionInput: $DeleteWidgetVersionInput) {
      ...Widget
    }
  }
  ${widgetFragment}
`;

export type UpdateWidgetStructureData = { updateWidgetStructure: Widget };

export type UpdateWidgetStructureVars = {
  structureId: string;
  versionId: string;
};

export const UPDATE_WIDGET_STRUCTURE = gql`
  mutation UpdateWidgetStructure($structureId: String!, $versionId: String!) {
    updateWidgetStructure(structureId: $structureId, versionId: $versionId) {
      ...Widget
    }
  }
  ${widgetFragment}
`;

export type PublishWidgetVersionVars = {
  entityVersionInput: {
    versionId: string;
  };
};

export const PUBLISH_WIDGET_VERSION = gql`
    mutation PublishWidgetVersion(
        $entityVersionInput: EntityVersionInput!
    ) {
        publishWidgetVersion(
            entityVersionInput: $entityVersionInput
        ) {
            ${metadata}
        }
    }
`;
