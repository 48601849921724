import Lottie from 'lottie-react';
import spinnerWaitingAnimation from './animations/spinner_waiting.json';
import spinnerCheckAnimation from './animations/spinner_check.json';
import { useTranslation } from 'react-i18next';

export type SubmitState = 'PENDING' | 'DONE';

type Props = {
  size?: number;
  state: SubmitState;
  onAnimationFinish?(): void;
};

export default function SpinnerWithCheckMark({
  state = 'PENDING',
  size = 200,
  onAnimationFinish,
}: Props) {
  const { t } = useTranslation();
  return (
    <div
      className="aspect-square"
      aria-label={t('common.loading')}
      style={{ width: `${size}px` }}
    >
      {(() => {
        switch (state) {
          case 'PENDING':
            return <Lottie animationData={spinnerWaitingAnimation} loop />;
          case 'DONE':
            return (
              <Lottie
                animationData={spinnerCheckAnimation}
                onComplete={onAnimationFinish}
                loop={false}
              />
            );
        }
      })()}
    </div>
  );
}
