import { gql } from '@apollo/client';

export const userFragment = gql`
  fragment User on ReturnUserType {
    _id
    email
    firstName
    lastName
    role
    isPending
  }
`;

export const groupMemberFragment = gql`
  fragment GroupMember on ReturnGroupMemberType {
    _user {
      _id
    }
    group_role
  }
`;

export const userGroupFragment = gql`
  fragment UsersGroup on ReturnUserGroupType {
    _id
    name
    members {
      ...GroupMember
    }
  }
  ${groupMemberFragment}
`;
