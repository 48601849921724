import { useMemo } from 'react';

export const useValidationEnum = <T>(data?: {
  live: T[];
  draft: T[];
  old: T[];
}): T[] => {
  return useMemo(() => {
    return data ? [...data.live, ...data.draft, ...data.old] : [];
  }, [data]);
};
