import { RefObject, useEffect, useRef } from 'react';

export const useClickOutside = <T extends HTMLElement>(
  callback: (
    ev: MouseEvent | TouchEvent,
    element: RefObject<T>['current'],
  ) => void,
  isActive = true,
  ignoredElements?: HTMLElement[],
) => {
  const ref = useRef<T>(null);
  useEffect(() => {
    if (!isActive) {
      return undefined;
    }
    const clickOutsideHandler = (event: MouseEvent | TouchEvent) => {
      if (
        event.target instanceof HTMLElement &&
        ignoredElements?.includes(event.target)
      ) {
        return;
      }
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback(event, ref.current);
      }
    };

    document.addEventListener('click', clickOutsideHandler);
    document.addEventListener('touchstart', clickOutsideHandler);

    return () => {
      document.removeEventListener('click', clickOutsideHandler);
      document.removeEventListener('touchstart', clickOutsideHandler);
    };
  }, [callback, isActive, ignoredElements, ref]);

  return { elementRef: ref };
};
