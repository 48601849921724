import styled from 'styled-components';
import { colors } from '../../../utils/colors';

export const SIDEBAR_WIDTH = 50;
export const HEADER_HEIGHT = 64;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - ${HEADER_HEIGHT}px);
  border-right: 1px solid ${colors.monochromatic5};
  padding: 22px 6px 43px;
  min-width: ${SIDEBAR_WIDTH}px;
  position: fixed;

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const SidebarItem = styled.div<{ isActive?: boolean }>`
  width: 38px;
  height: 36px;
  border-radius: 3px;
  color: ${({ isActive }) =>
    isActive ? colors.primary0 : colors.monochromatic2};
  background-color: ${({ isActive }) =>
    isActive ? colors.primary5 : 'transparent'};
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${colors.primary0};
  }
`;

export const SidebarTooltip = styled.div`
  padding: 4px 6px;
  background: ${colors.primary2};
  border-radius: 4px;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1.5px;
  color: ${colors.monochromatic7};
`;
