import cn from 'classnames';
import { DangerIcon, InfoIcon, SuccessIcon } from './icons';
import { PropsWithChildren } from 'react';

export enum InlineAlertType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
}

export enum InlineAlertSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

type Props = PropsWithChildren<{
  title: string;
  description?: string;
  type: InlineAlertType;
  size: InlineAlertSize;
  hasGrayBg?: boolean;
  fontSize?: 14 | 16 | 18;
  hideTitle?: boolean;
  disablePadding?: boolean;
  testId?: string;
}>;

export default function InlineAlert({
  title,
  description,
  type,
  size,
  hasGrayBg,
  fontSize,
  hideTitle = false,
  disablePadding = false,
  children,
  testId,
}: Props) {
  const sizeSmall = size === InlineAlertSize.SMALL;
  const sizeMedium = size === InlineAlertSize.MEDIUM;
  const sizeLarge = size === InlineAlertSize.LARGE;

  const iconWidth = {
    [InlineAlertSize.SMALL]: 20,
    [InlineAlertSize.MEDIUM]: 20,
    [InlineAlertSize.LARGE]: 26,
  };

  const iconSize = iconWidth[size];

  const Icon = {
    [InlineAlertType.SUCCESS]: (
      <SuccessIcon width={iconSize} height={iconSize} />
    ),
    [InlineAlertType.INFO]: <InfoIcon width={iconSize} height={iconSize} />,
    [InlineAlertType.ERROR]: (
      <DangerIcon width={iconSize + 2} height={iconSize + 2} />
    ),
  };

  return (
    <div
      id="inline-alert"
      data-testid={testId}
      role="status"
      className={cn(
        'rounded-lg',
        {
          'bg-green-4': type === InlineAlertType.SUCCESS,
          'bg-white': type === InlineAlertType.INFO && !hasGrayBg,
          'bg-concrete-jungle-8':
            hasGrayBg && !sizeSmall && type === InlineAlertType.INFO,
          'bg-raspberry-7': type === InlineAlertType.ERROR,
          'bg-transparent': sizeSmall,
        },
        {
          '!px-3 !py-4': !disablePadding && sizeSmall,
          'px-8 pb-8 pt-6': !disablePadding && sizeLarge,
          'px-4 pb-4 pt-3': !disablePadding && sizeMedium,
        },
      )}
      title={title}
      aria-labelledby="inlineAlertTitle"
    >
      <div
        className={cn('flex flex-row', {
          'gap-4': sizeLarge,
          'gap-2.5': sizeMedium,
          'items-start gap-2': sizeSmall,
        })}
      >
        <div id="inline-alert-icon" data-testid="inline-alert-icon">
          {Icon[type]}
        </div>
        <div
          className={cn('text-concrete-jungle-1', {
            'text-xl': sizeLarge && !fontSize,
            'text-base': sizeMedium && !fontSize,
            'text-sm': sizeSmall && !fontSize,
          })}
          style={fontSize ? { fontSize: `${fontSize / 16}rem` } : {}}
        >
          {!hideTitle && <h1 id="inlineAlertTitle">{title}</h1>}
          {!sizeSmall && description && (
            <p
              data-testid="description"
              className={cn('font-[350]', {
                'text-base': sizeLarge,
                'text-sm': sizeMedium,
              })}
            >
              {description}
            </p>
          )}
          {children}
        </div>
      </div>
    </div>
  );
}
