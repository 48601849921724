import styled, { css } from 'styled-components';
import { TabsWrapper } from '../Structure/Page/AddWidget/InsightsLibrary/styles';
import { Classes, Dialog, Tabs } from '@blueprintjs/core';
import { colors } from '../../../utils/colors';
import { Resizable } from 're-resizable';
import Btn from '../../../components/Btn';

export const EditorTabs = styled(TabsWrapper)`
  padding-left: 16px;
`;

export const Settings = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px - 16px);
  border-right: 1px solid ${colors.monochromatic5};
`;

export const EditorTabsContainer = styled(Tabs as any)`
  & > div {
    border-bottom: 1px solid ${colors.monochromatic6};
    display: flex;
  }
  & .${Classes.TAB} {
    color: ${colors.monochromatic2};
    display: flex;
    flex: auto;
    justify-content: center;
    width: 100%;
  }
`;

export const SettingsScrollContainer = styled.div`
  overflow-y: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const CollapseBtn = styled.div`
  position: absolute;
  top: 10px;
  right: -10px;
  transform: rotate(90deg);
  width: 20px;
  height: 20px;
  background-color: ${colors.primary5};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
`;

export const Container = styled.div`
  display: flex;
  height: calc(100% + 32px);

  #JSON {
    .editor-panel-headline {
      text-transform: uppercase;
    }
  }
`;

export const ResizablePanel = styled(Resizable)<{ collapsed?: boolean }>`
  ${({ collapsed }) =>
    collapsed &&
    css`
      visibility: hidden;
      width: 0 !important;
    `}
`;

export const CollapsedPanel = styled.div`
  width: 31px;
  background-color: ${colors.monochromatic7};
  border-right: 1px solid ${colors.monochromatic5};
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;

  > * {
    margin-top: 10px;
  }
`;

export const PublishDialog = styled(Dialog)`
  width: 90vw;
  height: 90vh;
  padding: 34px 41px 25px;
  background-color: white;
  position: relative;

  & > .${Classes.DIALOG_HEADER} {
    background-color: white;
    padding: 0 !important;

    & > h5 {
      margin-bottom: 6px;
      white-space: normal;
      font-weight: 400;

      & .title-text {
        font-size: 26px;
      }

      & .subtitle-text {
        font-size: 18px;
        color: ${colors.monochromatic2};
      }
    }

    & > button {
      position: absolute;
      top: 9px;
      right: 9px;
      border-radius: 50%;
      background-color: ${colors.primary0};
      min-height: 25px;
      height: 25px;
      min-width: 25px;
      width: 25px;

      &:hover {
        background-color: ${colors.primary2};
      }

      & > span {
        color: white;

        & > svg {
          height: 18px;
          width: 18px;
        }
      }
    }
  }

  & > .${Classes.DIALOG_BODY} {
    margin: 10px 0 0;
    overflow-y: auto;
  }
`;

export const PublishButton = styled(Btn)`
  margin-top: auto;
  align-self: flex-end;
`;

export const DEFAULT_SETTINGS_WIDTH = 400;
export const EXTENDED_SETTINGS_WIDTH = 585;
export const DEFAULT_SIDEBAR_WIDTH = 254;
