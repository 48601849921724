import cn from 'classnames';

export type Variant =
  | 'badge-1'
  | 'badge-2'
  | 'badge-3'
  | 'badge-4'
  | 'badge-5'
  | 'badge-6'
  | 'badge-7'
  | 'badge-8'
  | 'badge-9'
  | 'neutral'
  | 'disabled'
  | 'positive'
  | 'negative'
  | 'warning';

type Props = {
  /**
   * Defines the variant of the Badge
   */
  variant: Variant;
  /**
   * The text will be shown on the Badge
   */
  text: String;
};

export default function Badge({ variant, text }: Props) {
  const nonsemanticColors = [
    'bg-badge-light-1 text-badge-dark-1',
    'bg-badge-light-2 text-badge-dark-2',
    'bg-badge-light-3 text-badge-dark-3',
    'bg-badge-light-4 text-badge-dark-4',
    'bg-badge-light-5 text-badge-dark-5',
    'bg-badge-light-6 text-badge-dark-6',
    'bg-badge-light-7 text-badge-dark-7',
    'bg-badge-light-8 text-badge-dark-8',
    'bg-badge-light-9 text-badge-dark-9',
  ];
  const semanticColors: Record<string, string> = {
    neutral: 'bg-badge-light-neutral text-badge-dark-neutral',
    disabled: 'bg-badge-light-disabled text-badge-dark-disabled',
    positive: 'bg-badge-light-positive text-badge-dark-positive',
    negative: 'bg-badge-light-negative text-badge-dark-negative',
    warning: 'bg-badge-light-warning text-badge-dark-warning',
  };
  const badgeNumber = Number(variant.split('-')[1]) - 1;

  return (
    <div
      className={cn(
        'inline-flex w-fit items-center justify-center whitespace-nowrap rounded-sm px-[10px] py-[3px] text-[14px] tracking-wide',
        {
          [nonsemanticColors[badgeNumber]]: variant.startsWith('badge'),
          [semanticColors[variant]]: !variant.startsWith('badge'),
        },
      )}
    >
      {text}
    </div>
  );
}
