import React, { FC, ReactElement } from 'react';
import { ButtonProps, Intent } from '@blueprintjs/core';
import { CustomizedBtn } from './styles';
import { ReactComponent as Trash } from '../../assets/icons/trash.svg';

export type BtnProps = {
  xl?: boolean;
  lg?: boolean;
  lgSlim?: boolean;
  md?: boolean;
  sm?: boolean;
  xs?: boolean;
  iconBtn?: boolean;
  id?: string
} & ButtonProps;

const CUSTOM_ICONS: Record<string, ReactElement> = {
  trash: <Trash />,
};

const Btn: FC<BtnProps> = ({
  icon,
  iconBtn,
  lg,
  lgSlim,
  md,
  sm,
  xs,
  intent,
  outlined,
  disabled,
  onClick,
  ...props
}) => {
  return (
    <CustomizedBtn
      {...props}
      onClick={(e: any) => {
        if (!disabled) {
          onClick?.(e);
        }
      }}
      data-disabled={!!disabled}
      disabled={disabled}
      icon={CUSTOM_ICONS[icon as string] || icon}
      intent={intent || Intent.PRIMARY}
      md={md}
      lgSlim={lgSlim}
      sm={sm}
      lg={lg}
      xs={xs}
      iconBtn={iconBtn}
      outline={outlined}
    />
  );
};

export default Btn;
