import cn from 'classnames';
import { Icon } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import PropTypes from 'prop-types';
import { Fragment, useContext, useState } from 'react';
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineFileAdd,
  AiOutlineFolderAdd,
} from 'react-icons/ai';
import { SolutionsRoute } from '../../../app/Routes';
import { ReactComponent as Preview } from '../../../assets/icons/preview.svg';
import { ReactComponent as Trash } from '../../../assets/icons/trash.svg';
import { TooltipPosition } from '../../../containers/Hierarchy';
import { useExportStructure } from '../../../containers/Solutions/utils';
import { SolutionsContext } from '../../../context/solutions.context';
import { VERSION_TYPE } from '../../../gql/dataSource/types';
import {
  getIsAnalyticsEngineer,
  getIsDA,
  getLocalUserData,
} from '../../../gql/user/local';
import SkeletonLoader from '../../SkeletonLoader';
import { formatVersionText } from '../../VersionsList';
import { VersionIcon, VersionItem } from '../../VersionsList/styles';
import CheckBox from '../CheckBox/CheckBox';
import EditableName from '../EditableName/EditableName';
import ConfigContext from '../FolderTree/context';
import {
  getDefaultIcon,
  iconClassName,
  iconContainerClassName,
} from '../utils/iconUtils';
import EntityIcon from './EntityIcon';
import './TreeNode.styles.scss';

const TreeNode = ({
  path,
  name,
  checked,
  isOpen,
  children,
  noImports,
  isHierarchy,
  hasTooltip,
  tooltipNote,
  isCaretLeft,
  onCaretClick,
  structure,
  disabled,
  versionTypeIcon,
  isVersions,
  isAddingFromInsights,
  showVersionAsText,
  ...restData
}) => {
  const nodeData = {
    path,
    name,
    checked,
    isOpen,
    ...restData,
  };

  const {
    handleCheck,
    handleRename,
    handleDelete,
    handleAddNode,
    handleToggleOpen,

    iconComponents,
    indentPixels,
    onNameClick,
    showCheckbox,
    readOnly,
  } = useContext(ConfigContext);

  const solutionsContext = useContext(SolutionsContext);
  const exportStructure = useExportStructure();
  const tooltipPosition = useContext(TooltipPosition);
  const isFolder = !!children;
  const currentUser = getLocalUserData();
  const treeNodeStyle = {
    marginLeft: path.length * indentPixels,
  };

  const [isSelected, setIsSelected] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const {
    EditIcon = getDefaultIcon(AiOutlineEdit),
    DeleteIcon = getDefaultIcon(AiOutlineDelete),
    CancelIcon = getDefaultIcon(AiOutlineClose),
    AddFileIcon = getDefaultIcon(AiOutlineFileAdd),
    AddFolderIcon = getDefaultIcon(AiOutlineFolderAdd),
    OKIcon = getDefaultIcon(AiOutlineCheck),
  } = iconComponents;

  const handleCheckBoxChange = (e) => {
    if (readOnly) return;

    const newStatus = +e.target.checked;
    handleCheck(path, newStatus);
  };

  const onNameChange = (newName) => handleRename(path, newName);

  const selectMe = () => !isEditing && !readOnly && setIsSelected(true);
  const unSelectMe = () => setIsSelected(false);

  const openMe = (structure) => {
    if (onCaretClick) {
      onCaretClick(structure.versionId);
    }
    handleToggleOpen(path, true);
  };

  const closeMe = () => {
    handleToggleOpen(path, false);
  };

  const editMe = () => {
    setIsEditing(true);
    setIsSelected(false);
  };

  const deleteMe = () => handleDelete(path);

  const addFile = () => handleAddNode(path, false);
  const addFolder = () => handleAddNode(path, true);

  const handleNameClick = () => {
    const defaultOnClick = selectMe;

    if (nodeData.onClick) {
      nodeData.onClick({ defaultOnClick, nodeData });

      return;
    }

    if (nodeData.openOnNameClick) {
      try {
        isOpen ? closeMe(nodeData) : openMe(nodeData);
      } catch {
        // Executes on name click function if failed to open node element (given is the last one)
        !isEditing && onNameClick && onNameClick({ defaultOnClick, nodeData });
      }

      return;
    }

    if (onNameClick && typeof onNameClick === 'function') {
      !isEditing && onNameClick({ defaultOnClick, nodeData });
    } else {
      defaultOnClick();
    }
  };

  const TreeNodeToolBar = (
    <span className={iconContainerClassName('TreeNodeToolBar')}>
      <EditIcon
        className={iconClassName('EditIcon')}
        onClick={editMe}
        nodeData={nodeData}
      />
      <DeleteIcon
        className={iconClassName('DeleteIcon')}
        onClick={deleteMe}
        nodeData={nodeData}
      />
      {isFolder && (
        <>
          <AddFileIcon
            className={iconClassName('AddFileIcon')}
            onClick={addFile}
            nodeData={nodeData}
          />
          <AddFolderIcon
            className={iconClassName('AddFolderIcon')}
            onClick={addFolder}
            nodeData={nodeData}
          />
        </>
      )}

      <CancelIcon
        className={iconClassName('CancelIcon')}
        onClick={unSelectMe}
        nodeData={nodeData}
      />
    </span>
  );

  const folderCaret = (structure) => (
    <span className={iconContainerClassName('caretContainer')}>
      {isOpen ? (
        <Icon
          icon="chevron-up"
          className={iconClassName('CaretDownIcon')}
          onClick={closeMe}
        />
      ) : (
        <Icon
          icon="chevron-down"
          className={iconClassName('CaretRightIcon')}
          onClick={() => openMe(structure)}
        />
      )}
    </span>
  );

  const isDA = getIsDA();

  const pageIsCreatedByDA =
    isAddingFromInsights &&
    nodeData.__typename === 'Page' &&
    nodeData.creatorFullName === 'Polyteia';

  const treeItem =
    isVersions && !children && !nodeData.isAdditional ? (
      <div
        style={{ ...treeNodeStyle, cursor: 'pointer' }}
        onClick={handleNameClick}
      >
        <VersionItem>
          <VersionIcon versionType={nodeData.versionType} />
          {formatVersionText(nodeData)}
        </VersionItem>
      </div>
    ) : (
      <div
        className={['TreeNode', disabled ? 'disabled' : ''].join(' ').trim()}
        style={treeNodeStyle}
      >
        {showCheckbox && (
          <CheckBox status={checked} onChange={handleCheckBoxChange} />
        )}
        {isFolder && isCaretLeft && folderCaret(structure)}
        <EntityIcon
          type={restData.__typename}
          color={nodeData.color}
          versionTypeIcon={versionTypeIcon}
          icon={nodeData.icon}
          hideVersionIcon={showVersionAsText}
        />
        <span
          className={iconContainerClassName(
            `editableNameContainer ${pageIsCreatedByDA ? 'disabled' : ''}`,
          )}
          onClick={disabled || pageIsCreatedByDA ? null : handleNameClick}
        >
          <EditableName
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            onNameChange={onNameChange}
            OKIcon={OKIcon}
            CancelIcon={CancelIcon}
            nodeData={nodeData}
          />
        </span>
        {nodeData.color === 'blue' && showVersionAsText && (
          <div className="ml-1.5 rounded bg-concrete-jungle-7 bg-concrete-jungle-7 px-2.5 py-0.5 text-xs text-concrete-jungle-3">
            Draft
          </div>
        )}
        {isFolder && !isCaretLeft && folderCaret()}
        {isHierarchy &&
          nodeData?.__typename === 'Structure' &&
          nodeData?.color !== 'orange' &&
          nodeData?.versionType === VERSION_TYPE.LIVE &&
          (isDA || currentUser?.sub === nodeData.createdBy) && (
            <Preview
              className={cn({ VisibleImportIcon: isDA, ImportIcon: !isDA })}
              onClick={() => {
                window.open(`${SolutionsRoute.path}/${nodeData.id}`, '_blank');
              }}
            />
          )}
        {isHierarchy &&
          nodeData?.__typename === 'Structure' &&
          !noImports &&
          nodeData?.versionType === VERSION_TYPE.LIVE && (
            <Icon
              icon="import"
              className={'ImportIcon'}
              onClick={() => exportStructure(nodeData.id)}
            />
          )}
        {isHierarchy &&
          nodeData?.__typename === 'Structure' &&
          nodeData?.color !== 'orange' &&
          nodeData?.versionType === VERSION_TYPE.LIVE &&
          (isDA || currentUser?.sub === nodeData.createdBy) && (
            <Trash
              className={'ImportIcon'}
              onClick={() => {
                solutionsContext.setRemoveSolutionNode({
                  id: nodeData.id,
                  name: nodeData.name,
                });
              }}
            />
          )}
        {isSelected && TreeNodeToolBar}
      </div>
    );

  // displayErroneousName when entity live version not found

  const data = {
    id: nodeData.id,
    versionId: nodeData.versionId,
    versionNumber: nodeData.versionNumber,
    createdBy: nodeData.createdBy,
    createdAt: nodeData.createdAt,
    updatedBy: nodeData.updatedBy,
    updatedAt: nodeData.updatedAt,
    creatorFullName: nodeData.creatorFullName,
    updatorFullName: nodeData.updatorFullName,
    versionType: nodeData.versionType,
  };

  let tooltipItems = [];
  Object.keys(data).forEach((key) => {
    if (data[key])
      tooltipItems.push(
        <Fragment key={key}>
          {`${key}: ${data[key]}`}
          <br />
        </Fragment>,
      );
  });

  return (
    <>
      {hasTooltip && (nodeData.versionId || tooltipNote) ? (
        tooltipNote ? (
          <Tooltip2 content={tooltipNote} placement={tooltipPosition}>
            {treeItem}
          </Tooltip2>
        ) : (
          <Tooltip2
            placement={tooltipPosition}
            content={<span>{tooltipItems}</span>}
            openOnTargetFocus={false}
          >
            {treeItem}
          </Tooltip2>
        )
      ) : (
        treeItem
      )}
      {isFolder && isOpen && children?.[0]?.hasOwnProperty('fakePage') ? (
        <div style={{ paddingLeft: '25px' }}>
          <SkeletonLoader rows={children.length} width={'300px'} />
        </div>
      ) : (
        isFolder &&
        isOpen &&
        !children?.[0]?.hasOwnProperty('fakePage') &&
        children.map((data, idx) => (
          <TreeNode
            key={data._id}
            path={[...path, idx]}
            isAddingFromInsights={isAddingFromInsights}
            noImports={noImports}
            {...data}
            isVersions={isVersions}
          />
        ))
      )}
    </>
  );
};

TreeNode.propTypes = {
  path: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.number.isRequired,
  isOpen: PropTypes.bool,

  children: PropTypes.array,
  noImports: PropTypes.bool,
};

export default TreeNode;
