import styled, { css } from 'styled-components';
import { Slider, Classes } from '@blueprintjs/core';
import { colors } from '../../utils/colors';

export const StyledSlider = styled(Slider)<{
  tiltLabels?: boolean;
  first?: boolean;
  last?: boolean;
}>`
  .${Classes.SLIDER_HANDLE} {
    border-radius: 50%;
    height: 20px;
    width: 20px;
    top: -1px;
    background-color: white;
    box-shadow: rgb(140 140 140 / 30%) 0 2px 4px 0,
      rgb(140 140 140 / 20%) 0px -2px 4px 0px;

    &:focus {
      outline: none;
    }

    .${Classes.SLIDER_LABEL} {
      background-color: #e9eaef;
      color: ${colors.primary0};
      font-size: 16px;

      transform: translate(
        ${({ first, last }) => (first ? '0' : last ? '-100%' : '-50%')},
        25px
      );
      margin-left: ${({ first, last }) =>
        first ? '0' : last ? '16px' : '8px'};
    }
  }

  .${Classes.SLIDER_TRACK}, .${Classes.SLIDER_PROGRESS} {
    height: 8px;
  }

  .${Classes.SLIDER_TRACK} {
    border-radius: 8px;
  }

  .${Classes.SLIDER_LABEL} {
    transform: translate(-50%, 25px);
  }

  .${Classes.SLIDER_AXIS} {
    & {
      .${Classes.SLIDER_LABEL} {
        ${({ tiltLabels }) => css`
          transform: translate(-50%, ${tiltLabels ? 40 : 25}px)
            ${tiltLabels ? 'rotate(-50deg)' : ''};
          font-size: ${tiltLabels ? 10 : 16}px;
          color: ${colors.monochromatic1};
        `}

        white-space: nowrap;

        ${({ tiltLabels }) =>
          !tiltLabels &&
          css`
            &:first-child > div {
              transform: translateX(50%);
            }

            &:last-child > div {
              transform: translateX(-50%);
            }
          `}
      }
    }
  }

  .${Classes.SLIDER_PROGRESS}.${Classes.INTENT_PRIMARY} {
    background-color: ${colors.primary0};
  }
`;

export const Title = styled.div`
  margin-bottom: 10px;
  color: ${colors.monochromatic1};
`;
