export type Access = {
  public: boolean;
  included?: boolean;
};

export type AccessUser = {
  userId?: string;
  groupId?: string;
  customerRoles?: CustomerRole[];
};

export type AccessUserInherit = AccessUser & {
  inherit: boolean;
};

export type PermissionAccess<T> = Access & { users: T[] };

export enum ENTITY_SEARCH_TYPES {
  ID = 'ID',
  VERSION_ID = 'VERSION_ID',
}

export enum ENTITY_TYPES {
  STRUCTURE = 'Structure',
  PAGE = 'Page',
  WIDGET = 'Widget',
  QUERY = 'Query',
}

export enum CustomerRole {
  admin = 'admin',
  editor = 'editor',
}
