import styled from 'styled-components';
import { colors } from "../../../../../../utils/colors";

export const TextFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  line-height: 36px;

  position: relative;
  margin-bottom: 15px;
`;

export const TextTitleInput = styled.input`
  border: none;
  outline: none;
  color: ${colors.monochromatic0};
  width: 50%;
  background-color: transparent;

  &:focus ~ div {
    display: none;
  }
`;

export const TextTitlePlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 3px;
  pointer-events: none;

  color: ${colors.monochromatic3};
`;

export const TextTitleAsterisk = styled.span`
  color: ${colors.secondary0};
`;

export const ActionsWrapper = styled.div`
  margin-top: 14px;
  display: flex;
  justify-content: flex-end;

  & > * {
    margin-left: 17px;
  }
`;
