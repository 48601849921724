import React, { FC, ReactNode } from 'react';
import { Placement, Tooltip2 } from '@blueprintjs/popover2';
import { ReactComponent as InfoPoint } from '../../assets/icons/info-point.svg';

type Props = {
  content: string;
  isOpen?: boolean;
  placement?: Placement;
  popoverClassName?: string;
  children: ReactNode;
};

const InfoTooltip: FC<Props> = ({
  content,
  isOpen,
  placement = 'left',
  popoverClassName,
  children,
}) => {
  return (
    <Tooltip2
      popoverClassName={popoverClassName}
      disabled={!content}
      isOpen={isOpen}
      placement={placement}
      content={
        <div className="tooltip__content-inner">
          <div className="tooltip__content-inner__icon">
            <InfoPoint color="#4A599A" width={15} />
          </div>
          <div
            className="tooltip__content-inner__text"
            dangerouslySetInnerHTML={{
              __html: content as string,
            }}
          />
        </div>
      }
    >
      <div>{children}</div>
    </Tooltip2>
  );
};

export default InfoTooltip;
