import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import useTreeState, {
  findAllTargetPathByProp,
  findTargetNode,
  findTargetPathByProp,
  testData,
} from 'use-tree-state';
import TreeNode from '../TreeNode/TreeNode';
import ConfigContext from './context';
import './FolderTree.scss';

export const FolderTree = ({
  data,
  initCheckedStatus = 'unchecked',
  initOpenStatus = 'open',
  iconComponents = {},
  showCheckbox = true,
  indentPixels = 30,
  onNameClick = (e) => {},
  readOnly = false,
  noImports = false,
  isHierarchy = false,
  hasTooltip = true,
  isCaretLeft = false,
  onCaretClick = null,
  isVersions = false,
  isAddingFromInsights = false,
  showVersionAsText = false,
}) => {
  const options = {
    initCheckedStatus,
    initOpenStatus,
  };

  const { treeState, reducers } = useTreeState({
    data,
    options,
    function() {},
  });

  const {
    checkNode,
    renameNode,
    deleteNode,
    addNode,
    toggleOpen,
    setTreeState,
  } = reducers;

  useEffect(() => {
    setTreeState({
      ...data,
      isOpen: treeState?.isOpen,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!treeState) return null;

  const configs = {
    handleCheck: checkNode,
    handleRename: renameNode,
    handleDelete: deleteNode,
    handleAddNode: addNode,
    handleToggleOpen: toggleOpen,
    onNameClick,

    iconComponents,
    indentPixels,
    showCheckbox,
    readOnly,
  };

  /* ----------
    - custom configs are passed down in context, which is same for each tree node
    - tree node specific data is passed recursively to each node, which is different for each node
                                                                                        ---------- */
  return (
    <div className="FolderTree">
      <ConfigContext.Provider value={configs}>
        <TreeNode
          key={treeState._id}
          path={[]}
          structure={data}
          noImports={noImports}
          isHierarchy={isHierarchy}
          hasTooltip={hasTooltip}
          isCaretLeft={isCaretLeft}
          onCaretClick={onCaretClick}
          isVersions={isVersions}
          isAddingFromInsights={isAddingFromInsights}
          showVersionAsText={showVersionAsText}
          {...treeState}
        />
      </ConfigContext.Provider>
    </div>
  );
};

FolderTree.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func,

  initCheckedStatus: PropTypes.string,
  initOpenStatus: PropTypes.string,
  iconComponents: PropTypes.shape({
    FileIcon: PropTypes.func,
    FolderIcon: PropTypes.func,
    FolderOpenIcon: PropTypes.func,
    EditIcon: PropTypes.func,
    DeleteIcon: PropTypes.func,
    CancelIcon: PropTypes.func,
    AddFileIcon: PropTypes.func,
    AddFolderIcon: PropTypes.func,
    CaretRightIcon: PropTypes.func,
    CaretDownIcon: PropTypes.func,
  }),
  indentPixels: PropTypes.number,
  onNameClick: PropTypes.func,
  showCheckbox: PropTypes.bool,
  readOnly: PropTypes.bool,
  noImports: PropTypes.bool,
};

export {
  testData,
  findTargetNode,
  findAllTargetPathByProp,
  findTargetPathByProp,
};
