import cn from 'classnames';
import { FieldProps } from 'formik';
import { useId } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseLabelProps, InputLabel } from '../InputLabel';

type Props = FieldProps<boolean> & {
  id?: string;
  disabled?: boolean;
  size?: 'small' | 'default' | 'large';
  displayValue?: boolean;
} & BaseLabelProps;

const Toggle = ({
  id,
  disabled,
  size = 'default',
  field,
  form,
  meta,
  displayValue = true,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const generatedId = useId();

  return (
    <InputLabel {...rest} htmlFor={id || generatedId} disabled={disabled}>
      <label className="relative flex cursor-pointer items-center">
        <input
          type="checkbox"
          className="peer sr-only"
          checked={field.value}
          disabled={disabled}
          onChange={(e) => form.setFieldValue(field.name, e.target.checked)}
        />
        <div
          className={cn(
            "peer rounded-full bg-concrete-jungle-4 after:absolute after:rounded-full after:border after:border-white after:bg-white after:transition-all after:content-[''] peer-checked:bg-blueberry-1 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-focusBlue peer-disabled:after:bg-concrete-jungle-6 peer-disabled:peer-checked:after:border-concrete-jungle-6 dark:border-concrete-jungle-3 dark:bg-concrete-jungle-4 dark:peer-focus:ring-focusBlue",
            {
              'h-5 w-9 after:left-[2px] after:top-[2px] after:h-4 after:w-4':
                size === 'small',
              'h-6 w-11 after:left-[2px] after:top-[2px] after:h-5 after:w-5':
                size === 'default',
              'h-7 w-14 after:left-[4px] after:top-0.5 after:h-6 after:w-6':
                size === 'large',
            },
          )}
        />
        {displayValue && (
          <span className="ml-3 font-sans text-sm font-normal text-concrete-jungle-1 dark:text-concrete-jungle-1">
            {field.value ? t('common.yes') : t('common.no')}
          </span>
        )}
      </label>
    </InputLabel>
  );
};

export default Toggle;
