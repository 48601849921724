import { useEffect } from 'react';
import { useNavContext } from '../../app/Layout/NavBar';

export const usePageTitleEffect = (title: string, skip?: boolean) => {
  const { setPageTitle } = useNavContext();

  useEffect(() => {
    if (skip) {
      return;
    }

    setPageTitle(title);
    document.title = title;
  }, [setPageTitle, skip, title]);
};
