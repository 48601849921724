import { Theme } from '@nivo/core';
import { colors } from '../../../utils/colors';

export const NIVO_THEME: Theme = {
  fontFamily: "'GT-Eesti-Pro-Display', sans-serif",
  dots: {
    text: {
      fill: colors.monochromatic0,
    },
  },
  markers: {
    lineColor: colors.monochromatic2,
    lineStrokeWidth: 2,
  },
  axis: {
    ticks: {
      line: {
        stroke: colors.monochromatic5,
        strokeDasharray: 3,
      },
      text: {
        fontSize: 12,
        fill: colors.monochromatic2,
      },
    },
    legend: {
      text: {
        fill: colors.monochromatic2,
      },
    },
  },
  grid: {
    line: {
      stroke: colors.monochromatic5,
      strokeDasharray: 3,
    },
  },
  crosshair: {
    line: {
      strokeDasharray: '0',
      stroke: colors.monochromatic3,
    },
  },
};
