import { gql } from '@apollo/client';
import { emojiAnnotationFragment } from './fragments';
import { EmojiAnnotation } from './types';

export type GetEmojiAnnotationsData = {
  getEmojiAnnotations: EmojiAnnotation[];
};

export type GetEmojiAnnotationsVars = {
  entityVersionId: string;
};

export const GET_EMOJI_ANNOTATIONS = gql`
  query GetEmojiAnnotations($entityVersionId: String!) {
    getEmojiAnnotations(entityVersionId: $entityVersionId) {
      ...EmojiAnnotation
    }
  }
  ${emojiAnnotationFragment}
`;
