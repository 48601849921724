import React, { FC, Fragment, useEffect, useState } from 'react';
import { LinkProps, useHistory } from 'react-router-dom';
import { Icon } from '@blueprintjs/core';
import { BackLink } from './styles';
import { useTranslation } from 'react-i18next';
import { ENTITY_TYPES } from '../../gql/types';

type Props = LinkProps & {
  label?: string;
};

const BackBtn: FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <BackLink {...props}>
      <Icon icon="chevron-left" /> {props?.label || t('action.back')}
    </BackLink>
  );
};

export function useBackButton(
  props: Partial<Props>,
  setBackModalContent: (value: string) => void,
  isValid: boolean,
  hasLiveVersion?: boolean,
  entityId?: string,
  entityType?: ENTITY_TYPES,
  onIntercept?: () => boolean, // provide a function returning true to prevent the default action
) {
  const history = useHistory();

  const historyState = history.location.state as {
    backURL: string;
    backURLState?: Record<string, unknown>;
  };

  const [backURL, setBackURL] = useState('');
  const [backURLState, setBackURLState] = useState<Record<string, unknown>>({});

  useEffect(() => {
    if (historyState) {
      setBackURL(historyState?.backURL);
      setBackURLState(historyState?.backURLState ?? {});
    }
  }, [historyState]);

  const isBackBtnVisible = Boolean(backURL);

  useEffect(() => {
    if (isBackBtnVisible && (!isValid || !hasLiveVersion)) {
      const onUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        // Required for Chrome
        event.returnValue = '';
      };

      window.addEventListener('beforeunload', onUnload);

      return () => {
        window.removeEventListener('beforeunload', onUnload);
      };
    }
  }, [hasLiveVersion, isBackBtnVisible, isValid]);

  const backBtnDefaultAction = () => {
    if (isValid) {
      history.push(backURL, {
        entityId,
        entityType,
        ...backURLState,
      });

      return;
    }

    if (!hasLiveVersion) {
      setBackModalContent(
        `Please publish your ${entityType?.toLowerCase()} before going back. Otherwise, it will be deleted.`,
      );

      return;
    }

    setBackModalContent(
      `You have unsaved changes to this ${entityType?.toLowerCase()} If you go back all changes will be lost.`,
    );
  };

  const onBackBtnClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();

    if (onIntercept?.()) {
      return;
    }

    backBtnDefaultAction();
  };

  let backBtn = <Fragment />;

  if (isBackBtnVisible) {
    backBtn = <BackBtn {...props} to={'#'} onClick={onBackBtnClick} />;
  }

  return { backBtn, backURL, backURLState, backBtnDefaultAction };
}

export default BackBtn;
