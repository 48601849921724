import { gql } from '@apollo/client';
import { metadata } from '../common/fragments';

export const queryDimensionFragment = gql`
  fragment QueryDimension on QueryDimension {
    column
    description
    name
    type
    hidden
  }
`;

export const queryMeasureFragment = gql`
  fragment QueryMeasure on QueryMeasure {
    allowedOperations
    column
    description
    name
    type
    hidden
  }
`;

export const queryConfigFragment = gql`
  fragment QueryConfig on BaseQueryConfig {
    title
    name
    query
    type
    isHidden
    source
    datasourceId
    description
    dimensions {
      ...QueryDimension
    }
    measures {
      ...QueryMeasure
    }
  }
  ${queryDimensionFragment}
  ${queryMeasureFragment}
`;

export const queryFragment = gql`
  fragment Query on QueryType {
    ${metadata}
    queryConfig {
      ...QueryConfig
    }
  }
  ${queryConfigFragment}
`;
