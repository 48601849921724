import { gql } from '@apollo/client';
import { variableFragment } from '../structure/fragments';

const organizationConfigFragment = gql`
  fragment OrganizationConfig on OrganizationConfig {
    name
    planId
    solutionIds
    formIds
    defaultDatasourceId
    datasourceIds
    variables {
      ...Variable
    }
    users
    userGroups
  }
  ${variableFragment}
`;
export const organizationFragment = gql`
  fragment Organization on Organization {
    _id
    createdAt
    updatedAt
    createdBy
    updatedBy
    organizationConfig {
      ...OrganizationConfig
    }
  }
  ${organizationConfigFragment}
`;
