import React, { FC } from 'react';
import { SETTINGS } from './index';
import { MapBgRadioGroup, Title } from './styles';
import { Radio } from '@blueprintjs/core';

type Props = {
  val?: MAP_BG_OPTION;
  setSettingsVal?: React.Dispatch<React.SetStateAction<Record<SETTINGS, any>>>;
  onChange?: (newVal: any) => void;
};

export enum MAP_BG_OPTION {
  NONE = 'NONE',
  DEFAULT = 'DEFAULT',
}

const MapBg: FC<Props> = ({ val, setSettingsVal, onChange }) => {
  const currentVal = val ?? MAP_BG_OPTION.DEFAULT;

  return (
    <div>
      <Title>OpenStreetMaps Hintergrund anzeigen</Title>
      <MapBgRadioGroup
        onChange={(event) => {
          const newVal = event.currentTarget.value;

          setSettingsVal?.((prevState) => ({
            ...prevState,
            [SETTINGS.MAP_BG]: newVal,
          }));

          onChange?.(newVal);
        }}
        selectedValue={currentVal}
        inline
      >
        <Radio
          label="Nein"
          value={MAP_BG_OPTION.NONE}
          className={currentVal === MAP_BG_OPTION.NONE ? 'checked' : ''}
        />
        <Radio
          label="Standard"
          value={MAP_BG_OPTION.DEFAULT}
          className={currentVal === MAP_BG_OPTION.DEFAULT ? 'checked' : ''}
        />
      </MapBgRadioGroup>
    </div>
  );
};

export default MapBg;
