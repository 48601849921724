import React, { FC, useCallback, useEffect, useState } from 'react';
import { Col, Settings, SettingsWrapper } from './styles';
import { Collapse } from '@blueprintjs/core';
import ShowValue from './ShowValue';
import WidgetType from './WidgetType';
import VisibleData from './VisibleData';
import MapBg from './MapBg';
import { DataSeries } from '../../../gql/widget/types';

export type Setting = {
  id: string;
  initialValue?: any;
  onChange?: (newVal: any) => void;
};

export enum SETTINGS {
  SHOW_VALUE = 'SHOW_VALUE',
  WIDGET_TYPE = 'WIDGET_TYPE',
  VISIBLE_DATA = 'VISIBLE_DATA',
  SHOW_SETTINGS = 'SHOW_SETTINGS',
  MAP_BG = 'MAP_BG',
}

export function generateSeries(
  length: number,
  startFrom: number = 0,
): number[] {
  return Array.from({ length }).map((_, index) => index + startFrom);
}

type Props = {
  show: boolean;
  settings?: Setting[];
  setSettingsVal?: React.Dispatch<React.SetStateAction<Record<SETTINGS, any>>>;
  settingsVal?: Record<SETTINGS, any>;
  data_series?: DataSeries[];
};

const WidgetSettings: FC<Props> = ({
  show,
  settings,
  setSettingsVal,
  settingsVal,
  data_series,
}) => {
  const safeDataSeries = data_series ?? [];
  const dataSeriesLength = safeDataSeries.length;

  useEffect(() => {
    if (setSettingsVal) {
      setSettingsVal((prevState) => ({
        ...prevState,
        [SETTINGS.VISIBLE_DATA]: generateSeries(dataSeriesLength),
      }));
    }
  }, [dataSeriesLength, setSettingsVal]);

  const showSetting = useCallback(
    (s: SETTINGS) => settings?.some(({ id }) => id === s),
    [settings],
  );

  if (!settings?.length) {
    return null;
  }

  return (
    <Collapse isOpen={show} keepChildrenMounted>
      <SettingsWrapper data-html2canvas-ignore>
        <div className="font-w-bold mb-3">Einstellungen</div>
        <Settings>
          <Col>
            {showSetting(SETTINGS.SHOW_VALUE) && (
              <ShowValue
                val={settingsVal?.[SETTINGS.SHOW_VALUE]}
                setSettingsVal={setSettingsVal}
                onChange={
                  settings?.find(({ id }) => id === SETTINGS.SHOW_VALUE)
                    ?.onChange
                }
              />
            )}
            {showSetting(SETTINGS.WIDGET_TYPE) && (
              <WidgetType
                val={settingsVal?.[SETTINGS.WIDGET_TYPE]}
                setSettingsVal={setSettingsVal}
                domainLength={safeDataSeries[0]?.values?.length ?? 0}
                initialValue={
                  settings?.find(({ id }) => id === SETTINGS.WIDGET_TYPE)
                    ?.initialValue
                }
                onChange={
                  settings?.find(({ id }) => id === SETTINGS.WIDGET_TYPE)
                    ?.onChange
                }
              />
            )}
          </Col>
          <Col>
            {showSetting(SETTINGS.VISIBLE_DATA) && (
              <VisibleData
                val={settingsVal?.[SETTINGS.VISIBLE_DATA]}
                setSettingsVal={setSettingsVal}
                data_series={data_series}
                onChange={
                  settings?.find(({ id }) => id === SETTINGS.VISIBLE_DATA)
                    ?.onChange
                }
              />
            )}
            {showSetting(SETTINGS.MAP_BG) && (
              <MapBg
                val={settingsVal?.[SETTINGS.MAP_BG]}
                setSettingsVal={setSettingsVal}
                onChange={
                  settings?.find(({ id }) => id === SETTINGS.MAP_BG)?.onChange
                }
              />
            )}
          </Col>
        </Settings>
      </SettingsWrapper>
      <div className="p-divider__default" data-html2canvas-ignore />
    </Collapse>
  );
};

export default WidgetSettings;
