export enum FORM_ELEMENT_TYPE {
  SIGN = 'signaturepad',
  MATRIX = 'matrix',
  MATRIX_DROPDOWN = 'matrixdropdown',
  MATRIX_DYNAMIC = 'matrixdynamic',
  MULTI_TEXT = 'multipletext',
  PANEL = 'panel',
  PANEL_DYNAMIC = 'paneldynamic',
  BOOL = 'boolean',
}

export type SurveyTitle = string | { de?: string; default?: string };

export type SurveyElement = {
  name: string;
  title?: SurveyTitle;
  elements?: any;
  templateElements?: any;
  type: FORM_ELEMENT_TYPE;
  labelFalse?: SurveyTitle;
  labelTrue?: SurveyTitle;
  rows?: any;
  choices?: any[];
  columns?: any[];
};
