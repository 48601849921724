import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InfoIcon } from '../../assets/icons/round/xs/info.svg';
import { IconButton } from '../IconButton';
import { Popover } from '../Popover';

type Props = {
  popoverText?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export default function InfoIconButton({
  popoverText,
  disabled,
  onClick,
}: Props) {
  const { t } = useTranslation();

  return popoverText ? (
    <Popover text={popoverText}>
      <IconButton
        icon={InfoIcon}
        ariaLabel={t('common.info')}
        disabled={disabled}
        ariaHasPopUp
        size="xs"
        onClick={onClick}
      />
    </Popover>
  ) : (
    <IconButton
      icon={InfoIcon}
      ariaLabel={t('common.info')}
      disabled={disabled}
      ariaHasPopUp
      size="xs"
      onClick={onClick}
    />
  );
}
