import {
  AppliedWidgetFilter,
  BuiltWidgetFilter,
  DependantFilter,
  FILTER_TYPES,
  FilterDependencies,
  isMultipleFilterType,
  WidgetFilter,
} from '../gql/widget/types';
import { BuiltPageFilter } from '../gql/page/types';
import { useMemo } from 'react';
import { getFormattedValue } from './format';

export const TOTAL_FILTER_VALUE = 'TOTAL_FILTER_VALUE';

export const isTotalFilterValue = (filter: any) =>
  filter?.value === TOTAL_FILTER_VALUE ||
  filter?.multiValue?.includes(TOTAL_FILTER_VALUE);

export const transformRequestFilters = (
  filters: (AppliedWidgetFilter & { title?: string })[],
) =>
  filters.map(({ title, type, value, multiValue, ...rest }) => ({
    ...rest,
    type,
    ...(isMultipleFilterType(type)
      ? { multiValue: value || multiValue }
      : { value }),
  }));

export const getShouldResetFilters = (
  allFilters: (WidgetFilter | BuiltPageFilter)[],
  prevFilters: AppliedWidgetFilter[],
  newFilters: AppliedWidgetFilter[],
  isPage?: boolean,
) => {
  const changedFilterIds =
    allFilters
      ?.filter(({ id, type }) => {
        if (isMultipleFilterType(type as FILTER_TYPES)) {
          return false;
        }

        const prevVal = prevFilters.find(
          ({ filterId }) => filterId === id,
        )?.value;
        const newVal: any = newFilters.find(
          ({ filterId }) => filterId === id,
        )?.value;

        return newVal && prevVal !== newVal && newVal !== TOTAL_FILTER_VALUE;
      })
      .map(({ id }) => id) || [];

  return (
    allFilters
      ?.filter(({ dependencies }) => {
        const deps = (
          isPage ? dependencies : (dependencies as FilterDependencies)?.widget
        ) as DependantFilter[];

        return deps?.some(({ id }) => changedFilterIds.includes(id));
      })
      .map(({ id }) => id) || []
  );
};

export type TransformedFilter = {
  id: string;
  title?: string;
  type?: FILTER_TYPES;
  defaultSelectedFilter?: WidgetFilterEntry;
  tiltLabels?: boolean;
  info?: string;
  values: WidgetFilterEntry[];
};

export type WidgetFilterEntry = {
  title: string;
  value: any;
};

export const useFilters = (
  filters: (BuiltWidgetFilter | BuiltPageFilter)[],
): TransformedFilter[] =>
  useMemo(
    () =>
      filters?.map(
        ({
          id,
          title,
          values,
          labels,
          showTotal,
          totalLabel,
          type,
          valuesType,
          defaultSelectedFilter,
          tiltLabels,
          info,
        }) => {
          const filters = [
            FILTER_TYPES.FREETEXT,
            FILTER_TYPES.FREETEXT_VECTOR,
          ].includes(type)
            ? [{ value: '', title: '' }]
            : values
            ? values.map((value, ind) => {
                const title = getFormattedValue(
                  (Array.isArray(labels) && labels?.[ind]) || String(value),
                  valuesType,
                );

                return {
                  value,
                  title,
                };
              })
            : [];

          return {
            id,
            title,
            tiltLabels,
            info,
            type: type || FILTER_TYPES.DROPDOWN,
            ...(defaultSelectedFilter
              ? {
                  defaultSelectedFilter: {
                    value: defaultSelectedFilter,
                    title: defaultSelectedFilter,
                  },
                }
              : {}),
            values:
              showTotal && type !== FILTER_TYPES.FREETEXT
                ? [
                    {
                      value: TOTAL_FILTER_VALUE,
                      title: totalLabel,
                    },
                    ...filters,
                  ]
                : filters,
          };
        },
      ),
    [filters],
  );
