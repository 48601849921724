import styled from 'styled-components';

export const SidebarLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 20px;
  flex-grow: 1;

  & > div {
    width: 100%;
    margin-bottom: 10px;
    height: 20px;

    &:first-child {
      margin-bottom: 40px;
      height: 40px;
    }
  }
`;

export const EditorAddSiteButton = styled.div`
  color: #7A7A7A;
  margin-top: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;

  & > svg {
    width: 24px;
    height: 24px;
    background-color: #282D61;
    border-radius: 50%;
    margin-right: 8px;

    & > g {
      fill: #FFFFFF;
    }
  }
  
  &:hover {
    color: #4A599A;

    & > svg {
      background-color: currentColor;
    }
  }
`;
