import cn from 'classnames';
import { ReactNode, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIcon } from '../../../assets/icons/ui/l/search.svg';
import { includesWordStartsWith } from '../../../utils/common';

type Props<T> = {
  items?: T[];
  placeholder?: string;
  getItemString?: (item: T) => string;
  onFilterChange?: (filter: string) => void;
  children?: (items?: T[]) => ReactNode;
};

export default function SearchInput<T>({
  items,
  placeholder,
  getItemString,
  onFilterChange,
  children,
}: Props<T>) {
  const { t } = useTranslation();

  const [isInputFocused, setIsInputFocused] = useState(false);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    onFilterChange?.(filter);
  }, [onFilterChange, filter]);

  const filteredItems = items?.filter((field) =>
    getItemString
      ? includesWordStartsWith(getItemString(field), filter)
      : false,
  );
  return (
    <>
      <div
        className={cn([
          'focus-within:focused-input',
          'flex',
          'min-h-[44px]',
          'h-11',
          'w-full',
          'flex-row',
          'items-center',
          'justify-start',
          'rounded-md',
          'border',
          'border-concrete-jungle-5',
          'bg-white',
          'px-4',
          'gap-4',
          'text-sm',
          'text-primary',
          'focus:border-primary',
          'hover:border-concrete-jungle-3',
        ])}
        data-testid="search-input"
      >
        <SearchIcon
          className={cn({
            'text-blueberry': isInputFocused,
            'text-concrete-jungle-3': !isInputFocused,
          })}
        />
        <input
          data-testid="text-input"
          className={cn([
            'flex-1',
            'outline-none',
            'overflow-hidden',
            'whitespace-nowrap',
            'text-ellipsis',
            'text-sm',
            'h-full',
            'text-concrete-jungle',
            'active:text-blueberry',
            'placeholder:italic',
            'placeholder:text-concrete-jungle-3',
          ])}
          placeholder={placeholder ?? t('common.search-placeholder')}
          type="text"
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => setIsInputFocused(false)}
          onChange={(event) => setFilter(event.target.value)}
          value={filter}
        />
      </div>
      {children && children(filteredItems)}
    </>
  );
}
