import { gql } from '@apollo/client';
import { siteFragment } from './fragments';
import { EntityUsage, Site } from './types';
import { VERSION_TYPE } from '../dataSource/types';
import { metadata } from '../common/fragments';

export type GetSiteVersionData = { siteVersion: Site };

export type GetSiteVersionVars = {
  versionId: string;
};

export const GET_SITE_VERSION = gql`
  query GetSiteVersion($versionId: String!) {
    siteVersion(versionId: $versionId) {
      ...Site
    }
  }
  ${siteFragment}
`;

export type GetAllSiteVersionsData = {
  allSiteVersions: Array<Omit<Site, 'siteConfig'>>;
};

export type GetAllSiteVersionsVars = {
  id: string;
};

export const GET_ALL_SITE_VERSIONS = gql`
  query GetAllSiteVersions($id: String!) {
    allSiteVersions(id: $id) {
      ${metadata}
    }
  }
`;

export type GetSiteUsagesByEntityData = { allSiteUsagesByEntity: Site[] };

export type GetSiteUsagesByEntityVars = {
  entityType: EntityUsage;
  versionId: string;
};

export const GET_SITE_USAGES_BY_ENTITY = gql`
  query AllSiteUsagesByEntity($entityType: String!, $versionId: String!) {
    allSiteUsagesByEntity(entityType: $entityType, versionId: $versionId) {
      ...Site
    }
  }
  ${siteFragment}
`;

export type SearchSitesData = { searchSites: Site[] };

export type SearchSitesVars = {
  searchInput: {
    text: string;
    versionType: VERSION_TYPE;
    first?: number;
  };
};

export const SEARCH_SITES = gql`
  query SearchSites($searchInput: SearchEntitiesInput!) {
    searchSites(searchInput: $searchInput) {
      ...Site
    }
  }
  ${siteFragment}
`;
