import { Metadata } from '../common/types';

export enum DataSourceType {
  // TODO: rename postgre --> postgres and remove postgre
  postgre = 'postgre',
  postgres = 'postgres',
  trino = 'trino',
}

type DataSourceConfigPostgre = {
  type: `${DataSourceType}`;
  name: string;
  host: string;
  user: string;
  password: string;
  port: string;
  healthCheckQuery: string;
  schema: string;
  database?: string;
};

export type DataSourceConfig<T = 'postgres'> = T extends 'trino'
  ? Omit<DataSourceConfigPostgre, 'database'> & {
      catalog: string;
      useTLS: string;
      type: T;
    }
  : DataSourceConfigPostgre;

export enum VERSION_TYPE {
  LIVE = 'LIVE',
  DRAFT = 'DRAFT',
  OLD = 'OLD',
}

export type DataSource = Metadata & {
  dataSourceConfig: DataSourceConfig;
};
