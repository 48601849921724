type Color = {
  name: string;
  shades: Record<number, string>;
};

export const colors = {
  orange0: '#A66321',
  orange1: '#BF7326',
  orange3: '#F29D49',

  black: '#000',
  white: '#fff',
  primary0: '#282d61',
  primary1: '#3b4785',
  primary2: '#4a599a',
  primary3: '#6371a8',
  primary4: '#a2abcc',
  primary5: '#e9ebf2',
  secondary0: '#f26233',
  secondary1: '#b34419',
  secondary2: '#d9552b',
  secondary3: '#e75c2f',
  secondary4: '#f3774f',
  secondary5: '#f7ae97',
  secondary6: '#f8eae8',
  monochromatic0: '#212121',
  monochromatic1: '#3e3e3e',
  monochromatic2: '#7a7a7a',
  monochromatic3: '#ababab',
  monochromatic4: '#cacaca',
  monochromatic5: '#e0e0e0',
  monochromatic6: '#ebebeb',
  monochromatic7: '#f6f6f6',
  green0: '#26925e',
  green1: '#2ba664',
  green2: '#31bb67',
  green3: '#36d068',
  green4: '#68dc8e',
  green5: '#9be8b4',
  green6: '#c3f1d2',
  green7: '#d7f6e1',
  green8: '#ebfaf0',
  green9: '#f5fdf7',
  blue0: '#1856ad',
  blue1: '#1b6dc6',
  blue2: '#1f88de',
  blue3: '#3491e1',
  blue4: '#22a5f7',
  blue5: '#59bcf9',
  blue6: '#91d2fb',
  blue7: '#bde4fd',
  blue8: '#d3edfd',
  blue9: '#e9f6fe',
  blue10: '#f4fbff',
  firstExtended0: '#161a42',
  firstExtended1: '#343a67',
  firstExtended2: '#525c8f',
  firstExtended3: '#7281b8',
  firstExtended4: '#abb5db',
  firstExtended5: '#dadff2',
  firstExtended6: '#e6e7f5',
  secondExtended0: '#437899',
  secondExtended1: '#3daaad',
  secondExtended2: '#5db8cf',
  secondExtended3: '#79c6f2',
  secondExtended4: '#89a0e9',
  secondExtended5: '#9179e0',
  secondExtended6: '#d375a8',
  secondExtended7: '#ff7070',
  secondExtended8: '#ffa19d',

  grapefruit: '#f26233',
  raspberry: '#F26233',
};

export const POLYTEIA_COLORS = [
  {
    color: '#212121',
    name: 'Concrete Jungle',
  },
  {
    color: '#3E3E3E',
    name: 'Concrete Jungle 1',
  },
  {
    color: '#7A7A7A',
    name: 'Concrete Jungle 2',
  },
  {
    color: '#ABABAB',
    name: 'Concrete Jungle 3',
  },
  {
    color: '#CACACA',
    name: 'Concrete Jungle 4',
  },
  {
    color: '#E0E0E0',
    name: 'Concrete Jungle 5',
  },
  {
    color: '#EBEBEB',
    name: 'Concrete Jungle 6',
  },
  {
    color: '#F6F6F6',
    name: 'Concrete Jungle 7',
  },

  {
    color: '#282D61',
    name: 'Blueberry',
  },
  {
    color: '#3B4785',
    name: 'Blueberry 1',
  },
  {
    color: '#4A599A',
    name: 'Blueberry 2',
  },
  {
    color: '#6371A8',
    name: 'Blueberry 3',
  },
  {
    color: '#A2ABCC',
    name: 'Blueberry 4',
  },
  {
    color: '#E9EBF2',
    name: 'Blueberry 5',
  },

  {
    color: '#F26233',
    name: 'Raspberry',
  },
  {
    color: '#B34419',
    name: 'Raspberry 1',
  },
  {
    color: '#D9552B',
    name: 'Raspberry 2',
  },
  {
    color: '#E75C2F',
    name: 'Raspberry 3',
  },
  {
    color: '#F3774F',
    name: 'Raspberry 4',
  },
  {
    color: '#F7AE97',
    name: 'Raspberry 5',
  },
  {
    color: '#F8EAE8',
    name: 'Raspberry 6',
  },

  {
    color: '#161A42',
    name: 'Smooth berry',
  },
  {
    color: '#343a67',
    name: 'Smooth berry 1',
  },
  {
    color: '#525c8f',
    name: 'Smooth berry 2',
  },
  {
    color: '#7281B8',
    name: 'Smooth berry 3',
  },
  {
    color: '#ABB5DB',
    name: 'Smooth berry 4',
  },
  {
    color: '#DADFF2',
    name: 'Smooth berry 5',
  },
  {
    color: '#E6E7F5',
    name: 'Smooth berry 6',
  },

  {
    color: '#437899',
    name: 'Big Rainbow',
  },
  {
    color: '#3DAAAD',
    name: 'Big Rainbow 1',
  },
  {
    color: '#5DB8CF',
    name: 'Big Rainbow 2',
  },
  {
    color: '#79C6F2',
    name: 'Big Rainbow 3',
  },
  {
    color: '#89A0E9',
    name: 'Big Rainbow 4',
  },
  {
    color: '#9179E0',
    name: 'Big Rainbow 5',
  },
  {
    color: '#D375A8',
    name: 'Big Rainbow 6',
  },
  {
    color: '#FF7070',
    name: 'Big Rainbow 7',
  },
  {
    color: '#FFA19D',
    name: 'Big Rainbow 8',
  },
];

export const palette = [
  {
    name: 'persimmon',
    shades: {
      50: '#fff2ee',
      100: '#fdd8cd',
      200: '#ffb6a2',
      300: '#fa886b',
      400: '#e56647',
      500: '#b95238',
      600: '#8a412e',
      700: '#542d23',
      800: '#36201b',
      900: '#1c1411',
    },
  },
  {
    name: 'amber',
    shades: {
      50: '#fff4df',
      100: '#fbdea4',
      200: '#f4be47',
      300: '#d1a131',
      400: '#af872d',
      500: '#8a6b25',
      600: '#674c09',
      700: '#473506',
      800: '#332400',
      900: '#1b1508',
    },
  },
  {
    name: 'teal',
    shades: {
      50: '#d9fbfb',
      100: '#9ef0f0',
      200: '#3ddbd9',
      300: '#08bdba',
      400: '#009d9a',
      500: '#007d79',
      600: '#005d5d',
      700: '#004144',
      800: '#022b30',
      900: '#081a1c',
    },
  },
  {
    name: 'cobalt',
    shades: {
      50: '#f2f5fc',
      100: '#d8e1f9',
      200: '#b5c5f9',
      300: '#90a6f4',
      400: '#7087df',
      500: '#5167ce',
      600: '#3746bd',
      700: '#2006a0',
      800: '#151864',
      900: '#0c1233',
    },
  },
  {
    name: 'pink',
    shades: {
      50: '#fef1f6',
      100: '#fcd7e6',
      200: '#ffadcf',
      300: '#fe79b9',
      400: '#f042a0',
      500: '#c82b82',
      600: '#981c62',
      700: '#690341',
      800: '#450d2b',
      900: '#260b18',
    },
  },
];

export const priorityPaletteList = [
  'cobalt-700',
  'persimmon-200',
  'pink-500',
  'cobalt-100',
  'pink-300',
  'cobalt-400',
  'teal-600',
  'persimmon-800',
  'persimmon-400',
  'teal-200',
  'amber-300',
  'amber-500',
  'amber-700',
];

export const getColorBrightness = (color?: string) => {
  if (!color) {
    return 0;
  }

  const isHEX = color.indexOf('#') === 0;

  let r = 0;
  let g = 0;
  let b = 0;

  if (isHEX) {
    const hasFullSpec = color.length === 7;
    const m = color.substr(1).match(hasFullSpec ? /(\S{2})/g : /(\S)/g);

    if (m) {
      r = parseInt(m[0] + (hasFullSpec ? '' : m[0]), 16);
      g = parseInt(m[1] + (hasFullSpec ? '' : m[1]), 16);
      b = parseInt(m[2] + (hasFullSpec ? '' : m[2]), 16);
    }
  } else {
    const m = color.match(/(\d+){3}/g);

    if (m) {
      r = +m[0];
      g = +m[1];
      b = +m[2];
    }
  }

  return (r * 299 + g * 587 + b * 114) / 1000;
};

export const getContrastColor = (
  color: string,
  bright = 'white',
  dark = 'black',
) => (getColorBrightness(color) > 125 ? dark : bright);

export const getColorsFromPalette = () => {
  const paletteObj = palette.reduce((obj: Record<string, any>, item: Color) => {
    obj[item.name] = item.shades;
    return obj;
  }, {});

  const priorityColors = priorityPaletteList.map((item) => {
    let [color, shade] = item.split('-');
    return paletteObj[color][shade];
  });

  let otherColors = [];
  for (let color in paletteObj) {
    for (let shade in paletteObj[color]) {
      if (!priorityPaletteList.includes(`${color}-${shade}`)) {
        otherColors.push(paletteObj[color][shade]);
      }
    }
  }

  return priorityColors.concat(otherColors);
};
