import { gql } from '@apollo/client';
import { baseMetadata } from '../common/fragments';

export const formUserFragment = gql`
  fragment FormUser on FormUser {
    groupId
    userId
  }
`;

export const formAccessFragment = gql`
  fragment FormAccess on FormAccess {
    public
    users {
      ...FormUser
    }
  }
  ${formUserFragment}
`;

export const formConfigFragment = gql`
  fragment FormConfig on FormConfig {
    title
    name
    config
  }
`;

export const formFragment = gql`
  fragment Form on Form {
    ${baseMetadata}
    access {
        ...FormAccess
    }  
    formConfig {
      ...FormConfig
    }
  }
  ${formConfigFragment}
  ${formAccessFragment}
`;

export const surveyResultFragment = gql`
  fragment SurveyResult on SurveyResult {
    id
    formVersionId
    createdAt
    formId
    response
    updatedAt
    userId
  }
`;
