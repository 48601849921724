import React from 'react';
import { StyledLazyLoading } from './styles';
import { ReactComponent as PolyteiaIcon } from '../../assets/icons/logo.svg';

export function LazyLoading() {
  return (
    <StyledLazyLoading>
      <PolyteiaIcon />
    </StyledLazyLoading>
  );
}
