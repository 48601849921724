import { gql } from '@apollo/client';
import { Structure } from '../structure/types';
import { User, UsersGroup } from '../user/types';
import { organizationFragment } from './fragments';
import { Organization } from './types';

export type SearchOrganizationsData = {
  searchOrganizations: Organization[];
};

export type SearchOrganizationsVars = {
  searchInput: {
    text: string;
    first?: number;
  };
};

export const SEARCH_ORGANIZATIONS = gql`
  query SearchOrganizations($searchInput: SearchEntitiesInput!) {
    searchOrganizations(searchInput: $searchInput) {
      ...Organization
    }
  }
  ${organizationFragment}
`;

export type GetOrganizationSolutionsData = {
  getOrganizationSolutions: Structure[];
};

export type GetOrganizationSolutionsVars = {
  id: string;
};

export const GET_ORGANIZATION_SOLUTIONS = gql`
  query GetOrganizationSolutions($id: String!) {
    getOrganizationSolutions(id: $id) {
      id
      structureConfig {
        name
        title
      }
    }
  }
`;

export type GetOrganizationUsersData = {
  getOrganizationUsers: User[];
};

export type GetOrganizationUsersVars = {
  id: string;
};

export const GET_ORGANIZATION_USERS = gql`
  query GetOrganizationUsers($id: String!) {
    getOrganizationUsers(id: $id) {
      _id
      email
      firstName
      lastName
      role
      isPending
    }
  }
`;

export type GetUsersWithoutOrganizationData = {
  getUsersWithoutOrganization: User[];
};

export const GET_USERS_WITHOUT_ORGANIZATION = gql`
  query GetUsersWithoutOrganization {
    getUsersWithoutOrganization {
      _id
      email
      firstName
      lastName
      role
      isPending
    }
  }
`;

export type GetOrganizationUserGroupsData = {
  getOrganizationUserGroups: UsersGroup[];
};

export type GetOrganizationUserGroupsVars = {
  id: string;
};

export const GET_ORGANIZATION_USER_GROUPS = gql`
  query GetOrganizationUserGroups($id: String!) {
    getOrganizationUserGroups(id: $id) {
      _id
      name
      members {
        _user {
          _id
        }
      }
    }
  }
`;

export type GetUserGroupsWithoutOrganization = {
  getUserGroupsWithoutOrganization: UsersGroup[];
};

export const GET_USER_GROUPS_WITHOUT_ORGANIZATION = gql`
  query GetUserGroupsWithoutOrganization {
    getUserGroupsWithoutOrganization {
      _id
      name
      members {
        _user {
          _id
        }
      }
    }
  }
`;

export type GetOrganizationData = {
  getOrganization: Organization;
};

export type GetOrganizationVars = {};

export const GET_ORGANIZATION = gql`
  query GetOrganization {
    getOrganization {
      ...Organization
    }
  }
  ${organizationFragment}
`;

export type GetOrganizationsByPlanData = {
  getOrganizationsByPlan: Organization[];
};

export type GetOrganizationsByPlanVars = {
  planId: string;
};

export const GET_ORGANIZATIONS_BY_PLAN = gql`
  query GetOrganizationsByPlan($planId: String!) {
    getOrganizationsByPlan(planId: $planId) {
      ...Organization
    }
  }
  ${organizationFragment}
`;
