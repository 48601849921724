import React from 'react';

export interface IDeleteNode {
  id: string;
  name: string;
  title: string;
}

interface ISolutionsContext {
  setRemoveSolutionNode?: (obj: IDeleteNode | null) => void;
}

export const SolutionsContext = React.createContext<ISolutionsContext | null>(
  null,
);
