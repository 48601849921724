import { CSSProperties, RefObject, useEffect, useState } from 'react';
import _ from 'lodash';

export const calculateRectPosition = (
  rect: DOMRect,
  options: {
    distance: number;
    horizontal?: boolean;
  } = { distance: 0 },
) => {
  const tempStyles: CSSProperties = {};

  const { bottom, right } = rect;
  const { innerHeight: windowHeight, innerWidth: windowWidth } = window;

  const relativeBottomDistance = (windowHeight - bottom) / windowHeight;

  const absoluteDistance = `calc(100% + ${options.distance}px)`;

  const positionVerticalStyle =
    relativeBottomDistance < 0.35 ? 'bottom' : 'top';
  if (positionVerticalStyle) {
    tempStyles[positionVerticalStyle] = absoluteDistance;
  }

  if (options.horizontal) {
    const relativeRightDistance = (windowWidth - right) / windowWidth;

    if (relativeRightDistance < 0.15) {
      tempStyles['right'] = absoluteDistance;
    }
    if (relativeRightDistance > 0.65) {
      tempStyles['left'] = absoluteDistance;
    }
  }
  return tempStyles;
};

export const usePositionStyles = (
  ref: RefObject<HTMLElement>,
  options: {
    distance: number;
    horizontal?: boolean;
  },
  isActive = true,
) => {
  const [styles, setStyles] = useState<CSSProperties>({});

  useEffect(() => {
    if (!isActive) {
      return;
    }
    const calculateRefPosition = () => {
      if (!ref.current) {
        return;
      }

      const tempStyles = calculateRectPosition(
        ref.current.getBoundingClientRect(),
        options,
      );

      if (!_.isEqual(styles, tempStyles)) {
        setStyles(tempStyles);
      }
    };

    calculateRefPosition();

    document.addEventListener('scroll', calculateRefPosition);

    return () => {
      document.removeEventListener('scroll', calculateRefPosition);
    };
  });

  return { styles };
};
