import { gql } from '@apollo/client';
import { InviteMultipleUsersInput } from '../user/types';
import {
  hierarchyFragment,
  pageTreeFragment,
  pageVersionsFragment,
} from './fragments';
import { PageTree, PageVersions, StructureTree } from './types';

export type GetHierarchyByUserData = {
  getLiveStructureTreeByUser: StructureTree[];
};

export type GetHierarchyByUserVars = {
  user?: InviteMultipleUsersInput;
};

export const GET_HIERARCHY_BY_USER = gql`
  query GetHierarchyByUser($user: UserInput!) {
    getLiveStructureTreeByUser(user: $user) {
      ...StructureTree
    }
  }
  ${hierarchyFragment}
`;

export type GetHierarchyByGroupData = {
  getLiveStructureTreeByGroup: StructureTree[];
};

export type GetHierarchyByGroupVars = {
  groupId?: string;
};

export const GET_HIERARCHY_BY_GROUP = gql`
  query GetHierarchyByGroup($groupId: String!) {
    getLiveStructureTreeByGroup(groupId: $groupId) {
      ...StructureTree
    }
  }
  ${hierarchyFragment}
`;

export type GetHierarchyStructuresData = {
  getHierarchyStructures: StructureTree[];
};

export type GetHierarchyStructuresVars = {
  searchInput: {
    text: string;
    first?: number;
    offset?: number;
  };
  useLatestVersion?: boolean;
};

export const GET_HIERARCHY_STRUCTURES = gql`
  query GetHierarchyStructures(
    $searchInput: SearchEntitiesInput!
    $useLatestVersion: Boolean
  ) {
    getHierarchyStructures(
      searchInput: $searchInput
      useLatestVersion: $useLatestVersion
    ) {
      ...StructureTree
    }
  }
  ${hierarchyFragment}
`;

export type GetHierarchyEntitiesData = {
  getHierarchyEntitiesByStructure: PageTree[];
};

export type GetHierarchyEntitiesVars = {
  versionId: string;
};

export const GET_HIERARCHY_ENTITIES = gql`
  query GetHierarchyEntities($versionId: String!) {
    getHierarchyEntitiesByStructure(versionId: $versionId) {
      ...PageTree
    }
  }
  ${pageTreeFragment}
`;

export type GetHierarchyPageVersionsData = {
  getHierarchyPageVersionsByStructure: PageVersions[];
};

export type GetHierarchyPageVersionsVars = {
  versionId: string;
};

export const GET_HIERARCHY_PAGE_VERSIONS = gql`
  query GetHierarchyPageVersions($versionId: String!) {
    getHierarchyPageVersionsByStructure(versionId: $versionId) {
      ...StructurePageVersions
    }
  }
  ${pageVersionsFragment}
`;
