import { useQuery } from '@apollo/client/react/hooks/useQuery';
import { useMutation } from '@apollo/client';

export const CHRONOS_MICROSERVICE = 'CHRONOS_MICROSERVICE';

export const useChronosQuery: typeof useQuery = (query, options) =>
  useQuery(query, {
    ...options,
    context: { microservice: CHRONOS_MICROSERVICE },
  });

export const useChronosMutation: typeof useMutation = (mutation, options) =>
  useMutation(mutation, {
    ...options,
    context: { microservice: CHRONOS_MICROSERVICE },
  });
