import { useMemo } from 'react';
import { scaleLinear } from 'd3-scale';

export function numberLength(number: number) {
  // Math.floor(number).toString().length equivalent of return
  return Math.floor(Math.log10(number)) + 1; // returns the total number of digits of a number
}

export function calcPow10(number: number) {
  let log = numberLength(number);
  return parseInt('1'.padEnd(log < 2 ? 2 : log, '0')); // returns 10, 100, 1000 based on log
}

// We use generic as the hook might update in the future
interface Props {
  min?: number;
  max: number;

  tickTotal?: number;
  zeroBased?: boolean;
  regulator?: number;
  divByTen?: boolean;
  maxValue?: number;
}

interface Returns {
  domain: [number, number]; // should always have two values
  ticks: number[];
}

function nicelyRound({
  min,
  max,
  zeroBased,
}: Required<Pick<Props, 'min' | 'max' | 'zeroBased'>>) {
  let minimum = zeroBased ? 0 : min;

  // center point
  let diff: number = (minimum + max) / 2;

  // distance from graph point to center defaults to 6
  let maxVal = max && max > 6 ? max + (max - diff) : 6;

  let minVal = zeroBased ? 0 : minimum - (diff - minimum);

  return {
    domain: [minVal, maxVal],
    range: [minimum, max],
  };
}

export function scaleChart({
  min,
  max,
  regulator,
  zeroBased,
}: Required<Pick<Props, 'min' | 'max' | 'regulator' | 'zeroBased'>>) {
  if (!max || (max < 10 && min >= 0)) {
    return {
      domain: [0, 10],
      range: [0, 10],
    };
  }

  let expMax = calcPow10(max + regulator); // 132 -> 100
  let expMin = calcPow10(min - regulator); // 45 -> 10

  let maxVal = Math.ceil((max + regulator) / expMax) * expMax;
  let minVal = zeroBased ? 0 : Math.floor((min - regulator) / expMin) * expMin;

  return {
    domain: [minVal, maxVal],
    range: [min, max],
  };
}

export function returnScaleLinear(
  domainArr: number[],
  rangeArr: number[],
  tickTotal: number,
): Readonly<Returns> {
  // returns a beautified range between the new min and max (y = ax + b) but we nicely round them
  const values = scaleLinear().domain(domainArr).range(rangeArr).nice();

  const ticks: Returns['ticks'] = values.ticks(tickTotal); // returns x amount of ticks [minValue, ....., maxValue]
  const domain: Returns['domain'] = [ticks[0], ticks[ticks.length - 1]]; // nicely rounded values become domain so they're always shown

  return {
    ticks,
    domain,
  };
}

export default function useScale({
  min = 0,
  max = 6,
  tickTotal = 6,
  zeroBased,
  divByTen,
  regulator = 1,
  maxValue,
}: Props): Readonly<Returns> {
  return useMemo(() => {
    let domainArr: number[];
    let rangeArr: number[];

    if (!divByTen) {
      ({ domain: domainArr, range: rangeArr } = nicelyRound({
        min,
        max,
        zeroBased: zeroBased as boolean,
      }));
    } else {
      ({ domain: domainArr, range: rangeArr } = scaleChart({
        min,
        max,
        regulator: regulator as number,
        zeroBased: zeroBased as boolean,
      }));
    }

    const scale = returnScaleLinear(
      [
        domainArr[0],
        maxValue ?? rangeArr[1] + (rangeArr[1] - domainArr[0]) * 0.2,
      ],
      [rangeArr[0], maxValue ?? rangeArr[1]],
      tickTotal,
    );

    return {
      ticks: maxValue
        ? scale.ticks.filter((tick) => tick <= maxValue)
        : scale.ticks,
      domain: [scale.domain[0], maxValue ?? scale.domain[1]],
    };
  }, [divByTen, tickTotal, maxValue, min, max, zeroBased, regulator]);
}
