import React, { FC, useEffect, useState } from 'react';
import { Icon } from '@blueprintjs/core';
import { useQuery } from '@apollo/client';
import {
  STRUCTURES_BY_URL,
  StructuresByUrlData,
  StructuresByUrlVars,
} from '../../../../gql/structure/queries';
import SidebarStructure from './SidebarStructure';
import { useParams } from 'react-router-dom';
import { getToken } from '../../../../gql/user/local';
import SidebarLoader from './SidebarLoader';
import useWindowSize from '../../../../utils/hooks/useWindowSize';
import { ENV_VAR, getEnvVar } from '../../../../utils/common';
import { removeHubspot } from '../../../../utils/hubspot';
import { SolutionParams } from '../../../../app/Routes';
import { DEFAULT_SIDEBAR_WIDTH } from '../../StructureEditor/styles';

interface Props {
  isStructureEditor?: boolean;
}

export const LOGIN_URL = '/login';

export const getStructureSubdomain = () => {
  const host = window.location.hostname;
  return host.substring(0, host.indexOf(`.${getEnvVar(ENV_VAR.APP_URL)}`));
};

export const handleLogout = (queryParam = '') => {
  localStorage.clear();
  removeHubspot();
  window.location.href = `${window.location.origin}${LOGIN_URL}${queryParam}`;
};

const Sidebar: FC<Props> = ({ isStructureEditor }) => {
  const [collapse, setCollapse] = useState(false);
  const { structureUrl } = useParams<SolutionParams>();
  const { width } = useWindowSize();

  const subdomain = getStructureSubdomain();

  const { data: publicStructureIdsData, loading: publicStructureIdsLoading } =
    useQuery<StructuresByUrlData, StructuresByUrlVars>(STRUCTURES_BY_URL, {
      variables: {
        url: subdomain,
      },
      skip: !subdomain,
    });

  const publicStructureId = publicStructureIdsData?.liveStructureIdsByUrl[0];
  // todo add
  // useUsetiful(true, false, structureUrl);

  useEffect(() => {
    if (!structureUrl && !subdomain && !getToken()) {
      handleLogout();
    }
  }, [structureUrl, subdomain]);

  useEffect(() => {
    if (width && width > 1024) {
      // width where button should appear
      setCollapse(false);
    }
  }, [width]);

  return (
    <div className="u-position-relative border-right-mono-5 u-height-100">
      {!isStructureEditor && (
        <button
          className="btn-primary__outline collapse__button"
          onClick={() => setCollapse((s) => !s)}
        >
          <Icon icon={collapse ? 'chevron-right' : 'chevron-left'} size={15} />
        </button>
      )}
      <div
        className={`sidebar u-position-relative ${
          collapse ? 'isCollapsed' : ''
        }`}
        style={{
          minWidth: isStructureEditor ? 0 : DEFAULT_SIDEBAR_WIDTH,
          maxWidth: isStructureEditor ? 'none' : DEFAULT_SIDEBAR_WIDTH,
        }}
      >
        {publicStructureIdsLoading ? (
          <SidebarLoader />
        ) : (
          <div className="sidebar__container flex-grow-1 u-margin-bottom-60">
            <SidebarStructure structureId={publicStructureId || structureUrl} />
          </div>
        )}
      </div>
    </div>
  );
};
export default Sidebar;
