import { useQuery } from '@apollo/client';
import { FC, useEffect, useMemo } from 'react';
import { SolutionsRoute } from '../../../app/Routes';
import { EDIT_PATH } from '../../../constants/entities';
import { useAthenaQuery } from '../../../gql/dataSource/hooks';
import {
  GET_ALL_DATA_SOURCES_ALL_VERSION_TYPES,
  GetAllDataSourcesAllVersionTypesData,
  GetAllDataSourcesAllVersionTypesVars,
} from '../../../gql/dataSource/queries';
import {
  GET_ALL_PAGES_ALL_VERSION_TYPES,
  GetAllPagesAllVersionTypesData,
  GetAllPagesAllVersionTypesVars,
} from '../../../gql/page/queries';
import {
  GET_ALL_STRUCTURES_ALL_VERSION_TYPES,
  GetAllStructuresAllVersionTypesData,
  GetAllStructuresAllVersionTypesVars,
} from '../../../gql/structure/queries';
import {
  GET_ALL_WIDGETS_ALL_VERSION_TYPES,
  GetAllWidgetsAllVersionTypesData,
  GetAllWidgetsAllVersionTypesVars,
} from '../../../gql/widget/queries';
import { getMetaDescription } from '../../../utils/editor';
import { useValidationEnum } from '../../../utils/hooks/useValidationEnum';

type Props = {
  setSchema: (schema: Record<string, any>) => void;
};

export type IdsField = {
  type: string;
  enum: string[];
  enumDescriptions: string[];
  markdownEnumDescriptions: string[];
};

export const useIdsField = (
  arr: any[],
  pathToConfig: string,
  entityUrl: string,
  isEntityId?: boolean,
): IdsField =>
  useMemo(() => {
    const descriptions = arr.map((item) => {
      const { ...meta } = item;
      const config = item[pathToConfig];
      const name = config?.name ? `Name: ${config.name}\n` : '';

      return `\n- ${name}\n${getMetaDescription(meta)}\n[**Go to config**](${
        window.location.origin
      }/${entityUrl}/${meta.versionId})`;
    });

    const ids = arr.map(({ versionId, id }) => (isEntityId ? id : versionId));

    return {
      type: 'string',
      enum: ids,
      enumDescriptions: descriptions,
      markdownEnumDescriptions: descriptions,
    };
  }, [arr, entityUrl, isEntityId, pathToConfig]);

const SiteSchema: FC<Props> = ({ setSchema }) => {
  const { data: dataSourcesData } = useAthenaQuery<
    GetAllDataSourcesAllVersionTypesData,
    GetAllDataSourcesAllVersionTypesVars
  >(GET_ALL_DATA_SOURCES_ALL_VERSION_TYPES, {
    fetchPolicy: 'network-only',
  });

  const { data: structuresData } = useQuery<
    GetAllStructuresAllVersionTypesData,
    GetAllStructuresAllVersionTypesVars
  >(GET_ALL_STRUCTURES_ALL_VERSION_TYPES, {
    fetchPolicy: 'network-only',
  });

  const { data: pagesData } = useQuery<
    GetAllPagesAllVersionTypesData,
    GetAllPagesAllVersionTypesVars
  >(GET_ALL_PAGES_ALL_VERSION_TYPES, {
    fetchPolicy: 'network-only',
  });

  const { data: widgetsData } = useQuery<
    GetAllWidgetsAllVersionTypesData,
    GetAllWidgetsAllVersionTypesVars
  >(GET_ALL_WIDGETS_ALL_VERSION_TYPES, {
    fetchPolicy: 'network-only',
  });

  const datasources = useValidationEnum(dataSourcesData);
  const structures = useValidationEnum(structuresData);
  const pages = useValidationEnum(pagesData);
  const widgets = useValidationEnum(widgetsData);

  const dsVersionIdsField = useIdsField(
    datasources,
    'dataSourceConfig',
    'datasources',
  );
  const structureVersionIdsField = useIdsField(
    structures,
    'structureConfig',
    `${SolutionsRoute.path.slice(1)}/${EDIT_PATH}`,
  );
  const pageVersionIdsField = useIdsField(pages, 'pageConfig', 'pages');
  const widgetVersionIdsField = useIdsField(widgets, 'widgetConfig', 'widgets');

  const schema = useMemo(() => {
    return {
      type: 'object',
      properties: {
        name: {
          type: 'string',
        },
        dataSources: {
          type: 'array',
          uniqueItems: true,
          items: dsVersionIdsField,
        },
        structures: {
          type: 'array',
          uniqueItems: true,
          items: structureVersionIdsField,
        },
        pages: {
          type: 'array',
          uniqueItems: true,
          items: pageVersionIdsField,
        },
        widgets: {
          type: 'array',
          uniqueItems: true,
          items: widgetVersionIdsField,
        },
      },
    };
  }, [
    dsVersionIdsField,
    pageVersionIdsField,
    structureVersionIdsField,
    widgetVersionIdsField,
  ]);

  useEffect(() => {
    if (dataSourcesData && structuresData && pagesData && widgetsData) {
      setSchema(schema);
    }
  }, [
    dataSourcesData,
    pagesData,
    schema,
    setSchema,
    structuresData,
    widgetsData,
  ]);

  return null;
};

export default SiteSchema;
