import styled, { css } from 'styled-components';
import Btn from '../Btn';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const ModalHeader = styled.div`
  display: flex;
  margin-bottom: 13px;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
`;

export const ModalHeaderGroup = styled.div<{ hasExtra?: boolean }>`
  display: flex;
  justify-content: ${({ hasExtra }) => (hasExtra ? 'space-between' : 'center')};
  width: 100%;
`;

export const ModalSeparator = styled.div`
  width: 100%;
  border-bottom: 1px solid #ebebeb;
`;

export const ModalContent = styled.div`
  display: flex;
  margin: 20px 0;
  align-items: center;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  max-width: 85%;
`;

export const ModalActionsWrapper = styled.div<{
  intent: 'danger' | 'action' | undefined;
}>`
  display: flex;
  justify-content: center;

  ${(prop) =>
    prop.intent === 'action' &&
    css`
      flex-direction: row-reverse;
    `}
`;

export const ActionButton = styled(Btn)`
  padding: 12px 24px !important;
  white-space: nowrap;
  height: 40px !important;
`;

export const CancelButton = styled(Btn)`
  padding: 12px 24px !important;
  white-space: nowrap;
  background-color: white;
  color: #282d61 !important;
  border: 2px solid #282d61;
  height: 40px !important;
`;

export const ModalBtnSeparator = styled.div`
  width: 20px;
`;
