import { useMemo } from 'react';
import { DataSeries } from '../../../../gql/widget/types';

const findDomain = (series: DataSeries[]) => {
  let yD = [Infinity, -Infinity];

  series.forEach((s) => {
    const yValues = s.values.filter((val) => val !== null) as number[];

    yD = [Math.min(...yValues, yD[0]), Math.max(...yValues, yD[1])];
  });

  return yD;
};

export default function useFormatGraphSeries(
  domain: string[],
  series: DataSeries[],
  originalDataSeries?: DataSeries[],
) {
  return useMemo(() => {
    return series.length > 0 && domain?.length > 0
      ? findDomain(originalDataSeries || series)
      : [];
  }, [series, domain?.length, originalDataSeries]);
}
