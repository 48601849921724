import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import {
  GET_BAR_WIDGET_VERSION,
  GET_COLUMN_WIDGET_VERSION,
  GET_LINE_WIDGET_VERSION,
  GET_SIMPLE_STAT_WIDGET_VERSION,
  GET_MAP_WIDGET_VERSION,
  GET_WIDGET_VERSION,
  GetWidgetVersionData,
  GetWidgetVersionVars,
  GET_TEXT_WIDGET_VERSION,
  GET_TABLE_WIDGET_VERSION,
  GET_PIE_WIDGET_VERSION,
} from './queries';
import { Widget, WIDGET_TYPE } from './types';
import {
  CREATE_BAR_WIDGET,
  CREATE_BAR_WIDGET_VERSION,
  CREATE_COLUMN_WIDGET,
  CREATE_COLUMN_WIDGET_VERSION,
  CREATE_LINE_WIDGET,
  CREATE_LINE_WIDGET_VERSION,
  CREATE_MAP_WIDGET,
  CREATE_MAP_WIDGET_VERSION,
  CREATE_PIE_WIDGET,
  CREATE_PIE_WIDGET_VERSION,
  CREATE_SIMPLE_STAT_WIDGET,
  CREATE_SIMPLE_STAT_WIDGET_VERSION,
  CREATE_TABLE_WIDGET,
  CREATE_TABLE_WIDGET_VERSION,
  CREATE_TEXT_WIDGET,
  CREATE_TEXT_WIDGET_VERSION,
  UPDATE_AND_PUBLISH_BAR_WIDGET_VERSION,
  UPDATE_AND_PUBLISH_COLUMN_WIDGET_VERSION,
  UPDATE_AND_PUBLISH_LINE_WIDGET_VERSION,
  UPDATE_AND_PUBLISH_MAP_WIDGET_VERSION,
  UPDATE_AND_PUBLISH_PIE_WIDGET_VERSION,
  UPDATE_AND_PUBLISH_SIMPLE_STAT_WIDGET_VERSION,
  UPDATE_AND_PUBLISH_TABLE_WIDGET_VERSION,
  UPDATE_AND_PUBLISH_TEXT_WIDGET_VERSION,
  UPDATE_BAR_WIDGET_VERSION,
  UPDATE_COLUMN_WIDGET_VERSION,
  UPDATE_LINE_WIDGET_VERSION,
  UPDATE_MAP_WIDGET_VERSION,
  UPDATE_PIE_WIDGET_VERSION,
  UPDATE_SIMPLE_STAT_WIDGET_VERSION,
  UPDATE_TABLE_WIDGET_VERSION,
  UPDATE_TEXT_WIDGET_VERSION,
} from './mutations';
import { useCallback } from 'react';

const widgetConfigPathMap: Record<string, string> = {
  [WIDGET_TYPE.LINE_CHART]: 'lineChartWidgetVersion',
  [WIDGET_TYPE.PIE_CHART]: 'pieChartWidgetVersion',
  [WIDGET_TYPE.CHOROPLETH_MAP]: 'mapChartWidgetVersion',
  [WIDGET_TYPE.BAR_CHART]: 'barChartWidgetVersion',
  [WIDGET_TYPE.STACKED_COLUMN_CHART]: 'columnChartWidgetVersion',
  [WIDGET_TYPE.GROUPED_COLUMN_CHART]: 'columnChartWidgetVersion',
  [WIDGET_TYPE.SIMPLE_STAT]: 'simpleStatWidgetVersion',
  [WIDGET_TYPE.TABLE]: 'tableWidgetVersion',
  [WIDGET_TYPE.TEXT]: 'textWidgetVersion',
};

const GET_WIDGET_VERSION_QUERIES = {
  [WIDGET_TYPE.LINE_CHART]: GET_LINE_WIDGET_VERSION,
  [WIDGET_TYPE.PIE_CHART]: GET_PIE_WIDGET_VERSION,
  [WIDGET_TYPE.TABLE]: GET_TABLE_WIDGET_VERSION,
  [WIDGET_TYPE.STACKED_COLUMN_CHART]: GET_COLUMN_WIDGET_VERSION,
  [WIDGET_TYPE.GROUPED_COLUMN_CHART]: GET_COLUMN_WIDGET_VERSION,
  [WIDGET_TYPE.BAR_CHART]: GET_BAR_WIDGET_VERSION,
  [WIDGET_TYPE.CHOROPLETH_MAP]: GET_MAP_WIDGET_VERSION,
  [WIDGET_TYPE.SIMPLE_STAT]: GET_SIMPLE_STAT_WIDGET_VERSION,
  [WIDGET_TYPE.TEXT]: GET_TEXT_WIDGET_VERSION,
};

const UPDATE_WIDGET_VERSION_MUTATIONS = {
  [WIDGET_TYPE.LINE_CHART]: UPDATE_LINE_WIDGET_VERSION,
  [WIDGET_TYPE.PIE_CHART]: UPDATE_PIE_WIDGET_VERSION,
  [WIDGET_TYPE.TABLE]: UPDATE_TABLE_WIDGET_VERSION,
  [WIDGET_TYPE.STACKED_COLUMN_CHART]: UPDATE_COLUMN_WIDGET_VERSION,
  [WIDGET_TYPE.GROUPED_COLUMN_CHART]: UPDATE_COLUMN_WIDGET_VERSION,
  [WIDGET_TYPE.BAR_CHART]: UPDATE_BAR_WIDGET_VERSION,
  [WIDGET_TYPE.CHOROPLETH_MAP]: UPDATE_MAP_WIDGET_VERSION,
  [WIDGET_TYPE.SIMPLE_STAT]: UPDATE_SIMPLE_STAT_WIDGET_VERSION,
  [WIDGET_TYPE.TEXT]: UPDATE_TEXT_WIDGET_VERSION,
};

const CREATE_WIDGET_MUTATIONS = {
  [WIDGET_TYPE.LINE_CHART]: CREATE_LINE_WIDGET,
  [WIDGET_TYPE.PIE_CHART]: CREATE_PIE_WIDGET,
  [WIDGET_TYPE.TABLE]: CREATE_TABLE_WIDGET,
  [WIDGET_TYPE.STACKED_COLUMN_CHART]: CREATE_COLUMN_WIDGET,
  [WIDGET_TYPE.GROUPED_COLUMN_CHART]: CREATE_COLUMN_WIDGET,
  [WIDGET_TYPE.BAR_CHART]: CREATE_BAR_WIDGET,
  [WIDGET_TYPE.CHOROPLETH_MAP]: CREATE_MAP_WIDGET,
  [WIDGET_TYPE.SIMPLE_STAT]: CREATE_SIMPLE_STAT_WIDGET,
  [WIDGET_TYPE.TEXT]: CREATE_TEXT_WIDGET,
};

const CREATE_WIDGET_VERSION_MUTATIONS = {
  [WIDGET_TYPE.LINE_CHART]: CREATE_LINE_WIDGET_VERSION,
  [WIDGET_TYPE.PIE_CHART]: CREATE_PIE_WIDGET_VERSION,
  [WIDGET_TYPE.TABLE]: CREATE_TABLE_WIDGET_VERSION,
  [WIDGET_TYPE.STACKED_COLUMN_CHART]: CREATE_COLUMN_WIDGET_VERSION,
  [WIDGET_TYPE.GROUPED_COLUMN_CHART]: CREATE_COLUMN_WIDGET_VERSION,
  [WIDGET_TYPE.BAR_CHART]: CREATE_BAR_WIDGET_VERSION,
  [WIDGET_TYPE.CHOROPLETH_MAP]: CREATE_MAP_WIDGET_VERSION,
  [WIDGET_TYPE.SIMPLE_STAT]: CREATE_SIMPLE_STAT_WIDGET_VERSION,
  [WIDGET_TYPE.TEXT]: CREATE_TEXT_WIDGET_VERSION,
};

const UPDATE_AND_PUBLISH_WIDGET_VERSION_MUTATIONS = {
  [WIDGET_TYPE.LINE_CHART]: UPDATE_AND_PUBLISH_LINE_WIDGET_VERSION,
  [WIDGET_TYPE.PIE_CHART]: UPDATE_AND_PUBLISH_PIE_WIDGET_VERSION,
  [WIDGET_TYPE.TABLE]: UPDATE_AND_PUBLISH_TABLE_WIDGET_VERSION,
  [WIDGET_TYPE.STACKED_COLUMN_CHART]: UPDATE_AND_PUBLISH_COLUMN_WIDGET_VERSION,
  [WIDGET_TYPE.GROUPED_COLUMN_CHART]: UPDATE_AND_PUBLISH_COLUMN_WIDGET_VERSION,
  [WIDGET_TYPE.BAR_CHART]: UPDATE_AND_PUBLISH_BAR_WIDGET_VERSION,
  [WIDGET_TYPE.CHOROPLETH_MAP]: UPDATE_AND_PUBLISH_MAP_WIDGET_VERSION,
  [WIDGET_TYPE.SIMPLE_STAT]: UPDATE_AND_PUBLISH_SIMPLE_STAT_WIDGET_VERSION,
  [WIDGET_TYPE.TEXT]: UPDATE_AND_PUBLISH_TEXT_WIDGET_VERSION,
};

export const useWidgetConfig = () => {
  const client = useApolloClient();

  const { refetch: refetchWidget } = useQuery<
    GetWidgetVersionData,
    GetWidgetVersionVars
  >(GET_WIDGET_VERSION, { skip: true });

  return useCallback(
    async (versionId: string) => {
      const variables = { versionId };

      try {
        const {
          data: {
            widgetVersion: {
              widgetConfig: { type },
            },
          },
        } = await refetchWidget(variables);

        const { data: widgetData } = await client.query({
          query: GET_WIDGET_VERSION_QUERIES[type],
          variables,
        });

        return (widgetData[widgetConfigPathMap[type]] as Widget).widgetConfig;
      } catch (e) {}
    },
    [client, refetchWidget],
  );
};

export const useWidgetVersionQuery = (
  versionId: string,
  widgetType: WIDGET_TYPE,
  skip?: boolean,
  onCompleted?: (data: any) => void,
) => {
  const { data, ...rest } = useQuery(
    GET_WIDGET_VERSION_QUERIES[widgetType] || GET_LINE_WIDGET_VERSION,
    {
      variables: { versionId },
      fetchPolicy: 'cache-and-network',
      skip,
      onCompleted: (data) => {
        onCompleted?.(data[widgetConfigPathMap[widgetType]]);
      },
    },
  );

  return { data: data?.[widgetConfigPathMap[widgetType]], ...rest };
};

export const useUpdateWidgetVersionMutation = (widgetType: WIDGET_TYPE) => {
  return useMutation(
    UPDATE_WIDGET_VERSION_MUTATIONS[widgetType] || UPDATE_LINE_WIDGET_VERSION,
  );
};

export const useCreateWidgetMutation = (widgetType: WIDGET_TYPE) => {
  return useMutation(CREATE_WIDGET_MUTATIONS[widgetType] || CREATE_LINE_WIDGET);
};

export const useCreateWidgetVersionMutation = (widgetType: WIDGET_TYPE) => {
  return useMutation(
    CREATE_WIDGET_VERSION_MUTATIONS[widgetType] || CREATE_LINE_WIDGET_VERSION,
  );
};

export const useUpdateAndPublishWidgetVersionMutation = (
  widgetType: WIDGET_TYPE,
) => {
  return useMutation(
    UPDATE_AND_PUBLISH_WIDGET_VERSION_MUTATIONS[widgetType] ||
      UPDATE_AND_PUBLISH_LINE_WIDGET_VERSION,
  );
};
