import React, { FC } from 'react';
import { useField } from 'formik';
import { HTMLInputProps } from '@blueprintjs/core';
import { TextInputLegacy } from '../TextInput';

type Props = {
  label: string;
  name: string;
} & HTMLInputProps;

const FormField: FC<Props> = ({ label, name, ...props }) => {
  const [field, meta] = useField(name);

  const isError = !!(meta.touched && meta.error);

  return (
    <TextInputLegacy
      id={name}
      label={label}
      error={isError}
      assistiveText={isError ? meta.error : ''}
      {...field}
      {...props}
    />
  );
};

export default FormField;
