import styled from 'styled-components';
import { Checkbox, Classes } from '@blueprintjs/core';
import { colors } from '../../../utils/colors';
import check from '../../../assets/icons/check.svg';

export const StyledCheckbox = styled(Checkbox)<{ checked?: boolean }>`
  margin-bottom: 0;

  color: ${({ checked }) =>
    checked ? colors.primary0 : colors.monochromatic2};

  &&&&&&&& .${Classes.CONTROL_INDICATOR} {
    border: solid 2px #6d6b6b;
    border-radius: 4px;
    height: 18px;
    width: 18px;
    outline: none;
    box-shadow: none !important;
  }

  &&&&&&&& input:checked ~ .${Classes.CONTROL_INDICATOR} {
    border-color: ${colors.primary0};
    background-color: ${colors.primary5};
    ::before {
      background-image: url('${check}');
      background-position: center;
      background-repeat: no-repeat;
      width: 14px;
      height: 14px;
      transform: scale(0.8);
    }
  }
`;
