import styled from 'styled-components';
import Btn from '../../../components/Btn';
import { colors } from '../../../utils/colors';

export const SectionWrapper = styled.div`
  margin: 20px 0 !important;
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled.div<{ isPage?: boolean }>`
  font-weight: 500;
  color: ${colors.monochromatic0};
  margin-bottom: 20px;
  font-size: ${({ isPage }) => (isPage ? '18px' : '14px')};
  line-height: ${({ isPage }) => (isPage ? '36px' : 'inherit')};
`;

export const SectionFiltersWrapper = styled.div<{
  filtersNum: number;
  isSm?: boolean;
}>`
  display: grid;
  grid-template-columns: repeat(
    ${({ filtersNum, isSm }) => {
      if (isSm) {
        return 1;
      }

      return filtersNum > 3 ? 3 : filtersNum;
    }},
    1fr
  );
  grid-gap: 5%;
`;

export const SliderWrapper = styled.div`
  padding: 0 10px;
`;

export const ResetBtn = styled(Btn)`
  align-self: flex-end;
  margin-top: 30px;
`;
