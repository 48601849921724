import { Metadata } from '../../gql/common/types';

export type AddToSubModalProps<T = Metadata> = {
  search: string;
  element: {
    type: AddToElement;
    id: string;
  };
  onAdded(library: T, isNewVersion: boolean): void;
};

export enum AddToTarget {
  LIBRARY = 'library',
  PAGE = 'page',
}

export enum AddToElement {
  WIDGET = 'widget',
  QUERY = 'query',
}
