export function groupObjectsByProperty<T extends any, K extends keyof T>(
  source: T[],
  property: K,
): Record<string, T[]> {
  return source.reduce((result, element: T) => {
    const elementKey = element[property] as string;
    const groupedElements = (result[elementKey] ?? []) as T[];

    groupedElements.push(element);
    result[elementKey] = groupedElements;

    return result;
  }, {} as Record<string, T[]>);
}
