import cn from 'classnames';
import { motion } from 'framer-motion';
import { PropsWithChildren, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../assets/icons/ui/l/close.svg';
import { Button } from '../Button';
import { IconButton } from '../IconButton';

export type Props = PropsWithChildren<{
  acceptCaption?: string;
  secondaryAcceptCaption?: string;
  cancelCaption?: string;
  subtitle?: string;
  icon?: React.ReactNode;
  title?: string;
  size: 'small' | 'medium' | 'large';
  variant?: 'default' | 'red';
  contentAlignment?: 'left' | 'center' | 'right';
  cancelButtonDisabled?: boolean;
  acceptButtonDisabled?: boolean;
  secondaryAcceptButtonDisabled?: boolean;
  onCancel?(): void | Promise<void>;
  onClose?(): void | Promise<void>;
  onAccept?(): void | Promise<void>;
  onSecondaryAccept?(): void | Promise<void>;
  transitionIn?: boolean;
  transitionOut?: boolean;
}>;

function PortalOverlay({
  children,
  transitionIn = false,
}: PropsWithChildren<{ transitionIn: boolean; transitionOut: boolean }>) {
  const [wrapperElement, setWrapperElement] = useState<HTMLElement>();

  useLayoutEffect(() => {
    const element = document.getElementById('modal-root');
    if (element) {
      setWrapperElement(element);
    }
  }, []);

  if (!wrapperElement) {
    return null;
  }

  return createPortal(
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: transitionIn ? 0.3 : 0 }}
    >
      <div className="fixed inset-0 z-50 bg-black/50">
        <div className="flex h-full w-full flex-col items-center justify-center">
          {transitionIn ? (
            <motion.div
              className="flex flex-row justify-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 40 }}
            >
              {children}
            </motion.div>
          ) : (
            children
          )}
        </div>
      </div>
    </motion.div>,
    wrapperElement,
  );
}

export default function Modal({
  acceptCaption,
  secondaryAcceptCaption,
  cancelCaption,
  children,
  icon,
  cancelButtonDisabled = false,
  acceptButtonDisabled = false,
  secondaryAcceptButtonDisabled = false,
  title,
  size = 'medium',
  subtitle,
  onAccept,
  onSecondaryAccept,
  onCancel,
  onClose,
  transitionIn = false,
  transitionOut = false,
  variant = 'default',
  contentAlignment = 'left',
}: Props) {
  const { t } = useTranslation();
  const ctas = [onAccept, onSecondaryAccept, onCancel].filter(Boolean);

  return (
    <PortalOverlay transitionIn={transitionIn} transitionOut={transitionOut}>
      <div
        className={cn('rounded-lg bg-white py-8 shadow-md', {
          'w-1/3 max-w-[480px] px-6': size === 'small',
          'w-2/3 max-w-[900px] px-8': size === 'medium',
          'w-11/12 px-12': size === 'large',
        })}
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalSubtitle"
      >
        <div
          className={cn('relative flex flex-row items-start justify-between', {
            '!justify-start': contentAlignment !== 'center',
            '!justify-center': contentAlignment === 'center',
          })}
        >
          {icon && <div className="h-[60px]">{icon}</div>}
          {onClose && (
            <div className="absolute right-0 top-0">
              <IconButton
                onClick={onClose}
                icon={CloseIcon}
                ariaLabel={t('common.close')}
                title={t('common.close')}
              />
            </div>
          )}
        </div>
        <header
          className={cn({
            'text-left': contentAlignment === 'left',
            'text-center': contentAlignment === 'center',
            'text-right': contentAlignment === 'right',
          })}
        >
          {title && (
            <h1 className="mb-2 mt-4 text-xl" id="modalTitle">
              {title}
            </h1>
          )}
          {subtitle && (
            <p className="mb-4 text-base" id="modalSubtitle">
              {subtitle}
            </p>
          )}
        </header>
        {children && <section>{children}</section>}
        {(onCancel || onAccept || onSecondaryAccept) && (
          <div
            className={cn('mt-6 flex flex-row space-x-5', {
              'justify-between':
                contentAlignment !== 'center' &&
                size !== 'small' &&
                ctas.length > 1,
              'justify-end':
                (contentAlignment !== 'center' && size === 'small') ||
                (size === 'large' && ctas.length === 1) ||
                (size === 'medium' && ctas.length === 1),
              'justify-center': contentAlignment === 'center',
            })}
          >
            {onCancel && (
              <Button
                variant="ghost"
                size="small"
                onClick={onCancel}
                disabled={cancelButtonDisabled}
              >
                {cancelCaption}
              </Button>
            )}
            <div className="flex gap-4">
              {onSecondaryAccept && (
                <Button
                  variant={'ghost'}
                  size="small"
                  onClick={onSecondaryAccept}
                  disabled={secondaryAcceptButtonDisabled}
                >
                  {secondaryAcceptCaption || t('common.ok')}
                </Button>
              )}
              {onAccept && (
                <Button
                  variant={variant === 'red' ? 'cancel' : 'primary'}
                  size="small"
                  onClick={onAccept}
                  disabled={acceptButtonDisabled}
                >
                  {acceptCaption || t('common.ok')}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </PortalOverlay>
  );
}
