import React, { FC } from 'react';
import { ReactComponent as InfoPoint } from '../../../../assets/icons/info-point.svg';
import { BACKGROUND_COLOR_TAG, CARET } from '../simple_table/@constants';
import Tooltip from '../../../Tooltip';
import BackgroundColorTag from '../simple_table/BackgroundColorTag';

export interface TabItemProps {
  name: string;
  type: string;

  info?: string;
  number?: number | string;

  diff?: number | string;
  diff_color?: typeof BACKGROUND_COLOR_TAG[keyof typeof BACKGROUND_COLOR_TAG];
  indicator?: keyof typeof CARET;
}

interface Props {
  content: TabItemProps;
  disabled?: boolean;
  isActive?: boolean;
  customTabItemClassName?: string;
  styles?: { [key: string]: any };
}

export const TabItem: FC<Props> = ({
  styles = {},
  customTabItemClassName,
  content,
  disabled = false,
}) => {
  const words = content.name?.split(/(\(.*\))/);
  const name = words?.[0];
  const titleProp = words?.[1];

  const hasTitle = name || content.info || titleProp;

  return (
    <div
      style={styles}
      className={`        
          tabitem 
          u-padding-left-20
          u-margin-bottom-0
          ${customTabItemClassName && customTabItemClassName}
          ${disabled && 'isDisabledOpacity05 u-cursor-disabled'}
        `}
    >
      {hasTitle && (
        <div className="title u-margin-bottom-20">
          <div className="u-align-center-y">
            <div className="font-w-600">{name}</div>
            {content.info && (
              <Tooltip
                position="top"
                holder={
                  <div
                    className="u-vertical-align-middle"
                    style={{
                      marginTop: 1,
                      width: 12,
                      height: 12,
                      marginLeft: 5,
                    }} // Otherwise the SVG doesn't render in the center
                  >
                    <InfoPoint />
                  </div>
                }
              >
                <div style={{ maxWidth: 350, padding: 5, marginRight: 5 }}>
                  {content.info}
                </div>
              </Tooltip>
            )}
          </div>
          <div>{titleProp}</div>
        </div>
      )}
      <div className="value font-w-500">
        {content.number && (
          <span className={'value font-w-bold'}>{content.number}</span>
        )}
        {content.diff && (
          <div className="update">
            <BackgroundColorTag
              value={content.diff}
              tag={content.diff_color}
              customClassName={'u-display-inline-block u-text-align-center'}
              {...(content.indicator ? { icon: CARET[content.indicator] } : {})}
            />
          </div>
        )}
      </div>
    </div>
  );
};
