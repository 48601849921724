import { gql } from '@apollo/client';
import { libraryFragment } from './fragments';
import { Library, LibraryConfig } from './types';
import { AddToElement } from '../../components/BtnAddTo/types';

export type CreateLibraryData = { createLibrary: Library };

export type CreateLibraryVars = {
  CreateLibraryInput: { libraryConfig: LibraryConfig };
};

export const CREATE_LIBRARY = gql`
  mutation CreateLibrary($CreateLibraryInput: CreateLibraryInput!) {
    createLibrary(CreateLibraryInput: $CreateLibraryInput) {
      ...Library
    }
  }
  ${libraryFragment}
`;

export type CreateLibraryVersionData = {
  createLibraryVersion: Library;
};

export type CreateLibraryVersionVars = {
  CreateLibraryInput: { libraryConfig: LibraryConfig };
  id: string;
};

export const CREATE_LIBRARY_VERSION = gql`
  mutation CreateLibraryVersion(
    $CreateLibraryInput: CreateLibraryInput!
    $id: String!
  ) {
    createLibraryVersion(CreateLibraryInput: $CreateLibraryInput, id: $id) {
      ...Library
    }
  }
  ${libraryFragment}
`;

export type UpdateLibraryVersionData = {
  updateLibraryVersion: Library;
};

export type UpdateLibraryVersionVars = {
  UpdateLibraryVersionInput: {
    versionId: string;
    libraryConfig: LibraryConfig;
  };
};

export const UPDATE_LIBRARY_VERSION = gql`
  mutation UpdateLibraryVersion(
    $UpdateLibraryVersionInput: UpdateLibraryVersionInput!
  ) {
    updateLibraryVersion(
      UpdateLibraryVersionInput: $UpdateLibraryVersionInput
    ) {
      ...Library
    }
  }
  ${libraryFragment}
`;

export type UpdateAndPublishLibraryVersionData = {
  updateAndPublishLibraryVersion: Library;
};

export type UpdateAndPublishLibraryVersionVars = {
  UpdateAndPublishLibraryVersion: {
    versionId: string;
    libraryConfig: LibraryConfig;
  };
};

export const UPDATE_AND_PUBLISH_LIBRARY_VERSION = gql`
  mutation UpdateAndPublishLibraryVersion(
    $UpdateAndPublishLibraryVersion: UpdateAndPublishLibraryVersionInput!
  ) {
    updateAndPublishLibraryVersion(
      UpdateAndPublishLibraryVersion: $UpdateAndPublishLibraryVersion
    ) {
      ...Library
    }
  }
  ${libraryFragment}
`;

export type DeleteLibraryVersionData = {
  deleteLibraryVersions: Library[];
};

export type DeleteLibraryVersionVars = {
  DeleteLibraryVersionInput: {
    versionId: string;
  }[];
};

export const DELETE_LIBRARY_VERSION = gql`
  mutation DeleteLibraryVersion(
    $DeleteLibraryVersionInput: [DeleteLibraryVersionInput!]!
  ) {
    deleteLibraryVersions(
      DeleteLibraryVersionInput: $DeleteLibraryVersionInput
    ) {
      ...Library
    }
  }
  ${libraryFragment}
`;

export type AddToLibraryVersionData = {
  addToLibraryVersion: Library;
};

export type AddToLibraryVersionVars = {
  AddToLibraryVersionInput: {
    versionId: string;
    elementId: string;
    elementType: AddToElement;
  };
};

export const ADD_TO_LIBRARY_VERSION = gql`
  mutation AddToLibraryVersion(
    $AddToLibraryVersionInput: AddToLibraryVersionInput!
  ) {
    addToLibraryVersion(AddToLibraryVersionInput: $AddToLibraryVersionInput) {
      ...Library
    }
  }
  ${libraryFragment}
`;
