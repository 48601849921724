import styled, { css } from 'styled-components';
import { colors } from '../../../utils/colors';

export const Field = styled.div<{ error?: boolean }>`
  position: relative;
  margin: 10px 0 6px;
  overflow: hidden;
  border-radius: 8px;

  & input:focus ~ label,
  & input:not([value='']) ~ label,
  & input:-webkit-autofill ~ label {
    top: 9px;
    font-size: 10px;
  }

  & input:focus ~ .highlight,
  & input:not([value='']) ~ .highlight {
    width: 100%;
  }

  ${({ error }) =>
    error
      ? css`
          & .highlight {
            width: 100%;
          }
        `
      : ''}
`;

export const getInputStyles = (disabled?: boolean) => css`
  border: none;
  border-radius: 8px;
  font-size: 14px;
  height: 48px;
  background: #f0f1f5;
  box-shadow: none;
  padding-left: 16px;
  padding-top: 14px;
  width: 100%;
  color: ${disabled ? colors.monochromatic3 : colors.monochromatic0};

  cursor: ${disabled ? 'not-allowed' : 'auto'};

  &::placeholder {
    color: ${colors.monochromatic2};
    font-size: 14px;
  }

  &:focus {
    outline: none;
  }
`;

export const Input = styled.input<{ disabled?: boolean }>`
  ${({ disabled }) => getInputStyles(disabled)}
`;

export const Highlight = styled.span<{ error?: boolean }>`
  position: absolute;
  height: 2px;
  width: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  opacity: 1;
  background: ${({ error }) => (error ? colors.grapefruit : colors.primary0)};
  transition: 0.3s ease all;
`;

export const Label = styled.label<{ error?: boolean; disabled?: boolean }>`
  color: ${({ error, disabled }) => {
    if (disabled) {
      return colors.monochromatic3;
    }
    if (error) {
      return colors.grapefruit;
    }

    return colors.monochromatic2;
  }};
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 16px;
  top: 16px;
  transition: 0.2s ease all;
`;

export const AssistiveText = styled.p`
  color: ${colors.monochromatic2};
  font-size: 10px;
  padding-left: 16px;
  margin-bottom: 10px;
`;

export const Required = styled.span<{ disabled?: boolean }>`
  color: ${({ disabled }) =>
    disabled ? colors.monochromatic2 : colors.grapefruit};
`;
