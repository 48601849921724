import React, { FC, Fragment, useState } from 'react';
import { ReactComponent as InfoPoint } from '../../../assets/icons/info-point.svg';
import {
  FilterSection,
  Highlight,
  HIGHLIGHT_TYPE,
} from '../../../gql/widget/types';
import Tooltip from '../../Tooltip';
import {
  HIGHLIGHT_DASHARRAY,
  TRANSLUCENT_OPACITY,
} from '../utils/dataHighlights';

type AssignColor = (i: number) => string;

interface Props {
  legend: (Record<string, any> & { highlights?: Highlight[] })[];
  assignColor?: AssignColor;
  setSelectedData?: React.Dispatch<React.SetStateAction<number | null>>;
  visibleIds?: number[];
  isScenarios?: boolean;
  scenarios?: FilterSection[];
}

export const GraphLegend: FC<Props> = ({
  legend,
  assignColor,
  setSelectedData,
  visibleIds,
  isScenarios,
  scenarios,
}) => {
  const [persistentSelectedData, setPersistentSelectedData] = useState<
    number | null
  >(null);

  if (!legend) {
    return null;
  }

  return (
    <Fragment>
      {legend.map(({ title, info, highlights }, i) => {
        if (visibleIds && !visibleIds.includes(i)) {
          return null;
        }

        return (
          <span key={`GL-${i}`}>
            <div className="u-display-inline-block u-margin-top-30 u-margin-right-30 u-vertical-align-top">
              <div
                className="u-align-center-y u-cursor-pointer"
                onClick={() => {
                  const changeState = persistentSelectedData === i ? null : i;
                  setSelectedData?.(changeState);
                  setPersistentSelectedData(changeState);
                }}
                onMouseEnter={() => {
                  if (persistentSelectedData !== null) {
                    return;
                  }

                  setSelectedData?.(i);
                }}
                onMouseLeave={() => {
                  if (persistentSelectedData !== null) {
                    return;
                  }

                  setSelectedData?.(null);
                }}
              >
                <div
                  className="circle12"
                  style={{
                    backgroundColor: assignColor ? assignColor(i) : 'white',
                    opacity: isScenarios ? TRANSLUCENT_OPACITY : 1,
                  }}
                />
                <div className="u-margin-left-10 u-margin-right-5">
                  {isScenarios
                    ? `${title} (${scenarios?.[0]?.defaultLabel})`
                    : title}
                </div>
                {info && (
                  <Tooltip
                    holder={
                      <div
                        className="u-vertical-align-middle"
                        style={{
                          marginTop: 1,
                          width: 12,
                          height: 12,
                        }} // Otherwise the SVG doesn't render in the center
                      >
                        <InfoPoint color="#6371A8" />
                      </div>
                    }
                  >
                    <div
                      style={{ maxWidth: 350, padding: 5 }}
                      className="u-white-space__pre-line"
                    >
                      {info}
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
            {[
              ...(highlights || []),
              ...(isScenarios
                ? [
                    {
                      type: HIGHLIGHT_TYPE.TRANSLUCENT,
                      legend: `${title} (${scenarios?.[0]?.filteredLabel})`,
                      isScenario: true,
                    },
                  ]
                : []),
            ]?.map(({ legend, type, isScenario }, ind) => {
              const isDashed = type === HIGHLIGHT_TYPE.DASHED;
              const isTranslucent = type === HIGHLIGHT_TYPE.TRANSLUCENT;
              const isDotted = type === HIGHLIGHT_TYPE.DOTTED;
              const isDash = isDashed || isDotted;
              const strokeDasharray = HIGHLIGHT_DASHARRAY[type];

              return (
                legend && (
                  <div
                    key={`${i}-${ind}-highlight-legend`}
                    className="u-display-inline-block u-margin-top-30 u-margin-right-30 u-vertical-align-top"
                  >
                    <div className="u-align-center-y">
                      {isTranslucent && (
                        <div
                          className="circle12"
                          style={{
                            backgroundColor: assignColor
                              ? assignColor(i)
                              : 'white',
                            opacity: isScenario ? 1 : TRANSLUCENT_OPACITY,
                          }}
                        />
                      )}
                      {isDash && (
                        <svg
                          viewBox="0 0 24px 12px"
                          style={{ height: 12, width: 24 }}
                        >
                          <line
                            x1="3"
                            y1="6"
                            x2="24"
                            y2="6"
                            stroke={assignColor?.(i) || 'black'}
                            strokeDasharray={strokeDasharray}
                            strokeWidth={3}
                            strokeLinecap="round"
                          />
                        </svg>
                      )}
                      <div className="u-margin-left-10 u-margin-right-5">
                        {legend}
                      </div>
                    </div>
                  </div>
                )
              );
            })}
          </span>
        );
      })}
    </Fragment>
  );
};

export default GraphLegend;
