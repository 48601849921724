import React, { FC } from 'react';
import BarChart from '../BarChart';
import {
  BuiltWidgetConfig,
  OnFilterChange,
  WidgetCommonProps,
} from '../../../../gql/widget/types';
import { FilterSelection } from '../../../SimpleFilter';

type Props = {
  data: BuiltWidgetConfig;
  onFilterChange?: OnFilterChange;
  filtersVal?: FilterSelection[];
} & WidgetCommonProps;

const StackedColumnChart: FC<Props> = (props) => {
  return (
    <BarChart isColumn stacked={props?.data?.data?.length > 1} {...props} />
  );
};

export default StackedColumnChart;
