import { WIDGET_TYPE } from '../../../../../../gql/widget/types';
import {
  CREATE_BAR_WIDGET,
  CREATE_COLUMN_WIDGET,
  CREATE_LINE_WIDGET,
  CREATE_MAP_WIDGET,
  CREATE_PIE_WIDGET,
  CREATE_SIMPLE_STAT_WIDGET,
  CREATE_TABLE_WIDGET,
  CREATE_TEXT_WIDGET,
  UPDATE_AND_PUBLISH_BAR_WIDGET_VERSION,
  UPDATE_AND_PUBLISH_COLUMN_WIDGET_VERSION,
  UPDATE_AND_PUBLISH_LINE_WIDGET_VERSION,
  UPDATE_AND_PUBLISH_MAP_WIDGET_VERSION,
  UPDATE_AND_PUBLISH_PIE_WIDGET_VERSION,
  UPDATE_AND_PUBLISH_SIMPLE_STAT_WIDGET_VERSION,
  UPDATE_AND_PUBLISH_TABLE_WIDGET_VERSION,
  UPDATE_AND_PUBLISH_TEXT_WIDGET_VERSION,
} from '../../../../../../gql/widget/mutations';
import { useMutation } from '@apollo/client';

export const CREATE_WIDGET_FIELD_MAP: Record<any, any> = {
  [WIDGET_TYPE.LINE_CHART]: 'createLineChartWidget',
  [WIDGET_TYPE.PIE_CHART]: 'createPieChartWidget',
  [WIDGET_TYPE.TABLE]: 'createTableWidget',
  [WIDGET_TYPE.TEXT]: 'createTextWidget',
  [WIDGET_TYPE.CHOROPLETH_MAP]: 'createMapChartWidget',
  [WIDGET_TYPE.BAR_CHART]: 'createBarChartWidget',
  [WIDGET_TYPE.STACKED_COLUMN_CHART]: 'createColumnChartWidget',
  [WIDGET_TYPE.GROUPED_COLUMN_CHART]: 'createColumnChartWidget',
  [WIDGET_TYPE.SIMPLE_STAT]: 'createSimpleStatWidget',
};

export const CREATE_WIDGET_MUTATIONS = {
  [WIDGET_TYPE.LINE_CHART]: CREATE_LINE_WIDGET,
  [WIDGET_TYPE.PIE_CHART]: CREATE_PIE_WIDGET,
  [WIDGET_TYPE.TABLE]: CREATE_TABLE_WIDGET,
  [WIDGET_TYPE.STACKED_COLUMN_CHART]: CREATE_COLUMN_WIDGET,
  [WIDGET_TYPE.GROUPED_COLUMN_CHART]: CREATE_COLUMN_WIDGET,
  [WIDGET_TYPE.BAR_CHART]: CREATE_BAR_WIDGET,
  [WIDGET_TYPE.CHOROPLETH_MAP]: CREATE_MAP_WIDGET,
  [WIDGET_TYPE.SIMPLE_STAT]: CREATE_SIMPLE_STAT_WIDGET,
  [WIDGET_TYPE.TEXT]: CREATE_TEXT_WIDGET,
};

export const useCreateWidgetMutation = (widgetType: WIDGET_TYPE) => {
  return useMutation(CREATE_WIDGET_MUTATIONS[widgetType] || CREATE_LINE_WIDGET);
};

const UPDATE_AND_PUBLISH_WIDGET_VERSION_MUTATIONS = {
  [WIDGET_TYPE.LINE_CHART]: UPDATE_AND_PUBLISH_LINE_WIDGET_VERSION,
  [WIDGET_TYPE.PIE_CHART]: UPDATE_AND_PUBLISH_PIE_WIDGET_VERSION,
  [WIDGET_TYPE.TABLE]: UPDATE_AND_PUBLISH_TABLE_WIDGET_VERSION,
  [WIDGET_TYPE.STACKED_COLUMN_CHART]: UPDATE_AND_PUBLISH_COLUMN_WIDGET_VERSION,
  [WIDGET_TYPE.GROUPED_COLUMN_CHART]: UPDATE_AND_PUBLISH_COLUMN_WIDGET_VERSION,
  [WIDGET_TYPE.BAR_CHART]: UPDATE_AND_PUBLISH_BAR_WIDGET_VERSION,
  [WIDGET_TYPE.CHOROPLETH_MAP]: UPDATE_AND_PUBLISH_MAP_WIDGET_VERSION,
  [WIDGET_TYPE.SIMPLE_STAT]: UPDATE_AND_PUBLISH_SIMPLE_STAT_WIDGET_VERSION,
  [WIDGET_TYPE.TEXT]: UPDATE_AND_PUBLISH_TEXT_WIDGET_VERSION,
};

export const useUpdateAndPublishWidgetVersionMutation = (
  widgetType: WIDGET_TYPE
) => {
  return useMutation(
    UPDATE_AND_PUBLISH_WIDGET_VERSION_MUTATIONS[widgetType] ||
      UPDATE_AND_PUBLISH_LINE_WIDGET_VERSION
  );
};
