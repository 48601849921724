import { Classes } from '@blueprintjs/core';
import styled from 'styled-components';
import Btn from '../../../../components/Btn';

export const GroupsAddBtn = styled(Btn)`
  margin-top: 5px;
  margin-bottom: 15px;
`;

export const GroupRowWrapper = styled.div`
  width: 100%;
  display: flex;

  & > * {
    flex: 1;
  }
`;

export const GroupActions = styled.div`
  display: flex;
  flex: 0;
  margin-top: 3px;
`;

export const MultiInputLabel = styled.div`
  color: #7a7a7a;
  font-size: 14px;
`;

export const MultiInputListItem = styled.li`
  & > span {
    width: 100%;
  }
`;

export const MultiSelectTag = styled.div`
  padding: 5px;
`;

export const MultiSelectWrapper = styled.div<{ disabled: boolean }>`
  ${({ disabled }) =>
    disabled &&
    `
      .${Classes.INPUT}:hover {
        border: solid 1px #cacaca;
      }

      .${Classes.TAG_INPUT_VALUES} {
        cursor: not-allowed !important;
      }

      .${Classes.INPUT_GHOST} {
        cursor: not-allowed !important;
      }
  `}
`;
