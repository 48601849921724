import { Position } from '@blueprintjs/core';
import React, { FC, useMemo } from 'react';
import 'react-folder-tree/dist/style.css';
import { treeNodeColorMap } from '../../../containers/Hierarchy';
import { Page } from '../../../gql/page/types';
import { Site } from '../../../gql/site/types';
import { Structure } from '../../../gql/structure/types';
import { sortCompareByDateTime } from '../../../utils/time';
import { FolderTree } from '../../FolderTree/FolderTree/FolderTree';
import { TreeData } from '../PageUsages';
import { Widget } from '../../../gql/widget/types';

export const TooltipPosition = React.createContext('auto');

type Entity = { name: string } & (Site | Page | Structure | Widget);

type TreeEntity = {
  title: string;
  slug: string;
  entities: Array<Entity>;
};

type Props = {
  usages: Array<TreeEntity>;
};

type FolderTreeElem = { name: string; id: string; versionId: string } & Record<
  string,
  any
>;

type EntityUsage<T extends {}> =
  | {
      id: string;
      name: string;
      color: string;
      children: FolderTreeElem[];
    }
  | T;

export const resolveTreeData = <T extends { [key: string]: any }>(
  name: string,
  entityUsages: EntityUsage<T>[],
) => {
  const data: TreeData<T> = { name, openOnNameClick: true, children: [] };

  const rawTreeData = entityUsages.reduce((acc, val) => {
    if (!acc.children[val.id]) {
      acc.children[val.id] = [] as any;
    }

    acc.children[val.id]?.push(val);

    return acc;
  }, data);

  Object.entries(rawTreeData.children).forEach((el) => {
    const treeElements = Object.entries(el[1])?.map((el) => el[1]);

    data.children.push({
      children: treeElements,
      ...(treeElements[0] as T),
    });
  });

  data?.children?.forEach((el, idx, arr) => {
    arr[idx] = {
      children: el.children,
      ...el?.children[0],
    };
  });

  return data;
};

const WidgetUsages: FC<Props> = ({ usages }) => {
  const treeUsages = useMemo(
    () =>
      usages?.map((usage) => {
        return {
          ...usage,
          entities: usage.entities?.map((usage) => ({
            color: treeNodeColorMap[usage.versionType],
            style: { fontSize: '14px' },
            ...usage,
          })),
        };
      }),
    [usages],
  );

  return (
    <TooltipPosition.Provider value={Position.LEFT}>
      {treeUsages.map((usage) => {
        const entityUsages = usage.entities;

        if (!entityUsages?.length) {
          return (
            <div key={usage.slug}>
              <b>No {usage.title} usages</b>
            </div>
          );
        }

        return (
          <FolderTree
            isVersions
            key={usage.slug}
            noImports
            data={resolveTreeData(
              usage.title,
              entityUsages as EntityUsage<Entity>[],
            )}
            showCheckbox={false}
            readOnly
            initOpenStatus="closed"
            indentPixels={30}
            onNameClick={(event) => {
              window.open(
                `/${usage.slug}/${event.nodeData?.versionId}`,
                '_blank',
              );
            }}
          />
        );
      })}
    </TooltipPosition.Provider>
  );
};

export default WidgetUsages;
