import React, { FC, Fragment, useCallback, useMemo } from 'react';
import { Collapse, Icon, MenuItem } from '@blueprintjs/core';
import { useField, useFormikContext } from 'formik';
import { Column, COLUMN_TYPE, QueryProperties } from './form';
import {
  ColDescription,
  ColFields,
  ColName,
  ColNameText,
  ColPropsWrapper,
  ColSelects,
  DescAddBtn,
  DescriptionText,
  RemoveDesc,
} from './styles';
import useOpen from '../../../../utils/hooks/useOpen';
import FormSelect from '../../../../components/inputs/FormSelect';
import { MultiInputListItem } from '../../../Users/UserEdit/UserGroupsField/styles';
import FormField from '../../../../components/inputs/FormField';
import { QUERY_OPERATION } from '../../../../gql/query/types';
import { ReactComponent as Plus } from '../../../../assets/icons/plus.svg';
import { ReactComponent as EyeOpen } from '../../../../assets/icons/eye-open.svg';
import { ReactComponent as EyeClosed } from '../../../../assets/icons/eye-closed.svg';
import OldSearchableMultiInput from '../../../../components/inputs/SearchableMultiInput/OldSearchableMultiInput';
import { CONFIG_ELEMENT_VALUES_TYPE } from '../../../../gql/widget/types';
import { ToggleIcon } from '../../../../components/EditorPanels/styles';

const COLUMN_TYPE_OPTIONS = [
  {
    value: COLUMN_TYPE.MEASURE,
    title: 'Measure',
  },
  {
    value: COLUMN_TYPE.DIMENSION,
    title: 'Dimension',
  },
];

const VALUES_TYPE_OPTIONS = [
  {
    value: CONFIG_ELEMENT_VALUES_TYPE.string,
    title: 'String',
  },
  {
    value: CONFIG_ELEMENT_VALUES_TYPE.number,
    title: 'Number',
  },
  {
    value: CONFIG_ELEMENT_VALUES_TYPE.percent,
    title: 'Percent',
  },
  {
    value: CONFIG_ELEMENT_VALUES_TYPE.sqkm,
    title: 'km²',
  },
  {
    value: CONFIG_ELEMENT_VALUES_TYPE.currencyEUR,
    title: '€',
  },
  {
    value: CONFIG_ELEMENT_VALUES_TYPE.celsius,
    title: 'Celsius',
  },
  {
    value: CONFIG_ELEMENT_VALUES_TYPE.ugperm3,
    title: 'µg/m³',
  },
  {
    value: CONFIG_ELEMENT_VALUES_TYPE.date,
    title: 'Date',
  },
];

type AggregationOption = {
  value: QUERY_OPERATION;
  title: string;
};

const AGGREGATION_OPTIONS = [
  {
    value: QUERY_OPERATION.SUM,
    title: 'SUM',
  },
  {
    value: QUERY_OPERATION.AVG,
    title: 'AVG',
  },
  {
    value: QUERY_OPERATION.MIN,
    title: 'MIN',
  },
  {
    value: QUERY_OPERATION.MAX,
    title: 'MAX',
  },
];

type Props = { ind: number; onChange: () => void };

const ColumnProperties: FC<Props> = ({ ind, onChange }) => {
  const { open: openProp, handleToggle: toggleProp } = useOpen();
  const { values } = useFormikContext<QueryProperties>();
  const [, , { setValue: setFormikValue }] = useField<Column>(
    `columns[${ind}]`,
  );

  const col = values.columns[ind];

  const setValue = useCallback(
    (col: Column) => {
      setFormikValue(col);
      onChange();
    },
    [onChange, setFormikValue],
  );

  const {
    open: openDesc,
    handleOpen: handleOpenDesc,
    handleClose: handleCloseDesc,
  } = useOpen(!!col.description);

  const isMeasure = col.columnType === COLUMN_TYPE.MEASURE;

  const currentAggregations = useMemo(
    () => col.allowedOperations || [],
    [col.allowedOperations],
  );

  const onAggregationRemove = useCallback(
    (selected: AggregationOption | string) => {
      const _selected =
        typeof selected === 'string' ? selected : selected.value;

      col.allowedOperations = currentAggregations.filter(
        (agg) => agg !== _selected,
      );

      setValue(col);
    },
    [col, currentAggregations, setValue],
  );

  const renderAggregationItem = useCallback(
    (item: AggregationOption, handleClick: any) => {
      const isActive = currentAggregations.includes(item.value);

      return (
        <MultiInputListItem>
          <MenuItem
            active={isActive}
            key={item.value}
            onClick={() => {
              isActive ? onAggregationRemove(item) : handleClick();
            }}
            text={
              <div>
                {isActive ? (
                  <Fragment>
                    <Icon icon="small-tick" /> {item.title}
                  </Fragment>
                ) : (
                  item.title
                )}
              </div>
            }
          />
        </MultiInputListItem>
      );
    },
    [currentAggregations, onAggregationRemove],
  );

  return (
    <ColPropsWrapper>
      <ColName onClick={toggleProp}>
        <div className="d-flex u-width-100" style={{ flex: 1 }}>
          <ColNameText>{col?.column}</ColNameText>
          <div
            className="u-margin-left-5 d-flex align-items-center"
            onClick={(e) => {
              e.stopPropagation();

              setValue({
                ...col,
                hidden: !col.hidden,
              });
            }}
          >
            {col?.hidden ? <EyeClosed /> : <EyeOpen />}
          </div>
        </div>
        <ToggleIcon icon={openProp ? 'chevron-up' : 'chevron-down'} />
      </ColName>
      <Collapse isOpen={openProp}>
        <ColFields>
          <ColSelects>
            <FormSelect
              required
              name={`columns[${ind}].columnType`}
              options={COLUMN_TYPE_OPTIONS}
              additionalOnChange={onChange}
            />
            <FormSelect
              usePortal
              name={`columns[${ind}].type`}
              options={VALUES_TYPE_OPTIONS}
              additionalOnChange={onChange}
            />
            {isMeasure && (
              <OldSearchableMultiInput
                onItemSelect={(selected) => {
                  if (currentAggregations.includes(selected.value)) {
                    onAggregationRemove(selected);

                    return;
                  }

                  col.allowedOperations = [
                    ...currentAggregations,
                    selected.value,
                  ];

                  setValue(col);
                }}
                onRemove={(selected) => {
                  onAggregationRemove(selected);
                }}
                tagRenderer={(selected) => {
                  const item = AGGREGATION_OPTIONS.find(
                    ({ value }) => value === selected,
                  );

                  return item?.title;
                }}
                tagInputProps={{
                  className: 'u-cursor-pointer',
                  inputProps: {
                    className: 'u-caret-transparent u-cursor-pointer',
                  },
                }}
                placeholder="Aggregation"
                selectedItems={AGGREGATION_OPTIONS.filter(({ value }) =>
                  currentAggregations.includes(value),
                ).map(({ title }) => title)}
                items={AGGREGATION_OPTIONS}
                itemRenderer={(item, { handleClick }) => {
                  return renderAggregationItem(item, handleClick);
                }}
                popoverProps={{
                  minimal: true,
                  popoverClassName: 'popover-filter',
                }}
                itemsEqual={'value'}
              />
            )}
          </ColSelects>
          <FormField
            label="Column title"
            name={`columns[${ind}].name`}
            required
          />
          <ColDescription>
            <div className="d-flex align-items-center">
              <div className="u-margin-right-10 font-16">
                Column description
              </div>
              {openDesc && (
                <RemoveDesc
                  onClick={() => {
                    col.description = '';

                    setValue(col);

                    handleCloseDesc();
                  }}
                />
              )}
            </div>
            {!openDesc && (
              <DescAddBtn
                minimal
                icon={<Plus />}
                intent="none"
                onClick={handleOpenDesc}
              />
            )}
            <Collapse isOpen={openDesc}>
              <DescriptionText
                value={col.description}
                onChange={(e) => {
                  col.description = e.target.value;

                  setValue(col);
                }}
              />
            </Collapse>
          </ColDescription>
        </ColFields>
      </Collapse>
    </ColPropsWrapper>
  );
};

export default ColumnProperties;
