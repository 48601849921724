import { splitEvery } from 'ramda';
import React, { FC, Fragment, useRef } from 'react';
import SimpleFilter from '../../../SimpleFilter';
import GraphStand from '../../../GraphStand';
import {
  OnFilterChange,
  WidgetCommonProps,
} from '../../../../gql/widget/types';
import { TabItem, TabItemProps } from './TabItem';
import { BaseWidgetData } from '../simple_table';

export interface TabRowWidgetProp extends BaseWidgetData {
  columns?: number;
  data: TabItemProps[];
}

type Props = {
  widget: TabRowWidgetProp;
  onFilterChange?: OnFilterChange;
} & WidgetCommonProps;

interface RenderTabProps {
  tabs: TabItemProps[];
  itemClass?: string;
}

const RenderTabs: FC<RenderTabProps> = ({ tabs, itemClass = '' }) => (
  <div className="p-tab__inline-grid">
    {tabs.map((d: TabItemProps, i: number) => (
      <TabItem
        key={`TI-${i}`}
        content={d}
        customTabItemClassName={`${itemClass}`}
      />
    ))}
  </div>
);

const RenderTabsAsTable: FC<{
  data: TabItemProps[];
  columns: number;
}> = ({ data, columns }) => {
  return (
    <div className="p-tab__table__container">
      <table
        className="p-tab__table"
        style={{ minWidth: 'min-content' }}
        cellSpacing={0}
      >
        <tbody>
          {splitEvery(columns, data).map(
            (row: TabItemProps[], index: number) => (
              <tr key={`tab-row-${index}`}>
                {row.map((d: TabItemProps, i: number) => (
                  <td
                    style={{
                      paddingTop: index > 0 ? 40 : 0,
                    }}
                    className="u-padding-0 u-fit-content"
                    key={`RTAT-KPI-${i}`}
                  >
                    <TabItem
                      content={d}
                      customTabItemClassName="p-tab__kpi u-fit-content"
                    />
                  </td>
                ))}
              </tr>
            ),
          )}
        </tbody>
      </table>
    </div>
  );
};

const TabRow: FC<Props> = ({ widget, afterTitle }) => {
  const { data = [], header, columns, info, filtersVal, stand } = widget;
  const sliderContainerRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="widget tab_row u-fit-content">
      <SimpleFilter
        header={header}
        className="u-margin-bottom-32 u-fit-content"
        info={info}
        value={filtersVal}
        afterTitle={afterTitle}
        sliderContainerRef={sliderContainerRef}
      />
      <Fragment>
        {columns ? (
          <RenderTabsAsTable columns={columns} data={data} />
        ) : (
          <RenderTabs tabs={data} itemClass={`u-vertical-align-top`} />
        )}
      </Fragment>
      <div className="d-flex justify-content-end">
        <div style={{ paddingLeft: 23 }}>
          <GraphStand stand={stand} />
        </div>
      </div>
      <div ref={sliderContainerRef} />
    </div>
  );
};

export default TabRow;
