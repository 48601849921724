import cn from 'classnames';
import { FC, SVGProps, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckmarkSIcon } from '../../assets/icons/ui/s/checkmark.svg';
import { useKeyEvent } from '../../utils/hooks/useKeyEvent';

export type Option<V> = {
  label: string;
  key: string | number;
  value: V;
  icon?: FC<SVGProps<SVGSVGElement>>;
};

export type Props<V> = (
  | { multiple?: false; selection?: Option<V>['value'] }
  | {
      multiple: true;
      selection?: Option<V>['value'][];
      onSelectAll(values: Option<V>['value'][], selectAll: boolean): void;
    }
) & {
  items: Option<V>[];
  disabledKeys?: (string | number)[];
  onSelect(value: Option<V>['value']): void;
  handleInputFocus?: () => void;
  allowSelection?: boolean;
  showCheckmark?: boolean;
  showIcon?: boolean;
  noWrap?: boolean;
};

export default function DropdownList<V>({
  handleInputFocus,
  items,
  disabledKeys = [],
  onSelect,
  allowSelection = true,
  showCheckmark = true,
  showIcon = false,
  noWrap,
  ...props
}: Props<V>) {
  const { t } = useTranslation();

  const [currIndex, setCurrIndex] = useState(-1);

  const ref = useRef<HTMLUListElement>(null);

  const indexOffset = props.multiple && items.length > 0 ? 1 : 0;

  const isAllChecked =
    props.multiple &&
    items.length > 0 &&
    items
      .filter((item) => !disabledKeys.includes(item.key))
      .every((item) => props.selection?.includes(item.value));

  useKeyEvent(
    ['Enter', ' '],
    () => {
      if (props.multiple && items.length > 0 && currIndex === 0) {
        props.onSelectAll(
          items
            .filter((item) => !disabledKeys.includes(item.key))
            .map((item) => item.value),
          !isAllChecked,
        );
      } else {
        const item = items[currIndex - indexOffset];
        !disabledKeys.includes(item.key) && onSelect(item.value);
      }
    },
    allowSelection && currIndex !== -1,
  );

  useEffect(() => {
    const lastIndex = indexOffset + items.length - 1;
    setCurrIndex((index) => (index > lastIndex ? lastIndex : index));
    const handleArrowKeys = (event: KeyboardEvent) => {
      if (!ref.current) {
        return;
      }
      if (!items.length && handleInputFocus) {
        handleInputFocus();
      }
      switch (event.key) {
        case 'ArrowUp':
          setCurrIndex((index) => {
            if (index === -1) {
              return lastIndex;
            } else if (index === 0) {
              if (handleInputFocus) {
                event.stopPropagation();
                handleInputFocus();
                return -1;
              } else {
                return lastIndex;
              }
            } else {
              return index - 1;
            }
          });
          break;
        case 'ArrowDown':
          setCurrIndex((index) => {
            if (index === -1) {
              return 0;
            } else if (index === lastIndex) {
              if (handleInputFocus) {
                event.stopPropagation();
                handleInputFocus();
                return -1;
              } else {
                return 0;
              }
            } else {
              return index + 1;
            }
          });
          break;
      }
    };
    document.addEventListener('keydown', handleArrowKeys);
    return () => {
      document.removeEventListener('keydown', handleArrowKeys);
    };
  }, [indexOffset, items.length, handleInputFocus]);

  useEffect(() => {
    if (!ref.current || currIndex === -1) {
      return;
    }
    const element = ref.current.querySelectorAll('li')[currIndex - indexOffset];
    element?.scrollIntoView(false);
  }, [currIndex, indexOffset]);

  return (
    <div
      className={cn(
        'flex flex-col rounded-lg bg-white text-sm shadow-md focus-within:ring-4 focus-within:ring-focusBlue focus-within:ring-offset-2',
        {
          'cursor-pointer': items.length,
        },
      )}
    >
      {items.length ? (
        <>
          {props.multiple && (
            <button
              className={cn(
                'relative rounded-t-lg border-b border-concrete-jungle-6 py-3 text-start italic text-concrete-jungle-3 outline-none hover:bg-blueberry-6',
                {
                  'bg-blueberry-6': 0 === currIndex,
                },
                { 'pl-10': showCheckmark, 'px-6': !showCheckmark },
              )}
              type="button"
              onMouseDown={(event) => {
                event.preventDefault();
                props.onSelectAll(
                  items
                    .filter((item) => !disabledKeys.includes(item.key))
                    .map((item) => item.value),
                  !isAllChecked,
                );
              }}
              role="option"
              aria-selected={isAllChecked}
            >
              {showCheckmark && isAllChecked && (
                <div className="absolute left-5 top-1/2 aspect-square w-3 -translate-y-1/2">
                  <CheckmarkSIcon className="text-blueberry" />
                </div>
              )}
              {t('common.select-all')}
            </button>
          )}
          <ul
            className={cn('max-h-56 overflow-auto', {
              'rounded-b-lg': props.multiple,
              'rounded-lg': !props.multiple,
            })}
            role="listbox"
            aria-multiselectable={props.multiple}
            ref={ref}
          >
            {items.map((item, index) => {
              const Icon = item.icon;
              const isChecked =
                disabledKeys.includes(item.key) ||
                (props.multiple
                  ? props.selection?.some((value) => value === item.value)
                  : item.value === props.selection);
              return (
                <li
                  key={item.key}
                  className={cn(
                    'py-2.5',
                    {
                      'text-blueberry hover:bg-blueberry-6':
                        !disabledKeys.includes(item.key),
                      'text-concrete-jungle-3': disabledKeys.includes(item.key),
                    },
                    {
                      'whitespace-nowrap': noWrap,
                    },
                    {
                      'bg-blueberry-6': indexOffset + index === currIndex,
                    },
                    {
                      'relative pl-10': showCheckmark,
                      'relative px-6': !showCheckmark && !showIcon,
                    },
                    {
                      'flex gap-2.5 px-3': !showCheckmark && showIcon,
                    },
                  )}
                  onMouseDown={(event) => {
                    event.preventDefault();
                    !disabledKeys.includes(item.key) && onSelect(item.value);
                  }}
                  role="option"
                  aria-selected={isChecked}
                >
                  {showIcon && !showCheckmark && Icon && (
                    <Icon
                      className={cn('shrink-0', {
                        'text-concrete-jungle-3': disabledKeys.includes(
                          item.key,
                        ),
                        'text-blueberry': !disabledKeys.includes(item.key),
                      })}
                    />
                  )}
                  {showCheckmark && isChecked && (
                    <div className="absolute left-5 top-1/2 aspect-square w-3 -translate-y-1/2">
                      <CheckmarkSIcon
                        className={cn({
                          'text-concrete-jungle-3': disabledKeys.includes(
                            item.key,
                          ),
                          'text-blueberry': !disabledKeys.includes(item.key),
                        })}
                      />
                    </div>
                  )}
                  <div className="flex items-center">{item.label}</div>
                </li>
              );
            })}
          </ul>
        </>
      ) : (
        <div
          className={cn('py-3 italic text-concrete-jungle-3', {
            'pl-10': showCheckmark,
            'px-6': !showCheckmark,
          })}
        >
          {t('common.no-results')}
        </div>
      )}
    </div>
  );
}
