import { createBrowserHistory } from 'history';
import { VERSION_TYPE } from '../gql/dataSource/types';

export type HistoryState = {
  versionType?: VERSION_TYPE;
  search?: string;
  backURL?: string;
};

const history = createBrowserHistory();

export default history;
