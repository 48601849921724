import styled from 'styled-components';
import { colors } from '../../utils/colors';

export const NavBarWrapper = styled.div<{ isEdit?: boolean }>`
  height: 64px;
  width: 100%;
  background-color: ${({ isEdit }) => (isEdit ? colors.primary5 : '#fff')};
  border-bottom: 1px solid #e0e0e0;
  position: fixed;
  top: 0;
  z-index: 50;
  padding: 0 32px;
  display: flex;
  align-items: center;

  &:hover {
    .u-opacity-0 {
      opacity: 1;
    }
  }

  > *:not(:first-child) {
    margin-left: 25px;
  }

  .user-nav-buttons {
    border: 1px solid #282d61;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    flex: 0 0 32px;
    line-height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    &:hover {
      background-color: #e9ebf2;
    }

    & > .bp4-popover2-target {
      position: absolute;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    .bp4-overlay > .bp4-transition-container {
      transform: translate(calc(-100% + 32px), 40px) !important;
    }

    .bp4-menu-item {
      color: #7a7a7a;
      padding: 18px 24px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: start;
      align-items: center;

      .bp4-fill {
        flex: 0 0 auto;
        margin-right: 12px;
      }
    }
  }
`;

export const Spacer = styled.div<{ size?: number }>`
  flex: ${({ size }) => (size === undefined ? '1 0 auto' : `0 0 ${size}px`)};
`;

export const StyledTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  color: #282d61;
  display: flex;
  align-items: center;
  overflow: hidden;

  span {
    margin-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 500px;
  }

  .mx-1 {
    margin: 0 0 0 20px !important;
  }
`;

export const CustomLogo = styled.img`
  max-width: 100px;
  max-height: 40px;
`;

export const StructureTitleInput = styled.input`
  border: none;
  color: ${colors.primary0};
  margin-left: 8px;
  background-color: transparent;

  &::placeholder {
    color: ${colors.primary0};
  }
`;
