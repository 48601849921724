import React, { FC, ReactNode } from 'react';
import { Position } from '@blueprintjs/core';
import { ReactComponent as InfoPoint } from '../../assets/icons/info-point.svg';
import { Tooltip2 } from '@blueprintjs/popover2';

interface Props {
  holder?: React.JSX.Element;
  position?: Position;
  disabled?: boolean;
  popoverClassNames?: string;
  children: ReactNode;
}

export const ToolTip: FC<Props> = ({
  disabled,
  holder = <InfoPoint />,
  children,
  position = Position.BOTTOM,
  popoverClassNames = '',
}) => {
  return (
    <Tooltip2
      disabled={!!disabled}
      inheritDarkTheme={false}
      popoverClassName={`popover__tooltip ${popoverClassNames}`}
      lazy
      usePortal
      position={position}
      content={children as React.JSX.Element}
    >
      {holder}
    </Tooltip2>
  );
};

export default ToolTip;
