import { PropsWithChildren, useState } from 'react';
import type { PopoverProps, PopoverState } from 'react-tiny-popover';
import { ArrowContainer, Popover as TinyPopover } from 'react-tiny-popover';
import { useKeyEvent } from '../../utils/hooks/useKeyEvent';

export type Props = (
  | { text: string; content?: never }
  | { content: JSX.Element; text?: never }
) &
  PropsWithChildren<{
    on?: 'hover' | 'click';
    align?: PopoverProps['align'];
    isInitialOpen?: boolean;
  }>;

const PopoverText = ({
  position,
  childRect,
  popoverRect,
  text,
}: PopoverState & { text?: string }) => (
  <ArrowContainer
    position={position}
    childRect={childRect}
    popoverRect={popoverRect}
    arrowColor="#EBEBEB"
    arrowSize={10}
  >
    <div className="max-w-xs rounded-lg bg-concrete-jungle-7 p-2 font-sans text-sm text-concrete-jungle shadow-[0_2px_4px_-2px_rgba(0,0,0,0.1),0_4px_6px_-1px_rgba(0,0,0,0.1)]">
      {text}
    </div>
  </ArrowContainer>
);

export default function Popover({
  align = 'center',
  on = 'hover',
  isInitialOpen = false,
  content,
  text,
  children,
}: Props) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(isInitialOpen);
  const persist = on === 'click';

  useKeyEvent(
    'Escape',
    () => {
      setIsPopoverOpen(false);
    },
    persist,
  );

  const onEnterHandler = () => setIsPopoverOpen(true);
  const onLeaveHandler = persist ? undefined : () => setIsPopoverOpen(false);

  return (
    <TinyPopover
      align={align}
      isOpen={isPopoverOpen}
      content={content || ((props) => <PopoverText {...props} text={text} />)}
      containerStyle={{ zIndex: '9999' }}
      onClickOutside={() => setIsPopoverOpen(false)}
    >
      {on === 'hover' ? (
        <span
          onMouseEnter={onEnterHandler}
          onMouseLeave={onLeaveHandler}
          onFocusCapture={onEnterHandler}
          onBlurCapture={onLeaveHandler}
        >
          {children}
        </span>
      ) : (
        <button onClick={() => setIsPopoverOpen((state) => !state)}>
          {children}
        </button>
      )}
    </TinyPopover>
  );
}
