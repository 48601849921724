import React, {
  Fragment,
  useLayoutEffect,
  useRef,
  useState,
  PropsWithChildren,
} from 'react';
import { Icon, Menu, MenuDivider, MenuItem, Position } from '@blueprintjs/core';
import Tooltip from '../Tooltip';
import InfoTooltip from '../InfoTooltip';
import { Popover2 } from '@blueprintjs/popover2';
import { FILTER_TYPES } from '../../gql/widget/types';
import styled from 'styled-components';
import { colors } from '../../utils/colors';

export const Required = styled.span<{ disabled?: boolean }>`
  color: ${({ disabled }) =>
    disabled ? colors.monochromatic2 : colors.grapefruit};
`;

export interface Selected {
  name?: string;
  title: string;
  type?: FILTER_TYPES;
  icon?: any;
  disabled?: boolean;
  value: string | number;
  tooltipContent?: string;
}

interface Props<S> extends PropsWithChildren {
  selectedContent: S;
  selections: S[];
  onClick: ({ index, selected }: { index: number; selected: S }) => void;
  position?: (typeof Position)[keyof typeof Position];
  selectedClassName?: string;
  multiline?: boolean;
  disabled?: boolean;
  required?: boolean;
  usePortal?: boolean;
  'data-track-event'?: string;
  className?: string;
}

const PopoverLegacy = <S extends Selected>({
  selectedContent,
  selections = [],
  onClick,
  position = Position.BOTTOM_LEFT,
  selectedClassName = '',
  multiline = false,
  disabled,
  required,
  usePortal = false,
  'data-track-event': dataTrackEvent,
  className,
  children,
}: Props<S>) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedContentRef = useRef<HTMLDivElement | null>(null);
  const [showOnHover, setShowOnHover] = useState<boolean>(true);

  const [tooltipIndex, setTooltipIndex] = useState<number | null>(null);

  useLayoutEffect(() => {
    if (selectedContentRef.current) {
      const { firstElementChild } = selectedContentRef.current;
      if (
        firstElementChild &&
        firstElementChild.clientWidth < firstElementChild.scrollWidth
      ) {
        setShowOnHover(false);
      } else {
        setShowOnHover(true);
      }
    }
  }, [selectedContent]);

  function onClickMenuItem({
    index,
    selected,
  }: {
    index: number;
    selected: S;
  }) {
    if (!selected.disabled) {
      onClick({ index, selected });
    }
  }

  const menu = (
    <Menu>
      {selections.map((c, i) => {
        const title = c?.name || c?.title;

        if (c.type) {
          return <MenuDivider title={c?.title} key={title} />;
        }

        const isActive = selectedContent.value === c.value;

        const tooltipContent = c?.tooltipContent;

        return (
          <MenuItem
            multiline={multiline}
            disabled={c.disabled}
            active={isActive}
            data-testid={`popover__menu-item-${i}`}
            className={`popover__menu-item`}
            key={title}
            icon={c.icon ? <c.icon /> : null}
            onMouseEnter={() => setTooltipIndex(i)}
            onMouseLeave={() => setTooltipIndex(null)}
            text={
              <InfoTooltip
                popoverClassName="tooltip__content"
                isOpen={tooltipIndex === i}
                content={tooltipContent as string}
              >
                {isActive ? (
                  <Fragment>
                    <Icon icon="small-tick" />
                    {` ${title}`}
                  </Fragment>
                ) : (
                  title
                )}
              </InfoTooltip>
            }
            style={{ fontWeight: 'normal' }}
            onClick={() => onClickMenuItem({ index: i, selected: c })}
          />
        );
      })}
    </Menu>
  );

  return (
    <Popover2
      popoverClassName="popover"
      className={className}
      usePortal={usePortal}
      minimal
      content={menu}
      onOpening={() => setIsOpen(true)}
      onClosing={() => setIsOpen(false)}
      position={position}
      disabled={disabled}
    >
      {children || (
        <Tooltip
          disabled={showOnHover}
          holder={
            <div
              ref={selectedContentRef}
              className={`u-cursor-pointer u-justify-space-between u-align-center-y font-16 popover__selected ${selectedClassName} ${
                disabled
                  ? 'colorMonochromatic2 u-cursor-disabled'
                  : 'colorPrimary-0'
              }`}
            >
              <span>
                {selectedContent?.name || selectedContent?.title}
                {required && <Required disabled={disabled}>*</Required>}
              </span>
              <Icon
                icon={isOpen ? 'chevron-up' : 'chevron-down'}
                className="u-margin-left-5"
              />
            </div>
          }
        >
          <div className="u-padding-5" data-track-event={dataTrackEvent}>
            {selectedContent?.name || selectedContent?.title}
          </div>
        </Tooltip>
      )}
    </Popover2>
  );
};

export default PopoverLegacy;
