import styled, { css } from 'styled-components';
import { HORIZONTAL_ALIGN } from "../../gql/page/types";

const H_ALIGN = {
  [HORIZONTAL_ALIGN.LEFT]: 'flex-start',
  [HORIZONTAL_ALIGN.CENTER]: 'center',
  [HORIZONTAL_ALIGN.RIGHT]: 'flex-end',
};

export const WidgetWrapper = styled.div<{
  horizontalAlign?: HORIZONTAL_ALIGN;
  isDragging: boolean;
}>`
  ${({ horizontalAlign }) =>
    horizontalAlign
      ? css`
          display: flex;
          justify-content: ${H_ALIGN[horizontalAlign]};
        `
      : ''}
  ${({ isDragging }) =>
    isDragging
      ? css`
          background-color: white;
          border-radius: 8px;
          opacity: 0.6;
        `
      : ''}
`;
