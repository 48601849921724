import { useQuery } from '@apollo/client';
import { Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import {
  LOGIN_ERROR_PATH,
  SolutionParams,
  SolutionsRoute,
} from '../../../../app/Routes';
import { ERROR_CODES } from '../../../../app/apollo';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';
import { EDIT_PATH, NEW_ITEM_ID_URL } from '../../../../constants/entities';
import {
  EDITABLE_PAGES,
  EditablePagesData,
  EditablePagesVars,
  GET_ALL_PAGE_VERSIONS,
  GetAllPageVersionsData,
  GetAllPageVersionsVars,
} from '../../../../gql/page/queries';
import {
  GET_PERMISSION_BY_ENTITY_ID,
  GetPermissionByEntityIdData,
  GetPermissionByEntityIdVars,
} from '../../../../gql/permission/queries';
import {
  BUILD_LIVE_STRUCTURE,
  BUILD_STRUCTURE_BY_VERSION_ID,
  BuildLiveStructureData,
  BuildLiveStructureVars,
  BuildStructureByVersionIdData,
  BuildStructureByVersionIdVars,
} from '../../../../gql/structure/queries';
import { STRUCTURE_ELEMENT_TYPE } from '../../../../gql/structure/types';
import { AccessUserInherit, ENTITY_TYPES } from '../../../../gql/types';
import { getIsDA, getIsGroupEditor } from '../../../../gql/user/local';
import { usePageTitleEffect } from '../../../../utils/hooks/document';
import { usePreviewEntity } from '../../../../utils/preview';
import {
  getIsEditorPage,
  getPersistedPageVId,
  useCurrentLocalPage,
  useEditingContext,
} from '../editing';
import SidebarDND from './components/SidebarDND';
import { EditorAddSiteButton } from './styles';

export const useGetPageVIdById = () => {
  const { refetch: refetchVersions } = useQuery<
    GetAllPageVersionsData,
    GetAllPageVersionsVars
  >(GET_ALL_PAGE_VERSIONS, {
    skip: true,
  });

  return useCallback(
    async (id: string) => {
      const { data } = await refetchVersions({ id });

      return data.allPageVersions[0]?.versionId;
    },
    [refetchVersions],
  );
};

type Props = {
  structureId: string;
  disableAutoFirstPage?: boolean;
  currentPageId?: string;
};

const SidebarStructure = ({
  structureId: originalStructureId,
  disableAutoFirstPage = false,
  currentPageId,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { pageUrl } = useParams<SolutionParams>();
  const isNew = originalStructureId === NEW_ITEM_ID_URL;

  const {
    setCurrentStructure,
    setCurrentStructureAccess,
    isCustomerEditorModeEnabled,
    addPage,
    localEditorPagesStash,
    addStructureElement,
    localStructure,
    setIsFullEditable,
    isFullEditable,
    currentLockedPage,
  } = useEditingContext();

  const localPage = useCurrentLocalPage();

  const previewStructureIds = usePreviewEntity(ENTITY_TYPES.STRUCTURE);
  const isSitePreview = !!previewStructureIds;

  const structureId =
    previewStructureIds?.[originalStructureId] || originalStructureId;

  const getPageVIdById = useGetPageVIdById();

  const { data: structureData } = useQuery<
    BuildLiveStructureData,
    BuildLiveStructureVars
  >(BUILD_LIVE_STRUCTURE, {
    skip: isCustomerEditorModeEnabled || isSitePreview || !structureId || isNew,
    variables: { structureId },
    onError: (err) => {
      err.graphQLErrors.forEach(({ extensions }) => {
        if (
          extensions?.code === ERROR_CODES.ACCESS_DENIED ||
          extensions?.code === ERROR_CODES.INVALID_ID
        ) {
          history.replace(LOGIN_ERROR_PATH);
        }
      });
    },
    fetchPolicy: 'network-only',
  });

  const { data: structurePreviewData } = useQuery<
    BuildStructureByVersionIdData,
    BuildStructureByVersionIdVars
  >(BUILD_STRUCTURE_BY_VERSION_ID, {
    skip:
      isNew || (!isCustomerEditorModeEnabled && !isSitePreview) || !structureId,
    variables: { structureVersionId: structureId },
    fetchPolicy: 'cache-and-network',
    onError: (err) => {
      err.graphQLErrors.forEach(({ extensions }) => {
        if (extensions?.code === ERROR_CODES.ACCESS_DENIED) {
          history.replace(LOGIN_ERROR_PATH);
        }
      });
    },
  });

  const savedStructure =
    structurePreviewData?.buildStructureByVersionId ||
    structureData?.buildLiveStructure;

  const structure = isCustomerEditorModeEnabled
    ? localStructure
    : savedStructure;

  usePageTitleEffect(structure?.structureConfig.title ?? '');

  const { data: accessData } = useQuery<
    GetPermissionByEntityIdData<AccessUserInherit>,
    GetPermissionByEntityIdVars
  >(GET_PERMISSION_BY_ENTITY_ID, {
    variables: {
      entityId: structure?.id as string,
    },
    skip: isNew || !structure?.id,
    fetchPolicy: 'network-only',
  });

  const structureAccess = accessData?.getPermissionByEntityId?.access;

  useEffect(() => {
    if (isNew) {
      return;
    }

    setCurrentStructure(savedStructure);

    return () => setCurrentStructure(undefined);
  }, [setCurrentStructure, savedStructure, isNew]);

  useEffect(() => {
    if (isNew) {
      return;
    }

    setCurrentStructureAccess(structureAccess);

    return () => setCurrentStructureAccess(undefined);
  }, [isNew, setCurrentStructureAccess, structureAccess]);

  const getFirstPage = useCallback(async () => {
    if (!pageUrl && structure) {
      let firstPageId: string | undefined;

      structure?.structureConfig.elements.find(
        ({ type, groupElements, pageId }) => {
          if (type === STRUCTURE_ELEMENT_TYPE.PAGE) {
            firstPageId = pageId;
            return true;
          }

          if (type === STRUCTURE_ELEMENT_TYPE.GROUP) {
            firstPageId = groupElements?.find(
              ({ type }) => type === STRUCTURE_ELEMENT_TYPE.PAGE,
            )?.pageId;
            return true;
          }

          return false;
        },
      );

      const firstPageUrl =
        isCustomerEditorModeEnabled && !getIsEditorPage(firstPageId!)
          ? await getPageVIdById(firstPageId!)
          : firstPageId;

      const newPathname = `${SolutionsRoute.path}/${
        isCustomerEditorModeEnabled ? `${EDIT_PATH}/` : ''
      }${originalStructureId}/${firstPageUrl}`;

      history.replace({
        pathname: newPathname,
        search: location.search,
      });
    }
  }, [
    getPageVIdById,
    history,
    isCustomerEditorModeEnabled,
    location.search,
    originalStructureId,
    pageUrl,
    structure,
  ]);

  useEffect(() => {
    if (!disableAutoFirstPage) {
      getFirstPage();
    }
  }, [disableAutoFirstPage, getFirstPage]);

  const { data: editablePagesData } = useQuery<
    EditablePagesData,
    EditablePagesVars
  >(EDITABLE_PAGES, {
    skip:
      !isCustomerEditorModeEnabled || getIsDA() || isNew || !savedStructure?.id,
    variables: {
      editorStructureId: savedStructure?.id!,
    },
    fetchPolicy: 'cache-and-network',
  });

  const editablePages = editablePagesData?.getEditablePagesByLiveStructure;

  const editablePagesIds = useMemo(
    () =>
      (editablePages ?? []).concat(
        localEditorPagesStash.map(({ page }) => page.id!),
      ),
    [editablePages, localEditorPagesStash],
  );

  useEffect(() => {
    if (getIsGroupEditor()) {
      const isAllPagesEditable =
        savedStructure?.structureConfig.elements
          .flatMap((el) => el.groupElements || el)
          .filter(({ pageId }) => pageId).length === editablePages?.length;

      setIsFullEditable(isNew || isAllPagesEditable);
    }
  }, [
    editablePages?.length,
    isNew,
    savedStructure?.structureConfig.elements,
    setIsFullEditable,
  ]);

  const navigateToPageById = useCallback(
    async (pageId: string) => {
      history.push({
        pathname:
          !isCustomerEditorModeEnabled || getIsEditorPage(pageId)
            ? pageId
            : getPersistedPageVId(pageId) || (await getPageVIdById(pageId)),
        search: location.search,
      });
    },
    [getPageVIdById, history, isCustomerEditorModeEnabled, location.search],
  );

  const addPageAndShowConfirmation = useCallback(() => {
    const newPageId = addPage();

    navigateToPageById(newPageId);
  }, [addPage, navigateToPageById]);

  const showLabelOption = useMemo(() => {
    const hasLabel = localStructure?.structureConfig.elements.some(
      ({ type }) => type === STRUCTURE_ELEMENT_TYPE.LABEL,
    );

    return !hasLabel && (getIsDA() || (getIsGroupEditor() && isFullEditable));
  }, [isFullEditable, localStructure?.structureConfig.elements]);

  if (!structure) {
    return null;
  }

  return (
    <div className="sidebar-structure">
      <div>
        <SidebarDND
          elements={structure.structureConfig.elements}
          onPageClick={(pageId) => navigateToPageById(pageId)}
          isEditModeEnabled={isCustomerEditorModeEnabled}
          editablePagesIds={editablePagesIds}
          selectedPage={
            currentPageId ||
            (!isCustomerEditorModeEnabled || getIsEditorPage(pageUrl)
              ? pageUrl
              : localPage?.page.id! || currentLockedPage?.id!)
          }
        />
      </div>
      {isCustomerEditorModeEnabled && (
        <Popover2
          popoverClassName="popover popover-gray-menu"
          content={
            <Menu>
              {showLabelOption && (
                <MenuItem
                  text={t('structure.label')}
                  onClick={() =>
                    addStructureElement({
                      type: STRUCTURE_ELEMENT_TYPE.LABEL,
                      text: t('structure.label')!,
                      id: v4(),
                    })
                  }
                />
              )}
              <MenuItem
                text={t('structure.section')}
                onClick={() =>
                  addStructureElement({
                    type: STRUCTURE_ELEMENT_TYPE.SECTION,
                    text: t('structure.new-section')!,
                    id: v4(),
                  })
                }
              />
              <MenuItem
                text={t('entity.page')}
                onClick={addPageAndShowConfirmation}
              />
              <MenuItem
                text={t('structure.group')}
                onClick={() => {
                  addStructureElement({
                    type: STRUCTURE_ELEMENT_TYPE.GROUP,
                    title: t('structure.new-group')!,
                    groupElements: [
                      {
                        type: STRUCTURE_ELEMENT_TYPE.GROUP_END,
                        id: v4(),
                      },
                    ],
                    id: v4(),
                  });
                }}
              />
            </Menu>
          }
          minimal
          placement="bottom-end"
        >
          <EditorAddSiteButton id="add-page-btn">
            <PlusIcon />
            {t('action.create-new')}
          </EditorAddSiteButton>
        </Popover2>
      )}
    </div>
  );
};

export default SidebarStructure;
