import { TFunction } from 'i18next';
import { DragEvent, RefObject, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ReactComponent as CalendarSubtleIcon } from '../../assets/icons/subtle/standard/calendar.svg';
import { ReactComponent as NumberSubtleIcon } from '../../assets/icons/subtle/standard/number.svg';
import { ReactComponent as StringSubtleIcon } from '../../assets/icons/subtle/standard/string.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/ui/l/delete.svg';
import {
  ADD_FILTER_MODAL_KEY,
  FILTER_DRAG_DATA_TYPE,
} from '../../containers/Widgets/Widget/FilterPanel';
import { useModal } from '../../utils/hooks/modal';
import { useClickOutside } from '../../utils/hooks/useClickOutside';
import { useKeyEvent } from '../../utils/hooks/useKeyEvent';
import { PIVOT_FUNCTION } from '../../utils/pivot';
import { DraggableListItem } from '../DraggableListItem';
import { DropField } from '../DropField';
import { DropdownList, Option } from '../DropdownList';
import { IconButton } from '../IconButton';
import { InlineAlert, InlineAlertSize, InlineAlertType } from '../InlineAlert';
import { InputLabel } from '../inputs/InputLabel';
import { SearchInput } from '../inputs/SearchInput';

export const FIELD_DRAG_DATA_TYPE = 'text/x-pivot-chart-panel-field';
const ROW_FIELD_INPUT_ID = 'row-field';
const VALUE_FIELD_INPUT_ID = 'value-field';
const COLUMN_FIELD_INPUT_ID = 'column-field';

export const ALL_DATA_TYPES = ['string', 'number', 'date'] as const;
export type DataType = (typeof ALL_DATA_TYPES)[number];

type ContentType = 'dimension' | 'measure' | '';

export type Field = {
  id: string;
  name: string;
  type?: DataType;
  contentType: ContentType;
};

export const getIcon = (type?: DataType) => {
  switch (type) {
    case 'string':
      return StringSubtleIcon;
    case 'number':
      return NumberSubtleIcon;
    case 'date':
      return CalendarSubtleIcon;
    default:
      return undefined;
  }
};

const functions: Record<DataType, string[]> = {
  string: ['pivot.count', 'pivot.distinct-count'],
  number: [
    'pivot.count',
    'pivot.distinct-count',
    'pivot.sum',
    'pivot.average',
    'pivot.min',
    'pivot.max',
  ],
  date: ['pivot.count', 'pivot.distinct-count', 'pivot.min', 'pivot.max'],
};

const initialFunction: Record<DataType, string> = {
  string: 'pivot.count',
  number: 'pivot.sum',
  date: 'pivot.count',
};

const functionValues: Record<string, string> = {
  'pivot.count': PIVOT_FUNCTION.COUNT,
  'pivot.distinct-count': PIVOT_FUNCTION.DISTINCT_COUNT,
  'pivot.sum': PIVOT_FUNCTION.SUM,
  'pivot.average': PIVOT_FUNCTION.AVG,
  'pivot.min': PIVOT_FUNCTION.MIN,
  'pivot.max': PIVOT_FUNCTION.MAX,
};

const getFunctionName = (t: TFunction, field: Field, pivotFunction?: string) =>
  field.type
    ? pivotFunction
      ? // t('pivot.count')
        // t('pivot.distinct-count')
        // t('pivot.sum')
        // t('pivot.average')
        // t('pivot.min')
        // t('pivot.max')
        t(
          Object.entries(functionValues).find(
            (entry) => entry[1] === pivotFunction,
          )?.[0] ?? initialFunction[field.type],
        )
      : // t('pivot.count')
        // t('pivot.sum')
        t(initialFunction[field.type])
    : '';

const getFunctionValue = (t: TFunction, name: string) =>
  Object.entries(functionValues).find(
    (entry) =>
      // t('pivot.count')
      // t('pivot.distinct-count')
      // t('pivot.sum')
      // t('pivot.average')
      // t('pivot.min')
      // t('pivot.max')
      t(entry[0]) === name,
  )?.[1];

const ALL_TABLE_FIELDS = ['row', 'value', 'column', 'filter'] as const;
const ALL_CHART_FIELDS = ['x-axis', 'y-axis', 'group-by', 'filter'] as const;

export type TableField = (typeof ALL_TABLE_FIELDS)[number];

type Props = {
  fields?: Field[];
  table?: boolean;
  pivotFunctionFieldName: string;
  rowField?: Field;
  valueField?: Field;
  columnField?: Field;
  setRowField: (field?: Field) => void;
  setValueField: (field?: Field) => void;
  setColumnField: (field?: Field) => void;
  removeFilter: (id: string) => void;
  draggingField: TableField | null;
  setDraggingField: (field: TableField | null) => void;
  setFilterFieldToAdd: (field: Field | undefined) => void;
  openedFieldInput: string | undefined;
  setOpenedFieldInput: (fieldInput: string | undefined) => void;
};

const PivotChartPanel = ({
  fields = [],
  table,
  pivotFunctionFieldName,
  rowField,
  valueField,
  columnField,
  setRowField,
  setValueField,
  setColumnField,
  removeFilter,
  draggingField,
  setDraggingField,
  setFilterFieldToAdd,
  openedFieldInput,
  setOpenedFieldInput,
}: Props) => {
  const { t } = useTranslation();
  const rowRef = useRef<HTMLDivElement>(null);
  const valueRef = useRef<HTMLDivElement>(null);
  const columnRef = useRef<HTMLDivElement>(null);
  const [fieldRect, setFieldRect] = useState<DOMRect>();
  const [focusedFieldId, setFocusedFieldId] = useState<string>();
  const [showFieldOptions, setShowFieldOptions] = useState(false);
  const [isOnKeyDown, setIsOnKeyDown] = useState(false);

  const { isModalOpen, openModal, closeModal } = useModal();

  const { elementRef } = useClickOutside<HTMLDivElement>(
    () => setShowFieldOptions(false),
    showFieldOptions,
  );

  useKeyEvent(
    'Escape',
    () => {
      setShowFieldOptions(false);
      setOpenedFieldInput(undefined);
    },
    showFieldOptions || !!openedFieldInput,
  );

  useKeyEvent(
    'Enter',
    () => setShowFieldOptions(true),
    !!focusedFieldId && !showFieldOptions,
  );

  useKeyEvent('Tab', () => {
    setIsOnKeyDown(true);
    setTimeout(setIsOnKeyDown, 0, false);
  });

  const fieldOptions = useMemo(
    () =>
      ALL_TABLE_FIELDS.map((value, index) => {
        const key = `pivot.${table ? value : ALL_CHART_FIELDS[index]}-inline`;
        // t('pivot.row-inline')
        // t('pivot.value-inline')
        // t('pivot.column-inline')
        // t('pivot.x-axis-inline')
        // t('pivot.y-axis-inline')
        // t('pivot.group-by-inline')
        // t('pivot.filter-inline')
        return {
          label: t('pivot.add-field', { field: t(key) }),
          key,
          value,
        };
      }),
    [table, t],
  );

  const fieldItems: Option<Field>[] = useMemo(
    () =>
      fields.map((field) => ({
        label: field.name,
        key: field.id,
        value: field,
        icon: getIcon(field.type),
      })),
    [fields],
  );

  const resetDraggingField = (e: DragEvent<HTMLDivElement>) => {
    if (!(navigator.platform.match('Mac') ? e.altKey : e.ctrlKey)) {
      switch (draggingField) {
        case 'row':
          setRowField(undefined);
          break;
        case 'value':
          setValueField(undefined);
          break;
        case 'column':
          setColumnField(undefined);
          break;
        case 'filter':
          const filterId = e.dataTransfer.getData(FILTER_DRAG_DATA_TYPE);
          removeFilter(filterId);
          break;
      }
    }
  };

  const onDropField = (
    e: DragEvent<HTMLDivElement>,
    setField: (field?: Field) => void,
  ) => {
    resetDraggingField(e);

    const fieldId = e.dataTransfer.getData(FIELD_DRAG_DATA_TYPE);
    setField(fields.find(({ id }) => id === fieldId));
  };

  const handleClickDropField =
    (id: string, ref: RefObject<HTMLDivElement>) => () => {
      setOpenedFieldInput(id);

      const clickOutsideHandler = (event: MouseEvent | TouchEvent) => {
        if (!ref.current?.contains(event.target as Node)) {
          setOpenedFieldInput(undefined);
        }
      };

      document.addEventListener('click', clickOutsideHandler, { once: true });
      document.addEventListener('touchstart', clickOutsideHandler, {
        once: true,
      });
    };

  return (
    <div className="flex min-h-0 flex-col gap-2">
      <SearchInput items={fields} getItemString={(field) => field.name}>
        {(filteredFields) => (
          <div
            ref={elementRef}
            className="mx-[-0.5rem] min-h-[198px] overflow-y-auto"
            onKeyDown={(e) =>
              showFieldOptions &&
              (e.code === 'ArrowUp' || e.code === 'ArrowDown') &&
              e.preventDefault()
            }
          >
            <div className="flex flex-col gap-2 p-2">
              {filteredFields?.length ? (
                filteredFields.map((field) => (
                  <DraggableListItem
                    key={field.id}
                    state={'default'}
                    icon={getIcon(field.type)}
                    title={field.name}
                    badge={
                      field.contentType === 'measure'
                        ? t('pivot.measure')
                        : field.contentType === 'dimension'
                        ? t('pivot.dimension')
                        : ''
                    }
                    transform3D
                    onDragStart={(e) =>
                      e.dataTransfer.setData(FIELD_DRAG_DATA_TYPE, field.id)
                    }
                    onFocus={(e) => {
                      setFieldRect(e.currentTarget.getBoundingClientRect());
                      setFocusedFieldId(field.id);
                      setShowFieldOptions(isOnKeyDown);
                    }}
                    onBlur={() => {
                      setFieldRect(undefined);
                      setFocusedFieldId(undefined);
                      setShowFieldOptions(false);
                    }}
                  />
                ))
              ) : (
                <div className="p-2">
                  <InlineAlert
                    title={t('common.search-no-match-title')}
                    description={t('common.search-no-match-description')}
                    type={InlineAlertType.INFO}
                    size={InlineAlertSize.MEDIUM}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </SearchInput>
      {showFieldOptions && fieldRect && (
        <div
          className="absolute z-20"
          style={{
            right: window.innerWidth - fieldRect.right,
            top: fieldRect.top,
          }}
        >
          <DropdownList<TableField | null>
            items={fieldOptions}
            selection={null}
            onSelect={(option) => {
              if (!focusedFieldId) {
                return;
              }

              const field = fields.find(({ id }) => id === focusedFieldId);

              switch (option) {
                case 'row':
                  setRowField(field);
                  break;
                case 'value':
                  setValueField(field);
                  break;
                case 'column':
                  setColumnField(field);
                  break;
                case 'filter':
                  setFilterFieldToAdd(field);
                  openModal(ADD_FILTER_MODAL_KEY);
                  break;
              }
            }}
            multiple={false}
            showCheckmark={false}
          />
        </div>
      )}
      <div ref={rowRef}>
        <InputLabel
          htmlFor={ROW_FIELD_INPUT_ID}
          label={table ? t('pivot.rows') : t('pivot.x-axis')}
          required
        >
          {rowField ? (
            <DraggableListItem
              state={rowField.contentType === 'measure' ? 'warning' : 'default'}
              icon={getIcon(rowField.type)}
              title={rowField.name}
              badge={
                rowField.contentType === 'measure'
                  ? t('pivot.measure')
                  : rowField.contentType === 'dimension'
                  ? t('pivot.dimension')
                  : ''
              }
              buttons={() => [
                <IconButton
                  key="delete"
                  ariaLabel={t('common.delete')}
                  icon={DeleteIcon}
                  onClick={() => setRowField(undefined)}
                />,
              ]}
              messageTitle={
                rowField.contentType === 'measure'
                  ? t('pivot.rows-warning', {
                      label: table
                        ? t('pivot.rows-inline')
                        : t('pivot.x-axis-inline'),
                    })
                  : undefined
              }
              message={
                rowField.contentType === 'measure' ? (
                  <span className="font-sans text-sm">
                    <Trans
                      i18nKey="pivot.rows-warning-formatted"
                      values={{
                        label: table
                          ? t('pivot.rows-inline')
                          : t('pivot.x-axis-inline'),
                      }}
                    />
                  </span>
                ) : undefined
              }
              onDragStart={(e) => {
                e.dataTransfer.setData(FIELD_DRAG_DATA_TYPE, rowField.id);
                setDraggingField('row');
              }}
              onDragEnd={() => setDraggingField(null)}
              onDrop={(e) => onDropField(e, setRowField)}
              droppable
            />
          ) : (
            <DropField
              size="small"
              title={t('pivot.drop.title')}
              onDrop={(e) => onDropField(e, setRowField)}
              onClick={handleClickDropField(ROW_FIELD_INPUT_ID, rowRef)}
              content={
                openedFieldInput === ROW_FIELD_INPUT_ID && (
                  <div
                    className="absolute z-20 w-full"
                    style={{ left: 0, bottom: 'calc(100% + 2px)' }}
                  >
                    <DropdownList
                      onSelect={(field) => {
                        setRowField(field);
                        setOpenedFieldInput(undefined);
                      }}
                      items={fieldItems}
                      multiple={false}
                      showCheckmark={false}
                      showIcon
                    />
                  </div>
                )
              }
            />
          )}
        </InputLabel>
      </div>
      <div ref={valueRef}>
        <InputLabel
          htmlFor={VALUE_FIELD_INPUT_ID}
          label={table ? t('pivot.values') : t('pivot.y-axis')}
          required
        >
          {valueField ? (
            <DraggableListItem
              state={
                valueField.contentType === 'dimension' ? 'warning' : 'default'
              }
              icon={getIcon(valueField.type)}
              title={valueField.name}
              badge={
                valueField.contentType === 'measure'
                  ? t('pivot.measure')
                  : valueField.contentType === 'dimension'
                  ? t('pivot.dimension')
                  : ''
              }
              buttons={() => [
                <IconButton
                  key="delete"
                  ariaLabel={t('common.delete')}
                  icon={DeleteIcon}
                  onClick={() => setValueField(undefined)}
                />,
              ]}
              dropdownFieldName={pivotFunctionFieldName}
              dropdownTitle={t('pivot.aggregate-title')}
              dropdownOptions={
                // t('pivot.count')
                // t('pivot.distinct-count')
                // t('pivot.sum')
                // t('pivot.average')
                // t('pivot.min')
                // t('pivot.max')
                functions[valueField.type ?? 'number'].map((func) => ({
                  key: func,
                  label: t(func),
                  value: functionValues[func],
                }))
              }
              messageTitle={
                valueField.contentType === 'dimension'
                  ? t('pivot.values-warning', {
                      label: table
                        ? t('pivot.values-inline')
                        : t('pivot.y-axis-inline'),
                    })
                  : undefined
              }
              message={
                valueField.contentType === 'dimension' ? (
                  <span className="font-sans text-sm">
                    <Trans
                      i18nKey="pivot.values-warning-formatted"
                      values={{
                        label: table
                          ? t('pivot.values-inline')
                          : t('pivot.y-axis-inline'),
                      }}
                    />
                  </span>
                ) : undefined
              }
              onDragStart={(e) => {
                e.dataTransfer.setData(FIELD_DRAG_DATA_TYPE, valueField.id);
                setDraggingField('value');
              }}
              onDragEnd={() => setDraggingField(null)}
              onDrop={(e) => onDropField(e, setValueField)}
              droppable
            />
          ) : (
            <DropField
              size="small"
              title={t('pivot.drop.title')}
              onDrop={(e) => onDropField(e, setValueField)}
              onClick={handleClickDropField(VALUE_FIELD_INPUT_ID, valueRef)}
              content={
                openedFieldInput === VALUE_FIELD_INPUT_ID && (
                  <div
                    className="absolute z-20 w-full"
                    style={{ left: 0, bottom: 'calc(100% + 2px)' }}
                  >
                    <DropdownList
                      onSelect={(field) => {
                        setValueField(field);
                        setOpenedFieldInput(undefined);
                      }}
                      items={fieldItems}
                      multiple={false}
                      showCheckmark={false}
                      showIcon
                    />
                  </div>
                )
              }
            />
          )}
        </InputLabel>
      </div>
      <div ref={columnRef}>
        <InputLabel
          htmlFor={COLUMN_FIELD_INPUT_ID}
          label={table ? t('pivot.columns') : t('pivot.group-by')}
        >
          {columnField ? (
            <DraggableListItem
              state="default"
              icon={getIcon(columnField.type)}
              title={columnField.name}
              badge={
                columnField.contentType === 'measure'
                  ? t('pivot.measure')
                  : columnField.contentType === 'dimension'
                  ? t('pivot.dimension')
                  : ''
              }
              buttons={() => [
                <IconButton
                  key="delete"
                  ariaLabel={t('common.delete')}
                  icon={DeleteIcon}
                  onClick={() => setColumnField(undefined)}
                />,
              ]}
              onDragStart={(e) => {
                e.dataTransfer.setData(FIELD_DRAG_DATA_TYPE, columnField.id);
                setDraggingField('column');
              }}
              onDragEnd={() => setDraggingField(null)}
              onDrop={(e) => onDropField(e, setColumnField)}
              droppable
            />
          ) : (
            <DropField
              size="small"
              title={t('pivot.drop.title')}
              onDrop={(e) => onDropField(e, setColumnField)}
              onClick={handleClickDropField(COLUMN_FIELD_INPUT_ID, columnRef)}
              content={
                openedFieldInput === COLUMN_FIELD_INPUT_ID && (
                  <div
                    className="absolute z-20 w-full"
                    style={{ left: 0, bottom: 'calc(100% + 2px)' }}
                  >
                    <DropdownList
                      onSelect={(field) => {
                        setColumnField(field);
                        setOpenedFieldInput(undefined);
                      }}
                      items={fieldItems}
                      multiple={false}
                      showCheckmark={false}
                      showIcon
                    />
                  </div>
                )
              }
            />
          )}
        </InputLabel>
      </div>
    </div>
  );
};

export default PivotChartPanel;
