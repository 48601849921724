import React from "react";

export default function WidgetLoader({ className = "" }: { className?: string }) {
  return (
    <div className={`u-margin-bottom-60 ${className}`}>
      <div className="u-align-center-y u-justify-space-between borderBottomMono5 u-padding-bottom-10">
        <div className="widget-loading" style={{ height: 26, width: 300 }} />
        <div className="widget-loading" style={{ height: 18, width: 120 }} />
      </div>
      <div className="widget-loading u-margin-top-40" style={{ height: 300 }} />
    </div>
  )
}
