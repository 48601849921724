import styled from 'styled-components';
import { colors } from '../../utils/colors';

export const TagWrap = styled.span<{ color?: string; lg?: boolean }>`
  background-color: ${({ color }) => color ?? colors.primary1}30;
  border-radius: 2px;
  padding: ${() =>
    ({ lg }) =>
      lg ? '4.5px 12px' : '3px 10px'};
  color: ${({ color }) => color ?? colors.primary1};
  width: fit-content;
  height: fit-content;
  cursor: default;
`;

export const CloseIcon = styled.span`
  margin-left: 10px;
  cursor: pointer;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 17px;

  &:hover {
    background-color: ${colors.secondary0};
    color: white;
  }
`;
