import { useQuery } from '@apollo/client';
import { EXPORT_LIVE_STRUCTURE } from '../../gql/structure/queries';

export const useExportStructure = () => {
  const { refetch: exportLiveStructure } = useQuery(EXPORT_LIVE_STRUCTURE, {
    skip: true,
  });

  return async (structureId: string) => {
    const response = await exportLiveStructure({
      structureId,
    });

    const { fileName, file } = response?.data?.exportLiveStructure;

    const byteCharacters = atob(file);

    const charactersArray = Array.from({
      length: byteCharacters.length,
    });

    const byteNumbers = charactersArray.reduce(
      (byteNumbers: any[], _, index) => {
        byteNumbers[index] = byteCharacters.charCodeAt(index);

        return byteNumbers;
      },
      [],
    );

    const byteArray = new Uint8Array(byteNumbers);

    const url = window.URL.createObjectURL(
      new Blob([byteArray], { contentType: 'application/json' } as any),
    );

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  };
};
