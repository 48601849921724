export const CELL_TYPES = {
  TEXT: 'text' as 'text',
};

export const ROW_TYPES = {
  SUMMARY: 'summary' as 'summary',
};

export const BACKGROUND_COLOR_TAG = {
  blue: 'bg-tag__primary' as 'blue',
  primary: 'bg-tag__primary-1' as 'primary',
  green: 'bg-tag__more' as 'green',
  red: 'bg-tag__less' as 'red',
  grey: 'bg-tag__none' as 'grey',
};

export const CARET = {
  up: 'caret-up',
  down: 'caret-down',
};
