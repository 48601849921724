import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Metadata } from '../../gql/common/types';
import { useHistory } from 'react-router-dom';
import { HistoryState } from '../history';
import { useDebouncedCallback } from 'use-debounce';
import { NEW_ITEM_ID_URL } from '../../constants/entities';
import { DeletingVersionData } from '../../components/DeleteVersionModal';

const SHORTCUTS_KEY_CODES = {
  P: 'KeyP',
  I: 'KeyI',
  O: 'KeyO',
};

export const useShortcuts = (publish: () => void, metadata?: Metadata) => {
  const shortcutsActions = useMemo(
    () => ({
      [SHORTCUTS_KEY_CODES.P]: () => {
        publish();
      },
      [SHORTCUTS_KEY_CODES.I]: () => {
        const versionId = metadata?.versionId;
        navigator.clipboard.writeText(versionId || '');
      },
      [SHORTCUTS_KEY_CODES.O]: () => {
        const id = metadata?.id;
        navigator.clipboard.writeText(id || '');
      },
    }),
    [metadata?.id, metadata?.versionId, publish],
  );

  const shortcutsListener = useCallback(
    (e: KeyboardEvent) => {
      const isAltCtrl = e.altKey && e.ctrlKey;

      if (Object.values(SHORTCUTS_KEY_CODES).includes(e.code) && isAltCtrl) {
        e.preventDefault();

        shortcutsActions[e.code]?.();
      }
    },
    [shortcutsActions],
  );

  useEffect(() => {
    document.addEventListener('keydown', shortcutsListener);

    return () => {
      document.removeEventListener('keydown', shortcutsListener);
    };
  }, [shortcutsListener]);
};

export const useSearchEntityUtils = () => {
  const history = useHistory<HistoryState>();
  const historyState = history.location.state;
  const initialSearch = historyState?.search || '';

  const [search, setSearch] = useState(initialSearch);
  const [searchInput, setSearchInput] = useState(initialSearch);

  const debouncedSearch = useDebouncedCallback((input: string) => {
    setSearch(input);
  }, 800);

  const onSearch = useCallback(
    (val: string) => {
      setSearchInput(val);
      debouncedSearch(val);
    },
    [debouncedSearch],
  );

  const goToVersion = useCallback(
    (id?: string, pathname?: string) => {
      if (pathname) {
        history.push(`/${pathname}/${id || NEW_ITEM_ID_URL}`);

        return;
      }

      history.replace(history.location.pathname, {
        ...historyState,
        search,
      });

      history.push(
        `${history.location.pathname.replace(/\/$/g, '')}/${
          id || NEW_ITEM_ID_URL
        }`,
        {
          backURL: history.location.pathname,
        },
      );
    },
    [history, historyState, search],
  );

  return { searchInput, onSearch, goToVersion, search };
};

export const useBulkDeleteUtils = (
  entities: (Partial<Metadata> & Record<string, any>)[] | undefined,
  configPath: string,
) => {
  const [bulkDeletingData, setBulkDeletingData] = useState<
    DeletingVersionData[]
  >([]);

  const onSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, item: DeletingVersionData) => {
      const checked = event.target.checked;

      setBulkDeletingData((prev) => {
        if (checked) {
          return [...prev, item];
        }

        return bulkDeletingData.filter((_item) => _item?.id !== item?.id);
      });
    },
    [bulkDeletingData],
  );

  const onGlobalSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;

      if (!checked) {
        setBulkDeletingData([]);

        return;
      }

      setBulkDeletingData(
        entities?.map((item) => ({
          id: item.versionId || item?._id,
          type: item.versionType,
          title: item?.[configPath]?.name,
        })) || [],
      );
    },
    [configPath, entities],
  );

  return {
    bulkDeletingData,
    setBulkDeletingData,
    onSelect,
    onGlobalSelect,
  };
};

export type EntityListProps = {
  goToVersion: (id?: string) => void;
  next?: () => void;
  bulkDeletingData: DeletingVersionData[];
  onSelect: any;
  setDeletingData: Dispatch<SetStateAction<DeletingVersionData[]>>;
};
