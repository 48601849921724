import styled from 'styled-components';
import { colors } from '../../utils/colors';
import ModalLegacy from '../Modal/ModalLegacy';
import Switch from '../Switch';

export const StyledEditorSwitch = styled(Switch)`
  margin-bottom: 0;

  &::after {
    content: 'Visual editor';
    color: ${colors.primary0};
  }
`;

export const StyledPivotModeSwitch = styled(Switch)`
  margin-bottom: 0;

  &::after {
    content: 'Pivot mode';
    color: ${colors.primary0};
  }
`;

export const StyledModalLegacy = styled(ModalLegacy)`
  button > span {
    white-space: nowrap;
  }
`;
