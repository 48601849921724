import React, { FC } from 'react';
import { SwitchProps } from '@blueprintjs/core';
import { CustomizedSwitch } from './styles';

type Props = SwitchProps;

const Switch: FC<Props> = (props) => {
  return <CustomizedSwitch {...props} />;
};

export default Switch;
