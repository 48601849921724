import { Access, AccessUserInherit } from '../types';
import {
  CONFIG_ELEMENT_VALUES_TYPE,
  DefaultWidgetFilter,
  FILTER_TYPES,
  FilterSection,
} from '../widget/types';
import { Metadata } from '../common/types';

export type LayoutElement = {
  widgetId?: string;
  type: 'widget' | 'text' | 'image';
  text?: string;
};

export type Column = {
  colspan?: number;
  rowspan?: number;
  verticalAlign?: VERTICAL_ALIGN;
  horizontalAlign?: HORIZONTAL_ALIGN;
  elements: LayoutElement[];
};

export enum LAYOUT_TYPE {
  ONE_COL = '1col',
  TWO_COL = '2col',
  THREE_COL = '3col',
  FOUR_COL = '4col',
  FIVE_COL = '5col',
  SIX_COL = '6col',
  SEVEN_COL = '7col',
  EIGHT_COL = '8col',
  NINE_COL = '9col',
  TEN_COL = '10col',
}

export const LAYOUT_TYPE_VALS = Object.values(LAYOUT_TYPE);

export enum VERTICAL_ALIGN {
  TOP = 'top',
  MIDDLE = 'middle',
  BOTTOM = 'bottom',
}

export enum HORIZONTAL_ALIGN {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export type Layout = {
  type: LAYOUT_TYPE;
  separator?: boolean;
  columns: Column[];
  // FE only
  id?: string;
};

export type PageFilterDependency = {
  id: string;
};

export type PageFilter = {
  id: string;
  title: string;
  type: FILTER_TYPES;
  valuesType: CONFIG_ELEMENT_VALUES_TYPE;
  column: string;
  labels: string;
  totalAlgo: string;
  totalLabel: string;
  showTotal: boolean;
  queryId?: string;
  query?: string;
  defaultSelectedFilter: string;
  dependencies: PageFilterDependency[];
  tiltLabels?: boolean;
  info?: string;
};

export type PageConfig = {
  title: string;
  name: string;
  info: string;
  subTitle: string;
  pageFilters: PageFilter[];
  layout: Layout[];
  filterSections?: FilterSection[];
  defaultFilters?: DefaultWidgetFilter[];
  allowCustomerEdits?: boolean;
  creatorFullName?: string;
};

export type PageAccess = Access & { users: AccessUserInherit[] };

export type Page = Metadata & {
  pageConfig: PageConfig;
  // todo remove
  access?: PageAccess;
};

export type AppliedPageFilter = {
  filterId: string;
  value?: string;
  multiValue?: string[];
};

export type BuildPageByVersionIdInput = {
  structureId: string;
  pageVersionId: string;
  appliedFilters?: AppliedPageFilter[];
};

export type BuildPageInput = {
  structureId: string;
  pageId: string;
  appliedFilters?: AppliedPageFilter[];
};

export type BuiltPageFilter = Omit<
  PageFilter,
  'column' | 'query' | 'labels'
> & {
  labels: string[];
  values: string[];
};

export type BuiltPageConfig = Omit<PageConfig, 'pageFilters'> & {
  pageFilters: BuiltPageFilter[];
  query?: string;
  defaultFilters?: DefaultWidgetFilter[];
  filterSections?: FilterSection[];
  creatorFullName?: string;
  createdBy?: string;
  versionId: string;
  id: string;
};

export type BuiltPage = Metadata & {
  pageConfig: BuiltPageConfig;
};
