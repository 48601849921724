import { gql } from '@apollo/client';
import { EditorMode } from '../common/types';
import { queryFragment } from './fragments';
import { Query, QueryConfig } from './types';

export type CreateQueryData = { createQuery: Query };

export type CreateQueryVars = {
  CreateQueryInput: { queryConfig: QueryConfig; datasourceId?: string };
};

export const CREATE_QUERY = gql`
  mutation CreateQuery($CreateQueryInput: CreateQueryInput!) {
    createQuery(CreateQueryInput: $CreateQueryInput) {
      ...Query
    }
  }
  ${queryFragment}
`;

export type CreateQueryVersionData = {
  createQueryVersion: Query;
};

export type CreateQueryVersionVars = {
  CreateQueryInput: {
    queryConfig: QueryConfig;
    datasourceId?: string;
    editorMode: EditorMode;
  };
  id: string;
};

export const CREATE_QUERY_VERSION = gql`
  mutation CreateQueryVersion(
    $CreateQueryInput: CreateQueryInput!
    $id: String!
  ) {
    createQueryVersion(CreateQueryInput: $CreateQueryInput, id: $id) {
      ...Query
    }
  }
  ${queryFragment}
`;

export type UpdateQueryVersionData = {
  updateQueryVersion: Query;
};

export type UpdateQueryVersionVars = {
  UpdateQueryVersionInput: {
    versionId: string;
    queryConfig: QueryConfig;
    editorMode: EditorMode;
    dataSourceId?: string;
  };
};

export const UPDATE_QUERY_VERSION = gql`
  mutation UpdateQueryVersion(
    $UpdateQueryVersionInput: UpdateQueryVersionInput!
  ) {
    updateQueryVersion(UpdateQueryVersionInput: $UpdateQueryVersionInput) {
      ...Query
    }
  }
  ${queryFragment}
`;

export type UpdateAndPublishQueryVersionData = {
  updateAndPublishQueryVersion: Query;
};

export type UpdateAndPublishQueryVersionVars = {
  UpdateAndPublishQueryVersion: {
    versionId: string;
    queryConfig: QueryConfig;
    editorMode: EditorMode;
  };
};

export const UPDATE_AND_PUBLISH_QUERY_VERSION = gql`
  mutation UpdateAndPublishQueryVersion(
    $UpdateAndPublishQueryVersion: UpdateAndPublishQueryVersionInput!
  ) {
    updateAndPublishQueryVersion(
      UpdateAndPublishQueryVersion: $UpdateAndPublishQueryVersion
    ) {
      ...Query
    }
  }
  ${queryFragment}
`;

export type DeleteQueryVersionData = {
  deleteQueryVersion: Query[];
};

export type DeleteQueryVersionVars = {
  DeleteQueryVersionInput: {
    versionId: string;
  }[];
};

export const DELETE_QUERY_VERSION = gql`
  mutation DeleteQueryVersion(
    $DeleteQueryVersionInput: [DeleteQueryVersionInput!]!
  ) {
    deleteQueryVersions(DeleteQueryVersionInput: $DeleteQueryVersionInput) {
      ...Query
    }
  }
  ${queryFragment}
`;

export type UpdateQueryDataSourceData = { updateQueryDataSource: Query };

export type UpdateQueryDataSourceVars = {
  datasourceId: string;
  versionId: string;
};

export const UPDATE_QUERY_DATASOURCE = gql`
  mutation UpdateQueryDataSource($datasourceId: String!, $versionId: String!) {
    updateQueryDataSource(datasourceId: $datasourceId, versionId: $versionId) {
      ...Query
    }
  }
  ${queryFragment}
`;

export const UPDATE_QUERY_VISIBILITY = gql`
  mutation UpdateQueryConfigIsHidden(
    $versionId: String!
    $versionType: String!
    $isHidden: Boolean!
  ) {
    updateQueryConfigIsHidden(
      versionId: $versionId
      versionType: $versionType
      isHidden: $isHidden
    ) {
      ...Query
    }
  }
  ${queryFragment}
`;
