import { Classes, HTMLTable } from '@blueprintjs/core';
import styled, { css } from 'styled-components';
import { colors } from '../../utils/colors';

export const StyledEntityTable = styled(HTMLTable)<{
  autoColWidth?: boolean;
  textFirst?: boolean;
}>`
  width: 100%;

  &&& th {
    color: ${colors.monochromatic2};
    font-weight: normal;
    vertical-align: middle;

    ${({ autoColWidth, textFirst }) =>
      !autoColWidth
        ? textFirst
          ? css`
              &:first-child {
                width: 60%;
              }
            `
          : css`
              &:first-child {
                width: 3%;
              }

              &:nth-child(2) {
                width: 47%;
              }

              &:last-child {
                width: 15%;
              }
            `
        : ''}
  }

  &.${Classes.HTML_TABLE}.${Classes.INTERACTIVE} tbody tr:hover td {
    background-color: ${colors.monochromatic7};
  }

  &.simple-list {
    padding-left: 35px;
  }

  &.simple-list tbody {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  &.simple-list tbody tr td {
    font-size: 16px;
  }

  &&&.simple-list tbody tr:first-child td {
    box-shadow: unset;
  }

  &&&.simple-list tbody tr:hover td {
    background-color: unset;
    color: ${colors.blue2};
  }
`;

export const CenterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
`;

export const Row = styled.tr<{ verticalAlign?: string }>`
  &&& > td {
    vertical-align: ${({ verticalAlign }) => verticalAlign || 'middle'};
  }

  &&&:first-child > td {
    box-shadow: inset 0 1px 0 0 ${colors.monochromatic5};
  }
`;

export const ActionsTD = styled.td`
  & > * {
    margin-right: 5px;
  }
`;
