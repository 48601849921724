import React from 'react';

export const iconContainerClassName = (className) =>
  `iconContainer ${className}`;

export const iconClassName = (className) => `icon ${className}`;

export const getDefaultIcon = (Icon) =>
  function adapter({ className, onClick }) {
    return <Icon className={className} onClick={onClick} />;
  };
