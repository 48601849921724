import styled from 'styled-components';
import { Input } from '../TextInput/styles';
import { Icon } from '@blueprintjs/core';

export const Search = styled(Input)`
  height: 32px;
  width: 244px;
  padding-top: 0;
`;

export const SearchWrapper = styled.div`
  position: relative;
`;

export const ClearIcon = styled(Icon)`
  cursor: pointer;
`;
