import styled from 'styled-components';
import { colors } from "../../../../../utils/colors";

export const SeparatorWrapper = styled.div<{ isPage?: boolean }>`
  position: relative;
  margin-bottom: ${({ isPage }) => (isPage ? '0' : '16px')};

  & * {
    outline: none !important;
  }

  & > div {
    margin-bottom: 0 !important;
  }

  & > .middle {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
  }

  & .add-icon {
    transition: all 0.2s ease-in-out;
    width: 24px;
    height: 24px;
    cursor: pointer;
    color: ${colors.monochromatic4};

    transform: translateY(calc(-50% - 0.5px));

    &:hover {
      color: ${colors.primary2};
    }
  }

  .add-icon {
    background-color: white;
  }
`;
