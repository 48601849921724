import styled from 'styled-components';
import { colors } from '../../utils/colors';

export const PAGE_HEADER_MIN_HEIGHT = 54;
export const PAGE_HEADER_MB = 20;
export const PAGE_HEADER_FULL_HEIGHT = PAGE_HEADER_MIN_HEIGHT + PAGE_HEADER_MB;

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${PAGE_HEADER_MIN_HEIGHT}px;
  margin-bottom: ${PAGE_HEADER_MB}px;
  box-shadow: inset 0 -1px 0 0 ${colors.monochromatic5};
`;

export const TopBarTitle = styled.h2`
  font-size: 26px;
  font-weight: 500;
  margin: 0 0 6px;
`;

export const TopBarActions = styled.div`
  display: flex;
  margin-top: 8px;
  margin-bottom: 14px;
  margin-left: auto;

  & > * {
    margin-left: 20px;
  }
`;

export const MdText = styled.span`
  font-size: 18px;
`;
