import { gql } from '@apollo/client';

export const permissionUserFragment = gql`
  fragment PermissionUser on PermissionUser {
    groupId
    userId
    customerRoles
    inherit
  }
`;

export const permissionFragment = gql`
  fragment Permission on Permission {
    id
    entityType
    entityId
    access {
      public
      included
      users {
        ...PermissionUser
      }
    }
  }
  ${permissionUserFragment}
`;
