import { gql } from '@apollo/client';
import {
  CreateUserInput,
  Credentials,
  ResetPasswordInput,
  UpdateUserInput,
  User,
  InviteMultipleUsersInput,
  UsersGroup,
} from './types';
import { CustomerRole } from '../types';
import { userFragment, userGroupFragment } from './fragments';

export type AuthData = {
  authenticateUser: {
    accessToken: string;
    refreshToken: string;
    captchaSvg?: string;
  };
};

export type AuthVars = {
  userProperties: Credentials;
};

export const AUTH = gql`
  mutation Auth($userProperties: CredentialsInput!) {
    authenticateUser(userProperties: $userProperties) {
      accessToken
      refreshToken
      captchaSvg
    }
  }
`;

export type CreateUserData = {
  createUser: User;
};

export type CreateUserVars = {
  userProperties: CreateUserInput;
};

export const CREATE_USER = gql`
  mutation CreateUser($userProperties: CreateUserInput!) {
    createUser(userProperties: $userProperties) {
      ...User
    }
  }
  ${userFragment}
`;

export type InviteUsersData = {
  inviteUsers: User[];
};

export type InviteUsersVars = {
  users: InviteMultipleUsersInput[];
};

export const INVITE_USERS = gql`
  mutation InviteUsers($users: [InviteMultipleUsersInput!]!) {
    inviteUsers(users: $users) {
      ...User
    }
  }
  ${userFragment}
`;

export type DeleteUserData = {
  deleteUser: string;
};

export type DeleteUserVars = {
  ids: string[];
};

export const DELETE_USER = gql`
  mutation DeleteUser($ids: [String!]!) {
    deleteUsers(ids: $ids)
  }
`;

export type DeleteUserGroupData = {
  deleteUserGroup: string;
};

export type DeleteUserGroupVars = {
  ids: string[];
};

export const DELETE_USER_GROUP = gql`
  mutation DeleteUserGroup($ids: [String!]!) {
    deleteUserGroups(ids: $ids)
  }
`;

export type UpdateUserData = {
  updateUser: User;
};

export type UpdateUserVars = {
  userProperties: UpdateUserInput;
};

export const UPDATE_USER = gql`
  mutation UpdateUser($userProperties: UpdateUserInput!) {
    updateUser(userProperties: $userProperties) {
      ...User
    }
  }
  ${userFragment}
`;

export type UpdateUserGroupData = {
  updateUserGroup: UsersGroup;
};

export type UpdateUserGroupVars = {
  payload: {
    groupId: string;
    name: string;
  };
};

export const UPDATE_USER_GROUP = gql`
  mutation UpdateUserGroup($payload: UpdateUserGroupInput!) {
    updateUserGroup(payload: $payload) {
      ...UsersGroup
    }
  }
  ${userGroupFragment}
`;

export type CreateUserGroupData = {
  createUserGroup: UsersGroup;
};

export type CreateUserGroupVars = {
  payload: {
    name: string;
  };
};

export const CREATE_USER_GROUP = gql`
  mutation CreateUserGroup($payload: CreateUserGroupInput!) {
    createUserGroup(payload: $payload) {
      ...UsersGroup
    }
  }
  ${userGroupFragment}
`;

export type AddUserGroupMemberData = {
  addUserGroupMember: UsersGroup;
};

export type AddUserGroupMemberVars = {
  payload: {
    groupId: string;
    group_role: CustomerRole[];
    userId: string;
  };
};

export const ADD_USER_GROUP_MEMBER = gql`
  mutation AddUserGroupMember($payload: AddOrRemoveGroupMemberInput!) {
    addUserGroupMember(payload: $payload) {
      ...UsersGroup
    }
  }
  ${userGroupFragment}
`;

export type UpdateUserGroupMemberData = {
  updateUserGroupMember: UsersGroup;
};

export type UpdateUserGroupMemberVars = {
  payload: {
    groupId: string;
    group_role: CustomerRole[];
    userId: string;
  };
};

export const UPDATE_USER_GROUP_MEMBER = gql`
  mutation UpdateUserGroupMember($payload: UpdateUserGroupMemberInput!) {
    updateUserGroupMember(payload: $payload) {
      ...UsersGroup
    }
  }
  ${userGroupFragment}
`;

export type DeleteUserGroupMemberData = {
  deleteUserGroupMember: UsersGroup;
};

export type DeleteUserGroupMemberVars = {
  payload: {
    groupId: string;
    userId: string;
  };
};

export const DELETE_USER_GROUP_MEMBER = gql`
  mutation DeleteUserGroupMember($payload: DeleteGroupMemberInput!) {
    deleteUserGroupMember(payload: $payload) {
      ...UsersGroup
    }
  }
  ${userGroupFragment}
`;

export type ForgotUserPasswordData = {
  forgotPassword: string;
};

export type ForgotUserPasswordVars = {
  email: string;
};

export const FORGOT_USER_PASSWORD = gql`
  mutation ForgotUserPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export type ResetUserPasswordData = {
  forgotPassword: string;
};

export type ResetUserPasswordVars = {
  props: ResetPasswordInput;
};

export const RESET_USER_PASSWORD = gql`
  mutation ResetUserPassword($props: ResetPasswordInput!) {
    resetPassword(props: $props)
  }
`;

export type RegisterUserData = {
  registerUser: string;
};

export type RegisterUserVars = {
  token: string;
  props: {
    firstName: string;
    lastName: string;
    password: string;
  };
};

export const REGISTER_USER = gql`
  mutation RegisterUser($token: String!, $props: RegisterUserInput!) {
    registerUser(token: $token, props: $props)
  }
`;

export const REFRESH_TOKEN = gql`
  mutation RefreshToken {
    refreshToken {
      accessToken
      refreshToken
    }
  }
`;

export const START_SYNC_HUBSPOT = gql`
  mutation StartHubspotSync {
    syncHubspotUsers
  }
`;
