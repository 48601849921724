import React, { FC, useRef, useEffect } from 'react';
import { ZoomControlBtn, ZoomControlsWrapper } from './styles';
import { ReactComponent as Plus } from './icons/plus.svg';
import { ReactComponent as Minus } from './icons/minus.svg';
import { ReactComponent as Reset } from './icons/reset.svg';

import { Classes } from '@blueprintjs/core';

type Props = {
  onZoomIncrease: () => void;
  onZoomDecrease: () => void;
  onReset: () => void;
};

const ZoomControls: FC<Props> = ({
  onZoomIncrease,
  onZoomDecrease,
  onReset,
}) => {
  const wrapperRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    wrapperRef.current.addEventListener('dblclick', (event) => {
      event.stopPropagation();
    });
  }, []);

  return (
    <ZoomControlsWrapper
      data-html2canvas-ignore
      ref={wrapperRef}
      className={Classes.ELEVATION_2}
    >
      <ZoomControlBtn onClick={onReset}>
        <Reset />
      </ZoomControlBtn>
      <ZoomControlBtn onClick={onZoomIncrease}>
        <Plus />
      </ZoomControlBtn>
      <ZoomControlBtn onClick={onZoomDecrease}>
        <Minus />
      </ZoomControlBtn>
    </ZoomControlsWrapper>
  );
};

export default ZoomControls;
