import { Classes } from '@blueprintjs/core';
import styled, { css } from 'styled-components';
import { ReactComponent as Trash } from '../../../../../assets/icons/trash.svg';
import { colors } from '../../../../../utils/colors';

export const ElementWrapper = styled.div<{
  disableDeleteHover?: boolean;
  isInGroup?: boolean;
}>`
  &:hover {
    svg {
      opacity: ${({ disableDeleteHover }) => (disableDeleteHover ? 0 : 1)};
    }
  }

  & > div:not(.${Classes.COLLAPSE}) {
    min-height: 16px;
  }

  ${({ isInGroup }) =>
    isInGroup &&
    css`
      font-size: 12px;
    `}
`;

export const SubElementWrapper = styled.div`
  &:hover {
    svg {
      opacity: 1;
    }
  }

  & > div:not(.${Classes.COLLAPSE}) {
    min-height: 16px;
  }
`;

export const GroupWrapper = styled.div`
  &:hover > span.delete-icon {
    svg {
      opacity: 1;
    }
  }

  & > div:not(.${Classes.COLLAPSE}) {
    min-height: 16px;
  }
`;

export const ElementInput = styled.input`
  background: transparent;
  border: none;
  color: inherit;
  width: 100%;

  &:focus,
  &:active {
    background: transparent;
    border: none;
  }
`;

export const DeleteBtn = styled(Trash)`
  margin-left: 8px;
  width: 14px;
  height: 14px;
  opacity: 0;
  color: ${colors.primary0};
  cursor: pointer;
`;

export const StyledPageLink = styled.div<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    color: ${({ disabled }) =>
      disabled ? colors.monochromatic3 : colors.primary3};
  }
`;
