import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
} from 'react';
import { ReactComponent as ExitIcon } from '../../../assets/icons/exit.svg';
import { useCurrentUserQuery } from '../../../gql/user/hooks';
import { useLogout } from '../../../utils/hooks/useLogout';
import { StyledNavBar } from './styles';

export const DefaultNavBarData = {
  pageTitle: 'Polyteia',
} as NavContext;

type NavContext = {
  pageTitle: string;
  setPageTitle: Dispatch<SetStateAction<string>>;
  pageActions: React.JSX.Element[] | null;
  setPageActions: Dispatch<SetStateAction<React.JSX.Element[] | null>>;
};

export const NavBarData = createContext<NavContext>(DefaultNavBarData);

export const useNavContext = () => useContext(NavBarData);

const UserActionType = {
  LOGOUT: 'LOGOUT',
};

const UserActions = [
  {
    value: UserActionType.LOGOUT,
    title: 'Abmelden',
    icon: ExitIcon,
  },
];

const NavBar = () => {
  const { pageTitle, pageActions } = useNavContext();
  const { user } = useCurrentUserQuery();
  const logout = useLogout();

  const UserActionHandlers = {
    [UserActionType.LOGOUT]() {
      logout().catch(() => null);
    },
  };

  return (
    <StyledNavBar
      defaultTitle={pageTitle}
      userMenu={UserActions}
      extrasRight={pageActions ?? undefined}
      onUserMenuAction={(action) => UserActionHandlers[action.value]()}
      user={user}
    />
  );
};

export default NavBar;
