import React, { FC, useRef } from 'react';
import { LeafletMap } from './LeafletMap';
import {
  MapChartConfig,
  OnFilterChange,
  WidgetCommonProps,
} from '../../../../gql/widget/types';
import SimpleFilter, { FilterSelection } from '../../../SimpleFilter';
import { useFilters } from '../../../../utils/filters';
import { SETTINGS } from '../../../SimpleFilter/WidgetSettings';
import GraphStand from '../../../GraphStand';
import {
  ErrorComponents,
  WidgetError,
} from '../../components/WidgetErrorComponents';
import { getIsDA } from '../../../../gql/user/local';
import { useTranslation } from 'react-i18next';

type Props = {
  data: MapChartConfig;
  onFilterChange?: OnFilterChange;
  filtersVal?: FilterSelection[];
} & WidgetCommonProps;

const ChoroplethMap: FC<Props> = ({
  data,
  onFilterChange,
  filtersVal,
  afterTitle,
  settings,
  settingsVal,
  setSettingsVal,
  isEditing,
  editProps,
  handleDelete,
  handleReload,
  isCreatedByCustomer,
}) => {
  const sliderContainerRef = useRef<HTMLDivElement | null>(null);
  const filters = useFilters(data.widgetFilters);
  const isDA = getIsDA();
  const { t } = useTranslation();
  const AVAILABLE_SETTINGS: string[] = [
    SETTINGS.SHOW_VALUE,
    ...(data.background?.type === 'openstreetmaps' ? [SETTINGS.MAP_BG] : []),
  ];

  const getAlertCase = () => {
    if (editProps && isDA) {
      return ErrorComponents.ALERT_WITH_EDIT;
    }
    if (!isDA && isEditing && isCreatedByCustomer) {
      return ErrorComponents.ALERT_WITHOUT_EDIT;
    }
    return ErrorComponents.ALERT_WITH_RELOAD;
  };

  if (!data.data.length) {
    return (
      <WidgetError
        testId={'incomplete-data'}
        isDA={isDA}
        errorTitle={isDA ? t('error.widget.filter.title') : ''}
        alertCase={getAlertCase()}
        handleReload={handleReload ? handleReload : () => {}}
        handleDelete={handleDelete ? handleDelete : () => {}}
        editProps={editProps?.id ? editProps : { id: '', isPreview: false }}
      />
    );
  }
  return (
    <div className="widget">
      <SimpleFilter
        header={data.title}
        filters={filters}
        onFilterSelect={onFilterChange}
        info={data.info}
        value={filtersVal}
        afterTitle={afterTitle}
        sliderContainerRef={sliderContainerRef}
        settings={settings?.filter((s) => AVAILABLE_SETTINGS.includes(s.id))}
        settingsVal={settingsVal}
        setSettingsVal={setSettingsVal}
      />
      <LeafletMap
        handleReload={handleReload}
        handleDelete={handleDelete}
        data={data}
        settingsVal={settingsVal}
        disabled={isEditing}
      />
      <div className="d-flex u-justify-flex-end">
        <GraphStand stand={data.stand} />
      </div>
      <div ref={sliderContainerRef} />
    </div>
  );
};

export default ChoroplethMap;
