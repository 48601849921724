import React, { FC, useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  GET_TAGS,
  GET_TAGS_BY_ENTITY_ID,
  GetTagsByEntityIdData,
  GetTagsByEntityIdVars,
  GetTagsData,
} from '../../../gql/structure/queries';
import { MenuItem } from '@blueprintjs/core';
import {
  renderSearchableItem,
  SearchableOption,
  searchablePredicate,
} from '../../../utils/select';
import Btn from '../../Btn';
import { ITag } from '../../../gql/structure/types';
import {
  UPDATE_TAGS,
  UpdateTagsData,
  UpdateTagsVars,
} from '../../../gql/structure/mutations';
import { Actions, TagsContainer, Wrapper } from './styles';
import { getColorsFromPalette } from '../../../utils/colors';
import Tag from '../../Tag';
import SearchableInput from '../../inputs/SearchableInput';
import { getIsDA } from '../../../gql/user/local';

type Props = { entityId?: string };

const getTagsOptions = (
  currentTags: string[],
  tags?: ITag[],
): SearchableOption[] => {
  if (!tags) {
    return [];
  }

  return tags
    .filter(({ title }) => !currentTags.includes(title))
    .map(({ id, title }) => ({
      value: id,
      title,
    }));
};

const Tags: FC<Props> = ({ entityId }) => {
  const [currentTags, setCurrentTags] = useState<string[]>([]);
  const [query, setQuery] = useState<string>('');
  const [isQueryIncorrect, setIsQueryIncorrect] = useState(false);
  const [saved, setSaved] = useState(true);

  const isDA = getIsDA();

  const { data: allTagsData, refetch: refetchAllTags } = useQuery<GetTagsData>(
    GET_TAGS,
    {
      fetchPolicy: 'network-only',
      skip: !isDA,
    },
  );

  const { refetch: refetchEntityTags } = useQuery<
    GetTagsByEntityIdData,
    GetTagsByEntityIdVars
  >(GET_TAGS_BY_ENTITY_ID, {
    variables: { entityId: entityId as string },
    skip: !entityId || !isDA,
    onCompleted: (data) => {
      setCurrentTags(data.getTagsByEntityId.map(({ title }) => title));
    },
  });

  const allTags = allTagsData?.getTags;

  const allTagTitles = useMemo(
    () => allTags?.map((t) => t.title) || [],
    [allTags],
  );

  const [updateTags, { loading: updateTagsLoading }] = useMutation<
    UpdateTagsData,
    UpdateTagsVars
  >(UPDATE_TAGS);

  const tagsOptions = useMemo(
    () => getTagsOptions(currentTags, allTags),
    [allTags, currentTags],
  );

  const saveTags = useCallback(async () => {
    try {
      await updateTags({
        variables: {
          updateTagsInput: {
            entityId: entityId as string,
            titles: currentTags,
          },
        },
      });

      await refetchAllTags();
      await refetchEntityTags();

      setSaved(true);
    } catch {}
  }, [currentTags, refetchAllTags, refetchEntityTags, entityId, updateTags]);

  const createNewItemRenderer: (
    query: string,
    active: boolean,
    handleClick: React.MouseEventHandler<HTMLElement>,
  ) => React.JSX.Element | undefined = useCallback(
    (query, active) => {
      const alreadyAdded = currentTags.some(
        (t) => t.toLowerCase() === query.toLowerCase(),
      );

      const alreadyExist = allTagTitles.some(
        (t) => t.toLowerCase() === query.toLowerCase(),
      );

      if (alreadyAdded || alreadyExist) {
        return undefined;
      }

      return (
        <MenuItem
          active={active}
          disabled={isQueryIncorrect}
          key="new-item"
          onClick={() => {
            setCurrentTags((prev) => [...prev, query]);
            setSaved(false);
          }}
          text={isQueryIncorrect ? 'Invalid tag name' : `${query} (New tag)`}
        />
      );
    },
    [allTagTitles, currentTags, isQueryIncorrect],
  );

  return (
    <Wrapper>
      {allTags && (
        <div>
          <SearchableInput
            items={tagsOptions}
            itemRenderer={renderSearchableItem()}
            itemPredicate={searchablePredicate}
            onItemSelect={(selected: SearchableOption) => {
              setCurrentTags((prev) => [...prev, selected.title]);
              setSaved(false);
            }}
            createNewItemFromQuery={() => {}}
            createNewItemRenderer={createNewItemRenderer}
            inputProps={{
              placeholder: '+ Add a tag',
              className: 'searchable-input-group',
            }}
            resetOnClose
            query={query}
            onQueryChange={(query) => {
              const newQuery = query.trim();

              if (
                newQuery.length >= 48 ||
                !/^[A-Za-z0-9äöüÄÖÜß\s_]*$/.test(newQuery)
              ) {
                setIsQueryIncorrect(true);

                return;
              }

              setQuery(newQuery);
              setIsQueryIncorrect(false);
            }}
          />
          {!!currentTags.length && (
            <TagsContainer>
              {currentTags?.map((title, ind) => {
                const tagId = allTags.find((t) => t.title === title)?.id;

                const newTags = currentTags.filter(
                  (currentTag) => !allTagTitles.includes(currentTag),
                );

                const allTagsInd = allTags.findIndex((t) => t.id === tagId);

                const colorInd =
                  allTagsInd === -1
                    ? allTags.length + newTags.findIndex((t) => t === title)
                    : allTagsInd;

                const paletteColors = getColorsFromPalette();

                const tagColor = paletteColors[colorInd % paletteColors.length];

                return (
                  <Tag
                    key={ind}
                    lg
                    color={tagColor}
                    onDelete={() => {
                      setCurrentTags((prev) => prev.filter((t) => t !== title));

                      setSaved(false);
                    }}
                  >
                    {title}
                  </Tag>
                );
              })}
            </TagsContainer>
          )}
        </div>
      )}
      <Actions>
        <Btn
          text="Save"
          lg
          onClick={saveTags}
          disabled={saved}
          loading={updateTagsLoading}
        />
      </Actions>
    </Wrapper>
  );
};

export default Tags;
