import styled from 'styled-components';
import { colors } from '../../utils/colors';
import { Icon } from '@blueprintjs/core';

export const Wrapper = styled.div<{ isExpanded: boolean }>`
  padding: 6px 14px 14px;
  background-color: ${colors.monochromatic7};
  border-radius: 8px;
  height: auto;
  max-height: ${({ isExpanded }) => (isExpanded ? `auto` : `35px`)};
  min-height: ${({ isExpanded }) => (isExpanded ? `auto` : `35px`)};

  overflow: ${({ isExpanded }) => (isExpanded ? `visible` : `hidden`)};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 10px;
`;

export const Headline = styled.div<{ isInvalid?: boolean }>`
  color: ${colors.primary0};
  font-size: 16px;

  ${({ isInvalid }) =>
    isInvalid &&
    `
      display: flex;
      align-items: center;
      gap: 10px;

      &:after {
        display: block;
        content: '';
        width: 10px;
        height: 10px;
        background-color: ${colors.secondary0};
        border-radius: 50%;
      }
  `}
`;

export const ToggleIcon = styled(Icon)`
  cursor: pointer;

  & > svg {
    width: 20px;
    height: 20px;
  }
`;
