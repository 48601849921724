import styled from 'styled-components';
import { Button } from '@blueprintjs/core';

export const SliderFilterContainer = styled.div`
  padding: 30px;
  margin-bottom: 10px;
`;

export const SliderFilterWrapper = styled.div`
  padding: 0 10px;
`;

export const SettingsBtn = styled(Button)<{ showSettings: boolean }>`
  &&& {
    box-shadow: none;
    background-color: white;
  }

  opacity: ${({ showSettings }) => (showSettings ? 1 : 0)};
  padding: 0;

  & span {
    width: auto;
    overflow: visible;
  }
`;
