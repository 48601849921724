import React, { FC, Fragment } from 'react';
import { Suggest2, Suggest2Props } from '@blueprintjs/select';
import { ReactComponent as Search } from '../../../assets/icons/search.svg';
import { Popover2 } from '@blueprintjs/popover2';
import { Icon, MenuItem } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

type Props = { top?: boolean } & Partial<Suggest2Props<any>>;

const SearchableDropdown: FC<Props> = ({
  top,
  inputProps,
  popoverProps,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Popover2
        interactionKind="click"
        popoverClassName="searchable-outer-popover"
        placement={top ? 'top' : 'bottom-start'}
        minimal
        content={
          <Suggest2
            closeOnSelect
            inputProps={{
              rightElement: <Search />,
              placeholder: t('common.search')!,
              ...inputProps,
            }}
            popoverProps={{
              minimal: true,
              isOpen: true,
              usePortal: false,
              placement: top ? 'top' : 'bottom-start',
              ...popoverProps,
              className: `searchable-dropdown-wrapper ${
                top ? 'searchable-dropdown-wrapper-top' : ''
              } ${popoverProps?.className || ''}`,
              popoverClassName: `searchable-popover ${
                top ? 'searchable-popover-top' : ''
              } ${popoverProps?.popoverClassName || ''}`,
            }}
            {...props}
            inputValueRenderer={() => ''}
            noResults={<MenuItem disabled text={t('common.no-results')} />}
          />
        }
        renderTarget={({ isOpen, ref, ...targetProps }) => (
          <div
            ref={ref}
            {...targetProps}
            className={`searchable-outer-target u-cursor-pointer u-justify-space-between u-align-center-y font-16 popover__selected ${
              props?.disabled
                ? 'colorMonochromatic2 u-cursor-disabled'
                : 'colorPrimary-0'
            }`}
          >
            <span>
              {props?.selectedItem?.title || props?.items?.[0]?.title}
            </span>
            <Icon
              icon={isOpen ? 'chevron-up' : 'chevron-down'}
              className="u-margin-left-5"
            />
          </div>
        )}
      />
    </Fragment>
  );
};

export default SearchableDropdown;
