import React, { FC } from 'react';
import { SETTINGS } from './';
import { VisibleCheckboxes } from './styles';
import Checkbox from '../../inputs/Checkbox';
import { DataSeries } from '../../../gql/widget/types';

type Props = {
  val?: number[];
  setSettingsVal?: React.Dispatch<React.SetStateAction<Record<SETTINGS, any>>>;
  data_series?: DataSeries[];
  onChange?: (newVal: any) => void;
};

const VisibleData: FC<Props> = ({
  val,
  onChange,
  setSettingsVal,
  data_series,
}) => {
  const id = SETTINGS.VISIBLE_DATA;

  const rows = Math.ceil((data_series?.length || 0) / 3);

  return (
    <div>
      <div className="u-margin-bottom-10">In Diagramm anzeigen</div>
      <VisibleCheckboxes rows={rows}>
        {val &&
          data_series?.map(({ title }, ind) => {
            return (
              <Checkbox
                key={ind}
                onChange={(e) => {
                  const { checked } = e.currentTarget;

                  setSettingsVal?.((prevState) => {
                    return {
                      ...prevState,
                      [id]: checked
                        ? [...prevState[id], ind]
                        : prevState[id].length === 1
                        ? prevState[id]
                        : prevState[id].filter((i: number) => i !== ind),
                    };
                  });

                  onChange?.(ind);
                }}
                checked={val.includes(ind)}
                label={title}
              />
            );
          })}
      </VisibleCheckboxes>
    </div>
  );
};

export default VisibleData;
