import { getEnvVar, ENV_VAR } from './common';

declare global {
  interface Window {
    _hsq: {
      push(item: string[]): void
    }
  }
}

export enum HubspotEvent {
  SET_PATH = 'setPath',
  TRACK_PAGE_VIEW = 'trackPageView',
  IDENTIFY = 'identify',
}

export function addHubspot() {
  if (getEnvVar(ENV_VAR.HUBSPOT_TRACKING_ENABLED) !== 'true') {
    return;
  }

  const hubspotId = getEnvVar(ENV_VAR.HUBSPOT_ID);
  const hubspotScriptSrc = `//js-eu1.hs-scripts.com/${hubspotId}.js`;
  const hubspotScriptTag = document.createElement('script');

  hubspotScriptTag.src = hubspotScriptSrc;

  document.body.appendChild(hubspotScriptTag);
}

export function removeHubspot() {
  const hubspotId = getEnvVar(ENV_VAR.HUBSPOT_ID);
  const hubspotScriptSrc = `//js-eu1.hs-scripts.com/${hubspotId}.js`;

  document.querySelector(`script[src="${hubspotScriptSrc}"]`)?.remove();
  document.cookie =
    'hubspotutk=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

const hubspotUtils = Object.freeze({
  logIn(email: string) {
    hubspotUtils.trackEvent(HubspotEvent.IDENTIFY, { email });
  },
  trackPage(path: string) {
    hubspotUtils.trackEvent(HubspotEvent.SET_PATH, path);
    hubspotUtils.trackEvent(HubspotEvent.TRACK_PAGE_VIEW);
  },
  trackEvent(event: HubspotEvent, eventParameters?: any) {
    window._hsq.push([event, eventParameters]);
  },
});

export function useHubspot() {
  window._hsq = window._hsq || [];

  return hubspotUtils;
}
