import { useQuery } from '@apollo/client';
import { ResizeSensor2 } from '@blueprintjs/popover2';
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router-dom';
import { WidgetsRoute } from '../../../../../../app/Routes';
import { ReactComponent as QuestionMarkIllustrationIcon } from '../../../../../../assets/icons/illustration/standard/question-mark.svg';
import Btn from '../../../../../../components/Btn';
import { Modal } from '../../../../../../components/Modal';
import { Field } from '../../../../../../components/PivotChartPanel';
import { StyledPivotModeSwitch } from '../../../../../../components/VisualEditorSwitch/styles';
import {
  ErrorComponents,
  WidgetError,
} from '../../../../../../components/Widget/components/WidgetErrorComponents';
import Table from '../../../../../../components/Widget/widgets/Table';
import { NEW_ITEM_ID_URL } from '../../../../../../constants/entities';
import { InsightQuery } from '../../../../../../gql/library/types';
import { HORIZONTAL_ALIGN } from '../../../../../../gql/page/types';
import {
  GET_QUERY_PREVIEW,
  GetQueryPreviewData,
  GetQueryPreviewVars,
} from '../../../../../../gql/query/queries';
import {
  GET_STRUCTURE_BY_ACCESS,
  GetStructureByAccessData,
} from '../../../../../../gql/structure/queries';
import {
  getIsAnalyticsEngineer,
  getIsDA,
} from '../../../../../../gql/user/local';
import {
  COLUMN_TYPE,
  CONFIG_ELEMENT_VALUES_TYPE,
  DOMAIN_TYPE,
  QueryCol,
  TableConfig,
  WIDGET_TYPE,
} from '../../../../../../gql/widget/types';
import { useModal } from '../../../../../../utils/hooks/modal';
import { Filter } from '../../../../../Widgets/Widget/FilterPanel';
import { useEditingContext } from '../../../editing';
import WidgetEditor from './WidgetEditor';
import { getMagic } from './getMagic';
import { MagicStatic, QueryPreviewWrapper, QueryTableWrapper } from './styles';

const ROWS_STEP_SIZE = 50;
const ALL_ROWS_LIMIT = 99999;

const PIVOT_MODAL_KEY = 'widget-pivot-confirmation-prompt';

const MagicAnimated: FC = () => {
  useEffect(() => {
    getMagic();
  }, []);

  return <MagicStatic />;
};

export type CreateWidgetConfig = {
  type: WIDGET_TYPE;
  name: string;
  title: string;
  queryId: string;
  data: DataConfig[];
  pivot?: PivotConfig;
};

export type DataConfig = {
  align: HORIZONTAL_ALIGN;
  colType?: COLUMN_TYPE;
  borderRight: boolean;
  weight: number;
  id: string;
  column: string;
  title: string;
  sortable: boolean;
  disabled?: boolean;
  isSelectedDimension?: boolean;
  valuesType: CONFIG_ELEMENT_VALUES_TYPE;
};

type PivotConfig = {
  rowField?: Field;
  valueField?: Field;
  pivotFunction?: string;
  columnField?: Field;
  filters?: Filter[];
};

type Props = {
  colInd: number;
  datasourceId?: string;
  existingConfig?: CreateWidgetConfig;
  isCreatingWidget: boolean;
  isHierarchyShown?: boolean;
  isViewOnly?: boolean;
  query?: InsightQuery | null;
  queryId: string;
  rowInd: number;
  versionId?: string;
  isFilePreview?: boolean;
  visualizeInSamePage?: boolean;
  backButtonTitle?: string;
  handleClose: () => void;
  handleConfirm: () => void;
  onChange?: () => void;
  refetchWidget?: (newType: WIDGET_TYPE) => void;
  setDialogActions?: Dispatch<SetStateAction<JSX.Element | null>>;
  setIsCreatingWidget: Dispatch<SetStateAction<boolean>>;
  setIsPivotMode?: Dispatch<SetStateAction<boolean>>;
};

const QueryPreview: FC<Props> = ({
  colInd,
  datasourceId,
  existingConfig,
  isCreatingWidget,
  isHierarchyShown = false,
  isViewOnly = false,
  query,
  queryId,
  rowInd,
  versionId,
  isFilePreview = false,
  visualizeInSamePage,
  backButtonTitle,
  handleClose,
  handleConfirm,
  onChange,
  refetchWidget,
  setDialogActions,
  setIsCreatingWidget,
  setIsPivotMode,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isMagic, setIsMagic] = useState(false);
  const [height, setHeight] = useState(0);
  const [limit, setLimit] = useState(ROWS_STEP_SIZE);
  const [widgetConfig, setWidgetConfig] = useState<CreateWidgetConfig>({
    type: existingConfig?.type || WIDGET_TYPE.TABLE,
    name: existingConfig?.name || '',
    title: existingConfig?.title || '',
    pivot: existingConfig?.pivot,
    queryId,
    data: [],
  });
  const isDA = getIsDA();
  const isAE = getIsAnalyticsEngineer();
  const { savedStructure } = useEditingContext();

  const { data: structureData } = useQuery<GetStructureByAccessData>(
    GET_STRUCTURE_BY_ACCESS,
    {
      skip: Boolean(datasourceId) || !!savedStructure?.id,
    },
  );

  const structureId =
    datasourceId ||
    savedStructure?.id ||
    structureData?.getFirstStructureByAccess.id;

  const {
    data: previewData,
    previousData: prevPreviewData,
    error,
  } = useQuery<GetQueryPreviewData, GetQueryPreviewVars>(GET_QUERY_PREVIEW, {
    variables: {
      queryId,
      structureId: isFilePreview ? undefined : structureId,
      limit: isCreatingWidget ? ALL_ROWS_LIMIT : limit,
    },
    skip: !isFilePreview && !structureId,
  });

  const rawTable =
    previewData?.getInsightDataSetPreview ||
    prevPreviewData?.getInsightDataSetPreview;

  const [queryFields, setQueryFields] = useState<Field[]>();

  useEffect(() => {
    if (!rawTable) {
      return;
    }

    const { header } = rawTable;

    setWidgetConfig((prev) => {
      let pivotData;
      if (existingConfig?.pivot) {
        const queryColumns = rawTable.header ?? [];

        const getField = (column: QueryCol): Field => ({
          id: column.name,
          name: column.title,
          type:
            column.valuesType === CONFIG_ELEMENT_VALUES_TYPE.string
              ? 'string'
              : column.valuesType === CONFIG_ELEMENT_VALUES_TYPE.date
              ? 'date'
              : 'number',
          contentType:
            column.type === COLUMN_TYPE.DIMENSION
              ? 'dimension'
              : column.type === COLUMN_TYPE.MEASURE
              ? 'measure'
              : '',
        });
        const newQueryFields = queryColumns.map(getField);
        setQueryFields(newQueryFields);

        let groupByField =
          existingConfig.type === WIDGET_TYPE.TABLE
            ? newQueryFields.find(
                (field) => field.id === (existingConfig as any).pivot?.groupBy,
              )
            : newQueryFields.find(
                (field) =>
                  field.id === (existingConfig as any)?.domains?.[0]?.column,
              );

        // FIXME: avoid relying on user-visible text
        if (!groupByField) {
          groupByField = newQueryFields.find(
            (field) =>
              field.name === (existingConfig as any)?.domains?.[0]?.title,
          );
        }

        const dataField = newQueryFields.find(
          (field) => field.id === (existingConfig as any).pivot?.dataColumn,
        );
        const pivotField = newQueryFields.find(
          (field) => field.id === (existingConfig as any).pivot?.pivotColumn,
        );

        pivotData = {
          pivot: {
            rowField: groupByField,
            valueField: dataField,
            columnField: pivotField,
            pivotFunction: (existingConfig as any).pivot?.pivotFunction,
          },
        };
      }

      let isFirstDimensionSelected = false;

      const existingSelectedCols = (existingConfig as any)?.[
        existingConfig?.type === WIDGET_TYPE.TABLE ? 'header' : 'data'
      ].map((item: any) => item?.id);

      const existingSelectedDomain = (existingConfig as any)?.domains?.find(
        (d: any) => d?.type === DOMAIN_TYPE.X_AXIS,
      );

      const configData =
        prev.data.length && !isViewOnly
          ? undefined
          : {
              type: existingConfig?.type ?? prev?.type,
              data: header.map(({ title, name, type, valuesType }) => {
                const isDimension = type === COLUMN_TYPE.DIMENSION;

                const isFirstDimension =
                  isDimension && !isFirstDimensionSelected;

                if (isFirstDimension) {
                  isFirstDimensionSelected = true;
                }

                const savedData = existingConfig?.data.find(
                  ({ id }) => id === name,
                );

                return {
                  align: isDimension
                    ? HORIZONTAL_ALIGN.LEFT
                    : HORIZONTAL_ALIGN.RIGHT,
                  borderRight: true,
                  weight: 1,
                  id: name,
                  column: name,
                  title:
                    isFirstDimension && existingSelectedDomain
                      ? existingSelectedDomain?.title
                      : savedData?.title || title || name,
                  sortable: false,
                  isSelectedDimension: isFirstDimension,
                  colType: type,
                  disabled:
                    !!existingConfig && !existingSelectedCols?.includes(name),
                  valuesType: valuesType || CONFIG_ELEMENT_VALUES_TYPE.string,
                };
              }),
            };
      return { ...prev, ...pivotData, ...configData };
    });
  }, [existingConfig, rawTable, isViewOnly]);

  const tableData = useMemo(() => {
    if (!rawTable) {
      return;
    }

    const { header, content } = rawTable;

    return {
      header: header.map(({ title, name, type }) => ({
        align:
          type === COLUMN_TYPE.DIMENSION
            ? HORIZONTAL_ALIGN.LEFT
            : HORIZONTAL_ALIGN.RIGHT,
        weight: 1,
        borderRight: false,
        title: title || name,
        sortable: false,
      })),
      data: content.map((row) =>
        row.map((val, colInd) => ({
          type: header[colInd].valuesType || CONFIG_ELEMENT_VALUES_TYPE.string,
          value: val ?? '-',
        })),
      ),
    };
  }, [rawTable]);

  const { isModalOpen, openModal, closeModal } = useModal();

  useEffect(() => {
    if ((isCreatingWidget || existingConfig) && setDialogActions) {
      const isPivotEnableable = queryFields
        ? queryFields.length > 1
        : widgetConfig.data?.length > 1;
      setDialogActions(
        <div className="flex items-end justify-between">
          <div>
            {existingConfig ? (
              <span className="title-text">{widgetConfig.title}</span>
            ) : (
              <>
                <div className="font-26 font-w-400">Neue Visualisierung</div>
                <div className="font-18 font-w-400">
                  {query?.queryConfig.title}
                </div>
              </>
            )}
          </div>
          {isPivotEnableable && (
            <div className="py-2">
              <StyledPivotModeSwitch
                id="navbar-pivot-mode-switch"
                key="pivot-mode"
                checked={!!widgetConfig.pivot}
                onChange={() => {
                  if (widgetConfig.pivot) {
                    openModal(PIVOT_MODAL_KEY);
                  } else {
                    setWidgetConfig((prev) => ({ ...prev, pivot: {} }));
                    setIsPivotMode?.(true);
                  }
                }}
              />
            </div>
          )}
        </div>,
      );
    }
  }, [
    existingConfig,
    isCreatingWidget,
    query?.queryConfig.title,
    setDialogActions,
    setIsPivotMode,
    openModal,
    widgetConfig,
    widgetConfig.title,
    widgetConfig.data,
    widgetConfig.pivot,
    queryFields,
    t,
  ]);

  return isCreatingWidget || existingConfig ? (
    <div className="h-[75vh]">
      <WidgetEditor
        setWidgetConfig={setWidgetConfig}
        widgetConfig={widgetConfig}
        rawTable={rawTable}
        onClose={() => setIsCreatingWidget(false)}
        rowInd={rowInd}
        colInd={colInd}
        handleConfirm={handleConfirm}
        versionId={versionId}
        refetchWidget={refetchWidget}
        onChange={onChange}
        isHierarchyShown={isHierarchyShown}
        isViewOnly={isViewOnly}
        setDialogActions={setDialogActions}
        structureId={structureId}
        queryFields={queryFields}
        skipPermission={isFilePreview}
      />
      {isModalOpen(PIVOT_MODAL_KEY) && (
        <Modal
          acceptCaption={t('widget.exit-prompt-accept')}
          cancelCaption={t('widget.pivot-prompt-cancel')}
          icon={<QuestionMarkIllustrationIcon />}
          title={t('widget.pivot-prompt-title')}
          size="small"
          variant="red"
          contentAlignment="center"
          subtitle={t('widget.pivot-prompt-subtitle')}
          onAccept={() => {
            setWidgetConfig(({ pivot, ...prev }) => prev);
            setIsPivotMode?.(false);
            closeModal();
          }}
          onClose={closeModal}
          onCancel={closeModal}
        />
      )}
    </div>
  ) : (
    <ResizeSensor2
      onResize={(entries) => {
        const entryHeight = entries[0].contentRect.height;
        if (Math.abs(entryHeight - height) > 5) {
          setHeight(entryHeight);
        }
      }}
    >
      <QueryPreviewWrapper>
        {error ? (
          <WidgetError
            testId={'incomplete-data'}
            isDA={isDA}
            errorTitle={isDA ? t('error.widget.filter.title') : ''}
            alertCase={ErrorComponents.ALERT_WITHOUT_CLOSE}
            handleReload={() => {}}
            handleDelete={() => {}}
            editProps={{ id: '', isPreview: false }}
          />
        ) : (
          <QueryTableWrapper>
            {tableData && (
              <InfiniteScroll
                dataLength={tableData.data.length}
                height={Math.max(height - 100, 500)}
                next={() => setLimit((prev) => prev + ROWS_STEP_SIZE)}
                hasMore={tableData.data.length === limit}
                loader={null}
              >
                <div style={{ marginTop: -50 }}>
                  <Table data={tableData as TableConfig} dense />
                </div>
              </InfiniteScroll>
            )}
          </QueryTableWrapper>
        )}
        <div className="u-display-space-between mt-2">
          {(isDA || isAE) && (
            <Btn
              text={backButtonTitle ?? t('action.back')}
              lg
              outlined
              onClick={handleClose}
              intent="none"
            />
          )}
          <div
            onMouseEnter={() => setIsMagic(true)}
            onMouseLeave={() => setIsMagic(false)}
          >
            {!error && (
              <Btn
                id="visualize-dataset-btn"
                text={t('common.visualize')}
                lg
                icon={isMagic ? <MagicAnimated /> : <MagicStatic />}
                onClick={() =>
                  visualizeInSamePage
                    ? history.push(
                        `${WidgetsRoute.path}/${NEW_ITEM_ID_URL}?pivot=true&queryid=${queryId}`,
                      )
                    : window.open(
                        `${WidgetsRoute.path}/${NEW_ITEM_ID_URL}?pivot=true&queryid=${queryId}`,
                        '_blank',
                      )
                }
              />
            )}
          </div>
        </div>
      </QueryPreviewWrapper>
    </ResizeSensor2>
  );
};

export default QueryPreview;
