import styled, { css } from 'styled-components';
import { Button, Intent } from '@blueprintjs/core';
import { colors } from '../../utils/colors';

const bgColorMap = {
  [Intent.NONE]: {
    bg: 'white',
    hover: colors.monochromatic5,
    active: colors.monochromatic6,
  },
  [Intent.PRIMARY]: {
    bg: colors.primary0,
    hover: colors.primary1,
    active: colors.primary3,
  },
  [Intent.DANGER]: {
    bg: colors.secondary0,
    hover: colors.secondary1,
    active: colors.secondary3,
  },
  [Intent.SUCCESS]: {
    bg: colors.green0,
    hover: colors.green1,
    active: colors.green3,
  },
  [Intent.WARNING]: {
    bg: colors.orange0,
    hover: colors.orange1,
    active: colors.orange3,
  },
};

const iconColors = {
  [Intent.NONE]: 'white',
  [Intent.PRIMARY]: colors.primary4,
  [Intent.DANGER]: colors.secondary4,
  [Intent.SUCCESS]: 'white',
  [Intent.WARNING]: 'white',
};

export const CustomizedBtn = styled(Button)<{
  intent: Intent;
  md?: boolean;
  sm?: boolean;
  lg?: boolean;
  lgSlim?: boolean;
  xs?: boolean;
  minimal?: boolean;
  iconBtn?: boolean;
  outline?: boolean;
  disabled?: boolean;
}>`
  transition: all 0.2s ease-in-out;
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  box-shadow: none !important;
  ${({ disabled }) =>
    disabled &&
    css`
      color: #fff9 !important;
    `}
  padding: ${({ md, lgSlim, sm, lg, minimal, iconBtn, xs, outline }) => {
    if (minimal || iconBtn || xs) {
      return '0';
    }

    if (sm) {
      return `${outline ? 3 : 5}px 10px`;
    }

    if (md) {
      return `${outline ? 6 : 8}px 20px`;
    }

    if (lgSlim) {
      return `${outline ? 10 : 12}px 16px`;
    }

    if (lg) {
      return `${outline ? 10 : 12}px 24px`;
    }

    return `${outline ? 14 : 16}px 24px`;
  }} !important;

  background-color: ${({ intent, iconBtn, disabled }) => {
    if (disabled) {
      return colors.monochromatic2;
    }

    return iconBtn ? iconColors[intent] : bgColorMap[intent].bg;
  }} !important;
  background-image: none !important;

  ${({ iconBtn }) =>
    iconBtn
      ? css`
          &&& {
            color: ${colors.monochromatic0};
          }
        `
      : ''}

  ${({ outline }) =>
    outline
      ? css`
          background-color: transparent !important;
          color: ${colors.primary0} !important;
          border: 2px solid ${colors.primary0};

          & > .bp4-icon {
            color: ${colors.primary0};
          }
        `
      : ''}

  &:hover {
    box-shadow: 0 2px 4px 0 #cacaca !important;
    background-color: ${({ intent, iconBtn, disabled }) => {
      if (disabled) {
        return colors.monochromatic2;
      }

      return iconBtn ? 'none' : bgColorMap[intent].hover;
    }} !important;

    ${({ iconBtn }) =>
      iconBtn
        ? css`
            &&& {
              color: ${colors.monochromatic0};
            }
          `
        : ''}
  }

  &:active {
    box-shadow: none !important;

    background-color: ${({ intent, iconBtn, disabled }) => {
      if (disabled) {
        return colors.monochromatic2;
      }

      return iconBtn ? 'none' : bgColorMap[intent].active;
    }} !important;

    ${({ iconBtn }) =>
      iconBtn
        ? css`
            &&& {
              color: ${colors.monochromatic0};
            }
          `
        : ''}
  }
`;
