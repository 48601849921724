import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { difference } from 'lodash';
import { Header, Headline, ToggleIcon, Wrapper } from './styles';

export enum TAB_IDS {
  PREVIEW = 'Preview',
  VERSIONS = 'Versions',
  META = 'Metadata',
  ACCESS = 'Access',
  USAGES = 'Usages',
  SQL_EDITOR = 'Editor',
  JSON_EDITOR = 'JSON',
  ICON = 'Icon',
  TAGS = 'Tags',
  PROPERTIES = 'Properties',
  DETAILS = 'Details',
  WIDGET_TYPE = 'Widget type',
  COLUMNS = 'Columns',
  VARIABLES = 'Variables',
}

export enum TAB_ACTION_IDS {
  CREATE = 'CREATE',
  SAVE = 'SAVE',
  PUBLISH = 'PUBLISH',
  TEST_CONNECTION = 'TEST_CONNECTION',
  PREVIEW = 'PREVIEW',
}

export type Props = {
  tabs: Array<{
    id: TAB_IDS;
    content?: React.JSX.Element | null;
    badge?: boolean;
    isInvalid?: boolean;
    isDisplayContents?: boolean;
  }>;
};

function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

const EditorPanels: FC<Props> = ({ tabs }) => {
  const tabsIds = tabs.map(({ id }) => id);
  const previousTabsIds = usePrevious(tabsIds);
  const addedTabs = difference(tabsIds, previousTabsIds ?? tabsIds);
  const removedTabs = difference(previousTabsIds ?? tabsIds, tabsIds);

  const [expandedIds, setExpandedIds] = useState<string[]>(tabsIds);

  useEffect(() => {
    if (addedTabs.length || removedTabs.length) {
      setExpandedIds((prev) => {
        return prev
          .filter((id) => !removedTabs.includes(id as TAB_IDS))
          .concat(addedTabs);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedTabs.length, removedTabs.length]);

  const toggleExpand = (id: TAB_IDS) => {
    setExpandedIds((prev) => {
      if (prev.includes(id)) {
        return prev.filter((prevId) => prevId !== id);
      }

      return [...prev, id];
    });
  };

  return (
    <>
      {tabs.map(
        ({ id, content, isInvalid, isDisplayContents }) =>
          content && (
            <Fragment key={id}>
              {isDisplayContents ? (
                React.cloneElement(content)
              ) : (
                <Wrapper
                  isExpanded={expandedIds.includes(id)}
                  id={id}
                  className="editor-panel"
                >
                  <Header>
                    <Headline
                      isInvalid={isInvalid}
                      className="editor-panel-headline"
                    >
                      {id}
                    </Headline>
                    <ToggleIcon
                      onClick={() => toggleExpand(id)}
                      icon={
                        expandedIds.includes(id) ? 'chevron-up' : 'chevron-down'
                      }
                    />
                  </Header>
                  {React.cloneElement(content)}
                </Wrapper>
              )}
            </Fragment>
          ),
      )}
    </>
  );
};

export default EditorPanels;
