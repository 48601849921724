import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors } from '../../utils/colors';

export const BackLink = styled(Link)`
  color: ${colors.primary0};
  font-size: 12px;

  &:hover {
    color: ${colors.primary2};

    text-decoration: none;
  }
`;
