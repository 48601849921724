import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppToaster } from '../../app/Toaster';
import { ReactComponent as AddToPageIllustrationIcon } from '../../assets/icons/illustration/standard/add-to-page.svg';
import { Library } from '../../gql/library/types';
import { useModal } from '../../utils/hooks/modal';
import { Button } from '../Button';
import { Modal } from '../Modal';
import PopoverLegacy, { Selected } from '../Popover/PopoverLegacy';
import { SearchInput } from '../inputs/SearchInput';
import { AddToLibraryModalContent } from './AddToLibrary';
import { AddToPageModalContent } from './AddToPage';
import { AddToElement, AddToSubModalProps, AddToTarget } from './types';

type Props = {
  targets: Array<AddToTarget>;
  element: {
    type: AddToElement;
    id: string;
  };
  disabled: boolean;
  label: string;
};

const addOptions: Selected[] = [
  {
    title: 'Page',
    value: AddToTarget.PAGE,
  },
  {
    title: 'Library',
    value: AddToTarget.LIBRARY,
  },
];

const addToModals = {
  [AddToTarget.PAGE]: AddToPageModalContent,
  [AddToTarget.LIBRARY]: AddToLibraryModalContent,
};

const ADD_TO_MODAL_KEY = 'ADD_TO_MODAL_KEY';

export const ButtonAddTo: FC<Props> = ({
  targets,
  element,
  disabled,
  label,
}) => {
  const { t } = useTranslation();

  const [filter, setFilter] = useState<string>('');
  const [ActiveModal, setActiveModal] = useState<FC<AddToSubModalProps> | null>(
    null,
  );

  const { isModalOpen, openModal, closeModal } = useModal();

  const targetsSerialized = targets.toString();
  const selections = useMemo(() => {
    return addOptions.filter(({ value }) =>
      targets.includes(value as AddToTarget),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetsSerialized]);

  return (
    <>
      {selections.length === 1 ? (
        <Button
          variant="ghost"
          size="small2"
          disabled={disabled}
          onClick={() => {
            setActiveModal(
              () => addToModals[selections[0].value as AddToTarget],
            );
            openModal(ADD_TO_MODAL_KEY);
          }}
        >
          {label}
        </Button>
      ) : (
        <PopoverLegacy
          disabled={disabled}
          usePortal={true}
          onClick={({ selected }) => {
            setActiveModal(() => addToModals[selected.value as AddToTarget]);
            openModal(ADD_TO_MODAL_KEY);
          }}
          selectedContent={{} as any}
          selections={selections}
        >
          <Button variant="ghost" disabled={disabled} size="small2">
            {label}
          </Button>
        </PopoverLegacy>
      )}
      {isModalOpen(ADD_TO_MODAL_KEY) && ActiveModal && (
        <Modal
          size="large"
          title={label}
          onClose={() => {
            closeModal(ADD_TO_MODAL_KEY);
          }}
          icon={<AddToPageIllustrationIcon />}
        >
          <div className="mb-4 mt-[-40px] flex justify-end">
            <div className="w-[17.5rem]">
              <SearchInput
                placeholder={t('common.search-placeholder')}
                onFilterChange={setFilter}
              />
            </div>
          </div>
          <div className="h-[70vh]">
            <ActiveModal
              search={filter}
              element={element}
              onAdded={(library: Library, isNewVersion: boolean) => {
                setActiveModal(null);

                let message = 'Successfully added to a draft library';

                if (isNewVersion) {
                  message =
                    'Created a new draft of the library. You can see it in the usages.';
                }

                AppToaster.show({
                  message,
                });
              }}
            />
          </div>
        </Modal>
      )}
    </>
  );
};
