import React, { FC, Fragment } from 'react';
import { useFilters } from '../../../utils/filters';
import Slider from '../../../components/Slider';
import { FilterSelection, UpdateFiltersOptions } from '..';
import {
  ResetBtn,
  SectionFiltersWrapper,
  SectionTitle,
  SectionWrapper,
  SliderWrapper,
} from './styles';
import {
  FilterSection,
  FILTER_TYPES,
  BuiltWidgetFilter,
} from '../../../gql/widget/types';

type Props = {
  scenario: FilterSection;
  selectedFilters: FilterSelection[];
  updateSelectedFilters: (newVal: any, options?: UpdateFiltersOptions) => void;
  isSm?: boolean;
  isPage?: boolean;
};

const ScenarioSection: FC<Props> = ({
  scenario,
  selectedFilters,
  updateSelectedFilters,
  isSm,
  isPage,
}) => {
  const filters = useFilters(
    scenario.filters as unknown as BuiltWidgetFilter[],
  );

  if (!filters?.length) {
    return null;
  }

  return (
    <Fragment>
      <SectionWrapper>
        {scenario.title && (
          <SectionTitle isPage={isPage}>{scenario.title}</SectionTitle>
        )}
        {isPage && <div className="p-divider__default u-margin-bottom-20" />}
        <SectionFiltersWrapper filtersNum={filters.length} isSm={isSm}>
          {filters?.map(
            ({
              values,
              id,
              type,
              title,
              tiltLabels,
              defaultSelectedFilter,
            }) => {
              if (type !== FILTER_TYPES.SLIDER) {
                return null;
              }

              const selectedFilter = selectedFilters.find(
                ({ id: filterId }) => filterId === id,
              );

              const appliedFilter =
                selectedFilter || defaultSelectedFilter || values[0];

              const valInd = values.findIndex(
                ({ value }) => value === appliedFilter.value,
              );

              return (
                <SliderWrapper key={id}>
                  <Slider
                    tiltLabels={tiltLabels}
                    disableAutoTilt
                    disableAutoLabels
                    title={title}
                    labelRenderer={(ind, opts) => {
                      if (valInd === ind && !opts?.isHandleTooltip) {
                        return '';
                      }

                      const title = values[ind]?.title || '';

                      if (valInd === ind && opts?.isHandleTooltip) {
                        return title;
                      }

                      return (
                        <div>
                          {title.length >= 12
                            ? `${title.substring(0, 10)}...`
                            : title}
                        </div>
                      );
                    }}
                    onChange={(ind) => {
                      updateSelectedFilters({
                        id,
                        value: values[ind].value,
                        title: values[ind].title,
                        type: FILTER_TYPES.SLIDER,
                      });
                    }}
                    value={valInd}
                    min={0}
                    max={values.length - 1}
                  />
                </SliderWrapper>
              );
            },
          )}
        </SectionFiltersWrapper>
        <ResetBtn
          lg
          onClick={() => {
            const resetScenarioValues = filters.map(
              ({ id, defaultSelectedFilter, values }) => {
                const initialFilter = defaultSelectedFilter || values[0];

                return {
                  id,
                  value: initialFilter.value,
                  title: initialFilter.title,
                  type: FILTER_TYPES.SLIDER,
                };
              },
            );

            updateSelectedFilters(resetScenarioValues, {
              noDebounce: true,
              multiple: true,
            });
          }}
        >
          Zurücksetzen
        </ResetBtn>
      </SectionWrapper>
      {!isPage && <div className="p-divider__default" />}
    </Fragment>
  );
};

export default ScenarioSection;
