import { useContext } from 'react';
import ModalContext from '../../context/modal.context';

export const useModal = () => {
  const contextValue = useContext(ModalContext);

  if (!contextValue) {
    throw new Error(
      "Modal hasn't been initialized, have you installed the ModalProvider at the root?",
    );
  }

  return contextValue;
};
