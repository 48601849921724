import React, {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Editor, {
  DiffEditor,
  EditorProps,
  Monaco,
  OnChange,
} from '@monaco-editor/react';
import { editor } from 'monaco-editor';
import { useDebouncedCallback } from 'use-debounce';

type IStandaloneDiffEditor = editor.IStandaloneDiffEditor;
type IStandaloneCodeEditor = editor.IStandaloneCodeEditor;

type Props = {
  autosaveDeps?: any[];
  editorRef?: MutableRefObject<editor.IStandaloneCodeEditor | null>;
  language?: string;
  monacoRef?: MutableRefObject<Monaco | null>;
  original?: string | null;
  schema?: Record<string, any> | null;
  onAutoSave?: (val: string) => void;
  onSave?: (val: string) => void;
} & EditorProps;

export const getEditorValue = (ref: any): string => {
  const editor = ref.current;

  return editor?.getModifiedEditor?.().getValue() || editor?.getValue?.();
};

const DEFAULT_SCHEMA_URI = 'https://myserver/foo-schema.json';

const JsonEditor: FC<Props> = ({
  editorRef,
  monacoRef: propsMonacoRef,
  onSave,
  onAutoSave,
  autosaveDeps = [],
  onChange: propsOnChange,
  original,
  schema,
  language = 'json',
  onMount,
  value = '',
  ...props
}) => {
  const localEditorRef = useRef<
    IStandaloneCodeEditor | IStandaloneDiffEditor | null
  >(null);

  const monacoRef = useRef<Monaco | null>(null);

  const [isMounted, setIsMounted] = useState(false);
  const [oldVersion, setOldVersion] = useState('');

  useEffect(() => {
    const currentRef = editorRef?.current;

    if (!currentRef) {
      return;
    }

    const cursorPosition = currentRef.getSelection();
    currentRef.getModel()?.setValue(value);
    if (cursorPosition) {
      currentRef.setSelection(cursorPosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorRef?.current, value]);

  const setSchema = useCallback(() => {
    monacoRef.current?.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      trailingCommas: 'error',
      schemaValidation: 'error',
      allowComments: true,
      schemas: [
        {
          uri: DEFAULT_SCHEMA_URI,
          fileMatch: ['*'],
          schema,
        },
      ],
    });
  }, [schema]);

  const handleEditorDidMount = (editor: any, monaco: Monaco) => {
    if (editorRef) {
      editorRef.current = editor;
    }

    if (propsMonacoRef) {
      propsMonacoRef.current = monaco;
    }

    localEditorRef.current = editor;
    monacoRef.current = monaco;

    onMount?.(editor, monaco);

    if (original) {
      (editor as IStandaloneDiffEditor)
        ?.getModifiedEditor?.()
        .onDidChangeModelContent((e) =>
          onChange(getEditorValue(localEditorRef), e),
        );
    }

    monaco.editor.defineTheme('polyteia', {
      base: 'vs',
      colors: {
        'editor.background': '#ffffff00',
      },
      rules: [],
      inherit: true,
    });

    monaco.editor.setTheme('polyteia');

    setIsMounted(true);
  };

  const save = useCallback(
    (e: KeyboardEvent) => {
      if (
        onSave &&
        e.key === 's' &&
        (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)
      ) {
        e.preventDefault();

        onSave(getEditorValue(localEditorRef));
      }
    },
    [onSave],
  );

  const debouncedAutosave = useDebouncedCallback((omit?: boolean) => {
    if (omit) {
      return;
    }

    const val = getEditorValue(localEditorRef);

    if (oldVersion.length && val !== oldVersion) {
      onAutoSave?.(val);
      setOldVersion(val);
    } else if (!oldVersion.length) {
      setOldVersion(val);
    }
  }, 3000);

  const onChange: OnChange = useCallback(
    (val, e) => {
      debouncedAutosave(true);

      propsOnChange?.(val, e);
    },
    [debouncedAutosave, propsOnChange],
  );

  useEffect(() => {
    setOldVersion('');
    const intervalId = setInterval(debouncedAutosave, 60 * 1000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAutosave, ...autosaveDeps]);

  useEffect(() => {
    document.addEventListener('keydown', save);

    return () => document.removeEventListener('keydown', save);
  }, [save]);

  useEffect(() => {
    if (schema && isMounted) {
      setSchema();
    }
  }, [isMounted, schema, setSchema]);

  return original ? (
    <DiffEditor
      language={language}
      onMount={handleEditorDidMount}
      modified={value}
      original={original}
      height={props.height}
    />
  ) : (
    <Editor
      defaultLanguage={language}
      onMount={handleEditorDidMount}
      {...props}
      onChange={onChange}
    />
  );
};

export default JsonEditor;
