import cn from 'classnames';
import { FieldProps, getIn } from 'formik';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useClickOutside } from '../../../utils/hooks/useClickOutside';
import { useKeyEvent } from '../../../utils/hooks/useKeyEvent';
import { usePositionStyles } from '../../../utils/hooks/usePositionStyles';
import { DropdownList, Props as DropdownListProps } from '../../DropdownList';
import { BaseLabelProps, InputLabel } from '../InputLabel';

import { ReactComponent as ChevronDownIcon } from '../../../assets/icons/ui/s/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from '../../../assets/icons/ui/s/chevron-up.svg';
import { ReactComponent as ChevronDownXSIcon } from '../../../assets/icons/ui/xs/chevron-down.svg';
import { ReactComponent as ChevronUpXSIcon } from '../../../assets/icons/ui/xs/chevron-up.svg';

type Option<V> = DropdownListProps<V>['items'][number];

type Props<V> = FieldProps & {
  id?: string;
  /**
   * Adds a placeholder text which will be shown in the input without any selection. In case of variant compact it is not needed.
   */
  placeholder: string;
  /**
   * Add the data you want to be in your dropdown list
   */
  options: Option<V>[];
  /**
   * Add the keys for the disabled items.
   */
  disabledKeys?: (string | number)[];
  /**
   * Determines wether you can select multiple, which is not an option for variant compact
   */
  multiple: boolean;
  /**
   * Changes appearance to compact
   */
  variant?: 'compact';
  /**
   * Determines the size of the compact variant
   */
  size?: 'small' | 'large';
  /**
   * Determines whether the dropdown is right-aligned.
   */
  alignment?: 'right';
  /**
   * Add additional left padding.
   */
  hasLeftPadding?: boolean;
  /**
   * Show item icon in the dropdown.
   */
  showIcon?: boolean;
  /**
   * The set function to update whether the dropdown is opened.
   */
  setIsSelectOpen?: Dispatch<SetStateAction<boolean>>;
  /**
   * This event occurs after a single option has been selected.
   */
  onSingleSelect?: (value: Option<V>['value']) => void;
} & BaseLabelProps;

export default function Select<V>(props: Props<V>) {
  const {
    id,
    options,
    disabledKeys,
    placeholder,
    multiple = false,
    disabled,
    field,
    form,
    variant,
    size,
    alignment,
    hasLeftPadding,
    showIcon,
    setIsSelectOpen,
    onSingleSelect,
  } = props;

  const isCompact = variant === 'compact';

  const generatedId = useId();

  const [isOpen, setIsOpenInternal] = useState(false);

  const setIsOpen = useCallback(
    (isOpen: SetStateAction<boolean>) => {
      setIsOpenInternal(isOpen);
      setIsSelectOpen?.(isOpen);
    },
    [setIsSelectOpen],
  );

  const blurRef = useRef(false);

  const { elementRef } = useClickOutside<HTMLDivElement>(
    () => setIsOpen(false),
    isOpen,
  );
  useKeyEvent(
    'Escape',
    () => {
      setIsOpen(false);
    },
    isOpen,
  );
  useKeyEvent(['Enter', ' '], () => {
    if (!elementRef.current) {
      return;
    }
    const button = elementRef.current.querySelector('button');
    if (button && document.activeElement === button && !isOpen) {
      setIsOpen(true);
    }
  });

  const onSelectHandler = (value: Option<V>['value']) => {
    if (multiple) {
      const values = new Set(field.value);
      values.has(value) ? values.delete(value) : values.add(value);
      form.setFieldValue(field.name, Array.from(values));
      return;
    }
    form.setFieldValue(field.name, value);
    onSingleSelect?.(value);
    setIsOpen(false);
  };

  const onSelectAllHandler = (
    newValues: Option<V>['value'][],
    selectAll: boolean,
  ) => {
    if (multiple) {
      const values = new Set(field.value);
      selectAll
        ? newValues.forEach((value) => values.add(value))
        : newValues.forEach((value) => values.delete(value));
      form.setFieldValue(field.name, Array.from(values));
      return;
    }
  };

  const { styles: positionStyles } = usePositionStyles(elementRef, {
    distance: 8,
  });
  const placeholderText = isCompact ? options[0].label : placeholder;

  const caption = useMemo(() => {
    if (multiple) {
      return options
        .filter((option) => field.value.includes(option.value))
        .map((options) => options.label)
        .join(', ');
    }
    return options.find((option) => option.value === field.value)?.label;
  }, [field.value, multiple, options]);

  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  const styles = {
    defaultStyles: {
      select: [
        'h-11',
        'w-full',
        'justify-between',
        'border',
        'border-concrete-jungle-5',
        'bg-white',
        'pr-4',
        'pl-3.5',
        'text-sm',
        'disabled:border-concrete-jungle-5',
        'disabled:bg-concrete-jungle-8',
        'focus:border-primary',
        'hover:border-concrete-jungle-3',
        'ring-offset-2',
      ],
      text: [
        cn('disabled:text-disabled', {
          'font-[350] italic text-concrete-jungle-3': Array.isArray(field.value)
            ? !field.value.length
            : !field.value,
          'overflow-hidden text-ellipsis whitespace-nowrap text-concrete-jungle-1':
            Array.isArray(field.value) ? field.value.length : field.value,
          'not-italic !text-primary': isOpen,
        }),
      ],
    },
    compactStyles: {
      select: [
        'p-0.5',
        'tracking-wide',
        'bg-transparent',
        'rounded-lg',
        'max-w-[180px]',
        'w-fit',
        'gap-2',
        'disabled:text-disabled',
      ],
      text: [
        cn('bg-transparent overflow-hidden text-ellipsis whitespace-nowrap', {
          'text-base': size === 'large',
          'text-xs': size === 'small',
          'group-hover/select:text-blueberry-3': !disabled,
        }),
      ],
      isSmallAndOpen: isOpen && size === 'small',
      isSmallAndClosed: !isOpen && size === 'small',
    },
    sharedStyles: {
      select:
        'outline-none ring-focusBlue flex items-center rounded-md disabled:cursor-not-allowed text-primary',
      icon: {
        'text-blueberry-1 disabled:text-disabled': isCompact,
        'group-hover/select:text-blueberry-3': isCompact && !disabled,
        'text-disabled': isCompact && disabled,
      },
    },
  };
  const { compactStyles, sharedStyles, defaultStyles } = styles;
  const currentStyle = isCompact ? compactStyles : defaultStyles;

  return (
    <InputLabel {...props} error={touched && error} htmlFor={id || generatedId}>
      <div
        ref={elementRef}
        className={cn('relative', { 'pl-40': hasLeftPadding })}
      >
        <div className="group/select">
          <button
            className={cn([sharedStyles.select, ...currentStyle.select], {
              'border border-raspberry-3': touched && error,
              'focus:ring-4': isOpen && !isCompact,
              'focus-visible:ring-4': isOpen && isCompact,
            })}
            type="button"
            disabled={disabled}
            data-testid="select-input"
            onFocus={() => {
              setIsOpen(true);
              blurRef.current = true;
            }}
            onBlur={() => {
              setIsOpen(false);
              field.onBlur({
                target: { value: field.value, name: field.name },
              });
            }}
            onMouseUp={() => {
              if (blurRef.current) {
                blurRef.current = false;
                return;
              }
              setIsOpen((state) => !state);
            }}
          >
            <p className={cn(currentStyle.text)}>
              {caption || placeholderText}
            </p>
            <div className={cn(sharedStyles.icon)}>
              {compactStyles.isSmallAndOpen ? (
                <ChevronUpXSIcon />
              ) : isOpen ? (
                <ChevronUpIcon />
              ) : compactStyles.isSmallAndClosed ? (
                <ChevronDownXSIcon />
              ) : (
                <ChevronDownIcon />
              )}
            </div>
          </button>
        </div>
        {isOpen && (
          <div
            className={cn('absolute z-20', {
              'inset-x-0': alignment !== 'right',
              'right-0': alignment === 'right',
            })}
            style={positionStyles}
          >
            <DropdownList
              items={options}
              disabledKeys={disabledKeys}
              selection={field.value}
              onSelect={onSelectHandler}
              onSelectAll={onSelectAllHandler}
              multiple={multiple}
              showCheckmark={false}
              noWrap={alignment === 'right'}
              showIcon={showIcon}
            />
          </div>
        )}
      </div>
    </InputLabel>
  );
}
