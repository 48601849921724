import cn from 'classnames';
import { Field } from 'formik';
import {
  DragEventHandler,
  FC,
  FocusEventHandler,
  ReactElement,
  ReactNode,
  SVGProps,
  useState,
} from 'react';
import { Badge, Variant as BadgeVariant } from '../Badge';
import { Option } from '../DropdownList';
import type { Props as IconButtonProps } from '../IconButton';
import { InlineAlert, InlineAlertSize, InlineAlertType } from '../InlineAlert';
import { Select } from '../inputs/Select';

type DraggableListItemState = 'default' | 'warning' | 'inactive';

type Props = {
  state: DraggableListItemState;
  icon?: FC<SVGProps<SVGSVGElement>>;
  title: string;
  badge?: string;
  buttons?: () => ReactElement<IconButtonProps>[];
  dropdownFieldName?: string;
  dropdownTitle?: string;
  dropdownOptions?: Option<string>[];
  additionalTextTitle?: string;
  additionalText?: string;
  messageTitle?: string;
  message?: ReactNode;
  transform3D?: boolean;
  draggable?: boolean;
  droppable?: boolean;
  onDragStart?: DragEventHandler<HTMLDivElement>;
  onDragEnd?: DragEventHandler<HTMLDivElement>;
  onDrop?: DragEventHandler<HTMLDivElement>;
  onFocus?: FocusEventHandler<HTMLDivElement>;
  onBlur?: FocusEventHandler<HTMLDivElement>;
};

const getBadgeVariant = (
  state: DraggableListItemState,
  isDragging: boolean,
): BadgeVariant => {
  if (isDragging) {
    return 'disabled';
  }

  switch (state) {
    case 'inactive':
      return 'disabled';
    case 'warning':
      return 'warning';
    case 'default':
    default:
      return 'neutral';
  }
};

const DraggableListItem = ({
  state,
  icon: Icon,
  title,
  badge,
  buttons,
  dropdownFieldName,
  dropdownTitle,
  dropdownOptions,
  additionalText,
  additionalTextTitle,
  messageTitle,
  message,
  transform3D,
  draggable = true,
  droppable,
  onDragStart,
  onDragEnd,
  onDrop,
  onFocus,
  onBlur,
}: Props) => {
  const [isDragging, setIsDragging] = useState(false);

  return (
    <div className="flex flex-col gap-2">
      <div
        className={cn(
          'flex cursor-pointer flex-col gap-1 rounded-sm p-1 focus-visible:z-10 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-focusBlue',
          {
            'bg-extended-1-5': !isDragging && state === 'default',
            'bg-amber-2': !isDragging && state === 'warning',
            'bg-concrete-jungle-7': isDragging || state === 'inactive',
          },
          {
            'cursor-grabbing': isDragging,
            'transform-gpu': transform3D,
          },
        )}
        style={{
          cursor: isDragging ? 'grabbing' : 'pointer',
        }}
        tabIndex={!buttons && !dropdownOptions?.length ? 0 : undefined}
        draggable={draggable && state !== 'inactive'}
        onDragStart={(e) => onDragStart?.(e)}
        onDrag={() => setIsDragging(true)}
        onDragEnd={(e) => {
          setIsDragging(false);
          onDragEnd?.(e);
        }}
        onDragOver={droppable ? (e) => e.preventDefault() : undefined}
        onDrop={(e) => onDrop?.(e)}
        onFocus={(e) => onFocus?.(e)}
        onBlur={(e) => onBlur?.(e)}
      >
        <div className="flex items-center gap-2">
          {Icon && <Icon />}
          <span className="grow text-sm">{title}</span>
          {badge && (
            <Badge text={badge} variant={getBadgeVariant(state, isDragging)} />
          )}
          {buttons?.()}
        </div>
        {dropdownFieldName && dropdownOptions?.length && (
          <div className="flex items-center justify-between">
            <span className="text-xs">{`${dropdownTitle ?? ''}:`}</span>
            <div className="relative flex max-w-xs grow justify-end">
              <Field
                component={Select<string>}
                name={dropdownFieldName}
                variant="compact"
                size="small"
                alignment="right"
                options={dropdownOptions}
              />
            </div>
          </div>
        )}
        {(additionalTextTitle || additionalText) && (
          <div className="flex items-center justify-between text-xs">
            <span>{`${additionalTextTitle ?? ''}:`}</span>
            <span>{additionalText}</span>
          </div>
        )}
      </div>
      {messageTitle && (
        <InlineAlert
          title={messageTitle}
          type={InlineAlertType.INFO}
          size={InlineAlertSize.SMALL}
          hideTitle
          disablePadding
          hasGrayBg
        >
          {message}
        </InlineAlert>
      )}
    </div>
  );
};

export default DraggableListItem;
