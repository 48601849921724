import React, { FC } from 'react';
import { getFormattedValue } from '../../../utils/format';
import { HoverSeries } from '../utils/hovers';

enum AGGREGATION_FUNCTION {
  AVG = 'avg',
  SUM = 'sum',
}

type Props = {
  aggregationLabel?: string;
  aggregationFunction?: AGGREGATION_FUNCTION | string;
  hoverSeries: HoverSeries[];
  domainValsIndex: number;
};

const separatorStyle = {
  border: 0,
  backgroundColor: '#ebebeb',
  height: '1px',
};

const aggregationValueStyle = {
  fontWeight: 500,
};

const AggregationFooter: FC<Props> = ({
  aggregationLabel,
  aggregationFunction,
  hoverSeries,
  domainValsIndex,
}) => {
  const resolveAggregation = (series: HoverSeries[], idx: number) => {
    let aggregationValue = '';
    const reduceAggregation = (hovers: number, hover: HoverSeries) => {
      const num = !isNaN(+hover.info[idx]) ? parseFloat(hover.values[idx]) : 0;

      return hovers + num;
    };

    const sum = series.reduce(reduceAggregation, 0);
    const type = series[series?.length - 1].type;

    if (aggregationFunction === AGGREGATION_FUNCTION.SUM) {
      aggregationValue = getFormattedValue(sum.toString(), type);
    } else if (aggregationFunction === AGGREGATION_FUNCTION.AVG) {
      aggregationValue = getFormattedValue(
        (sum / series.length).toString(),
        type,
      );
    }

    return aggregationValue;
  };

  return (
    <div className="u-margin-top-10">
      <hr style={separatorStyle} />
      <div className="u-margin-top-10 u-align-center-y u-justify-space-between">
        <div className="font-12 colorMonochromatic2">{aggregationLabel}</div>
        <div style={aggregationValueStyle}>
          {resolveAggregation(hoverSeries, domainValsIndex)}
        </div>
      </div>
    </div>
  );
};

export default AggregationFooter;
