import { useMemo } from 'react';

export const useLegendData = (originalValues: number[]) =>
  useMemo(() => {
    const negatives = originalValues.filter((n) => n < 0);
    const positives = originalValues.filter((n) => n >= 0);

    const oneNegative = negatives.length === 1;
    const onePositive = positives.length === 1;

    const hasPositive = !!positives.length && !onePositive;
    const hasNegative = !!negatives.length && !oneNegative;

    const hasBoth = hasNegative && hasPositive;

    return {
      negatives,
      positives,
      oneNegative,
      onePositive,
      hasPositive,
      hasNegative,
      hasBoth,
    };
  }, [originalValues]);

const epsilon = 1e-12;

export const useNormalizedValues = (
  values: number[],
  positiveValues: number[],
  negativeValues: number[],
) =>
  useMemo(() => {
    const negativeMax = Math.min(...negativeValues) - epsilon;
    const positiveMax = Math.max(...positiveValues) + epsilon;

    const reductionIndex: number = 0.8;

    return values.map(
      (el) =>
        (el * reductionIndex) / (el < 0 ? -negativeMax : positiveMax) +
        (el >= 0 ? 0.2 : -0.2),
    );
  }, [negativeValues, positiveValues, values]);

export const useColorsDomainStart = (values: number[], isFromMin: boolean) =>
  useMemo(() => {
    let positiveColorDomainStart = 0;
    let negativeColorDomainStart = 0;

    const p = values.filter((val) => val >= 0);
    const minP = Math.min(...p);

    const n = values.filter((val) => val < 0);
    const maxN = Math.max(...n);

    if (isFromMin) {
      const minN = Math.min(...n);

      if (maxN === minN) {
        negativeColorDomainStart = 0;
      } else if (maxN < 0) {
        const startN = maxN + Math.abs(minN - maxN) * 0.2;
        negativeColorDomainStart = startN < 0 ? startN : 0;
      }

      const maxP = Math.max(...p);

      if (minP === maxP) {
        positiveColorDomainStart = 0;
      } else {
        const startP = minP - (maxP - minP) * 0.2;

        positiveColorDomainStart = startP >= 0 ? startP : 0;
      }
    }

    return {
      positiveColorDomainStart,
      negativeColorDomainStart: Math.abs(negativeColorDomainStart),
      minP,
      maxN,
    };
  }, [isFromMin, values]);
