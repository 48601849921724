import { gql } from '@apollo/client';
import { EditorMode, IFile } from '../common/types';
import { structureFragment, tagFragment } from './fragments';
import { ITag, Structure, StructureAccess, StructureConfig } from './types';
import { Page } from '../page/types';

export type CreateStructureData = { createStructure: Structure };

export type CreateStructureVars = {
  CreateStructureInput: {
    structureConfig: StructureConfig;
    access?: StructureAccess;
    icon?: IFile;
    editorMode?: EditorMode;
  };
};

export const CREATE_STRUCTURE = gql`
  mutation CreateStructure($CreateStructureInput: CreateStructureInput!) {
    createStructure(CreateStructureInput: $CreateStructureInput) {
      ...Structure
    }
  }
  ${structureFragment}
`;

export type CreateStructureVersionData = {
  createStructureVersion: Structure;
};

export type CreateStructureVersionVars = {
  CreateStructureInput: {
    structureConfig: StructureConfig;
    access?: StructureAccess;
    icon?: IFile;
    editorMode: EditorMode;
  };
  id: string;
};

export const CREATE_STRUCTURE_VERSION = gql`
  mutation CreateStructureVersion(
    $CreateStructureInput: CreateStructureInput!
    $id: String!
  ) {
    createStructureVersion(
      CreateStructureInput: $CreateStructureInput
      id: $id
    ) {
      ...Structure
    }
  }
  ${structureFragment}
`;

export type UpdateStructureVersionData = {
  updateStructureVersion: Structure;
};

export type UpdateStructureVersionVars = {
  UpdateStructureVersionInput: {
    versionId: string;
    structureConfig: StructureConfig;
    editorMode: EditorMode;
  };
};

export const UPDATE_STRUCTURE_VERSION = gql`
  mutation UpdateStructureVersion(
    $UpdateStructureVersionInput: UpdateStructureVersionInput!
  ) {
    updateStructureVersion(
      UpdateStructureVersionInput: $UpdateStructureVersionInput
    ) {
      ...Structure
    }
  }
  ${structureFragment}
`;

export type UpdateAndPublishStructureVersionData = {
  updateAndPublishStructureVersion: Structure;
};

export type UpdateAndPublishStructureVersionVars = {
  UpdateAndPublishStructureVersion: {
    versionId: string;
    structureConfig: StructureConfig;
  };
};

export const UPDATE_AND_PUBLISH_STRUCTURE_VERSION = gql`
  mutation UpdateAndPublishStructureVersion(
    $UpdateAndPublishStructureVersion: UpdateAndPublishStructureVersionInput!
  ) {
    updateAndPublishStructureVersion(
      UpdateAndPublishStructureVersion: $UpdateAndPublishStructureVersion
    ) {
      ...Structure
    }
  }
  ${structureFragment}
`;

export type DeleteStructureVersionData = {
  deleteStructureVersion: Structure[];
};

export type DeleteStructureVersionVars = {
  DeleteStructureVersionInput: {
    versionId: string;
  }[];
};

export const DELETE_STRUCTURE_VERSION = gql`
  mutation DeleteStructureVersion(
    $DeleteStructureVersionInput: [DeleteStructureVersionInput!]!
  ) {
    deleteStructureVersions(
      DeleteStructureVersionInput: $DeleteStructureVersionInput
    ) {
      ...Structure
    }
  }
  ${structureFragment}
`;

export type DeleteStructureData = {
  deleteStructure: Structure[];
};

export type DeleteStructureVars = {
  DeleteStructureInput: {
    id: string;
  };
};

export const DELETE_STRUCTURE = gql`
  mutation DeleteStructure($DeleteStructureInput: DeleteStructureInput!) {
    deleteStructure(DeleteStructureInput: $DeleteStructureInput) {
      ...Structure
    }
  }
  ${structureFragment}
`;

export type UploadStructureIconData = { uploadStructureIcon: Structure };

export type UploadStructureIconVars = {
  structureVersionId: string;
  file: File;
};

export const UPDATE_STRUCTURE_ICON = gql`
  mutation UploadStructureIcon($structureVersionId: String!, $file: Upload!) {
    uploadStructureIcon(structureVersionId: $structureVersionId, file: $file) {
      ...Structure
    }
  }
  ${structureFragment}
`;

export type deleteStructureIconData = { deleteStructureIcon: Structure };

export type deleteStructureIconVars = {
  structureVersionId: string;
};

export const DELETE_STRUCTURE_ICON = gql`
  mutation DeleteStructureIcon($structureVersionId: String!) {
    deleteStructureIcon(structureVersionId: $structureVersionId) {
      ...Structure
    }
  }
  ${structureFragment}
`;

export type UpdateTagsData = { updateTags: ITag[] };

export type UpdateTagsVars = {
  updateTagsInput: {
    entityId: string;
    titles: string[];
  };
};

export const UPDATE_TAGS = gql`
  mutation UpdateTags($updateTagsInput: UpdateTagsInput!) {
    updateTags(updateTagsInput: $updateTagsInput) {
      ...Tag
    }
  }
  ${tagFragment}
`;

export type ImportStructureData = {
  importStructure: string;
};

export type ImportStructureVars = {
  fileContents: string;
};

export const IMPORT_STRUCTURE = gql`
  mutation ImportStructure($fileContents: String!) {
    importStructure(fileContents: $fileContents)
  }
`;

export type CreateStructureByExternalUserData = {
  createStructureByExternalUser: Structure;
};

export type CreateStructureByExternalUserVars = {
  CreateStructureInput: {
    structureConfig: Partial<StructureConfig>;
    access?: StructureAccess;
    icon?: IFile;
  };
};

export const CREATE_STRUCTURE_BY_EXTERNAL_USER = gql`
  mutation CreateStructureByExternalUser(
    $CreateStructureInput: CreateStructureInput!
  ) {
    createStructureByExternalUser(CreateStructureInput: $CreateStructureInput) {
      ...Structure
    }
  }
  ${structureFragment}
`;

export type PublishEntitiesData = {
  structure: Structure | null;
  pages: Page[];
};

export type PublishEntitiesVars = {
  publishEntitiesInput: {
    structureVersionId: string | null;
    pagesVersionIds: string[];
  };
};

// TODO: move result to a separate fragment
export const PUBLISH_ENTITIES_VERSION = gql`
  mutation PublishEntitiesVersions(
    $publishEntitiesInput: PublishEntitiesInput!
  ) {
    publishEntitiesVersions(PublishEntitiesInput: $publishEntitiesInput) {
      structure {
        id
        versionId
      }
      pages {
        id
        versionId
      }
    }
  }
`;
