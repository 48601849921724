export const InfoIcon = ({ width = 20, height = 20 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
      fill="#282D61"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 9C10.5523 9 11 9.44772 11 10V13.6C11 14.1523 10.5523 14.6 10 14.6C9.44772 14.6 9 14.1523 9 13.6V10C9 9.44772 9.44772 9 10 9Z"
      fill="#282D61"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 6.3999C9 5.84762 9.44772 5.3999 10 5.3999H10.009C10.5613 5.3999 11.009 5.84762 11.009 6.3999C11.009 6.95219 10.5613 7.3999 10.009 7.3999H10C9.44772 7.3999 9 6.95219 9 6.3999Z"
      fill="#282D61"
    />
  </svg>
);
