import { EffectCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export enum ENV_VAR {
  USERSNAP_GLOBAL_API_KEY = 'REACT_APP_USERSNAP_GLOBAL_API_KEY',
  GRAPHQL_URL = 'REACT_APP_GRAPHQL_URL',
  GRAPHQL_USER_MANAGEMENT_URL = 'REACT_APP_GRAPHQL_USER_MANAGEMENT_URL',
  GRAPHQL_COLLECT_URL = 'REACT_APP_GRAPHQL_COLLECT_URL',
  GRAPHQL_CHRONOS_URL = 'REACT_APP_GRAPHQL_CHRONOS_URL',
  GRAPHQL_ATHENA_URL = 'REACT_APP_GRAPHQL_ATHENA_URL',
  USETIFUL_TOKEN = 'REACT_APP_USETIFUL_TOKEN',
  HUBSPOT_SYNC_ENABLED = 'REACT_APP_HUBSPOT_SYNC_ENABLED',
  HUBSPOT_ID = 'REACT_APP_HUBSPOT_ID',
  HUBSPOT_TRACKING_ENABLED = 'REACT_APP_HUBSPOT_TRACKING_ENABLED',
  APP_URL = 'REACT_APP_URL',
  SHOW_QUERIES_LIBRARY = 'REACT_APP_SHOW_QUERIES_LIBRARY',
  GAINSIGHT_API_KEY = 'REACT_APP_GAINSIGHT_API_KEY',
  ENABLE_PUBLISH_TABLE = 'REACT_APP_ENABLE_PUBLISH_TABLE',
  ENABLE_CSV_DATASETS = 'REACT_APP_CUSTOMER_CSV_DATASETS',
  // Todo: Temporary
  CSV_STRUCTURE_ID = 'REACT_APP_CSV_STRUCTURE_ID',
  CSV_DATASET_TABLE_NAME = 'REACT_APP_CSV_DATASET_TABLE_NAME',
}

export const getEnvVar = (envVar: ENV_VAR) => {
  return (
    (window._env_ as Record<string, string>)?.[envVar] || process.env[envVar]
  );
};

export const useMountEffect = (effect: EffectCallback) => {
  //  eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
};

export const WIDGET_BREAKPOINTS = {
  SM: 480,
  MD: 1000,
};

export const useQueryParam = (paramName: string) => {
  const search = useLocation().search;
  const param = new URLSearchParams(search).get(paramName);

  return param && JSON.parse(param);
};

export const includesWordStartsWith = (text: string, filter: string) => {
  const normalizedFilter = filter.toLowerCase();
  const normalizedText = text.toLowerCase();
  const normalizedWords = [normalizedText, ...normalizedText.split(' ')];
  return normalizedWords.some((word) => word.startsWith(normalizedFilter));
};
