import React, { FC } from 'react';
import Switch from '../../../components/Switch';
import { SETTINGS } from './index';
import { Title } from './styles';

type Props = {
  val?: boolean;
  setSettingsVal?: React.Dispatch<React.SetStateAction<Record<SETTINGS, any>>>;
  onChange?: (newVal: any) => void;
};

const ShowValue: FC<Props> = ({ val, setSettingsVal, onChange }) => {
  return (
    <div>
      <Title>Werte im Diagramm anzeigen</Title>
      <Switch
        large
        label={val ? 'Ja' : 'Nein'}
        checked={val}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const newVal = e.target.checked;

          setSettingsVal?.((prevState) => ({
            ...prevState,
            [SETTINGS.SHOW_VALUE]: newVal,
          }));
          onChange?.(newVal);
        }}
      />
    </div>
  );
};

export default ShowValue;
