import cn from 'classnames';
import { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DangerTriangle } from '../../../assets/icons/danger-triangle.svg';
import { Popover } from '../../Popover';
import { InfoPoint } from './icons';

type Props = {
  label?: string;
  htmlFor: string;
  disabled?: boolean;
  required?: boolean;
  info?: string;
  infoBgColor?: string;
  hint?: string;
  error?: string;
};

export type BaseLabelProps = Omit<Props, 'htmlFor'>;

export default function InputLabel({
  htmlFor,
  label,
  children,
  disabled = false,
  required,
  info,
  infoBgColor,
  hint,
  error,
}: PropsWithChildren<Props>) {
  const { t } = useTranslation();

  if (!label) {
    return <>{children}</>;
  }

  return (
    <div className="flex w-full flex-col">
      <div className="mb-1 flex flex-row items-center justify-start">
        <label
          htmlFor={htmlFor}
          className={cn('text-sm text-concrete-jungle-1', {
            '!text-disabled': disabled,
          })}
        >
          {label}
        </label>
        {required && (
          <span
            className={cn('ml-1 text-sm text-raspberry-3', {
              '!text-disabled': disabled,
            })}
          >
            *
          </span>
        )}
        {info && (
          <div className="cursor-pointer">
            <Popover text={info}>
              <InfoPoint color={disabled ? '#757575' : '#282d61'} />
            </Popover>
          </div>
        )}
        {error && (
          <div className="ml-auto">
            <DangerTriangle width={16} aria-label={t('error.validation')} />
          </div>
        )}
      </div>
      <div className="relative">{children}</div>
      {error && (
        <p
          aria-live="off"
          className="peer mt-2 text-xs italic text-raspberry-2"
        >
          {error}
        </p>
      )}
      {hint && (
        <p className="mt-2 text-xs italic text-concrete-jungle-3 peer-aria-[live]:hidden">
          {hint}
        </p>
      )}
    </div>
  );
}
