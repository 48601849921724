import React, { FC, ReactNode } from 'react';
import { TopBar, TopBarActions, TopBarTitle } from './styles';

type Props = {
  title: ReactNode;
  actions?: ReactNode;
};

const PageHeader: FC<Props> = ({ title, actions }) => {
  return (
    <TopBar>
      <TopBarTitle>{title}</TopBarTitle>
      {actions && <TopBarActions>{actions}</TopBarActions>}
    </TopBar>
  );
};

export default PageHeader;
