import { FC, ReactElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PolyteiaIcon } from '../../assets/icons/polyteia-icon.svg';
import { useEditingContext } from '../../containers/Solutions/Structure/editing';
import { getIsGroupEditor } from '../../gql/user/local';
import { CustomLogo, StructureTitleInput, StyledTitle } from './styles';

export type Props = {
  afterTitle?: ReactElement;
  defaultTitle: string;
};

const PolyteiaTitle: FC<Props> = ({ defaultTitle, afterTitle }) => {
  const { t } = useTranslation();
  const titleInputRef = useRef<HTMLInputElement | null>(null);
  const { isCustomerEditorModeEnabled, localStructure, updateStructureConfig } =
    useEditingContext();

  const isInput = isCustomerEditorModeEnabled && getIsGroupEditor();

  return (
    <>
      {localStructure?.icon?.file ? (
        <CustomLogo src={localStructure.icon.file} alt="Logo" />
      ) : (
        <PolyteiaIcon />
      )}
      {isInput ? (
        <StructureTitleInput
          ref={titleInputRef}
          value={localStructure?.structureConfig.title}
          onChange={(e) => {
            const title = e.target.value;
            updateStructureConfig({ title, name: title });
          }}
          onFocus={(e) => {
            e.preventDefault();
            const end = e.target.value.length;
            titleInputRef.current?.setSelectionRange(end, end);
          }}
          onBlur={(e) => {
            const currentValue = e.target.value;
            let newValue = currentValue;
            if (!currentValue) {
              newValue = t(`structure.new-structure`);
              updateStructureConfig({ title: newValue, name: newValue });
            }
          }}
        />
      ) : (
        <>
          <span>{defaultTitle}</span>
          <span className="name-for-matomo" style={{ display: 'none' }}>
            {localStructure?.structureConfig.name}
          </span>
        </>
      )}
      {afterTitle}
    </>
  );
};

export const PageTitle: FC<Props> = (props) => {
  return (
    <StyledTitle id="navbar-title">
      <PolyteiaTitle {...props} />
    </StyledTitle>
  );
};
