import React, { FC, useCallback, useMemo } from 'react';
import {
  STRUCTURE_ELEMENT_TYPE,
  StructureConfigElement,
} from '../../../../../gql/structure/types';
import SidebarElement from './SidebarElement';
import { CUSTOMER_SECTION, useEditingContext } from '../../editing';
import Tree, {
  RenderItemParams,
  TreeData,
  TreeDestinationPosition,
  TreeSourcePosition,
} from '@atlaskit/tree';
import { AppToaster } from '../../../../../app/Toaster';
import { useTranslation } from 'react-i18next';
import { getIsAnalyticsEngineer, getIsDA } from '../../../../../gql/user/local';

const ROOT_ID = 'ROOT_ID';

type Props = {
  elements: StructureConfigElement[];
  onPageClick: (pageId: string) => void;
  isEditModeEnabled?: boolean;
  editablePagesIds?: string[];
  selectedPage: string;
};

const SidebarDND: FC<Props> = ({ elements, ...props }) => {
  const { t } = useTranslation();
  const {
    isCustomerEditorModeEnabled,
    localStructure,
    updateStructureElements,
    isFullEditable,
  } = useEditingContext();
  const isDA = getIsDA();

  const [staticElements, editableElements, customerSectionStart] =
    useMemo(() => {
      const isDA = getIsDA();
      const isAE = getIsAnalyticsEngineer();

      const fullEdit = isDA || isAE || isFullEditable;

      let customerSectionStart = fullEdit
        ? -1
        : elements.findIndex(({ text }) => text === CUSTOMER_SECTION);

      if (
        (!fullEdit && customerSectionStart === -1) ||
        !isCustomerEditorModeEnabled
      ) {
        return [elements, [] as StructureConfigElement[]];
      }

      if (elements[0]?.type === STRUCTURE_ELEMENT_TYPE.LABEL && fullEdit) {
        customerSectionStart++;
      }

      return [
        elements.slice(0, customerSectionStart + 1),
        elements.slice(customerSectionStart + 1),
        customerSectionStart,
      ];
    }, [elements, isCustomerEditorModeEnabled, isFullEditable]);

  const editableTree: TreeData = useMemo(() => {
    return {
      rootId: ROOT_ID,
      items: {
        [ROOT_ID]: {
          id: ROOT_ID,
          children: editableElements.map((el) => el.id).filter(Boolean),
          hasChildren: true,
          isExpanded: true,
          isChildrenLoading: false,
        },
        ...editableElements
          .flatMap((el) => (el.groupElements ? [el, ...el.groupElements] : el))
          .reduce((acc, el) => {
            return {
              ...acc,
              [el.id]: {
                id: el.id,
                children: el.groupElements?.map((groupEl) => groupEl.id) || [],
                hasChildren: el.type === STRUCTURE_ELEMENT_TYPE.GROUP,
                isExpanded: el.type === STRUCTURE_ELEMENT_TYPE.GROUP,
                isChildrenLoading: false,
                data: el,
              },
            };
          }, {}),
      },
    };
  }, [editableElements]);

  const onDragEnd = useCallback(
    (source: TreeSourcePosition, destination?: TreeDestinationPosition) => {
      if (
        !destination ||
        destination.index === undefined ||
        isNaN(destination.index) ||
        (source.parentId === destination.parentId &&
          destination.index === source.index)
      ) {
        return;
      }

      let elementsCopy = [...localStructure?.structureConfig.elements!];

      const sInGroup = source.parentId !== ROOT_ID;
      const dInGroup = destination.parentId !== ROOT_ID;

      let dragEl;
      if (sInGroup) {
        const groupInd = elementsCopy.findIndex(
          (el) => el.id === source.parentId,
        );

        const group = elementsCopy[groupInd];

        dragEl = group.groupElements?.[source.index]!;

        elementsCopy = [
          ...elementsCopy.slice(0, groupInd),
          {
            ...group,
            groupElements: group.groupElements?.filter(
              (_, ind) => ind !== source.index,
            ),
          },
          ...elementsCopy.slice(groupInd + 1),
        ];
      } else {
        const sInd = source.index + customerSectionStart! + 1;

        dragEl = elementsCopy[sInd];

        const dGroupEls = elementsCopy.find(
          (el) => el.id === destination.parentId,
        )?.groupElements;

        if (
          dragEl.type === STRUCTURE_ELEMENT_TYPE.GROUP &&
          dInGroup &&
          destination.index !== dGroupEls?.length &&
          isDA
        ) {
          AppToaster.show({
            message: t('structure.group-drop-err'),
            intent: 'danger',
          });

          return;
        }

        elementsCopy.splice(sInd, 1);
      }

      if (dInGroup) {
        const groupInd = elementsCopy.findIndex(
          (el) => el.id === destination.parentId,
        );

        const group = elementsCopy[groupInd];

        const groupEl = group.groupElements!;

        if (groupEl.length === destination.index) {
          elementsCopy.splice(groupInd + customerSectionStart!, 0, dragEl);
        } else {
          elementsCopy = [
            ...elementsCopy.slice(0, groupInd),
            {
              ...group,
              groupElements: [
                ...groupEl?.slice(0, destination.index),
                dragEl,
                ...groupEl?.slice(destination.index),
              ],
            },
            ...elementsCopy.slice(groupInd + 1),
          ];
        }
      } else {
        const dInd = destination.index + customerSectionStart! + 1;

        elementsCopy.splice(dInd!, 0, dragEl);
      }

      updateStructureElements(elementsCopy);
    },
    [
      customerSectionStart,
      localStructure?.structureConfig.elements,
      t,
      updateStructureElements,
      isDA,
    ],
  );

  const renderItem = useCallback(
    ({ item, provided, depth }: RenderItemParams) => {
      const el: StructureConfigElement = item.data;
      const elements = localStructure?.structureConfig.elements!;

      if (!elements) {
        return null;
      }

      const topLevelInd = elements.findIndex((_el) => _el.id === el.id);
      const groupInd = elements.findIndex((_el) =>
        _el.groupElements?.some((groupEl) => groupEl.id === el.id),
      );

      return (
        <SidebarElement
          editable
          ind={
            topLevelInd !== -1
              ? topLevelInd
              : elements[groupInd]?.groupElements?.findIndex(
                  (groupEl) => groupEl.id === el.id,
                )!
          }
          groupInd={groupInd === -1 ? undefined : groupInd}
          element={{
            ...el,
            groupElements: undefined,
            _groupElements: el.groupElements,
          }}
          provided={provided}
          isSubEl={!!depth}
          {...props}
        />
      );
    },
    [localStructure?.structureConfig.elements, props],
  );

  return (
    <>
      {staticElements.map((el, ind) => (
        <SidebarElement key={ind} ind={ind} element={el} {...props} />
      ))}
      {!!editableElements.length && (
        <Tree
          tree={editableTree}
          renderItem={renderItem}
          onDragEnd={onDragEnd}
          isDragEnabled={({ data: { type } }) =>
            type !== STRUCTURE_ELEMENT_TYPE.GROUP_END &&
            type !== STRUCTURE_ELEMENT_TYPE.LABEL &&
            isCustomerEditorModeEnabled
          }
          offsetPerLevel={10}
        />
      )}
    </>
  );
};

export default SidebarDND;
