import styled from 'styled-components';
import { colors } from '../../../../utils/colors';
import Btn from '../../../../components/Btn';
import { ReactComponent as Trash } from '../../../../assets/icons/trash-min.svg';

export const ColPropsWrapper = styled.div`
  padding: 6px 14px 14px;
  background-color: ${colors.monochromatic7};
  border-radius: 8px;

  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ColName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: ${colors.primary0};
`;

export const ColNameText = styled.div`
  flex-shrink: 1;
  max-width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ColFields = styled.div`
  & > * {
    margin-top: 13px;
  }
`;

export const ColSelects = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 30px;
  }
`;

export const ColDescription = styled.div`
  color: ${colors.primary0};
`;

export const DescriptionText = styled.textarea`
  color: ${colors.primary0};
  margin-top: 10px;
  padding: 14px 10px;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  height: 112px;

  background: transparent;
  border: 1px solid ${colors.monochromatic5};
  border-radius: 8px;
  outline: none;
  resize: none;
`;

export const DescAddBtn = styled(Btn)`
  margin-top: 8px;
`;

export const RemoveDesc = styled(Trash)`
  cursor: pointer;
`;
