import { useMemo } from 'react';
import { colors, getColorsFromPalette } from '../../../utils/colors';

export const UNSELECTED_COLOR = `${colors.monochromatic2}50`;

function usePalette(
  dataSeries?: { color?: string }[],
  selectedData?: number | null,
) {
  return useMemo(() => {
    let colors = getColorsFromPalette();
    const customColors = dataSeries?.map(({ color }) => color);

    if (customColors) {
      colors =
        (colors as string[])?.map(
          (defaultColor, ind) => customColors[ind] || defaultColor,
        ) || colors;
    }

    if (selectedData !== null && selectedData !== undefined) {
      colors = (colors as string[])?.map((color, ind) =>
        ind === selectedData ? color : UNSELECTED_COLOR,
      );
    }

    return colors;
  }, [dataSeries, selectedData]);
}

export default usePalette;
