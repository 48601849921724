import React from 'react';
import { CustomLayer } from '@nivo/line';
import { Highlight, HIGHLIGHT_TYPE } from "../../../../../gql/widget/types";
import { HIGHLIGHT_DASHARRAY, TRANSLUCENT_OPACITY } from "../../dataHighlights";

const LineHighlight: CustomLayer = ({
  series,
  lineGenerator,
  xScale: originalXScale,
  yScale: originalYScale,
  lineWidth,
}) => {
  const xScale = originalXScale as (val: string) => number;
  const yScale = originalYScale as (val: string) => number;

  return series.map(({ id, data, customColor, highlight, isScenarios }) => {
    const type = (highlight as Highlight)?.type;
    const isDashed = type === HIGHLIGHT_TYPE.DASHED;
    const isDotted = type === HIGHLIGHT_TYPE.DOTTED;
    const isDash = isDashed || isDotted;
    const strokeDasharray = HIGHLIGHT_DASHARRAY[type];

    return (
      <path
        key={id}
        d={lineGenerator(
          data
            .filter((d) => d.data.y !== null)
            .map((d) => ({
              x: xScale(d.data.x as string),
              y: yScale(d.data.y as string),
            })),
        )}
        fill="none"
        stroke={customColor}
        strokeWidth={lineWidth}
        opacity={
          type === HIGHLIGHT_TYPE.TRANSLUCENT || isScenarios
            ? TRANSLUCENT_OPACITY
            : 1
        }
        strokeLinecap="round"
        strokeDasharray={isDash ? strokeDasharray : 'none'}
      />
    );
  });
};

export default LineHighlight;
