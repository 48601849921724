import { gql } from '@apollo/client';
import { metadata } from '../common/fragments';

export const siteConfigFragment = gql`
  fragment SiteConfig on BaseSiteConfig {
    name
    structures
    pages
    widgets
    dataSources
    evaluatedName
  }
`;

export const siteFragment = gql`
  fragment Site on Site {
    ${metadata}
    siteConfig {
      ...SiteConfig
    }
  }
  ${siteConfigFragment}
`;
