import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import de from './de.json';

export enum LANG {
  EN = 'EN',
  DE = 'DE',
}

const resources = {
  [LANG.EN]: {
    translation: en,
  },
  [LANG.DE]: {
    translation: de,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: LANG.DE,
  interpolation: {
    escapeValue: false,
  },
  returnNull: false,
});

export default i18n;
