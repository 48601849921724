import { useCallback } from 'react';
import { removeToken, removeRefreshToken } from '../../gql/user/local';
import { INITIAL_LOCATION, AUTH_ROUTES } from '../../app/Routes';
import { useHistory } from 'react-router-dom';
import { client } from '../../app/apollo';
import { removeHubspot } from '../hubspot';
import { addUsetiful } from '../usetiful';
import { useUsersnapApi } from '../../components/UsersnapProvider';

export const useLogout = () => {
  const history = useHistory();
  const usersnap = useUsersnapApi();

  return useCallback(async () => {
    removeToken();
    removeRefreshToken();
    removeHubspot();
    localStorage.removeItem(INITIAL_LOCATION);
    sessionStorage.clear();

    try {
      await client.clearStore();
    } catch (e) {}

    usersnap?.init();

    addUsetiful();

    history.push(AUTH_ROUTES[0].path);
  }, [history, usersnap]);
};
