import { useEffect } from 'react';

type Key =
  | 'Escape'
  | 'Enter'
  | ' '
  | 'ArrowDown'
  | 'ArrowUp'
  | 'ArrowLeft'
  | 'ArrowRight'
  | 'Tab';

export const useKeyEvent = (
  key: Key | Key[],
  callback: (event: KeyboardEvent) => void,
  isActive = true,
) => {
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (!isActive) {
        return;
      }
      if (
        (Array.isArray(key) && key.includes(event.key as Key)) ||
        event.key === key
      ) {
        callback(event);
      }
    };

    window.addEventListener('keydown', keyDownHandler);
    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  });
};
