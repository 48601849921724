import { PermissionAccess } from '../types';

export enum PERMISSION_ENTITY_TYPE {
  WIDGET = 'widget',
  PAGE = 'page',
  STRUCTURE = 'structure',
  FORM = 'form',
  LIBRARY = 'library',
}

export type Permission<T> = {
  id: string;
  entityType: PERMISSION_ENTITY_TYPE;
  entityId: string;
  access: PermissionAccess<T>;
};
