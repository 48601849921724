import styled from 'styled-components';
import { colors } from '../../../../../utils/colors';

export const TextTitleWrapper = styled.div<{ subtitle?: boolean }>`
  font-family: 'GT-Eesti-Pro-Display', sans-serif;
  display: flex;
  justify-content: space-between;
  line-height: 36px;
  font-size: ${({ subtitle }) => (subtitle ? '18px' : '26px')};
  font-weight: ${({ subtitle }) => (subtitle ? 400 : 500)};
  font-style: ${({ subtitle }) => (subtitle ? 'italic' : 'normal')};

  position: relative;
  margin-bottom: 3px;
`;

export const TextTitleInput = styled.input`
  border: none;
  outline: none;
  color: ${colors.monochromatic0};
  width: 500px;
  height: 36px;

  &:focus ~ div {
    display: none;
  }
`;

export const TextTitlePlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 3px;
  pointer-events: none;

  color: ${colors.monochromatic3};
`;

export const TextTitleAsterisk = styled.span`
  color: ${colors.secondary0};
`;
