import { Metadata } from '../common/types';

export type QueryDimension = {
  column: string;
  description?: string;
  name: string;
  type: string;
  hidden: boolean;
};

export enum QUERY_OPERATION {
  AVG = 'AVG',
  MAX = 'MAX',
  MIN = 'MIN',
  SUM = 'SUM',
}

export type QueryMeasure = {
  allowedOperations?: QUERY_OPERATION[];
  column: string;
  description?: string;
  name: string;
  type: string;
  hidden: boolean;
};

export type QueryConfigFilterType = 'queries' | QueryConfigType;

export type QueryConfigType =
  | 'dataset'
  | 'filter'
  | 'default-filter'
  | 'stand'
  | 'separator'
  | 'highlight'
  | 'variable'
  | undefined;

export type QueryConfig = {
  name: string;
  title: string;
  query: string;
  dimensions?: QueryDimension[];
  measures?: QueryMeasure[];
  isHidden: boolean;
  type?: QueryConfigType;
  source?: string;
  datasourceId?: string;
  description?: string;
};

export type Query = Metadata & {
  queryConfig: QueryConfig;
  datasourceId: string;
};

export type GetCsvDatasetPreviewVars = {
  queryId: string;
  limit?: number;
};
