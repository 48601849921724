import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import {
  BuiltPage,
  BuiltPageConfig,
  Layout,
  LAYOUT_TYPE_VALS,
} from '../../../gql/page/types';
import {
  Structure,
  STRUCTURE_ELEMENT_TYPE,
  StructureConfig,
  StructureConfigElement,
} from '../../../gql/structure/types';
import { AccessUserInherit, PermissionAccess } from '../../../gql/types';
import { useParams } from 'react-router-dom';
import { AppToaster } from '../../../app/Toaster';
import { v4 } from 'uuid';
import { VERSION_TYPE } from '../../../gql/dataSource/types';
import i18n from '../../../app/i18n';
import { SolutionParams } from '../../../app/Routes';
import { NEW_ITEM_ID_URL } from '../../../constants/entities';
import { INITIAL_COLSPAN } from './Page/PageRow';
import { EditorMode, IFile } from '../../../gql/common/types';
import {
  getIsAnalyticsEngineer,
  getIsDA,
  getLocalUserData,
} from '../../../gql/user/local';

const PERSIST_PAGE_VID_KEY = 'PERSIST_PAGE_VID';

export const persistPageVId = (id: string, versionId: string) => {
  sessionStorage.setItem(`${PERSIST_PAGE_VID_KEY}-${id}`, versionId);
};

export const getPersistedPageVId = (id: string) => {
  return sessionStorage.getItem(`${PERSIST_PAGE_VID_KEY}-${id}`);
};

export const clearPersistedPageVIds = () => {
  Object.keys(sessionStorage)
    .filter((key) => key.startsWith(PERSIST_PAGE_VID_KEY))
    .forEach((key) => sessionStorage.removeItem(key));
};

const NEW_STRUCTURE = {
  id: NEW_ITEM_ID_URL,
  createdAt: '',
  creatorFullName: '',
  createdBy: '',
  updatedAt: '',
  updatedBy: '',
  versionId: NEW_ITEM_ID_URL,
  updatorFullName: '',
  versionNumber: '',
  versionType: VERSION_TYPE.DRAFT,
  editorMode: EditorMode.VISUAL,
  structureConfig: {
    name: '',
    title: i18n.t('structure.new-structure'),
    datasourceId: '', // Empty datasource while creating structure
    url: '',
    info: '',
    elements: [],
  },
};

export type EditorPage = {
  action: EditingAction;
  page: Partial<BuiltPage>;
};

export enum EditingAction {
  CREATE,
  EDIT,
  DELETE,
}

export const DEFAULT_TOP_BAR_TITLE = 'Polyteia';

export const CUSTOMER_SECTION = 'Individuelle Berichte';

export const SIDEBAR_EL_NAME = {
  [STRUCTURE_ELEMENT_TYPE.PAGE]: i18n.t('entity.page'),
  [STRUCTURE_ELEMENT_TYPE.LABEL]: i18n.t('structure.label'),
  [STRUCTURE_ELEMENT_TYPE.SECTION]: i18n.t('structure.section'),
  [STRUCTURE_ELEMENT_TYPE.GROUP]: i18n.t('structure.group'),
  [STRUCTURE_ELEMENT_TYPE.LINK]: i18n.t('structure.link'),
  [STRUCTURE_ELEMENT_TYPE.GROUP_END]: '',
};

export enum DND_TYPE {
  ROW = 'ROW',
  WIDGET = 'WIDGET',
}

export const GERMAN_TEXT = /^[A-Za-z0-9äöüÄÖÜß\s!%*()_\-=+[\]{}@,.?&]*$/;

export const PAGE_TITLE_MAX_LENGTH = 30;

export const getValidLengthTitle = (title: string) => {
  if (title.length === PAGE_TITLE_MAX_LENGTH + 1 || !title.match(GERMAN_TEXT)) {
    return null;
  }

  if (title.length > PAGE_TITLE_MAX_LENGTH) {
    return title.slice(0, PAGE_TITLE_MAX_LENGTH);
  }

  return title;
};

export type EditingContextType = {
  topBarTitle: string;
  setTopBarTitle: (newTitle?: string) => void;
  isCustomerEditorModeEnabled: boolean;
  setCustomerEditorModeEnabled: (enabled: boolean) => void;
  savedStructure?: Structure;
  localStructure?: Structure;
  setCurrentStructure: (newStructure?: Structure) => void;
  currentStructureAccess?: PermissionAccess<AccessUserInherit>;
  setCurrentStructureAccess: (
    newStructure?: PermissionAccess<AccessUserInherit>,
  ) => void;
  localEditorPagesStash: EditorPage[];
  addPage: (layout?: Layout[]) => string;
  addExistingPage: (page: Partial<BuiltPage>) => void;
  updatePageConfig: (
    newPage: Partial<BuiltPageConfig>,
    pageId?: string,
    editorMode?: EditorMode,
  ) => void;
  updateStructureElements: (
    newStructureElements: StructureConfigElement[],
  ) => void;
  updateStructureConfig: (
    newConfig: Partial<StructureConfig>,
    editorMode?: EditorMode,
  ) => void;
  updateStructureElement: (
    el: Partial<StructureConfigElement>,
    ind: number,
    groupInd?: number,
  ) => void;
  addStructureElement: (newStructureElement: StructureConfigElement) => void;
  updateStructurePage: (
    pageId: string,
    newPage: Partial<StructureConfigElement>,
  ) => void;
  deletingStructureEl?: StructureConfigElement;
  setDeletingStructureEl: (el?: StructureConfigElement) => void;
  confirmDeleteStructureEl: (el: StructureConfigElement) => void;
  resetEditorStash: (preserveId?: string) => void;
  pageActions: React.JSX.Element[] | null;
  setPageActions: (actions: React.JSX.Element[] | null) => void;
  createNewStructure: (
    newStructure?: Structure,
    fullEditable?: boolean,
    layout?: Layout[],
  ) => string;
  isFullEditable: boolean;
  setIsFullEditable: (b: boolean) => void;
  setStructureIcon: (icon?: IFile) => void;
  skipAutoStructureUpdate: boolean;
  setSkipAutoStructureUpdate: (
    b: boolean,
    newStructure?: Partial<Structure>,
  ) => void;
  refetchWidgets: () => void;
  loadedWidgets?: Record<string, boolean>;
  setLoadedWidget: (id: string) => void;
  currentLockedPage?: BuiltPageConfig;
  setCurrentLockedPage: (p?: BuiltPageConfig) => void;
  isTempStructure: boolean;
  setIsTempStructure: (b: boolean) => void;
};

export const DefaultEditingContextValue: EditingContextType = {
  topBarTitle: DEFAULT_TOP_BAR_TITLE,
  setTopBarTitle() {},
  createNewStructure() {
    return '';
  },
  isCustomerEditorModeEnabled: false,
  setCustomerEditorModeEnabled() {},
  savedStructure: undefined,
  localStructure: undefined,
  setCurrentStructure() {},
  currentStructureAccess: undefined,
  setCurrentStructureAccess() {},
  localEditorPagesStash: [],
  addPage() {
    return '';
  },
  addExistingPage() {
    return;
  },
  updatePageConfig() {
    return;
  },
  updateStructureElements() {
    return;
  },
  updateStructureConfig() {
    return;
  },
  updateStructureElement() {
    return;
  },
  updateStructurePage() {
    return;
  },
  addStructureElement() {
    return;
  },
  deletingStructureEl: undefined,
  setDeletingStructureEl() {},
  confirmDeleteStructureEl() {},
  resetEditorStash() {},
  setPageActions() {},
  pageActions: null,
  isFullEditable: false,
  setIsFullEditable() {},
  setStructureIcon() {},
  skipAutoStructureUpdate: false,
  setSkipAutoStructureUpdate() {},
  loadedWidgets: undefined,
  refetchWidgets() {},
  setLoadedWidget() {},
  currentLockedPage: undefined,
  setCurrentLockedPage() {},
  isTempStructure: false,
  setIsTempStructure() {},
};

export function buildEditingContextReducer(
  setEditingContextValue: Dispatch<SetStateAction<EditingContextType>>,
  defaultIsEdit = false,
) {
  setEditingContextValue(() => {
    const addStructureElement: EditingContextType['addStructureElement'] = (
      newStructureElement,
    ) => {
      setEditingContextValue((prev) => {
        const { localStructure } = prev;

        if (!localStructure) {
          return prev;
        }

        const hasCustomerSection =
          localStructure.structureConfig.elements.some(
            (el) => el.text === CUSTOMER_SECTION,
          ) ||
          getIsDA() ||
          getIsAnalyticsEngineer() ||
          prev.isFullEditable;

        const isLabel =
          newStructureElement.type === STRUCTURE_ELEMENT_TYPE.LABEL;

        return {
          ...prev,
          localStructure: {
            ...localStructure,
            structureConfig: {
              ...localStructure.structureConfig,
              elements: isLabel
                ? [
                    newStructureElement,
                    ...localStructure.structureConfig.elements,
                  ]
                : [
                    ...localStructure.structureConfig.elements,
                    ...(!hasCustomerSection
                      ? [
                          {
                            id: CUSTOMER_SECTION,
                            type: STRUCTURE_ELEMENT_TYPE.SECTION,
                            text: CUSTOMER_SECTION,
                          },
                        ]
                      : []),
                    newStructureElement,
                  ],
            },
          },
        };
      });
    };

    const addPage: EditingContextType['addPage'] = (layout) => {
      const newPage = editorCreateNewPage(layout);

      setEditingContextValue((prev) => ({
        ...prev,
        localEditorPagesStash: [
          ...prev.localEditorPagesStash,
          {
            action: EditingAction.CREATE,
            page: newPage,
          },
        ],
      }));

      addStructureElement({
        id: v4(),
        type: STRUCTURE_ELEMENT_TYPE.PAGE,
        pageId: newPage.id,
        title: '',
      });

      return newPage.pageConfig.versionId;
    };

    const setCurrentStructure = (newStructure?: Structure) => {
      setEditingContextValue((prev) => {
        if (prev.skipAutoStructureUpdate) {
          return {
            ...prev,
            skipAutoStructureUpdate: !newStructure,
          };
        }

        const structureWithIds = newStructure && {
          ...newStructure,
          structureConfig: {
            ...newStructure.structureConfig,
            elements: newStructure.structureConfig.elements.map(
              ({ groupElements, ...el }) => {
                const id = v4();

                const elWithID = { ...el, id };

                return groupElements
                  ? {
                      ...elWithID,
                      groupElements: [
                        ...groupElements.map((groupEl) => ({
                          ...groupEl,
                          id: v4(),
                        })),
                        {
                          type: STRUCTURE_ELEMENT_TYPE.GROUP_END,
                          id: v4(),
                        },
                      ],
                    }
                  : elWithID;
              },
            ),
          },
        };

        return {
          ...prev,
          savedStructure: newStructure,
          localStructure: structureWithIds,
        };
      });
    };

    const resetEditorStash = (preserveId?: string) => {
      setEditingContextValue((previousValue) => ({
        ...previousValue,
        localEditorPagesStash: previousValue.localEditorPagesStash.filter(
          ({ page: { id } }) => id === preserveId,
        ),
      }));
    };

    const setIsFullEditable = (val: boolean) => {
      setEditingContextValue((previousValue) => ({
        ...previousValue,
        isFullEditable: val,
      }));
    };

    return {
      addStructureElement,
      topBarTitle: DEFAULT_TOP_BAR_TITLE,
      setTopBarTitle(topBarTitle: string = DEFAULT_TOP_BAR_TITLE) {
        setEditingContextValue((previousContextValue) => ({
          ...previousContextValue,
          topBarTitle,
        }));
      },
      pageActions: null,
      setPageActions(pageActions) {
        setEditingContextValue((previousContextValue) => ({
          ...previousContextValue,
          pageActions,
        }));
      },
      isCustomerEditorModeEnabled: defaultIsEdit,
      setCustomerEditorModeEnabled(isCustomerEditorModeEnabled) {
        setEditingContextValue((previousContextValue) => ({
          ...previousContextValue,
          isCustomerEditorModeEnabled,
        }));
      },

      savedStructure: undefined,
      setCurrentStructure,
      currentStructureAccess: undefined,
      setCurrentStructureAccess(newStructureAccess) {
        setEditingContextValue((previousContextValue) => ({
          ...previousContextValue,
          currentStructureAccess: newStructureAccess,
        }));
      },
      localEditorPagesStash: [],
      addPage,
      addExistingPage(page) {
        setEditingContextValue((prev) => {
          if (prev.skipAutoStructureUpdate) {
            return prev;
          }

          const newStash = prev.localEditorPagesStash.filter(
            ({ page: { id } }) => id !== page.id,
          );

          return {
            ...prev,
            localEditorPagesStash: [
              ...newStash,
              {
                action: EditingAction.EDIT,
                page,
              },
            ],
          };
        });
      },
      updatePageConfig(newPage, pageId, editorMode) {
        if (!pageId) {
          return;
        }

        setEditingContextValue((previousValue) => {
          const pageIndex = previousValue.localEditorPagesStash.findIndex(
            ({ page: { id } }) => id === pageId,
          );

          const oldPage = previousValue.localEditorPagesStash[pageIndex];

          return {
            ...previousValue,
            localEditorPagesStash: [
              ...previousValue.localEditorPagesStash.slice(0, pageIndex),
              {
                ...oldPage,
                page: {
                  ...oldPage?.page,
                  pageConfig: {
                    ...oldPage?.page.pageConfig,
                    ...(newPage as BuiltPageConfig),
                  },
                  editorMode: editorMode ?? oldPage.page.editorMode,
                },
              },
              ...previousValue.localEditorPagesStash.slice(pageIndex + 1),
            ],
          };
        });
      },
      updateStructureConfig(newConfig, editorMode) {
        setEditingContextValue((prev) => {
          const { localStructure } = prev;

          if (!localStructure) {
            return prev;
          }

          const { datasourceId, ...updatedLocalStructureConfig } =
            localStructure.structureConfig;

          if (newConfig.datasourceId === '') {
            newConfig.datasourceId = undefined;
          }

          return {
            ...prev,
            localStructure: {
              ...localStructure,
              structureConfig: {
                ...updatedLocalStructureConfig,
                ...newConfig,
              },
              editorMode: editorMode ?? localStructure.editorMode,
            },
          };
        });
      },
      updateStructureElements(newStructureElements) {
        setEditingContextValue((prev) => {
          const { localStructure } = prev;

          if (!localStructure) {
            return prev;
          }

          return {
            ...prev,
            localStructure: {
              ...localStructure,
              structureConfig: {
                ...localStructure.structureConfig,
                elements: newStructureElements,
              },
            },
          };
        });
      },
      updateStructureElement(el, ind, groupInd) {
        setEditingContextValue((prev) => {
          const { localStructure } = prev;

          if (!localStructure) {
            return prev;
          }

          const topInd = groupInd ?? ind;

          const elements = localStructure.structureConfig.elements;

          return {
            ...prev,
            localStructure: {
              ...localStructure,
              structureConfig: {
                ...localStructure.structureConfig,
                elements: [
                  ...elements.slice(0, topInd),
                  {
                    ...elements[topInd],
                    ...(groupInd === undefined
                      ? el
                      : {
                          groupElements: [
                            ...elements[topInd].groupElements!.slice(0, ind),
                            {
                              ...elements[topInd].groupElements![ind],
                              ...el,
                            },
                            ...elements[topInd].groupElements!.slice(ind + 1),
                          ],
                        }),
                  },
                  ...elements.slice(topInd + 1),
                ],
              },
            },
          };
        });
      },
      updateStructurePage(pageId, newPage) {
        setEditingContextValue((prev) => {
          const { localStructure } = prev;

          if (!localStructure) {
            return prev;
          }

          const currentElements = localStructure.structureConfig.elements;
          const pageInd = currentElements.findIndex(
            (el) => el.pageId === pageId,
          );

          if (pageInd !== -1) {
            return {
              ...prev,
              localStructure: {
                ...localStructure,
                structureConfig: {
                  ...localStructure.structureConfig,
                  elements: [
                    ...currentElements.slice(0, pageInd),
                    {
                      ...currentElements[pageInd],
                      ...newPage,
                    },
                    ...currentElements.slice(pageInd + 1),
                  ],
                },
              },
            };
          }

          const groupInd = currentElements.findIndex((el) =>
            el.groupElements?.some((groupEl) => groupEl.pageId === pageId),
          );

          if (groupInd !== -1) {
            const group = currentElements[groupInd];

            const pageInd = group.groupElements?.findIndex(
              (groupEl) => groupEl.pageId === pageId,
            )!;

            return {
              ...prev,
              localStructure: {
                ...localStructure,
                structureConfig: {
                  ...localStructure.structureConfig,
                  elements: [
                    ...currentElements.slice(0, groupInd),
                    {
                      ...group,
                      groupElements: [
                        ...group.groupElements!.slice(0, pageInd),
                        {
                          ...group.groupElements![pageInd],
                          ...newPage,
                        },
                        ...group.groupElements!.slice(pageInd + 1),
                      ],
                    },
                    ...currentElements.slice(groupInd + 1),
                  ],
                },
              },
            };
          }

          return prev;
        });
      },
      deletingStructureEl: undefined,
      setDeletingStructureEl(el) {
        setEditingContextValue((previousValue) => ({
          ...previousValue,
          deletingStructureEl: el,
        }));
      },
      confirmDeleteStructureEl(el) {
        setEditingContextValue((previousValue) => {
          const prevElements =
            previousValue.localStructure!.structureConfig.elements;

          const groupInd = prevElements.findIndex(
            ({ type, groupElements }) =>
              type === STRUCTURE_ELEMENT_TYPE.GROUP &&
              groupElements?.some((groupEl) => groupEl.id === el.id),
          );

          const updatedStructure: Structure = {
            ...previousValue.localStructure!,
            structureConfig: {
              ...previousValue.localStructure!.structureConfig,
              elements:
                groupInd !== -1
                  ? [
                      ...prevElements.slice(0, groupInd),
                      {
                        ...prevElements[groupInd],
                        groupElements: prevElements[
                          groupInd
                        ].groupElements?.filter((_el) => _el.id !== el.id),
                      },
                      ...prevElements.slice(groupInd + 1),
                    ]
                  : prevElements.filter((_el) => _el.id !== el.id),
            },
          };

          const commonReturn = {
            ...previousValue,
            localStructure: updatedStructure,
            deletingStructureEl: undefined,
          };

          if (el.type === STRUCTURE_ELEMENT_TYPE.PAGE) {
            const deletePageId = el.pageId;

            const updatedLocalStash = [...previousValue.localEditorPagesStash];

            const pageInLocalStash = updatedLocalStash.find(
              ({ page }) => page.id === deletePageId,
            );

            const addDeleteAction = () =>
              updatedLocalStash.push({
                action: EditingAction.DELETE,
                page: {
                  id: deletePageId,
                },
              });

            if (pageInLocalStash) {
              updatedLocalStash.splice(
                updatedLocalStash.indexOf(pageInLocalStash),
                1,
              );

              if (pageInLocalStash.action === EditingAction.EDIT) {
                addDeleteAction();
              }
            } else {
              addDeleteAction();
            }

            return {
              ...commonReturn,
              localEditorPagesStash: updatedLocalStash,
            };
          } else if (el.type === STRUCTURE_ELEMENT_TYPE.GROUP) {
            const groupInd = prevElements.findIndex((_el) => _el.id === el.id);

            return {
              ...commonReturn,
              localStructure: {
                ...updatedStructure,
                structureConfig: {
                  ...updatedStructure.structureConfig,
                  elements: [
                    ...updatedStructure.structureConfig.elements.slice(
                      0,
                      groupInd,
                    ),
                    ...el._groupElements!.filter(
                      (groupEl) =>
                        groupEl.type !== STRUCTURE_ELEMENT_TYPE.GROUP_END,
                    ),
                    ...updatedStructure.structureConfig.elements.slice(
                      groupInd,
                    ),
                  ],
                },
              },
            };
          }

          return commonReturn;
        });

        AppToaster.show({
          intent: 'success',
          message: i18n.t('structure.remove-sidebar-element-success', {
            type: SIDEBAR_EL_NAME[el.type],
          }),
        });
      },
      resetEditorStash,
      createNewStructure(newStructure, fullEditable = true, layout) {
        setIsFullEditable(fullEditable);
        resetEditorStash();
        setCurrentStructure(
          newStructure || (NEW_STRUCTURE as unknown as Structure),
        );

        return addPage(layout);
      },
      isFullEditable: false,
      setIsFullEditable,
      setStructureIcon: (icon) => {
        setEditingContextValue((prev) => {
          return {
            ...prev,
            savedStructure: {
              ...(prev.savedStructure as Structure),
              icon: icon,
            },
            localStructure: {
              ...(prev.localStructure as Structure),
              icon: icon,
            },
          };
        });
      },
      skipAutoStructureUpdate: false,
      setSkipAutoStructureUpdate: (b, newStructure) => {
        setEditingContextValue((prev) => ({
          ...prev,
          skipAutoStructureUpdate: b,
          savedStructure: {
            ...prev.savedStructure!,
            ...newStructure,
          },
          localStructure: {
            ...prev.localStructure!,
            ...newStructure,
          },
        }));
      },
      loadedWidgets: undefined,
      refetchWidgets() {
        setEditingContextValue((prev) => ({
          ...prev,
          loadedWidgets: {},
        }));
      },
      setLoadedWidget(id) {
        setEditingContextValue((prev) => ({
          ...prev,
          loadedWidgets: {
            ...prev.loadedWidgets,
            [id]: true,
          },
        }));
      },
      setCurrentLockedPage(p) {
        setEditingContextValue((prev) => ({
          ...prev,
          currentLockedPage: p,
        }));
      },
      isTempStructure: false,
      setIsTempStructure(b) {
        setEditingContextValue((prev) => ({
          ...prev,
          isTempStructure: b,
        }));
      },
    };
  });
}

export const EDITOR_PAGE_PREFIX = 'editor-new-';

export const getIsEditorPage = (pageId?: string) =>
  pageId?.startsWith(EDITOR_PAGE_PREFIX);

export const getNewRow = () => ({
  type: LAYOUT_TYPE_VALS[INITIAL_COLSPAN - 1],
  id: v4(),
  columns: [{ elements: [], colspan: INITIAL_COLSPAN }],
});

export const editorCreateNewPage = (layout?: Layout[]): BuiltPage => {
  const tempPageId = EDITOR_PAGE_PREFIX + new Date().getTime().toString(16);

  const isDA = getIsDA();

  const user = getLocalUserData();

  return {
    id: tempPageId,
    createdAt: '',
    creatorFullName: isDA ? 'Polyteia' : `${user?.firstName} ${user?.lastName}`,
    createdBy: '',
    updatedAt: '',
    updatedBy: '',
    versionId: '',
    updatorFullName: '',
    versionType: VERSION_TYPE.DRAFT,
    editorMode: EditorMode.VISUAL,
    pageConfig: {
      defaultFilters: [],
      filterSections: [],
      pageFilters: [],
      layout: layout || [getNewRow()],
      info: '',
      name: '',
      title: i18n.t('structure.new-page'),
      allowCustomerEdits: !getIsDA(),
      subTitle: '',
      versionId: tempPageId,
      id: tempPageId,
    },
  };
};

export const EditingContext = createContext<EditingContextType>(
  DefaultEditingContextValue,
);

export const useEditingContext = () => {
  return useContext(EditingContext);
};

export const useCurrentLocalPage = () => {
  const { pageUrl } = useParams<SolutionParams>();
  const { localEditorPagesStash } = useEditingContext();

  return useMemo(
    () =>
      localEditorPagesStash.find(
        ({ page }) => page.pageConfig?.versionId === pageUrl,
      ),
    [localEditorPagesStash, pageUrl],
  );
};

export const useAddWidgetIdToPage = () => {
  const localPage = useCurrentLocalPage();
  const { updatePageConfig } = useEditingContext();

  const layout = useMemo(
    () => localPage?.page?.pageConfig?.layout || [],
    [localPage?.page?.pageConfig?.layout],
  );

  return useCallback(
    (
      id: string,
      rowInd: number,
      colInd: number,
      isCreateCol?: boolean,
      widgetInd?: number,
    ) => {
      if (!localPage?.page.id) {
        return;
      }

      const row = isCreateCol ? getNewRow() : layout[rowInd];

      updatePageConfig(
        {
          layout: [
            ...layout.slice(0, rowInd),
            {
              ...row,
              columns: [
                ...row.columns.slice(0, colInd),
                {
                  ...row.columns[colInd],
                  elements:
                    typeof widgetInd === 'number'
                      ? [
                          ...row.columns[colInd].elements.slice(0, widgetInd),
                          {
                            type: 'widget',
                            widgetId: id,
                          },
                          ...row.columns[colInd].elements.slice(widgetInd + 1),
                        ]
                      : [
                          {
                            type: 'widget',
                            widgetId: id,
                          },
                        ],
                },
                ...row.columns.slice(colInd + 1),
              ],
            },
            ...layout.slice(rowInd + 1),
          ],
        },
        localPage?.page?.id,
      );
    },
    [layout, localPage?.page.id, updatePageConfig],
  );
};
