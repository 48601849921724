import { VERSION_TYPE } from '../dataSource/types';

export enum EditorMode {
  EXPERT = 'expert',
  VISUAL = 'visual',
}

export type Metadata = {
  id: string;
  versionId: string;
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  creatorFullName: string;
  updatorFullName: string;
  versionType: VERSION_TYPE;
  editorMode: EditorMode;
};

export type IFile = {
  encoding: string;
  file: string;
  filename: string;
  mimetype: string;
};
