import styled from 'styled-components';
import { ENTITY_TYPES } from '../../../gql/types';

const FONT_SIZE = {
  [ENTITY_TYPES.STRUCTURE]: 16,
  [ENTITY_TYPES.PAGE]: 14,
  [ENTITY_TYPES.WIDGET]: 12,
  [ENTITY_TYPES.QUERY]: 12,
};

export const EntityName = styled.span<{
  entityType: ENTITY_TYPES;
}>`
  font-size: ${({ entityType }) => `${FONT_SIZE[entityType]}px`};
`;
