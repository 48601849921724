import styled from 'styled-components';
import { colors } from '../../../../utils/colors';

export const DialogTitle = styled.h2`
  font-size: 26px;
  font-weight: 500;

  margin: 0 0 9px;
`;

export const RowTitle = styled.div`
  cursor: pointer;
  font-size: 16px;
  line-height: 16px;
  color: ${colors.monochromatic0};
  margin-bottom: 34px;
`;

export const CreateQueryRowTitle = styled(RowTitle)`
  margin-top: 17px;

  & span {
    margin-right: 3px;
  }
`;
