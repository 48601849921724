import styled from 'styled-components';

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

export const Wrapper = styled.div`
  margin: 24px 8px;
`;

export const TagsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin: 5px 10px 5px 0;
  }
`;
