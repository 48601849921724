import { gql } from '@apollo/client';
import { metadata } from '../common/fragments';
import {
  builtFilterSectionFragment,
  defaultFilterFragment,
  filterSectionFragment,
} from '../widget/fragments';

export const layoutElementFragment = gql`
  fragment LayoutElement on BaseLayoutElement {
    widgetId
    type
    text
  }
`;

export const pageColumnFragment = gql`
  fragment Column on BaseColumn {
    colspan
    verticalAlign
    horizontalAlign
    elements {
      ...LayoutElement
    }
  }
  ${layoutElementFragment}
`;

export const pageLayoutFragment = gql`
  fragment Layout on BaseLayout {
    type
    separator
    columns {
      ...Column
    }
  }
  ${pageColumnFragment}
`;

export const pageFilterFragment = gql`
  fragment PageFilter on BasePageFilter {
    id
    title
    type
    info
    valuesType
    column
    labels
    totalAlgo
    totalLabel
    showTotal
    queryId
    defaultSelectedFilter
    defaultValueColumn
    defaultValueQueryId
    dependencies {
      id
      column
      valuesType
    }
  }
`;

export const pageConfigFragment = gql`
  fragment PageConfig on BasePageConfig {
    title
    name
    info
    subTitle
    allowCustomerEdits
    defaultFilters {
      ...DefaultFilter
    }
    filterSections {
      ...FilterSection
    }
    pageFilters {
      ...PageFilter
    }
    layout {
      ...Layout
    }
  }
  ${pageFilterFragment}
  ${pageLayoutFragment}
  ${filterSectionFragment}
  ${defaultFilterFragment}
`;

export const pageUserFragment = gql`
  fragment PageUser on PageUser {
    groupId
    userId
    inherit
  }
`;

export const pageFragment = gql`
  fragment Page on Page {
    ${metadata}
    pageConfig {
      ...PageConfig
    }
    access {
      public
      users {
        ...PageUser
      }
    }
  }
  ${pageConfigFragment}
  ${pageUserFragment}
`;

export const buildPageFilterFragment = gql`
  fragment BuiltPageFiltersConfig on BuiltPageFiltersConfig {
    id
    title
    type
    info
    valuesType
    totalAlgo
    totalLabel
    showTotal
    labels
    values
    defaultSelectedFilter
    defaultValueColumn
    defaultValueQueryId
    dependencies {
      id
    }
  }
`;

export const buildPageConfigFragment = gql`
  fragment BuiltPageConfig on BuiltPageConfig {
    title
    name
    info
    subTitle
    allowCustomerEdits
    versionId
    id
    creatorFullName
    createdBy
    defaultFilters {
      ...DefaultFilter
    }
    filterSections {
      ...BuiltFilterSectionConfig
    }
    pageFilters {
      ...BuiltPageFiltersConfig
    }
    layout {
      ...Layout
    }
  }
  ${buildPageFilterFragment}
  ${pageLayoutFragment}
  ${builtFilterSectionFragment}
  ${defaultFilterFragment}
`;
