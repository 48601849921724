import { useMemo } from 'react';
import {
  add,
  converge,
  head,
  map,
  reduce,
  tail,
  unapply,
  zipWith,
} from 'ramda';
import { NewDataSeries } from '../../../../gql/widget/types';

type CustomSetDomainRange = (series: NewDataSeries[]) => number[];

export const findStackedRange = (series: any) => {
  const sumByIndex = unapply(
    converge(reduce(zipWith(add) as (...a: any[]) => any), [head, tail]),
  );
  const totalSum = map((a) => a.values, series);
  const sumOfSeries = sumByIndex(...totalSum);

  return [Math.min(...sumOfSeries), Math.max(...sumOfSeries)];
};

function findDomainRange(
  series: NewDataSeries[],
  customSetDomainRange?: CustomSetDomainRange,
) {
  if (!customSetDomainRange) {
    const domainValue = +(series?.[0]?.values?.[0] || 0);

    let yD = [domainValue, domainValue];

    series.forEach((s) => {
      yD = [
        Math.min(...(s?.values as number[]), yD[0]),
        Math.max(...(s?.values as number[]), yD[1]),
      ];
    });

    return yD;
  }

  return customSetDomainRange(series);
}

export default function useGraphSeries(
  series: NewDataSeries[],
  customSetDomainRange?: CustomSetDomainRange,
  originalDataSeries?: NewDataSeries[],
) {
  return useMemo(
    () => findDomainRange(originalDataSeries || series, customSetDomainRange),
    [customSetDomainRange, originalDataSeries, series],
  );
}
