import styled from 'styled-components';
import { Card, Dialog } from '@blueprintjs/core';

export const WidgetsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-content: center;
  justify-content: center;
  justify-items: center;
  grid-gap: 15px;
`;

export const TypeItem = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 110px;
  width: 110px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 8px;
  & > *:first-child {
    margin-bottom: 5px;
  }
`;

export const SelectionDialog = styled(Dialog)`
  width: auto;
  padding-bottom: 0;
`;
