import { useQuery } from '@apollo/client';
import { Tooltip2 } from '@blueprintjs/popover2';
import { Field, useFormikContext } from 'formik';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { DatasourcesRoute, SolutionsRoute } from '../../../app/Routes';
import { ReactComponent as EyeClosed } from '../../../assets/icons/eye-closed.svg';
import { ReactComponent as EyeOpen } from '../../../assets/icons/eye-open.svg';
import { ReactComponent as RedirectIcon } from '../../../assets/icons/redirect.svg';
import { ReactComponent as RefreshIcon } from '../../../assets/icons/refresh.svg';
import { ReactComponent as NewTabIcon } from '../../../assets/icons/ui/l/new-tab.svg';
import { EDIT_PATH } from '../../../constants/entities';
import { useAthenaMutation } from '../../../gql/dataSource/hooks';
import {
  GET_ALL_DATA_SOURCES,
  GetAllDataSourcesData,
  GetAllDataSourcesVars,
} from '../../../gql/dataSource/queries';
import { DataSource, VERSION_TYPE } from '../../../gql/dataSource/types';
import {
  GET_ALL_INSIGHT_QUERIES,
  GetAllInsightQueriesData,
  GetAllInsightQueriesVars,
} from '../../../gql/library/queries';
import { UPDATE_QUERY_VISIBILITY } from '../../../gql/query/mutations';
import {
  GET_ALL_QUERIES,
  GetAllQueriesData,
  GetAllQueriesVars,
} from '../../../gql/query/queries';
import {
  SEARCH_STRUCTURES,
  SearchStructuresData,
  SearchStructuresVars,
} from '../../../gql/structure/queries';
import { ENTITY_TYPES } from '../../../gql/types';
import { getIsCustomer, getIsDA } from '../../../gql/user/local';
import Btn from '../../Btn';
import { IconButton } from '../../IconButton';
import { SearchSelectInput } from '../../SearchSelectInput';
import FormField from '../../inputs/FormField';
import { TextInput } from '../../inputs/TextInput';
import { DetailProperties, validationSchema } from './form';
import {
  DetailsForm,
  DetailsFormik,
  FormFieldActions,
  FormFieldWrapper,
  IconWrapper,
  Wrapper,
} from './styles';

const getDataSourceOptions = (dataSources: DataSource[] | undefined) =>
  (dataSources || [])?.map(({ id, dataSourceConfig: { name } }) => ({
    value: id,
    label: name,
    key: id,
  }));

const SetProperties: FC<Pick<Props, 'setIsValid'>> = ({ setIsValid }) => {
  const { isValid } = useFormikContext<DetailProperties>();

  useEffect(() => {
    setIsValid?.(isValid);
  }, [isValid, setIsValid]);

  return null;
};

export enum DetailsFields {
  TITLE = 'title',
  NAME = 'name',
  DATA_SOURCE = 'datasourceId',
  QUERY = 'queryId',
  STRUCTURE = 'structureId',
  IS_HIDDEN = 'isHidden',
}

type Props = {
  entityType: ENTITY_TYPES;
  properties: DetailProperties;
  setProperties: (val: DetailProperties) => void;
  onBlur?: (val: Record<string, any>) => void;
  setIsValid?: (errors: any) => void;
  handleAddOpen?: () => void;
  handleAddStructureOpen?: () => void;
  fields: Array<DetailsFields>;
};

const Details: FC<Props> = ({
  entityType,
  properties,
  setProperties,
  setIsValid,
  handleAddOpen,
  handleAddStructureOpen,
  fields,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const routeParams = useRouteMatch<{ id?: string }>('/queries/:id');

  const isStructure = entityType === ENTITY_TYPES.STRUCTURE;
  const isWidget = entityType === ENTITY_TYPES.WIDGET;
  const isQuery = entityType === ENTITY_TYPES.QUERY;
  const isWidgetOrQuery = isWidget || isQuery;

  const isDA = getIsDA();
  const isCustomer = getIsCustomer();

  const { data: dataSourcesData } = useQuery<
    GetAllDataSourcesData,
    GetAllDataSourcesVars
  >(GET_ALL_DATA_SOURCES, {
    variables: {
      versionType: VERSION_TYPE.LIVE,
    },
    skip: !isStructure || !isDA,
  });

  const { data: queriesData } = useQuery<GetAllQueriesData, GetAllQueriesVars>(
    GET_ALL_QUERIES,
    {
      variables: {
        versionType: VERSION_TYPE.LIVE,
      },
      skip: !isWidget || isCustomer,
    },
  );

  const { data: insightQueriesData } = useQuery<
    GetAllInsightQueriesData,
    GetAllInsightQueriesVars
  >(GET_ALL_INSIGHT_QUERIES, {
    fetchPolicy: 'network-only',
    variables: {
      searchInput: {
        text: '',
        first: 100,
      },
    },
    skip: !isWidget || !isCustomer,
  });

  const { data: structuresData } = useQuery<
    SearchStructuresData,
    SearchStructuresVars
  >(SEARCH_STRUCTURES, {
    variables: {
      searchInput: {
        text: '',
        versionType: VERSION_TYPE.LIVE,
      },
    },
    skip: !isWidgetOrQuery || !isDA,
  });

  const [updateIsHiddenData] = useAthenaMutation<any, any>(
    UPDATE_QUERY_VISIBILITY,
  );

  const dataSourceOptions = useMemo(
    () => getDataSourceOptions(dataSourcesData?.allDataSourcesByType),
    [dataSourcesData?.allDataSourcesByType],
  );

  const queries = isCustomer
    ? insightQueriesData?.getAllInsightLiveQueries
    : queriesData?.allQueriesByType;

  const query = queries?.find((query) => query.id === properties?.queryId);

  const structures = structuresData?.searchStructures;

  const structure = structures?.find(
    (structure) => structure.id === properties?.structureId,
  );

  const redirectToQuery = (versionId: string) => {
    const widgetVersionId = history.location.pathname.split('/')[2];

    history.push(`/queries/${versionId}`, {
      backURL: `/widgets/${widgetVersionId}`,
    });
  };

  const openDatasourceTabById = (datasourceId?: string) => {
    const datasource = dataSourcesData?.allDataSourcesByType?.find(
      (datasource) => datasource.id === datasourceId,
    );

    if (datasource) {
      const datasourceVersionId = datasource.versionId;
      window.open(`${DatasourcesRoute.path}/${datasourceVersionId}`, '_blank');
    }
  };

  return (
    <Wrapper>
      <DetailsFormik
        initialValues={properties}
        onSubmit={setProperties}
        enableReinitialize
        validateOnMount
        validationSchema={validationSchema}
      >
        {function DetailsFormWrapper(props) {
          const { values } = props;

          useEffect(() => {
            props.submitForm();
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [
            values?.title,
            values?.name,
            values?.datasourceId,
            values?.queryId,
            values?.structureId,
            values?.isHidden,
            values?.versionType,
          ]);

          return (
            <DetailsForm>
              <SetProperties setIsValid={setIsValid} />
              <div className="flex flex-col gap-2">
                {fields.includes(DetailsFields.TITLE) && (
                  <Field
                    name="title"
                    id="title"
                    label={t('editor-panel.details.form.title', {
                      entityType:
                        // t('entity.structure')
                        // t('entity.page')
                        // t('entity.widget')
                        // t('entity.query')
                        t(`entity.${entityType.toLowerCase()}`),
                    })}
                    required
                    component={TextInput}
                    placeholder={t(
                      'editor-panel.details.form.title-placeholder',
                    )}
                  />
                )}
                {fields.includes(DetailsFields.NAME) && (
                  <Field
                    name="name"
                    id="name"
                    label={t('editor-panel.details.form.name', {
                      entityType:
                        // t('entity.structure')
                        // t('entity.page')
                        // t('entity.widget')
                        // t('entity.query')
                        t(`entity.${entityType.toLowerCase()}`),
                    })}
                    component={TextInput}
                    placeholder={t(
                      'editor-panel.details.form.name-placeholder',
                    )}
                  />
                )}
                {fields.includes(DetailsFields.DATA_SOURCE) && (
                  <div className="flex items-center gap-2">
                    <div className="basis-[90%]">
                      <Field
                        name="datasourceId"
                        label={t(
                          'editor-panel.details.form.default-datasource',
                        )}
                        component={SearchSelectInput}
                        placeholder={t(
                          'editor-panel.details.form.datasource-placeholder',
                        )}
                        options={dataSourceOptions}
                        info={t('editor-panel.details.form.datasource-info')}
                      />
                    </div>
                    <div className="mt-4">
                      <IconButton
                        ariaLabel={t('editor-panel.details.form.redirect')}
                        icon={NewTabIcon}
                        disabled={!properties?.datasourceId}
                        onClick={() =>
                          openDatasourceTabById(properties?.datasourceId)
                        }
                      />
                    </div>
                  </div>
                )}
              </div>

              {fields.includes(DetailsFields.QUERY) && (
                <FormFieldWrapper onClick={handleAddOpen}>
                  <FormField
                    label={`Select query`}
                    name="query"
                    required
                    value={query?.queryConfig.name}
                    type="button"
                  />
                  <FormFieldActions>
                    <IconWrapper onClick={handleAddOpen}>
                      <Tooltip2
                        content="Change"
                        className="d-flex justify-content-center align-items-center"
                      >
                        <RefreshIcon />
                      </Tooltip2>
                    </IconWrapper>

                    <IconWrapper
                      onClick={(event) => {
                        event.stopPropagation();
                        redirectToQuery(query?.versionId!);
                      }}
                    >
                      <Tooltip2
                        content="Open"
                        className="d-flex justify-content-center align-items-center"
                      >
                        <RedirectIcon />
                      </Tooltip2>
                    </IconWrapper>
                  </FormFieldActions>
                </FormFieldWrapper>
              )}
              {fields.includes(DetailsFields.STRUCTURE) && (
                <FormFieldWrapper onClick={handleAddStructureOpen}>
                  <FormField
                    label={`Select structure`}
                    name="structure"
                    required
                    value={structure?.structureConfig.name}
                    type="button"
                  />
                  <FormFieldActions>
                    <IconWrapper onClick={handleAddStructureOpen}>
                      <Tooltip2
                        content="Change"
                        className="d-flex justify-content-center align-items-center"
                      >
                        <RefreshIcon />
                      </Tooltip2>
                    </IconWrapper>

                    <IconWrapper
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <Tooltip2
                        content="Open"
                        className="d-flex justify-content-center align-items-center"
                      >
                        <a
                          href={`${SolutionsRoute.path}/${EDIT_PATH}/${structure?.versionId}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <RedirectIcon />
                        </a>
                      </Tooltip2>
                    </IconWrapper>
                  </FormFieldActions>
                </FormFieldWrapper>
              )}
              {fields.includes(DetailsFields.IS_HIDDEN) && (
                <>
                  <div style={{ marginTop: '20px', marginBottom: `5px` }}>
                    {properties?.isHidden ? 'Show' : `Hide`} in insight library
                  </div>
                  <Tooltip2
                    content={
                      properties?.isHidden
                        ? `Show to editors`
                        : `Hide from editors`
                    }
                  >
                    <Btn
                      minimal
                      intent="none"
                      aria-label="show/hide query"
                      icon={properties?.isHidden ? <EyeClosed /> : <EyeOpen />}
                      onClick={async (e) => {
                        e.stopPropagation();
                        await updateIsHiddenData({
                          variables: {
                            versionId: routeParams?.params.id,
                            versionType: properties?.versionType,
                            isHidden: !properties?.isHidden,
                          },
                        });
                        // TODO: refetchQueries not working
                        setProperties({
                          ...properties,
                          isHidden: !properties?.isHidden,
                        });
                      }}
                    />
                  </Tooltip2>
                </>
              )}
            </DetailsForm>
          );
        }}
      </DetailsFormik>
    </Wrapper>
  );
};

export default Details;
