import React, { FC, useCallback } from 'react';
import { WIDGET_TYPE } from '../../../../gql/widget/types';
import useOpen from '../../../../utils/hooks/useOpen';
import { Classes, Elevation, Icon } from '@blueprintjs/core';
import { stringifyForEditor } from '../../../../utils/editor';
import { SelectionDialog, TypeItem, WidgetsGrid } from './styles';
import { IconName } from '@blueprintjs/icons';
import { colors } from '../../../../utils/colors';
import {
  BAR_CONFIG,
  GROUPED_COLUMN_CONFIG,
  LINE_CONFIG,
  MAP_CONFIG,
  OTHER_CONFIG,
  PIE_CONFIG,
  SIMPLE_STAT_CONFIG,
  STACKED_COLUMN_CONFIG,
  TABLE_CONFIG,
  TEXT_CONFIG,
} from './skeletons';
import { useHistory } from 'react-router-dom';

export const OTHER = 'other';

type SelectTypeOption = WIDGET_TYPE | 'other';

const SELECT_TYPES: SelectTypeOption[] = [
  WIDGET_TYPE.PIE_CHART,
  WIDGET_TYPE.STACKED_COLUMN_CHART,
  WIDGET_TYPE.GROUPED_COLUMN_CHART,
  WIDGET_TYPE.BAR_CHART,
  WIDGET_TYPE.TABLE,
  WIDGET_TYPE.LINE_CHART,
  WIDGET_TYPE.SIMPLE_STAT,
  WIDGET_TYPE.CHOROPLETH_MAP,
  WIDGET_TYPE.TEXT,
  OTHER,
];

export const TYPE_ICONS: Record<SelectTypeOption, IconName> = {
  [WIDGET_TYPE.CHOROPLETH_MAP]: 'heat-grid',
  [WIDGET_TYPE.PIE_CHART]: 'pie-chart',
  [WIDGET_TYPE.GROUPED_COLUMN_CHART]: 'grouped-bar-chart',
  [WIDGET_TYPE.STACKED_COLUMN_CHART]: 'stacked-chart',
  [WIDGET_TYPE.BAR_CHART]: 'horizontal-bar-chart',
  [WIDGET_TYPE.LINE_CHART]: 'timeline-line-chart',
  [WIDGET_TYPE.TEXT]: 'new-text-box',
  [WIDGET_TYPE.TABLE]: 'th',
  [WIDGET_TYPE.SIMPLE_STAT]: 'changes',
  [OTHER]: 'more',
};
const TYPE_LABELS: Record<SelectTypeOption, string> = {
  [WIDGET_TYPE.CHOROPLETH_MAP]: 'Choropleth map',
  [WIDGET_TYPE.PIE_CHART]: 'Pie chart',
  [WIDGET_TYPE.GROUPED_COLUMN_CHART]: 'Grouped column chart',
  [WIDGET_TYPE.STACKED_COLUMN_CHART]: 'Stacked column chart',
  [WIDGET_TYPE.BAR_CHART]: 'Bar chart',
  [WIDGET_TYPE.LINE_CHART]: 'Line chart',
  [WIDGET_TYPE.TEXT]: 'Text',
  [WIDGET_TYPE.TABLE]: 'Table',
  [WIDGET_TYPE.SIMPLE_STAT]: 'Simple stat',
  [OTHER]: 'Other',
};

const TYPE_CONFIGS: Record<SelectTypeOption, Record<any, any>> = {
  [WIDGET_TYPE.CHOROPLETH_MAP]: MAP_CONFIG,
  [WIDGET_TYPE.PIE_CHART]: PIE_CONFIG,
  [WIDGET_TYPE.GROUPED_COLUMN_CHART]: GROUPED_COLUMN_CONFIG,
  [WIDGET_TYPE.STACKED_COLUMN_CHART]: STACKED_COLUMN_CONFIG,
  [WIDGET_TYPE.BAR_CHART]: BAR_CONFIG,
  [WIDGET_TYPE.LINE_CHART]: LINE_CONFIG,
  [WIDGET_TYPE.TEXT]: TEXT_CONFIG,
  [WIDGET_TYPE.TABLE]: TABLE_CONFIG,
  [WIDGET_TYPE.SIMPLE_STAT]: SIMPLE_STAT_CONFIG,
  [OTHER]: OTHER_CONFIG,
};

type Props = { onSelect: (skeleton: string) => void };

const WidgetSkeletonSelector: FC<Props> = ({ onSelect }) => {
  const history = useHistory();
  const { open, handleClose } = useOpen(true);

  const selectSkeleton = useCallback(
    (widgetType: SelectTypeOption) => {
      onSelect(stringifyForEditor(TYPE_CONFIGS[widgetType]));
      handleClose();
    },
    [handleClose, onSelect],
  );

  return (
    <SelectionDialog
      isOpen={open}
      backdropProps={{
        onClick: () => history.push('/widgets'),
      }}
    >
      <WidgetsGrid className={Classes.DIALOG_BODY}>
        {SELECT_TYPES.map((type) => (
          <TypeItem
            key={type}
            onClick={() => selectSkeleton(type)}
            elevation={Elevation.TWO}
            interactive
          >
            <Icon icon={TYPE_ICONS[type]} color={colors.primary0} />
            <div>{TYPE_LABELS[type]}</div>
          </TypeItem>
        ))}
      </WidgetsGrid>
    </SelectionDialog>
  );
};

export default WidgetSkeletonSelector;
