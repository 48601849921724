import { gql } from '@apollo/client';
import { buildPageConfigFragment, pageFragment } from './fragments';
import {
  BuildPageByVersionIdInput,
  BuildPageInput,
  BuiltPageConfig,
  Page,
  PageConfig,
} from './types';
import { VERSION_TYPE } from '../dataSource/types';
import { ENTITY_SEARCH_TYPES } from '../types';
import { metadata } from '../common/fragments';
import { Structure } from '../structure/types';
import { structureFragment } from '../structure/fragments';

export type GetAllPagesData = { allPagesByType: Page[] };

export type GetAllPagesVars = {
  versionType: VERSION_TYPE;
};

export const GET_ALL_PAGES = gql`
  query GetAllPages($versionType: String!) {
    allPagesByType(versionType: $versionType) {
      ...Page
    }
  }
  ${pageFragment}
`;

export type GetAllPagesAllVersionTypesData = {
  live: Page[];
  draft: Page[];
  old: Page[];
};

export type GetAllPagesAllVersionTypesVars = null;

export const GET_ALL_PAGES_ALL_VERSION_TYPES = gql`
  query GetAllPagesAllVersionTypes {
    live: allPagesByType(versionType: "${VERSION_TYPE.LIVE}") {
      ...GetAllWidgetsAllVersionTypesFragment
    }
    draft: allPagesByType(versionType: "${VERSION_TYPE.DRAFT}") {
      ...GetAllWidgetsAllVersionTypesFragment
    }
    old: allPagesByType(versionType: "${VERSION_TYPE.OLD}") {
      ...GetAllWidgetsAllVersionTypesFragment
    }
  }
  fragment GetAllWidgetsAllVersionTypesFragment on Page {
    pageConfig {
      name
    }
    ${metadata}
  }
`;

export type GetPageVersionData = { pageVersion: Page };

export type GetPageVersionVars = {
  versionId: string;
};

export const GET_PAGE_VERSION = gql`
  query GetPageVersion($versionId: String!) {
    pageVersion(versionId: $versionId) {
      ...Page
    }
  }
  ${pageFragment}
`;

export type GetAllPageVersionsData = {
  allPageVersions: Array<Omit<Page, 'pageConfig' | 'access'>>;
};

export type GetAllPageVersionsVars = {
  id: string;
};

export const GET_ALL_PAGE_VERSIONS = gql`
  query GetAllPageVersions($id: String!) {
    allPageVersions(id: $id) {
      ${metadata}
    }
  }
`;

export type AllPagesPageFiltersIds = Omit<Page, 'access' | 'pageConfig'> & {
  pageConfig?: {
    name: string;
    pageFilters?: Array<{
      id?: string;
    }>;
    filterSections?: Array<{
      filters?: Array<{
        id?: string;
      }>;
    }>;
  };
};

export type GetAllPagesPageFiltersIdsData = {
  live: AllPagesPageFiltersIds[];
  draft: AllPagesPageFiltersIds[];
  old: AllPagesPageFiltersIds[];
};

export type GetAllPagesPageFiltersIdsVars = null;

export const GET_ALL_PAGES_PAGE_FILTERS_IDS = gql`
  query GetAllPagesPageFiltersIds {
    live: allPagesByType(versionType: "${VERSION_TYPE.LIVE}") {
        ...GetAllPagesPageFiltersIdsFragment
    }
    draft: allPagesByType(versionType: "${VERSION_TYPE.DRAFT}") {
      ...GetAllPagesPageFiltersIdsFragment
    }
    old: allPagesByType(versionType: "${VERSION_TYPE.OLD}") {
      ...GetAllPagesPageFiltersIdsFragment
    }
  }
  fragment GetAllPagesPageFiltersIdsFragment on Page {
    ${metadata}
    pageConfig {
      name
      pageFilters {
        id
      }
      filterSections {
        filters {
          id
        }
      }
    }
  }
`;

export type GetAllPageUniqueIdsData = { allPageUniqueIDs: string[] };

export type GetAllPageUniqueIdsVars = null;

export const GET_ALL_PAGE_UNIQUE_IDS = gql`
  query GetAllPageUniqueIds {
    allPageUniqueIDs
  }
`;

export type BuildPageData = { buildPage: BuiltPageConfig };

export type BuildPageVars = {
  source: BuildPageInput;
};

export const BUILD_PAGE = gql`
  query BuildPage($source: BuildPageInput!) {
    buildPage(source: $source) {
      ...BuiltPageConfig
    }
  }
  ${buildPageConfigFragment}
`;

export type BuildPageByVersionIdData = {
  buildPageByVersionId: BuiltPageConfig;
};

export type BuildPageByVersionIdVars = {
  source: BuildPageByVersionIdInput;
};

export const BUILD_PAGE_BY_VERSION_ID = gql`
  query BuildPageByVersionId($source: BuildPageByVersionIdInput!) {
    buildPageByVersionId(source: $source,
      structureSearchType: "${ENTITY_SEARCH_TYPES.ID}",
      pageSearchType: "${ENTITY_SEARCH_TYPES.VERSION_ID}") {
      ...BuiltPageConfig
    }
  }
  ${buildPageConfigFragment}
`;

export type BuildPageByVersionWithSearchTypeVars = {
  source: BuildPageByVersionIdInput;
  structureSearchType: ENTITY_SEARCH_TYPES;
  pageSearchType: ENTITY_SEARCH_TYPES;
};

export const BUILD_PAGE_BY_VERSION_WITH_SEARCH_TYPE = gql`
  query BuildPageByVersionIdWithSearchType(
    $source: BuildPageByVersionIdInput!
    $structureSearchType: String!
    $pageSearchType: String!
  ) {
    buildPageByVersionId(
      source: $source
      structureSearchType: $structureSearchType
      pageSearchType: $pageSearchType
    ) {
      ...BuiltPageConfig
    }
  }
  ${buildPageConfigFragment}
`;

export type WidgetUsagesByPagesData = { allWidgetUsagesByPages: Page[] };

export type WidgetUsagesVars = {
  entityId: {
    id: string;
  };
};

export const WIDGET_USAGES_BY_PAGES = gql`
  query AllWidgetUsagesByPages($entityId: EntityIdInput!) {
    allWidgetUsagesByPages(widgetId: $entityId) {
      ...Page
    }
  }
  ${pageFragment}
`;

export type WidgetUsagesByStructuresData = {
  allWidgetUsagesByStructures: Structure[];
};

export const WIDGET_USAGES_BY_STRUCTURES = gql`
  query AllWidgetUsagesByStructures($entityId: EntityIdInput!) {
    allWidgetUsagesByStructures(widgetId: $entityId) {
      ...Structure
    }
  }
  ${structureFragment}
`;

export type SearchPagesData = { searchPages: Page[] };

export type SearchPagesVars = {
  searchInput: {
    text: string;
    versionType: VERSION_TYPE;
    first?: number;
  };
};

export const SEARCH_PAGES = gql`
  query SearchPages($searchInput: SearchEntitiesInput!) {
    searchPages(searchInput: $searchInput) {
      ...Page
    }
  }
  ${pageFragment}
`;

export type EditablePagesData = {
  getEditablePagesByLiveStructure: string[];
};

export type EditablePagesVars = {
  editorStructureId: string;
};

export const EDITABLE_PAGES = gql`
  query GetEditablePages($editorStructureId: String!) {
    getEditablePagesByLiveStructure(editorStructureId: $editorStructureId)
  }
`;

export type LivePageVersionData = {
  pageLiveVersion: {
    versionId: string;
    pageConfig: PageConfig;
  };
};

export const LIVE_PAGE_VERSION = gql`
  query LivePageVersion($id: String!) {
    pageLiveVersion(id: $id) {
      versionId
      pageConfig {
        name
        title
        subTitle
        info
        layout {
          separator
          type
          columns {
            colspan
            elements {
              type
              widgetId
            }
          }
        }
      }
    }
  }
`;
