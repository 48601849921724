export const DangerIcon = ({ width = 22, height = 20 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.57028 0.711481C10.0067 0.465759 10.4991 0.33667 11 0.33667C11.5009 0.33667 11.9933 0.465759 12.4297 0.711481C12.8662 0.957204 13.2319 1.31126 13.4917 1.7395L13.4946 1.74425L21.6013 15.2779L21.6095 15.2917C21.8639 15.7324 21.9986 16.232 22 16.7409C22.0014 17.2497 21.8696 17.7501 21.6176 18.1922C21.3656 18.6343 21.0022 19.0027 20.5637 19.2607C20.1251 19.5188 19.6266 19.6575 19.1178 19.6631L19.1068 19.6632L2.88221 19.6632C2.37338 19.6576 1.87489 19.5188 1.43632 19.2607C0.997753 19.0027 0.634402 18.6343 0.382414 18.1922C0.130426 17.7501 -0.00141336 17.2497 1.14268e-05 16.7409C0.00143622 16.232 0.136076 15.7324 0.390536 15.2917L0.398659 15.2779L8.50831 1.73949C8.76807 1.31126 9.13384 0.957204 9.57028 0.711481ZM11 2.33667C10.8429 2.33667 10.6884 2.37717 10.5515 2.45425C10.4152 2.53096 10.3009 2.64133 10.2195 2.7748L2.11926 16.2975C2.04154 16.4344 2.00044 16.589 2 16.7465C1.99956 16.9061 2.04092 17.0631 2.11997 17.2018C2.19902 17.3405 2.31301 17.456 2.45059 17.537C2.58688 17.6172 2.74161 17.6606 2.89966 17.6632H19.1003C19.2584 17.6606 19.4131 17.6172 19.5494 17.537C19.687 17.456 19.801 17.3405 19.88 17.2018C19.9591 17.0631 20.0004 16.9061 20 16.7465C19.9996 16.589 19.9585 16.4344 19.8807 16.2975L11.7817 2.77676C11.7813 2.77613 11.7809 2.77551 11.7805 2.77488C11.6991 2.64138 11.5848 2.53098 11.4485 2.45425C11.3116 2.37717 11.1571 2.33667 11 2.33667Z"
      fill="#F26233"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 6.17773C11.5523 6.17773 12 6.62545 12 7.17773V11.0062C12 11.5585 11.5523 12.0062 11 12.0062C10.4477 12.0062 10 11.5585 10 11.0062V7.17773C10 6.62545 10.4477 6.17773 11 6.17773Z"
      fill="#F26233"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 14.8347C10 14.2824 10.4477 13.8347 11 13.8347H11.0096C11.5619 13.8347 12.0096 14.2824 12.0096 14.8347C12.0096 15.387 11.5619 15.8347 11.0096 15.8347H11C10.4477 15.8347 10 15.387 10 14.8347Z"
      fill="#F26233"
    />
  </svg>
);
