import cn from 'classnames';
import {
  ButtonHTMLAttributes,
  FC,
  MouseEventHandler,
  SVGProps,
  forwardRef,
} from 'react';

export type Props = {
  ariaLabel: string;
  disabled?: boolean;
  active?: boolean;
  icon?: FC<SVGProps<SVGSVGElement>>;
  title?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  ariaHasPopUp?: boolean;
  hasBorder?: boolean;
  size?: 'lg' | 'm' | 's' | 'xs';
  variant?: 'primary' | 'inherit';
} & Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'dataId'>;

const IconButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      dataId,
      ariaLabel,
      disabled,
      active,
      icon: Icon,
      title,
      onClick,
      ariaHasPopUp,
      hasBorder,
      size = 'lg',
      variant = 'primary',
    }: Props,
    ref,
  ) => {
    return (
      <button
        className={cn(
          'flex flex-none cursor-pointer items-center justify-center rounded-sm p-0',
          'focus-visible:outline-none focus-visible:ring-focusBlue ',
          'disabled:cursor-not-allowed disabled:bg-inherit',
          'active:bg-concrete-jungle-8',
          {
            'bg-inherit': !active,
            'bg-concrete-jungle-8': active,
            'hover:bg-concrete-jungle-7': variant === 'primary',
          },
          {
            'border border-concrete-jungle-6': hasBorder,
            'border-0': !hasBorder,
          },
          {
            'h-[1.875rem] w-[1.875rem] focus-visible:ring-4': size === 'lg',
            'h-6 w-6 focus-visible:ring': size === 'm',
            'h-5 w-5 focus-visible:ring-2': size === 's',
            'h-3.5 w-3.5 focus-visible:ring-2': size === 'xs',
          },
        )}
        ref={ref}
        type="button"
        data-id={dataId}
        aria-label={ariaLabel}
        title={title}
        disabled={disabled}
        onClick={onClick}
        aria-haspopup={ariaHasPopUp}
      >
        {Icon && (
          <Icon
            className={cn({
              'text-primary': !disabled && variant === 'primary',
              'text-disabled': disabled,
            })}
          />
        )}
      </button>
    );
  },
);

IconButton.displayName = 'IconButton';
export default IconButton;
