import styled, { css } from 'styled-components';
import { LEGEND_PLACEMENT } from "../../../../gql/widget/types";

type Props = {
  legendPlacement: LEGEND_PLACEMENT;
  isSm: boolean;
};

export const PieWidgetWrapper = styled.div<Props>`
  display: flex;
  align-items: center;
  flex-direction: ${({ legendPlacement, isSm }) => {
    if (isSm) {
      return 'column-reverse';
    }

    return legendPlacement === LEGEND_PLACEMENT.LEFT ? 'row' : 'row-reverse';
  }};

  & > * {
    flex: 1;
  }
`;

export const PieWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    overflow: visible;
  }
`;

export const LegendTable = styled.div<Props>`
  width: 100%;
  max-width: 400px;

  ${({ legendPlacement, isSm }) => {
    if (isSm) {
      return '';
    }

    return legendPlacement === LEGEND_PLACEMENT.LEFT
      ? css`
          padding-right: 50px;
        `
      : css`
          padding-left: 50px;
        `;
  }}
`;
