import React, { FC, ReactNode, useContext } from 'react';
import { NavBarData } from '../../app/Layout/NavBar';
import SimplePageHeader from './SimplePageHeader';

type Props = {
  collapsed?: boolean;
  title: ReactNode;
  actions?: ReactNode;
};

const SharedPageHeader = SimplePageHeader as FC<Props>;

const SimplePageHeaderBypass: FC<Props> = (props) => {
  const navBarData = useContext(NavBarData);

  if (typeof props.title === 'string') {
    navBarData.setPageTitle(props.title);
  }

  if (props.collapsed) {
    return null;
  }

  return <SharedPageHeader {...props} />;
};

export default SimplePageHeaderBypass;
