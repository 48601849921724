import jwt_decode from 'jwt-decode';
import { JWT } from './hooks';
import { USER_ROLES } from './types';
import { CustomerRole } from '../types';

const TOKEN_KEY = 'POLYTEIA-ADMIN-TOKEN';
const REFRESH = 'REFRESH';
export const NEW_USER = 'NEW_USER';
export const CAPTCHA_SVG = 'CAPTCHA_SVG';

export const setRefreshToken = (refreshToken: string) =>
  localStorage.setItem(REFRESH, refreshToken);

export const setToken = (token: string) =>
  localStorage.setItem(TOKEN_KEY, token);

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const getRefreshToken = () => localStorage.getItem(REFRESH);

export const isAuth = () => !!localStorage.getItem(TOKEN_KEY);

export const removeToken = () => localStorage.removeItem(TOKEN_KEY);

export const removeRefreshToken = () => localStorage.removeItem(REFRESH);

export const getLocalUserData = () => {
  const token = getToken();
  let userData = null;

  if (token) {
    try {
      userData = jwt_decode<JWT>(token);
    } catch {}
  }

  return userData;
};

export const getIsDA = () => {
  const user = getLocalUserData();

  return user?.role === USER_ROLES.DATA_ANALYST;
};

export const getIsCustomer = () => {
  const user = getLocalUserData();

  return user?.role === USER_ROLES.CUSTOMER;
};

export const getIsGroupAdmin = () => {
  const user = getLocalUserData();

  return user?.groups.some(({ roles }) => roles.includes(CustomerRole.admin));
};

export const getIsGroupEditor = () => {
  const user = getLocalUserData();

  return user?.groups.some(({ roles }) => roles.includes(CustomerRole.editor));
};

export const getIsAnalyticsEngineer = () => {
  const user = getLocalUserData();

  return user?.role === USER_ROLES.ANALYTICS_ENGINEER;
};
