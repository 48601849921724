import { Classes, Dialog } from '@blueprintjs/core';
import styled from 'styled-components';
import { ReactComponent as ChevronDown } from '../../../../../../assets/icons/chevron-down-primary.svg';
import { colors } from '../../../../../../utils/colors';
import { ReactComponent as MagicSvg } from './icons/magic-animated.svg';

export const InsightsDialog = styled(Dialog)`
  width: 90vw;
  height: 80vh;
  padding: 34px 41px 25px;
  background-color: white;
  position: relative;

  & > .${Classes.DIALOG_HEADER} {
    background-color: white;
    padding: 0 !important;

    & > h5 {
      margin-bottom: 6px;
      white-space: normal;
      font-weight: 400;

      & .title-text {
        font-size: 26px;
      }

      & .subtitle-text {
        font-size: 18px;
        color: ${colors.monochromatic2};
      }
    }

    & > button {
      position: absolute;
      top: 9px;
      right: 9px;
      border-radius: 50%;
      background-color: ${colors.primary0};
      min-height: 25px;
      height: 25px;
      min-width: 25px;
      width: 25px;

      &:hover {
        background-color: ${colors.primary2};
      }

      & > span {
        color: white;

        & > svg {
          height: 18px;
          width: 18px;
        }
      }
    }
  }

  & > .${Classes.DIALOG_BODY} {
    margin: 10px 0 0;
    overflow-y: auto;
  }
`;

export const InsightWidgetRow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 50px;
  padding-left: 25px;
  padding-right: 25px;

  &:hover {
    background-color: ${colors.monochromatic7};
  }

  & > *:not(:last-child) {
    margin-right: 16px;
  }
`;

export const InsightLoader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;

  & > div {
    width: 100%;
    margin-bottom: 15px;
    margin-top: 15px;
    height: 20px;
  }
`;

export const NoResults = styled.div`
  top: 33%;
  position: relative;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: ${colors.monochromatic4};
`;

export const TabsWrapper = styled.div`
  margin-right: 30px;

  & .${Classes.TAB} {
    color: ${colors.monochromatic5};
    font-size: 16px;
    font-weight: 400;
  }

  & .${Classes.TAB_INDICATOR} {
    background-color: ${colors.primary0};
    height: 2px;
  }

  & .${Classes.TAB}[aria-selected='true'],
  .${Classes.TAB}:not([aria-disabled='true']):hover {
    color: ${colors.primary0};
  }

  & .${Classes.TAB_LIST} > *:not(:last-child) {
    margin-right: 14px;
  }
`;

export const MagicStatic = styled(MagicSvg)`
  width: 15px;
  height: 15px;
  background-color: transparent !important;
`;

export const QueryTableWrapper = styled.div`
  margin-bottom: 20px;
`;

export const QueryPreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const WidgetEditorWrapper = styled.div`
  display: flex;
  height: 95%;
`;

export const Settings = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  border-right: 1px solid #e0e0e0;
  height: 100%;

  & > * {
    margin-top: 10px;
  }
`;

export const SettingsSection = styled.div`
  width: 355px;
  display: flex;
  flex-direction: column;
  background: ${colors.monochromatic7};
  border-radius: 8px;
  padding: 8px 14px 14px;

  & > div:first-child {
    margin-bottom: 10px;
    font-size: 16px;
    color: ${colors.primary0};
  }
`;

export const Chevron = styled(ChevronDown)<{ isOpen?: boolean }>`
  transition: all 0.2s ease-in-out;

  transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : 0)});
`;

export const DetailsInput = styled.input`
  background: white;
  border: 1px solid ${colors.monochromatic4};
  border-radius: 8px;
  height: 44px;
  width: 100%;
  padding-left: 15px;
`;

export const TitlePlaceholder = styled.div`
  position: absolute;
  top: 13px;
  left: 17px;
  pointer-events: none;
  color: ${colors.monochromatic3};
`;

export const WidgetTypeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
`;

export const WidgetTypeCard = styled.div<{
  selected: boolean;
  title: string;
  isTeaser?: boolean;
}>`
  height: 52px;
  border-radius: 3px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  outline: ${({ selected }) =>
    selected ? `1px solid ${colors.primary3}` : 'none'} !important;
  position: relative;
  cursor: ${({ isTeaser }) => (isTeaser ? 'not-allowed' : 'pointer')};

  &:hover > .card-title {
    opacity: 1;
  }
`;

export const CardTitle = styled.div`
  opacity: 0;
  transition: all 0.2s ease-in-out;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  text-align: center;
  width: 94px;
  padding: 7px 10px;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.07);
  backdrop-filter: blur(1.5px);

  font-size: 10px;
  line-height: 12px;
  color: ${colors.primary0};
`;

export const TeaserText = styled.div`
  font-style: italic;
  font-weight: 300;
  font-family: 'GT-Eesti-Pro-Display-light', sans-serif;
`;

export const ColWrapper = styled.div`
  display: flex;
  align-items: center;

  & > .${Classes.CHECKBOX}, & > .${Classes.RADIO} {
    height: 18px;
    width: 18px;
    margin: 0;
  }
`;

export const ColTitleInput = styled.input`
  background: transparent;
  border: none;
  height: 27px;
  flex: 1;
  padding-left: 7px;
  font-size: 14px;
  color: ${colors.monochromatic2};
  border-radius: 5px;

  &:focus {
    background: white;
  }
`;

export const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  margin-top: 15px;
  flex: 1;
`;

export const WidgetPreviewWrapper = styled.div<{ isTable: boolean }>`
  overflow-y: auto;
  overflow-x: ${({ isTable }) => (isTable ? 'auto' : 'hidden')};
  flex: 1;
`;

export const WidgetEditorActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  & > * {
    margin-left: 20px;
  }
`;
