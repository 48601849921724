import { PropsWithChildren, useState } from 'react';
import ModalContext from '../../context/modal.context';

const DEFAULT_MODAL_KEY = 'DEFAULT_MODAL_KEY';

export default function ModalProvider({ children }: PropsWithChildren<{}>) {
  const [activeModalKey, setActiveModalKey] = useState<string | null>(null);

  const openModal = (key = DEFAULT_MODAL_KEY) => setActiveModalKey(key);
  const closeModal = () => setActiveModalKey(null);
  const isModalOpen = (key = DEFAULT_MODAL_KEY) => activeModalKey === key;

  return (
    <ModalContext.Provider value={{ isModalOpen, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
}
