import { Collapse, Radio } from '@blueprintjs/core';
import { Formik } from 'formik';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Field,
  PivotChartPanel,
} from '../../../../../../components/PivotChartPanel';
import { TableField } from '../../../../../../components/PivotChartPanel/PivotChartPanel';
import Checkbox from '../../../../../../components/inputs/Checkbox';
import {
  COLUMN_TYPE,
  CONFIG_ELEMENT_VALUES_TYPE,
  WIDGET_TYPE,
} from '../../../../../../gql/widget/types';
import useOpen from '../../../../../../utils/hooks/useOpen';
import { PIVOT_FUNCTION } from '../../../../../../utils/pivot';
import { Filter } from '../../../../../Widgets/Widget/FilterPanel';
import { TextTitleAsterisk } from '../../RowSeparator/TextWidgetForm/styles';
import { CreateWidgetConfig } from './QueryPreview';
import { ReactComponent as BarIcon } from './icons/bar.svg';
import { ReactComponent as GroupedColIcon } from './icons/grouped-col.svg';
import { ReactComponent as LineChartIcon } from './icons/line.svg';
import { ReactComponent as MapIcon } from './icons/map.svg';
import { ReactComponent as StackedColIcon } from './icons/stacked-col.svg';
import { ReactComponent as TableIcon } from './icons/table.svg';
import {
  CardTitle,
  Chevron,
  ColTitleInput,
  ColWrapper,
  DetailsInput,
  Settings,
  SettingsSection,
  TeaserText,
  TitlePlaceholder,
  WidgetTypeCard,
  WidgetTypeGrid,
} from './styles';

export const WIDGET_TYPE_TILES = [
  {
    type: WIDGET_TYPE.LINE_CHART,
    icon: <LineChartIcon />,
    title: 'Liniendiagramm',
    // t('widget.editor.types.line-chart')
    i18nKey: 'widget.editor.types.line-chart',
  },
  {
    type: WIDGET_TYPE.GROUPED_COLUMN_CHART,
    icon: <GroupedColIcon />,
    title: 'Säulendiagramm',
    // t('widget.editor.types.grouped-column-chart')
    i18nKey: 'widget.editor.types.grouped-column-chart',
  },
  {
    type: WIDGET_TYPE.STACKED_COLUMN_CHART,
    icon: <StackedColIcon />,
    title: 'Gestapeltes \nSäulendiagramm',
    // t('widget.editor.types.stacked-column-chart')
    i18nKey: 'widget.editor.types.stacked-column-chart',
  },
  {
    type: WIDGET_TYPE.TABLE,
    icon: <TableIcon />,
    title: 'Tabelle',
    // t('widget.editor.types.table')
    i18nKey: 'widget.editor.types.table',
  },
  {
    type: WIDGET_TYPE.BAR_CHART,
    icon: <BarIcon />,
    title: 'Balkendiagramm',
    // t('widget.editor.types.bar-chart')
    i18nKey: 'widget.editor.types.bar-chart',
  },
  {
    type: WIDGET_TYPE.CHOROPLETH_MAP,
    icon: <MapIcon />,
    title: 'Karte',
    isTeaser: true,
    // t('widget.editor.types.map')
    i18nKey: 'widget.editor.types.map',
  },
];

type Props = {
  widgetConfig: CreateWidgetConfig;
  setWidgetConfig: Dispatch<SetStateAction<CreateWidgetConfig>>;
  onChange?: () => void;
  queryFields?: Field[];
};

const WidgetSettings: FC<Props> = ({
  widgetConfig,
  setWidgetConfig,
  onChange,
  queryFields,
}) => {
  const { t } = useTranslation();

  const { open: detailsOpen, handleToggle: detailsToggle } = useOpen(true);
  const { open: typeOpen, handleToggle: typeToggle } = useOpen(true);
  const { open: pivotOpen, handleToggle: pivotToggle } = useOpen(true);
  const { open: colsOpen, handleToggle: colsToggle } = useOpen(true);
  const { open: dimensionOpen, handleToggle: dimensionToggle } = useOpen(true);

  const title = widgetConfig.title;
  const isTable = widgetConfig.type === WIDGET_TYPE.TABLE;
  const isBar = widgetConfig.type === WIDGET_TYPE.BAR_CHART;

  const [dimensionsData, measuresData] = useMemo(() => {
    if (!widgetConfig.data?.length) {
      return Array.of([], []);
    }

    return [
      widgetConfig.data.filter(
        ({ colType }) => colType === COLUMN_TYPE.DIMENSION,
      ),
      widgetConfig.data.filter(
        ({ colType }) => colType === COLUMN_TYPE.MEASURE,
      ),
    ];
  }, [widgetConfig.data]);

  const handleRowFieldChange = useCallback(
    (field?: Field) => {
      setWidgetConfig((prev) => ({
        ...prev,
        pivot:
          field || prev.pivot
            ? {
                ...prev.pivot,
                rowField: field,
              }
            : undefined,
      }));
    },
    [setWidgetConfig],
  );

  const handleValueFieldChange = useCallback(
    (field?: Field) => {
      setWidgetConfig((prev) => ({
        ...prev,
        pivot:
          field || prev.pivot
            ? {
                ...prev.pivot,
                valueField: field,
                pivotFunction: field
                  ? field.type === 'number'
                    ? PIVOT_FUNCTION.SUM
                    : PIVOT_FUNCTION.COUNT
                  : undefined,
              }
            : undefined,
      }));
    },
    [setWidgetConfig],
  );

  const handlePivotFunctionChange = useCallback(
    (pivotFunction?: string) => {
      setWidgetConfig((prev) => ({
        ...prev,
        pivot:
          pivotFunction || prev.pivot
            ? {
                ...prev.pivot,
                pivotFunction,
              }
            : undefined,
      }));
    },
    [setWidgetConfig],
  );

  const handleColumnFieldChange = useCallback(
    (field?: Field) => {
      setWidgetConfig((prev) => ({
        ...prev,
        pivot:
          field || prev.pivot
            ? {
                ...prev.pivot,
                columnField: field,
              }
            : undefined,
      }));
    },
    [setWidgetConfig],
  );

  const addFilter = useCallback(
    (filter: Omit<Filter, 'id'>) => {
      setWidgetConfig((prev) => {
        let filters: Filter[];
        if (prev.pivot?.filters?.length) {
          const regex = new RegExp(`filter_(\\d+)`);
          const count =
            prev.pivot?.filters.reduce((previousValue, currentValue) => {
              const match = currentValue.id.match(regex);
              return Math.max(
                Number(match ? match[1] ?? 0 : -1),
                previousValue,
              );
            }, -1) + 1;
          const id = `filter_${count}`;
          filters = [...prev.pivot?.filters, { id, ...filter }];
        } else if (filter.field?.id) {
          filters = [{ id: filter.field.id, ...filter }];
        } else {
          return prev;
        }

        return {
          ...prev,
          pivot: {
            ...prev.pivot,
            filters,
          },
        };
      });
    },
    [setWidgetConfig],
  );

  const editFilter = useCallback(
    (id: string, filter: Omit<Filter, 'id'>) => {
      setWidgetConfig((prev) => ({
        ...prev,
        pivot: {
          ...prev.pivot,
          filters: prev.pivot?.filters?.map((pivotFilter) => {
            if (pivotFilter.id === id) {
              return {
                id,
                ...filter,
              };
            }
            return pivotFilter;
          }),
        },
      }));
    },
    [setWidgetConfig],
  );

  const removeFilter = useCallback(
    (id: string) => {
      setWidgetConfig((prev) => {
        const filteredFilters = prev.pivot?.filters?.filter(
          (filter) => filter.id !== id,
        );

        return {
          ...prev,
          pivot: {
            ...prev.pivot,
            filters: filteredFilters?.length ? filteredFilters : undefined,
          },
        };
      });
    },
    [setWidgetConfig],
  );

  const [draggingField, setDraggingField] = useState<TableField | null>(null);

  const [filterFieldToAdd, setFilterFieldToAdd] = useState<Field>();

  const [openedFieldInput, setOpenedFieldInput] = useState<string>();

  return (
    <Settings>
      <SettingsSection id="widget-editor-details-panel">
        <div
          onClick={detailsToggle}
          className="u-display-space-between align-items-center u-cursor-pointer"
        >
          <div>Details</div>
          <Chevron isOpen={detailsOpen} />
        </div>
        <Collapse isOpen={detailsOpen}>
          <DetailsInput
            value={title}
            onChange={(e) => {
              setWidgetConfig((prev) => ({
                ...prev,
                title: e.target.value,
                name: e.target.value,
              }));
              onChange?.();
            }}
          />
          {!title && (
            <TitlePlaceholder>
              Titel hinzufügen
              <TextTitleAsterisk>*</TextTitleAsterisk>
            </TitlePlaceholder>
          )}
        </Collapse>
      </SettingsSection>
      <SettingsSection id="widget-editor-type-panel">
        <div
          onClick={typeToggle}
          className="u-display-space-between align-items-center u-cursor-pointer"
        >
          <div>Art der Darstellung</div>
          <Chevron isOpen={typeOpen} />
        </div>
        <Collapse isOpen={typeOpen}>
          <WidgetTypeGrid>
            {WIDGET_TYPE_TILES.map(({ type, title, icon, isTeaser }) => (
              <WidgetTypeCard
                key={type}
                selected={widgetConfig.type === type}
                title={title}
                isTeaser={isTeaser}
                onClick={() => {
                  if (isTeaser) {
                    return;
                  }

                  setWidgetConfig((prev) => ({
                    ...prev,
                    type,
                  }));

                  onChange?.();
                }}
              >
                {icon}
                <CardTitle className="card-title">
                  <div>{title}</div>
                  {isTeaser && <TeaserText>demnächst</TeaserText>}
                </CardTitle>
              </WidgetTypeCard>
            ))}
          </WidgetTypeGrid>
        </Collapse>
      </SettingsSection>
      {widgetConfig.pivot &&
      (queryFields ? queryFields.length : widgetConfig.data.length) ? (
        <SettingsSection id="widget-editor-pivot-panel">
          <div
            onClick={pivotToggle}
            className="u-display-space-between align-items-center u-cursor-pointer"
          >
            <div>{t('pivot.title')}</div>
            <Chevron isOpen={pivotOpen} />
          </div>
          <Collapse isOpen={pivotOpen}>
            <Formik<{ pivotFunction?: string }>
              initialValues={{
                pivotFunction: widgetConfig.pivot.pivotFunction,
              }}
              onSubmit={() => {}}
            >
              {function Render(props) {
                useEffect(() => {
                  handlePivotFunctionChange(props.values.pivotFunction);
                }, [props.values.pivotFunction]);

                return (
                  <PivotChartPanel
                    fields={
                      queryFields ??
                      widgetConfig.data.map((dataConfig) => ({
                        id: dataConfig.id,
                        name: dataConfig.title,
                        type:
                          dataConfig.valuesType ===
                          CONFIG_ELEMENT_VALUES_TYPE.string
                            ? 'string'
                            : dataConfig.valuesType ===
                              CONFIG_ELEMENT_VALUES_TYPE.date
                            ? 'date'
                            : 'number',
                        contentType:
                          dataConfig.colType === COLUMN_TYPE.DIMENSION
                            ? 'dimension'
                            : dataConfig.colType === COLUMN_TYPE.MEASURE
                            ? 'measure'
                            : '',
                      }))
                    }
                    table={isTable}
                    pivotFunctionFieldName="pivotFunction"
                    rowField={widgetConfig.pivot?.rowField}
                    valueField={widgetConfig.pivot?.valueField}
                    columnField={widgetConfig.pivot?.columnField}
                    setRowField={handleRowFieldChange}
                    setValueField={handleValueFieldChange}
                    setColumnField={handleColumnFieldChange}
                    removeFilter={removeFilter}
                    draggingField={draggingField}
                    setDraggingField={setDraggingField}
                    setFilterFieldToAdd={setFilterFieldToAdd}
                    openedFieldInput={openedFieldInput}
                    setOpenedFieldInput={setOpenedFieldInput}
                  />
                );
              }}
            </Formik>
          </Collapse>
        </SettingsSection>
      ) : (
        <>
          {!isTable && (
            <SettingsSection id="widget-editor-dimension-panel">
              <div
                onClick={dimensionToggle}
                className="u-display-space-between align-items-center u-cursor-pointer"
              >
                <div>{isBar ? 'Y-Achse' : 'X-Achse'}</div>
                <Chevron isOpen={dimensionOpen} />
              </div>
              <Collapse isOpen={dimensionOpen}>
                {dimensionsData.map((dataConfig) => {
                  const ind = widgetConfig.data.findIndex(
                    ({ column }) => column === dataConfig.column,
                  );

                  return (
                    <ColWrapper key={ind}>
                      <Radio
                        value={dataConfig.column}
                        checked={dataConfig.isSelectedDimension}
                        onClick={() => {
                          setWidgetConfig?.((prev) => {
                            const newData = prev.data.map((d) => ({
                              ...d,
                              isSelectedDimension: false,
                            }));

                            return {
                              ...prev,
                              data: [
                                ...newData.slice(0, ind),
                                {
                                  ...dataConfig,
                                  isSelectedDimension: true,
                                },
                                ...newData.slice(ind + 1),
                              ],
                            };
                          });

                          onChange?.();
                        }}
                      />
                      <ColTitleInput
                        value={dataConfig.title}
                        onChange={(e) => {
                          setWidgetConfig((prev) => ({
                            ...prev,
                            data: [
                              ...prev.data.slice(0, ind),
                              {
                                ...dataConfig,
                                title: e.target.value,
                              },
                              ...prev.data.slice(ind + 1),
                            ],
                          }));

                          onChange?.();
                        }}
                      />
                    </ColWrapper>
                  );
                })}
              </Collapse>
            </SettingsSection>
          )}
          <SettingsSection id="widget-editor-measure-panel">
            <div
              onClick={colsToggle}
              className="u-display-space-between align-items-center u-cursor-pointer"
            >
              <div>{isTable ? 'Spalten' : isBar ? 'X-Achse' : 'Y-Achse'}</div>
              <Chevron isOpen={colsOpen} />
            </div>
            <Collapse isOpen={colsOpen}>
              {(isTable ? widgetConfig.data : measuresData).map(
                (dataConfig) => {
                  const ind = widgetConfig.data.findIndex(
                    ({ column }) => column === dataConfig.column,
                  );

                  return (
                    <ColWrapper key={ind}>
                      <Checkbox
                        onChange={(e) => {
                          const { checked } = e.currentTarget;

                          setWidgetConfig?.((prev) => {
                            const selectableData = isTable
                              ? prev.data
                              : prev.data.filter(
                                  ({ colType }) =>
                                    colType === COLUMN_TYPE.MEASURE,
                                );

                            if (
                              !checked &&
                              selectableData.filter(({ disabled }) => !disabled)
                                .length === 1
                            ) {
                              return prev;
                            }

                            return {
                              ...prev,
                              data: [
                                ...prev.data.slice(0, ind),
                                {
                                  ...dataConfig,
                                  disabled: !checked,
                                },
                                ...prev.data.slice(ind + 1),
                              ],
                            };
                          });

                          onChange?.();
                        }}
                        checked={!dataConfig.disabled}
                      />
                      <ColTitleInput
                        value={dataConfig.title}
                        onChange={(e) => {
                          setWidgetConfig((prev) => ({
                            ...prev,
                            data: [
                              ...prev.data.slice(0, ind),
                              {
                                ...dataConfig,
                                title: e.target.value,
                              },
                              ...prev.data.slice(ind + 1),
                            ],
                          }));

                          onChange?.();
                        }}
                      />
                    </ColWrapper>
                  );
                },
              )}
            </Collapse>
          </SettingsSection>
        </>
      )}
    </Settings>
  );
};

export default WidgetSettings;
