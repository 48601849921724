import styled from 'styled-components';
import { colors } from '../../utils/colors';
import { AllStyledComponent } from '@remirror/styles/styled-components';
import { LAYOUT_TYPE } from "../../gql/page/types";

export const EditorWrapper = styled(AllStyledComponent)<{ isEmpty: boolean }>`
  position: relative;

  & .remirror-editor-wrapper {
    padding-top: 0;
  }

  & .ProseMirror.remirror-editor {
    min-height: 307px;
    padding: 20px 20px 70px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.05), 0 2px 8px 0 rgba(0, 0, 0, 0.1) !important;
    overflow-y: auto;
    border: 1px solid
      ${({ isEmpty }) =>
        isEmpty ? colors.monochromatic5 : colors.monochromatic0};
    transition: border-color 0.1s ease-in-out;

    &:hover {
      border-color: ${colors.monochromatic3};
    }

    &:focus {
      border: 1.5px solid ${colors.primary0};
      padding: 19.5px 19.5px 69.5px;
    }

    & .remirror-collapsible-list-item-button {
      width: 4px;
      height: 4px;
      background-color: black;
    }

    & .remirror-list-item-marker-container {
      left: -24px;
    }
  }
`;
const TOOLBAR_PADDING_MAP = {
  [LAYOUT_TYPE.ONE_COL]: '5px 10px',
  [LAYOUT_TYPE.TWO_COL]: '5px 10px',
  [LAYOUT_TYPE.THREE_COL]: '3.5px 7px',
  [LAYOUT_TYPE.FOUR_COL]: '3.5px 7px',
  [LAYOUT_TYPE.FIVE_COL]: '2.5px 5.5px',
  [LAYOUT_TYPE.SIX_COL]: '2.5px 5.5px',
  [LAYOUT_TYPE.SEVEN_COL]: '2.5px 5.5px',
  [LAYOUT_TYPE.EIGHT_COL]: '2.5px 5.5px',
  [LAYOUT_TYPE.NINE_COL]: '2.5px 5.5px',
  [LAYOUT_TYPE.TEN_COL]: '2.5px 5.5px',
};

export const ToolbarWrapper = styled.div<{ layoutType: LAYOUT_TYPE }>`
  position: absolute;
  bottom: 74px;
  left: 50%;
  transform: translateX(-50%);
  padding: ${({ layoutType }) => TOOLBAR_PADDING_MAP[layoutType]};
  display: flex;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1), 0 0 0 rgba(0, 0, 0, 0.05);
`;

const ITEM_SIZE_MAP = {
  [LAYOUT_TYPE.ONE_COL]: 30,
  [LAYOUT_TYPE.TWO_COL]: 30,
  [LAYOUT_TYPE.THREE_COL]: 24,
  [LAYOUT_TYPE.FOUR_COL]: 18,
  [LAYOUT_TYPE.FIVE_COL]: 16,
  [LAYOUT_TYPE.SIX_COL]: 16,
  [LAYOUT_TYPE.SEVEN_COL]: 16,
  [LAYOUT_TYPE.EIGHT_COL]: 16,
  [LAYOUT_TYPE.NINE_COL]: 16,
  [LAYOUT_TYPE.TEN_COL]: 16,
};

const ITEM_GAP_MAP = {
  [LAYOUT_TYPE.ONE_COL]: 20,
  [LAYOUT_TYPE.TWO_COL]: 20,
  [LAYOUT_TYPE.THREE_COL]: 14,
  [LAYOUT_TYPE.FOUR_COL]: 14,
  [LAYOUT_TYPE.FIVE_COL]: 10.5,
  [LAYOUT_TYPE.SIX_COL]: 10.5,
  [LAYOUT_TYPE.SEVEN_COL]: 10.5,
  [LAYOUT_TYPE.EIGHT_COL]: 10.5,
  [LAYOUT_TYPE.NINE_COL]: 10.5,
  [LAYOUT_TYPE.TEN_COL]: 10.5,
};

export const ToolbarItem = styled.div<{
  active: boolean;
  disabled?: boolean;
  layoutType: LAYOUT_TYPE;
}>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  width: ${({ layoutType }) => ITEM_SIZE_MAP[layoutType]}px;
  height: ${({ layoutType }) => ITEM_SIZE_MAP[layoutType]}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  color: ${({ disabled }) =>
    disabled ? colors.monochromatic3 : colors.primary0};
  background-color: ${({ active, disabled }) => {
    if (disabled) {
      return colors.monochromatic5;
    }

    return active ? colors.primary5 : 'white';
  }};

  transition: all 0.1s ease-in-out;

  &:not(:last-child) {
    margin-right: ${({ layoutType }) => ITEM_GAP_MAP[layoutType]}px;
  }
`;
