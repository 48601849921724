import { useLazyQuery, useQuery } from '@apollo/client';
import { Classes, Icon, Tab, Tabs } from '@blueprintjs/core';
import { ResizeSensor2 } from '@blueprintjs/popover2';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ReactComponent as DangerIcon } from '../../../../../../assets/icons/danger.svg';
import ModalLegacy from '../../../../../../components/Modal/ModalLegacy';
import SearchInputLegacy from '../../../../../../components/inputs/SearchInputLegacy';
import { useAthenaQuery } from '../../../../../../gql/dataSource/hooks';
import { VERSION_TYPE } from '../../../../../../gql/dataSource/types';
import {
  GET_ALL_INSIGHT_QUERIES,
  GET_ALL_INSIGHT_WIDGETS,
  GET_INSIGHT_QUERIES_BY_STRUCTURE,
  GET_INSIGHT_WIDGETS_BY_STRUCTURE,
  GetAllInsightQueriesData,
  GetAllInsightQueriesVars,
  GetAllInsightWidgetsData,
  GetAllInsightWidgetsVars,
  GetInsightQueriesByStructureData,
  GetInsightQueriesByStructureVars,
  GetInsightWidgetsByStructureData,
  GetInsightWidgetsByStructureVars,
} from '../../../../../../gql/library/queries';
import { InsightQuery } from '../../../../../../gql/library/types';
import {
  SEARCH_QUERIES,
  SearchQueriesData,
  SearchQueriesVars,
} from '../../../../../../gql/query/queries';
import { getIsDA } from '../../../../../../gql/user/local';
import {
  SEARCH_WIDGETS,
  SearchWidgetsData,
  SearchWidgetsVars,
} from '../../../../../../gql/widget/queries';
import { colors } from '../../../../../../utils/colors';
import { ENV_VAR, getEnvVar } from '../../../../../../utils/common';
import { useSearchEntityUtils } from '../../../../../../utils/hooks/entity';
import useOpen from '../../../../../../utils/hooks/useOpen';
import { TYPE_ICONS } from '../../../../../Widgets/Widget/WidgetSkeletonSelector';
import { useAddWidgetIdToPage, useEditingContext } from '../../../editing';
import QueryPreview from './QueryPreview';
import {
  InsightLoader,
  InsightWidgetRow,
  InsightsDialog,
  NoResults,
  TabsWrapper,
} from './styles';

export enum LIBRARY_ENTITY {
  WIDGET = 'widget',
  QUERY = 'query',
}

type Props = {
  open: boolean;
  rowInd: number;
  colInd: number;
  handleClose: () => void;
};

const LIMIT_STEP = 50;
const isQueriesDisabled = getEnvVar(ENV_VAR.SHOW_QUERIES_LIBRARY) === 'false';

const InsightsLibrary: FC<Props> = ({ open, handleClose, rowInd, colInd }) => {
  const { t } = useTranslation();

  const [entityType, setEntityType] = useState(LIBRARY_ENTITY.WIDGET);
  const [query, setQuery] = useState<InsightQuery | null>(null);
  const [isCreatingWidget, setIsCreatingWidget] = useState<boolean>(false);
  const {
    open: exitModalOpen,
    handleOpen: handleExitModalOpen,
    handleClose: handleExitModalClose,
  } = useOpen();

  const { searchInput, search, onSearch } = useSearchEntityUtils();

  const { savedStructure } = useEditingContext();
  const addWidgetIdToPage = useAddWidgetIdToPage();

  const [limit, setLimit] = useState(LIMIT_STEP);
  const [height, setHeight] = useState(0);

  const [isSearching, setIsSearching] = useState<boolean>(false);

  const isDA = getIsDA();

  const isExtendedLibrary =
    savedStructure?.structureConfig.allowExtendedLibrary;

  const [
    getInsightWidgetsByStructure,
    {
      data: insightWidgetsDataByStructure,
      loading: insightWidgetsLoadingByStructure,
      previousData: prevInsightWidgetsDataByStructure,
    },
  ] = useLazyQuery<
    GetInsightWidgetsByStructureData,
    GetInsightWidgetsByStructureVars
  >(GET_INSIGHT_WIDGETS_BY_STRUCTURE, {
    variables: {
      searchInput: {
        text: search,
        first: limit,
        sortField: 'widgetConfig.title',
        sortOrder: 'ASC',
      },
      structureId: savedStructure?.id!,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      setIsSearching(false);
    },
  });

  const [
    getAllInsightWidgets,
    {
      data: insightAllWidgetsData,
      loading: insightAllWidgetsLoading,
      previousData: prevAllInsightWidgetsData,
    },
  ] = useLazyQuery<GetAllInsightWidgetsData, GetAllInsightWidgetsVars>(
    GET_ALL_INSIGHT_WIDGETS,
    {
      variables: {
        searchInput: {
          text: search,
          first: limit,
          sortField: 'widgetConfig.title',
          sortOrder: 'ASC',
        },
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: () => {
        setIsSearching(false);
      },
    },
  );

  const customerWidgetsLoading =
    insightWidgetsLoadingByStructure || insightAllWidgetsLoading;

  const getCustomerWidgets = isExtendedLibrary
    ? getAllInsightWidgets
    : getInsightWidgetsByStructure;

  const customerWidgetsData = isExtendedLibrary
    ? insightAllWidgetsData?.getAllInsightLiveWidgets
    : insightWidgetsDataByStructure?.getInsightLiveWidgetsByStructure;

  const customerPrevWidgetsData = isExtendedLibrary
    ? prevAllInsightWidgetsData?.getAllInsightLiveWidgets
    : prevInsightWidgetsDataByStructure?.getInsightLiveWidgetsByStructure;

  const [
    getAllWidgets,
    {
      data: allWidgetsData,
      loading: allWidgetsLoading,
      previousData: prevAllWidgetsData,
    },
  ] = useLazyQuery<SearchWidgetsData, SearchWidgetsVars>(SEARCH_WIDGETS, {
    variables: {
      searchInput: {
        versionType: VERSION_TYPE.LIVE,
        text: search,
        first: limit,
      },
    },
    onCompleted: () => {
      setIsSearching(false);
    },
  });

  const widgetsLoading = customerWidgetsLoading || allWidgetsLoading;

  const getWidgets = isDA ? getAllWidgets : getCustomerWidgets;

  const newWidgetsData = isDA
    ? allWidgetsData?.searchWidgets
    : customerWidgetsData;

  const prevWidgetsData = isDA
    ? prevAllWidgetsData?.searchWidgets
    : customerPrevWidgetsData;

  const widgetsData = newWidgetsData || prevWidgetsData;

  const isAllQueries = isDA || isExtendedLibrary;

  const {
    data: newQueriesByStructureData,
    loading: queriesByStructureLoading,
    previousData: prevQueriesByStructureData,
  } = useQuery<
    GetInsightQueriesByStructureData,
    GetInsightQueriesByStructureVars
  >(GET_INSIGHT_QUERIES_BY_STRUCTURE, {
    variables: {
      searchInput: {
        text: search,
        first: limit,
        sortField: 'queryConfig.title',
        sortOrder: 'ASC',
      },
      structureId: savedStructure?.id!,
    },
    skip: entityType !== LIBRARY_ENTITY.QUERY || isAllQueries,
  });

  const {
    data: newAllQueriesData,
    loading: queriesAllLoading,
    previousData: prevAllQueriesData,
  } = useQuery<GetAllInsightQueriesData, GetAllInsightQueriesVars>(
    GET_ALL_INSIGHT_QUERIES,
    {
      variables: {
        searchInput: {
          text: search,
          first: limit,
          sortField: 'queryConfig.title',
          sortOrder: 'ASC',
        },
      },
      skip: entityType !== LIBRARY_ENTITY.QUERY || !isAllQueries,
    },
  );

  const {
    data: searchQueriesData,
    loading: searchQueriesLoading,
    previousData: searchPrevQueriesData,
  } = useAthenaQuery<SearchQueriesData, SearchQueriesVars>(SEARCH_QUERIES, {
    variables: {
      text: search,
      versionType: VERSION_TYPE.LIVE,
      first: limit,
    },
    skip: entityType !== LIBRARY_ENTITY.QUERY || !isDA,
  });

  const queriesLoading =
    queriesByStructureLoading || queriesAllLoading || searchQueriesLoading;

  const newQueriesData = isDA
    ? searchQueriesData?.searchQueryByType
    : isAllQueries
    ? newAllQueriesData?.getAllInsightLiveQueries
    : newQueriesByStructureData?.getInsightLiveQueriesByStructure;

  const prevQueriesData = isDA
    ? searchPrevQueriesData?.searchQueryByType
    : isAllQueries
    ? prevAllQueriesData?.getAllInsightLiveQueries
    : prevQueriesByStructureData?.getInsightLiveQueriesByStructure;

  const queriesData = newQueriesData || prevQueriesData;

  const isFirstLoad = !prevWidgetsData && !prevQueriesData;

  const isWidgets = entityType === LIBRARY_ENTITY.WIDGET;

  const listLoading = isWidgets ? widgetsLoading : queriesLoading;

  const widgetsList = useMemo(
    () =>
      widgetsData?.map(({ widgetConfig, id }) => (
        <InsightWidgetRow
          key={id}
          onClick={() => addWidgetIdToPage(id, rowInd, colInd)}
        >
          <Icon icon={TYPE_ICONS[widgetConfig?.type]} color={colors.primary0} />
          <div className="font-16">{widgetConfig?.title}</div>
        </InsightWidgetRow>
      )),
    [addWidgetIdToPage, colInd, widgetsData, rowInd],
  );

  const queriesList = useMemo(
    () =>
      queriesData
        ?.filter(({ queryConfig }) => {
          if (isDA) {
            return true;
          }
          return !queryConfig.isHidden;
        })
        ?.map((query) => (
          <InsightWidgetRow key={query.id} onClick={() => setQuery(query)}>
            <div className="font-16">{query.queryConfig?.title}</div>
          </InsightWidgetRow>
        )),
    [queriesData, isDA],
  );

  const lists = useMemo(() => {
    const list = isWidgets ? widgetsList : queriesList;

    return (
      <ResizeSensor2
        onResize={(entries) => {
          setHeight(entries[0].contentRect.height);
        }}
      >
        <div className="u-height-100">
          <InfiniteScroll
            dataLength={
              isWidgets ? widgetsData?.length || 0 : queriesData?.length || 0
            }
            height={height - 10}
            next={() => setLimit((prev) => prev + LIMIT_STEP)}
            hasMore
            loader={null}
          >
            {(listLoading && isFirstLoad) || isSearching ? (
              <InsightLoader>
                {Array(12)
                  .fill(null)
                  .map((_, ind) => (
                    <div key={ind} className="widget-loading" />
                  ))}
              </InsightLoader>
            ) : isFirstLoad &&
              !(isWidgets ? widgetsData : queriesData)?.length ? (
              <NoResults>
                <div>
                  Es wurden keine Ergebnisse zu Ihrer Suchanfrage gefunden.
                </div>
                <div>
                  Bitte versuchen Sie es noch einmal mit anderen Begriffen.
                </div>
              </NoResults>
            ) : (
              list
            )}
          </InfiniteScroll>
        </div>
      </ResizeSensor2>
    );
  }, [
    widgetsData,
    queriesData,
    isWidgets,
    listLoading,
    queriesList,
    widgetsList,
    isFirstLoad,
    height,
    isSearching,
  ]);

  const [dialogActions, setDialogActions] = useState<JSX.Element | null>(null);

  useEffect(() => {
    if (isCreatingWidget) {
      return;
    }
    setDialogActions(
      <div className="u-display-space-between align-items-center">
        <div className="u-margin-right-10 d-flex flex-column">
          <span className="title-text font-26 font-w-500">
            {isCreatingWidget
              ? 'Neue Visualisierung'
              : query?.queryConfig?.title || t('entity.insights-libraries')}
          </span>
          {isCreatingWidget && (
            <span className="subtitle-text font-26 font-w-500">
              {query?.queryConfig?.title}
            </span>
          )}
        </div>

        {!query && (
          <div className="d-flex">
            {!isQueriesDisabled && (
              <TabsWrapper>
                <Tabs
                  id="EntityTypeTabs"
                  onChange={(id) => {
                    setEntityType(id as LIBRARY_ENTITY);
                    setLimit(LIMIT_STEP);
                  }}
                  selectedTabId={entityType}
                >
                  <Tab id={LIBRARY_ENTITY.WIDGET} title="Diagramme" />
                  <Tab id={LIBRARY_ENTITY.QUERY} title={t('entity.datasets')} />
                </Tabs>
              </TabsWrapper>
            )}
            <SearchInputLegacy
              onChange={(value) => {
                onSearch(value);
                setIsSearching(true);
              }}
              value={searchInput}
            />
          </div>
        )}
      </div>,
    );
  }, [isCreatingWidget, query, entityType, onSearch, searchInput, t]);

  return (
    <>
      <InsightsDialog
        enforceFocus={false}
        isOpen={open}
        onClose={() =>
          isCreatingWidget ? handleExitModalOpen() : handleClose()
        }
        onOpening={() => getWidgets()}
        title={dialogActions}
      >
        <div className={Classes.DIALOG_BODY}>
          {query ? (
            <QueryPreview
              queryId={query.id}
              handleClose={() => setQuery(null)}
              handleConfirm={() => {
                handleClose();
                setQuery(null);
              }}
              setIsCreatingWidget={setIsCreatingWidget}
              isCreatingWidget={isCreatingWidget}
              rowInd={rowInd}
              colInd={colInd}
              setDialogActions={setDialogActions}
            />
          ) : (
            lists
          )}
        </div>
      </InsightsDialog>
      <ModalLegacy
        title={<DangerIcon />}
        content={
          <p style={{ width: 475 }}>
            Sind Sie sicher, dass Sie den Diagrammeditor schließen wollen?
            <br />
            Alle Ihre bisherigen Einstellungen gehen verloren.
          </p>
        }
        confirmButtonText="Verlassen"
        cancelButtonText="Abbrechen"
        intent="danger"
        isOpen={exitModalOpen}
        onCancel={handleExitModalClose}
        onConfirm={() => {
          handleExitModalClose();
          handleClose();
          setIsCreatingWidget(false);
          setQuery(null);
        }}
      />
    </>
  );
};

export default InsightsLibrary;
