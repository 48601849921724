import { gql } from '@apollo/client';
import { metadata } from '../common/fragments';

export const dataSourceConfigFragment = gql`
  fragment DataSourceConfig on DataSourceConfig {
    type
    name
    host
    user
    password
    database
    schema
    port
    healthCheckQuery
    catalog
    useTLS
  }
`;

export const dataSourceFragment = gql`
  fragment DataSource on DataSource {
    ${metadata}
    dataSourceConfig {
      ...DataSourceConfig
    }
  }
  ${dataSourceConfigFragment}
`;
