import React, { FC } from 'react';
import { MultiSelect2, MultiSelect2Props } from '@blueprintjs/select';

const OldSearchableMultiInput: FC<MultiSelect2Props<any>> = ({
  popoverProps,
  ...props
}) => {
  const hasValue = !!props.selectedItems?.some((item) => item?.title);

  return (
    <MultiSelect2
      className={`widget-filter searchable-multi-filter freetext-filter ${hasValue ? 'has-value' : ''}`}
      popoverProps={{
        minimal: true,
        popoverClassName: 'popover-filter',
        ...popoverProps,
      }}
      {...props}
    />
  );
};

export default OldSearchableMultiInput;
