import React from 'react';
import { CustomLayerProps } from '@nivo/line';
import { ComputedBarDatum } from '@nivo/bar/dist/types/types';
import { DOMAIN_POINT, Highlight } from '../../../../../gql/widget/types';

const DomainHighlight =
  (
    highlights: Highlight[],
    xAxisVals: string[],
    horizontal?: boolean,
    seriesNum = 1,
    stacked?: boolean,
  ): ((
    props: CustomLayerProps & { bars: ComputedBarDatum<any>[] },
  ) => React.ReactNode) =>
  ({
    bars,
    innerHeight,
    innerWidth,
    xScale: originalXScale,
    yScale: originalYScale,
  }) => {
    const xScale = originalXScale as (val: string) => number;
    const yScale = originalYScale as (val: string) => number;

    return highlights.map(({ color, start, end }, ind) => {
      const startX = xAxisVals.findIndex((val) => val === start);
      const endX = xAxisVals.findIndex((val) => val === end);

      const isStartIncorrect = startX === -1 && start !== DOMAIN_POINT.ASTERIX;

      const isEndIncorrect = endX === -1 && end !== DOMAIN_POINT.ASTERIX;

      if (isStartIncorrect && isEndIncorrect) {
        return null;
      }

      const x0 = start === DOMAIN_POINT.ASTERIX ? xAxisVals[0] : start;
      const x1 =
        end === DOMAIN_POINT.ASTERIX || isEndIncorrect
          ? xAxisVals[xAxisVals.length - 1]
          : end;

      const bar = bars?.[0];

      return (
        <rect
          className="domain-rect"
          key={ind}
          fill={color}
          opacity={color?.startsWith('rgba') ? undefined : 0.3}
          x={horizontal ? 0 : xScale(x0)}
          y={horizontal ? yScale(x1) : 0}
          width={
            horizontal
              ? innerWidth
              : xScale(x1) -
                xScale(x0) +
                (bar?.width || 0) * (stacked ? 1 : seriesNum)
          }
          height={
            horizontal
              ? yScale(x0) - yScale(x1) + (bar?.height || 0) * seriesNum
              : innerHeight
          }
        />
      );
    });
  };

export default DomainHighlight;
