import cn from 'classnames';
import {
  DragEventHandler,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  useState,
} from 'react';

type Size = 'small' | 'medium' | 'large';

type DropFieldProps = PropsWithChildren<{
  /**
   * Determines the size of the DropField
   */
  size: Size;
  /**
   * Determines the state of the DropField.
   */
  isInitialActive?: boolean;
  /**
   * Defines the header (Main Text) of the DropField.
   */
  title: string;
  /**
   * Defines the additional information (Secondary Text) of the DropField.
   */
  subtitle?: string;
  /**
   * Additional content.
   */
  content?: ReactNode;
  /**
   * callback to be called on drop
   */
  onDrop: DragEventHandler<HTMLDivElement>;
  /**
   * callback to be called on click
   */
  onClick?: MouseEventHandler<HTMLDivElement>;
}>;

export default function DropField({
  size,
  title,
  subtitle,
  content,
  children,
  isInitialActive = false,
  onDrop,
  onClick,
}: DropFieldProps) {
  const [isActive, setIsActive] = useState(isInitialActive);

  return (
    <div
      id="drop-zone"
      onDragEnter={(e) => {
        e.preventDefault();
        setIsActive(true);
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        setIsActive(false);
      }}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => {
        e.preventDefault();
        onDrop(e);
        setIsActive(false);
      }}
      onClick={onClick}
      className={cn(
        'relative rounded-lg border border-dashed border-blueberry-3 px-6',
        {
          'border-2 bg-blueberry-6': isActive,
        },
      )}
    >
      <div
        className={cn(
          'flex h-full w-full flex-col items-center justify-center',
          { 'pointer-events-none': isActive, '-my-[1px]': isActive },
          {
            'py-6': size === 'large',
            'py-4': size === 'medium',
            'py-2': size === 'small',
          },
        )}
      >
        <p
          className={cn('text-center', {
            'text-lg': size === 'large',
            'text-base': size === 'medium',
            'text-sm': size === 'small',
          })}
        >
          {title}
        </p>
        {children && (
          <div
            className={cn('pt-2.5', {
              'pb-6': size === 'large',
              'pb-4': size === 'medium',
              'pb-2': size === 'small',
            })}
          >
            {children}
          </div>
        )}
        <p
          className={cn('text-center', {
            'text-base': size === 'large',
            'text-sm': size === 'medium',
            'text-xs': size === 'small',
          })}
        >
          {subtitle}
        </p>
      </div>
      {content}
    </div>
  );
}
