import styled, { css } from 'styled-components';

export const SortSVG = styled.svg<{ isSorted: boolean }>`
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 3px;

  ${({ isSorted }) =>
    isSorted
      ? css`
          &&& {
            opacity: 1;
          }
        `
      : ''}
`;

export const TableWrapper = styled.div`
  & .sort-icon {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover .sort-icon {
    opacity: 1;
  }
`;
