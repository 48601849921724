import {
  DOMAIN_POINT,
  Highlight,
  HIGHLIGHT_TYPE,
} from '../../../gql/widget/types';

export type HighlightWithRange = {
  highlight: Highlight;
  ranges: [number, number][];
};

export const HIGHLIGHT_DASHARRAY = {
  [HIGHLIGHT_TYPE.DASHED]: '5, 8',
  [HIGHLIGHT_TYPE.DOTTED]: '1, 5',
  [HIGHLIGHT_TYPE.TRANSLUCENT]: undefined,
};

export const TRANSLUCENT_OPACITY = 0.3;

export const getNoHighlightsRanges = (
  highlightRanges: [number, number][],
  domainLength: number,
): [number, number][] => {
  if (!highlightRanges.length) {
    return [[0, domainLength]];
  }

  const domainIndexes = Array.from({ length: domainLength }, (_, index) => ({
    domainIndex: index,
    isHighlited: false,
  }));

  highlightRanges.forEach(([start, end]) => {
    for (let index = start; index < end - 1; index++) {
      domainIndexes[index].isHighlited = true;

      const nextDomainValue = domainIndexes[index + 1];
      // Otherwise last element wont be highlighted
      if (nextDomainValue.domainIndex === domainLength - 1) {
        nextDomainValue.isHighlited = true;
      }
    }
  });

  const noHighlightDomainIndexes = domainIndexes
    .filter(({ isHighlited }) => !isHighlited)
    .map(({ domainIndex }) => domainIndex);

  const noHighlightRanges: [number, number][] = [];

  let rangeStart = noHighlightDomainIndexes[0];

  noHighlightDomainIndexes.forEach((domainInd, ind, arr) => {
    const nextDomainInd = arr[ind + 1];

    if (domainInd + 1 !== nextDomainInd || ind === arr.length - 1) {
      const start = rangeStart;
      const end = domainInd + 2;

      noHighlightRanges.push([
        start < 0 ? start + 1 : start,
        end > domainLength - 1 ? end : end,
      ]);

      rangeStart = nextDomainInd;
    }
  });

  return noHighlightRanges;
};

const getNowDomainIndex = (domainValues: string[]) => {
  const now = new Date();
  const currentYear = now.getFullYear();
  return domainValues?.findIndex((val: string) => val === String(currentYear));
};

function buildManualHighlightPositions(
  highlight: Highlight,
  domainValues: string[],
) {
  if (!highlight) {
    return;
  }

  // Will pass if fixed or undefined
  if (highlight.source && highlight.source !== 'fixed') {
    return;
  }

  if (!highlight.start || !highlight.end) {
    return;
  }

  let startRange;
  if (highlight.start === DOMAIN_POINT.ASTERIX) {
    startRange = 0;
  } else if (highlight.start === DOMAIN_POINT.NOW) {
    startRange = getNowDomainIndex(domainValues);
  } else {
    startRange = domainValues?.findIndex(
      (val: string) => val === highlight.start,
    );
  }

  let endRange;
  if (highlight.end === DOMAIN_POINT.ASTERIX) {
    endRange = domainValues.length - 1;
  } else if (highlight.end === DOMAIN_POINT.NOW) {
    endRange = getNowDomainIndex(domainValues);
  } else {
    endRange = domainValues?.findIndex((val: string) => val === highlight.end);
  }

  if ((startRange ?? -1) === -1) {
    return;
  }

  if ((endRange ?? -1) === -1) {
    return;
  }

  return [startRange, endRange + 1];
}

function buildAutomaticHighlightPositions(
  highlight: Highlight,
): number[][] | undefined {
  if (!highlight) {
    return;
  }
  // Will pass if fixed or undefined
  if (highlight.source !== 'automatic') {
    return;
  }

  if (!highlight.column || !highlight.automaticHighlightsMap?.length) {
    return;
  }

  const { ranges } = highlight.automaticHighlightsMap.reduce(
    (
      agg: { prev: number; ranges: [number, number][] },
      isHighlighted: boolean,
      index: number,
    ) => {
      const isLast =
        (highlight.automaticHighlightsMap as boolean[]).length - 1 === index;
      if (isHighlighted && !isLast) {
        agg.prev += 1;

        return agg;
      }

      const isLastAndHighlighted = isHighlighted && isLast;

      // If more than one highlight or one and the last
      if (agg.prev > 1 || (isLastAndHighlighted && agg.prev)) {
        const startPos = index - agg.prev;
        agg.ranges.push([
          startPos,
          // If is the last one do + 1 ( don't know why yet )
          isLast && isHighlighted ? index + 1 : index,
        ]);
      }

      agg.prev = 0;

      return agg;
    },
    { prev: 0, ranges: [] },
  );

  return ranges;
}

export const getHighlightsRanges = (
  highlights: Highlight[],
  domainValues: string[],
) => {
  return [
    ...highlights.map((highlight) => {
      const ranges = buildManualHighlightPositions(highlight, domainValues);

      if (!ranges?.length) {
        return;
      }

      // Must be consistent as with automatic ranges, so its [number, number][]
      return { highlight, ranges: [ranges] };
    }),
    ...highlights.flatMap((highlight) => {
      const ranges = buildAutomaticHighlightPositions(highlight);

      if (!ranges?.length) {
        return;
      }

      return { highlight, ranges };
    }),
  ].filter(Boolean) as HighlightWithRange[];
};

export const getSeriesRanges = (
  highlights: Highlight[],
  domainValues: string[] = [],
): {
  noHighlightRanges: [number, number][];
  highlightRanges: HighlightWithRange[];
} => {
  const highlightRanges = getHighlightsRanges(highlights, domainValues);

  if (!highlightRanges.length) {
    return {
      noHighlightRanges: [[0, domainValues.length]],
      highlightRanges: [],
    };
  }

  const flatRanges = highlightRanges.flatMap((hr) => hr.ranges);

  const noHighlightRanges = getNoHighlightsRanges(
    flatRanges,
    domainValues.length,
  );

  return {
    noHighlightRanges,
    highlightRanges,
  };
};
