import { Fragment } from 'react';
import {
  AssistiveText,
  Field,
  Highlight,
  Input,
  Label,
  Required,
} from './styles';
import { HTMLInputProps } from "@blueprintjs/core";

type Props = {
  label: string;
  error?: boolean;
  assistiveText?: string;
} & HTMLInputProps;

export default function TextInput({
  label,
  error,
  assistiveText,
  required,
  ...props
}: Props) {
  return (
    <Fragment>
      <Field error={error}>
        <Input {...props} type={props.type || 'text'} />
        <Highlight className="highlight" error={error} />
        <Label error={error} disabled={props.disabled}>
          {label}
          {required && <Required disabled={props.disabled}>*</Required>}
        </Label>
      </Field>
      {assistiveText && <AssistiveText>{assistiveText}</AssistiveText>}
    </Fragment>
  );
};