import styled from 'styled-components';
import { RadioGroup } from '@blueprintjs/core';
import { colors } from '../../../utils/colors';

export const SettingsWrapper = styled.div`
  padding: 20px 0;
`;

export const Settings = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Col = styled.div`
  & > * {
    margin-bottom: 15px;
  }

  &:first-child {
    margin-right: 90px;
  }
`;

export const TypeSelector = styled.div`
  display: flex;
  margin-top: 4px;
`;

export const TypeOption = styled.div<{
  isSelected: boolean;
  disabled: boolean;
}>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  margin-right: 4px;
  color: ${({ isSelected, disabled }) => {
    if (disabled) {
      return colors.monochromatic2;
    }

    return isSelected ? colors.primary0 : colors.monochromatic3;
  }};
`;

export const VisibleCheckboxes = styled.div<{ rows: number }>`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(${({ rows }) => rows}, 1fr);
  grid-gap: 10px 20px;
`;

export const Title = styled.div`
  margin-bottom: 7px;
`;

export const MapBgRadioGroup = styled(RadioGroup)`
  display: flex;
  justify-content: space-between;
`;
