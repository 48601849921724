import styled from 'styled-components';
import { Classes, Switch } from '@blueprintjs/core';
import { colors } from '../../utils/colors';

export const CustomizedSwitch = styled(Switch)<{ checked?: boolean }>`
  &&& {
    font-size: 12px;
    color: ${({ checked }) =>
      checked ? colors.primary0 : colors.monochromatic2};

    & input {
      &:focus {
        outline: none;
      }
    }

    & .${Classes.CONTROL_INDICATOR} {
      width: 38px;
      height: 19px;
      background: ${colors.monochromatic3};

      &:focus {
        outline: none;
      }

      &::before {
        height: 14px;
        width: 14px;
        left: 2px;
        transform: translateY(0.5px);
      }
    }

    & input:checked ~ .${Classes.CONTROL_INDICATOR} {
      background: ${colors.primary0};

      &::before {
        left: calc(100% - 19px);
      }
    }
  }
`;
