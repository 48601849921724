import React, { FC, useMemo } from 'react';
import { PageTitle, Props as TitleProps } from './StructureLogo';
import { NavBarWrapper, Spacer } from './styles';
import PopoverLegacy, { Selected } from '../Popover/PopoverLegacy';
import { useEditingContext } from '../../containers/Solutions/Structure/editing';
import { getIsCustomer } from '../../gql/user/local';

export type User = {
  firstName: string;
  lastName: string;
};

type Props = {
  user: User | null | undefined;
  userMenu: Array<Selected>;
  extrasLeft?: Array<React.JSX.Element>;
  extrasCenter?: Array<React.JSX.Element>;
  extrasRight?: Array<React.JSX.Element>;
  onUserMenuAction: (selected: Selected) => void;
} & TitleProps;

const NavBar: FC<Props> = ({
  user,
  userMenu,
  extrasLeft = [],
  extrasCenter = [],
  extrasRight = [],
  onUserMenuAction,
  ...props
}) => {
  const { isCustomerEditorModeEnabled } = useEditingContext();

  const userInitials = useMemo(() => {
    if (!user) {
      return '';
    }

    const { firstName, lastName } = user;

    return `${firstName?.[0] || ''}${lastName?.[0] || ''}`.trim().toUpperCase();
  }, [user]);

  const hideUser = isCustomerEditorModeEnabled && getIsCustomer();

  return (
    <NavBarWrapper {...props} isEdit={isCustomerEditorModeEnabled} id="navbar">
      <PageTitle {...props} />

      {extrasLeft}
      <Spacer />
      {extrasCenter}
      <Spacer />
      {extrasRight}

      {user && !hideUser && (
        <div className="user-nav-buttons u-has-transition">
          <span>{userInitials}</span>
          <PopoverLegacy
            onClick={({ selected }) => onUserMenuAction(selected)}
            selections={userMenu}
            selectedContent={{} as any}
            className="navbar-user-icon"
          />
        </div>
      )}
    </NavBarWrapper>
  );
};

export default NavBar;
